import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RectificationItem } from '@atline/core/models/rectificationItem.Model';
import { Icons } from '@atline-shared/enums/icons.enum';

@Component({
  selector: 'anc-rectificatif-item',
  templateUrl: './anc-rectificatif-item.component.html',
  styleUrls: ['./anc-rectificatif-item.component.scss']
})
export class AncRectificatifItemComponent {

  @Input()
  rectification!: RectificationItem;

  @Output()
  removeRectification = new EventEmitter<string>();

  @Output()
  rectificationChange = new EventEmitter<RectificationItem>();

  openEdit = false;
  icons = Icons;

  remove(): void {
    this.rectificationChange.emit(this.rectification);
  }

  
  rectificationSaved(event: RectificationItem): void {
    this.rectification = event;
    this.rectificationChange.emit(event);
    this.openEdit = !this.openEdit
  }

}
