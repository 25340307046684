<div class="anc-component"
  fxLayout="row wrap"
  fxLayoutAlign="space-around"
  fxLayout.lt-sm="column"
  >
  <ng-container *ngFor="let jal of supports; let iJal = index">
    <div class="block-jal" fxLayout="column nowrap" fxLayoutGap="16px">
      <div fxLayout="row nowrap" fxLayoutGap="16px">
        <div class="container-img">
          <img [src]="bases64[iJal] || DEFAULT_IMG" alt="logo_ent"/>
        </div>
        <div fxLayout="column nowrap"  fxLayoutGap="16px">
          <div fxLayout="row nowrap" fxLayoutAlign="start center">
            <app-round-checkbox></app-round-checkbox>
            {{ jal.denomination }}
          </div>
          <div class="options">
            <mat-radio-group fxLayout="row nowrap" fxLayoutGap="10px" *ngIf="jal.option_logo_available">
              <mat-radio-button>Publication avec logo</mat-radio-button>
              <mat-radio-button>Sans logo</mat-radio-button>
            </mat-radio-group>
            <mat-form-field appearance="outline" *ngIf="jal.option_formule_available">
              <mat-select>
                <mat-option *ngFor="let formule of jal.option_formule_list" [value]="formule.id">
                  {{ formule.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <span class="description" [innerHTML]="jal.description"></span>
        </div>
      </div>
      <div *ngIf="jal.option_lrar_available" fxLayout="row nowrap" fxLayoutAlign="start center">
        <app-round-checkbox></app-round-checkbox>
        Envoyer en e-LRAR
      </div>
    </div>
  </ng-container>
</div>
