<div class="anc-component" fxLayout="column nowrap" style="gap: 10px">
    <div class="actions-marche">
      <div class="action">
        <app-button size="medium" [icon]="Icons.Plus" (click)="addMarche()"></app-button>
        {{'APP.ANNONCES.MARCHES.ADD' | translate | uppercase}}
      </div>
      <div class="action">
        <app-button size="medium" [icon]="Icons.Bin" (click)="removeAllMarche()"></app-button>
        {{'APP.ANNONCES.MARCHES.DELETE_ALL' | translate | uppercase}}
      </div>
    </div>
    <div class="lots-list" fxLayout="column nowrap" fxLayoutGap="20px">
      <app-marche-annonce *ngFor="let marche of marches; let i = index" [marche]="marche"
        (marcheChange)="marcheChange(i, $event)"
        (cancel)="removeMarche(i)"
        (deleted)="removeMarche(i)">
      </app-marche-annonce>
      <div *ngIf="!marches.length" fxLayoutAlign="center center">
        {{'APP.ANNONCES.MARCHES.EMPTY' | translate }}
      </div>
    </div>
  </div>
  