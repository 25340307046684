import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { TOKEN_ANC_BLOCK } from '@atline/core/models/pa/annonce/anc-token-inject.model';
import { ComponentDisplay } from '@atline/core/models/pa/annonce/form-display.model';
import { ManagerPaEditionAnnonceService } from '../../manager-pa-edition-annonce.service';
import { AbstractAncComponent } from '../abstract-anc-component';


@Component({
 selector: 'anc-procedure',
 templateUrl: './anc-procedure.component.html',
 styleUrls: ['./anc-procedure.component.scss']
})
export class AncProcedureComponent extends AbstractAncComponent implements OnInit {

 readonly descriptionFC = this.manager.controls.procedure_principale_carac;
 readonly maxLengthDescription = 6000;
 readonly procedureFC = this.manager.controls.procedure;
 readonly relanceInfructeuxFC = this.manager.controls.is_procedure_relance;

  readonly options = [
    { value: 'comp-dial', libelle: 'Dialogue compétitif' },
    { value: 'comp-tend', libelle: "Appel d'offres concurantiel"},
    { value: 'innovation', libelle: "Partenariat d'innovation"},
    { value: 'neg-w-call', libelle: "Négociée sans mise en concurrence préalable d'un appel à la concurrence / concurentielle avec négociation"},
    { value: 'neg-wo-call', libelle: 'Négociée sans mise en concurrence préalable'},
    { value: 'open', libelle: 'Ouverte'},
    { value: 'oth-mult', libelle: 'Autre procédure en plusieurs étapes'},
    { value: 'oth-single', libelle: 'Autre procédure en une seule étape'},
    { value: 'restricted', libelle: 'Restreinte'},
  ] as const;

   constructor(
        @Inject(TOKEN_ANC_BLOCK) display: ComponentDisplay,
        private readonly manager: ManagerPaEditionAnnonceService,
        { nativeElement }: ElementRef<HTMLElement>,
      ) { super(display, nativeElement); }

 ngOnInit(): void {
    this.initValidators();
 }

 private initValidators(): void {
  this.descriptionFC?.setValidators([this.createValidatorRequired('description'), Validators.maxLength(this.maxLengthDescription)]);
  this.procedureFC?.setValidators([this.createValidatorRequired('procedure')]);
 }

 

}