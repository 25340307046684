import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from './button/button.module';
import { DialogModule } from './dialog/dialog.module';
import { LoadingSpinnerModule } from './loading-spinner/loading-spinner.module';
import { CircleCheckboxModule } from './circle-checkbox/circle-checkbox.module';
import { RoundCheckboxModule } from './round-checkbox/round-checkbox.module';
import { SearchFormModule } from './search-form/search-form.module';
import { MarchesContainerModule } from './marches-container/marches-container.module';

@NgModule({
  declarations: [ ],
  imports: [
    CommonModule,
    ButtonModule,
    DialogModule,
    CircleCheckboxModule,
    RoundCheckboxModule,
    SearchFormModule,
  ],
  exports: [
    ButtonModule,
    DialogModule,
    CircleCheckboxModule,
    LoadingSpinnerModule,
    RoundCheckboxModule,
    SearchFormModule,
    MarchesContainerModule,
  ],
})
export class DesignModule {}
