<app-dialog [dialogRef]="dialogRef">

  <ng-container header>
    {{data.title | translate: data.informations }}
  </ng-container>


  <ng-container body>

    <div [innerHTML]="data.description | translate: data.informations"></div>

  </ng-container>

  <ng-container>
    <div fxLayoutAlign="center center" class="info-dialog-actions">
      <button color="primary" mat-raised-button mat-dialog-close>{{data.actionTitle | translate: data.informations
        }}</button>
    </div>

  </ng-container>

</app-dialog>