<div *ngIf="showProcedures" class="body" [formGroup]="procedureForm" >
    <table class="table">
        <tbody>
            <tr class="first">
                <div class="firsttr" fxLayout="row" fxLayoutGap="5px">
                    <div  fxLayout="row" fxLayoutGap="15px">
                        <button mat-stroked-button class="button proc" (click)="addAllProc()">
                            <div fxLayout="row" fxLayoutGap="35px">
                            <h4>{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.USER_PROC.ADD_ALL' | translate | uppercase }}</h4>
                            <mat-icon [svgIcon]="icons.plus"></mat-icon>
                            </div>
                        </button>
                    
                    </div>
                    <div  fxLayout="row" fxLayoutGap="15px">
                        <button mat-stroked-button class="button proc" (click)="deleteAllProc()">
                            <div fxLayout="row" fxLayoutGap="35px">
                                <h4>{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.USER_PROC.DELETE_ALL' | translate | uppercase }}</h4>
                                <mat-icon class="moins"></mat-icon>
                            </div>
                        </button>
                    </div>
                </div>
                <div class="firsttr" fxLayout="row" fxLayoutGap="5px">
                    <div  fxLayout="row" fxLayoutGap="15px">
                        <mat-form-field
                        class="input-field"
                        appearance="outline"
                        fxFlex="100"
                        >
                            <mat-select (selectionChange)="procedures()" formControlName="type">
                                <mat-option *ngFor="let type of types" [value]="type.value">
                                {{ type.display | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutGap="15px">
                        <mat-form-field
                        class="input-field"
                        appearance="outline"
                        fxFlex="100"
                                                >
                            <mat-select (selectionChange)="procedures()" formControlName="finalite">
                                <mat-option *ngFor="let finalite of finalites" [value]="finalite.value">
                                {{ finalite.display | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </tr>
        </tbody>
    </table>

    <div fxLayout="row" fxLayoutAlign="space-between center" class="header">
        <h3 class="text-bl">
        {{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.USER_PROC.PROC' | translate | uppercase }}
        </h3>
        <h3 class="text-bl">
            {{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.USER_PROC.AUTHORIZED' | translate | uppercase }}
        </h3>
    </div>

    <table class="table">
        <tbody>
            <tr *ngFor="let itemProc of proceduresList">
                <th fxFlex="5">
                    <mat-icon *ngIf="itemProc.autorise" [svgIcon]="icons.check"></mat-icon>
                    <mat-icon *ngIf="!itemProc.autorise" [svgIcon]="icons.plus" class="nonAutorise"></mat-icon>
                </th>
                <th fxFlex="87">
                <h4>{{ itemProc.libelle_procedure }}</h4>
                </th>
                <th>
                    <button class="button others" (click)="checkProc(itemProc)">
                        <mat-icon *ngIf="itemProc.autorise" [svgIcon]="icons.check"></mat-icon>
                        <mat-icon *ngIf="!itemProc.autorise" ></mat-icon>
                    </button>
                </th>
            </tr>

            <h3 *ngIf="empty">{{ 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.GROUPS.EMPTY_PROC' | translate }}</h3>
        </tbody> 
    </table> 
</div>