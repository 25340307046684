<app-dialog [dialogRef]="dialogRef">

  <div header>
    {{'APP.MARCHES_SECURISES.CONSULTATION.DERIVATION.DIALOG.TITLE' | translate: {consultationId: consultationId} }}
  </div>

  <ng-container body>
    <div class="mat-dialog-body">
      <div class="option-title">
        {{'APP.MARCHES_SECURISES.CONSULTATION.DERIVATION.DIALOG.OPTIONS' | translate }}
      </div>
      <div class="options" *ngIf="options" fxLayout="column" fxLayoutGap="8px">
        <div *ngFor="let option of options">
          <ng-container *ngIf="option.visibility">
            <app-round-checkbox [checked]="option.value" [code]="option.key" [name]="option.key"
              (changeState)="option.value = $event">
              <label [for]="option.key">{{'APP.MARCHES_SECURISES.CONSULTATION.DERIVATION.DIALOG.' + option.key |
                translate}}</label>
            </app-round-checkbox>
            <!-- <mat-checkbox class="option" [(ngModel)]="option.value" color="primary">
              
            </mat-checkbox> -->
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>


  <ng-container footer>
    <div fxFlex="100" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" class="mat-dialog-footer">
      <app-button [size]="'auto'"  [mat-dialog-close]="options">
        {{'APP.MARCHES_SECURISES.CONSULTATION.DERIVATION.DIALOG.BUTTON' | translate}}
      </app-button>
      <!-- <button mat-raised-button</button> -->
    </div>
  </ng-container>

</app-dialog>