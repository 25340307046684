import { Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractAncComponent } from '../abstract-anc-component';
import { TOKEN_ANC_BLOCK } from '@atline/core/models/pa/annonce/anc-token-inject.model';
import { ComponentDisplay } from '@atline/core/models/pa/annonce/form-display.model';
import { ManagerPaEditionAnnonceService } from '../../manager-pa-edition-annonce.service';
import { typesStructure } from '@atline/core/models/typesStructure.model';
import { Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, filter, mergeMap, tap } from 'rxjs/operators';
import { ChorusService } from '@atline/core/services/chorus.service';
import { ChorusResponse } from '@atline/core/models/api/responses/chorus.response';

@Component({
  selector: 'anc-chorus',
  templateUrl: './anc-chorus.component.html',
  styleUrls: ['./anc-chorus.component.scss']
})
export class AncChorusComponent extends AbstractAncComponent implements OnInit, OnDestroy {

  private static readonly TYPE_STRUCTURE = typesStructure[0];

  readonly siretFC =      this.manager.controls.fact_siret;
  readonly tvaInterFC =   this.manager.controls.fact_tva_inter;
  readonly enfagementFC = this.manager.controls.fact_noEngagementJuridique;
  readonly isChorusFC =   this.manager.controls.is_CHORUS;

  private siretChange?: Subscription;

  public loadChorus = false;
  public isChorusResponse?: ChorusResponse;
  public error: any;

  constructor(
    @Inject(TOKEN_ANC_BLOCK) display: ComponentDisplay,
    private readonly manager: ManagerPaEditionAnnonceService,
    private readonly chorus: ChorusService,
    { nativeElement }: ElementRef<HTMLElement>,
  ) { super(display, nativeElement); }

  ngOnInit(): void {
    this.siretFC?.setValidators([
      Validators.required,
      Validators.pattern(AncChorusComponent.TYPE_STRUCTURE.pattern || '')
    ]);
    this.enfagementFC?.setValidators(Validators.maxLength(50));

    const sub = {
      next: (res: ChorusResponse) => {
        this.isChorusResponse = res;
        delete this.error;
        this.loadChorus = false;
      },
      error: (err: any) => {
        delete this.isChorusResponse;
        this.error = err;
        this.loadChorus = false;
      }
    } as const;

    if (this.siretFC?.valid) {
      this.loadChorus = true;
      delete this.error;
      delete this.isChorusResponse;
      this.chorus.isChorusUser(this.siretFC.value).subscribe(sub);
    }

    this.siretChange = this.siretFC?.valueChanges.pipe(
      debounceTime(200),
      filter(() => this.siretFC?.valid ?? false),
      tap(() => {
        this.loadChorus = true;
        delete this.error;
        delete this.isChorusResponse;
      }),
      mergeMap(value => this.chorus.isChorusUser(value))
    ).subscribe(sub);
  }

  ngOnDestroy(): void {
    this.siretChange?.unsubscribe();
  }

  haveSiret(): boolean {
    return !!this.composition?.siret;
  }

  haveTvaIntra(): boolean {
    return !!this.composition?.tvaIntra;
  }

  haveEngagement(): boolean {
    return !!this.composition?.engagement && this.isChorusFC?.value === '1';
  }

}
