<div [formGroup]="formGroup" fxLayout="row nowrap" fxLAyoutGap="20px" fxLayoutAlign="start center">
    <mat-radio-group formControlName="formControl" fxLayout="row">
        <mat-radio-button (click)="clickYes.emit()" [value]="1" color="primary" fxLayout="row">
            <span>
                {{'APP.MARCHES_SECURISES.PA.CREATE.YES' | translate | uppercase}}
            </span>
            <ng-content select="[yes]"></ng-content>

        </mat-radio-button>
        <mat-radio-button (click)="clickNo.emit()" [value]="0" color="primary" fxLayout="row">
            <span>
                {{'APP.MARCHES_SECURISES.PA.CREATE.NO' | translate | uppercase}}
            </span>
            <ng-content select="[no]"></ng-content>
        </mat-radio-button>
    </mat-radio-group>
    <button mat-icon-button type="button" (click)="formControl.setValue(null)" *ngIf="displayBin && formControl.value !== null">
        <mat-icon svgIcon="bin"></mat-icon>
    </button>
</div>