import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { RoutingEnum } from '@atline-shared/enums/routing.enum';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) { }

  get firstFragment(): string {
    switch (this.document.location.hostname) {
      case environment.domain.ats:
      case environment.domain.atswww:
      case environment.domain.local:
      case environment.domain.atsPreprod:
        return RoutingEnum.MARCHES_SECURISES;
      case environment.domain.ms:
        return '';
      default:
        return '';
    }
  }
}
