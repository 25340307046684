  <div class="anc-component">
    <app-field-edition *ngIf="isAcceleratedMarketFC"
      [label]="'APP.ANNONCES.PROCEDURE_TYPE.PROCEDURE_ACCELEREE' | translate"
      [required]="isRequired(required?.isAcceleratedMarket)"
      [invalid]="false">
      <span sub-label class="sub-label">BT-106-Procedure</span>
      <div fxLayout="column" fxLayoutAlign="right">
        <app-round-checkbox name="adapted-structure"
          [disabled]="false"
          [checked]="isAcceleratedProcedure"
          (change)="toggleAdaptedStructure()"
          (changeState)="toggleAdaptedStructure()">
        </app-round-checkbox>
      </div>
    </app-field-edition>
    <app-field-edition *ngIf=" isAcceleratedProcedure && descriptionFC"
      [label]="'APP.ANNONCES.PROCEDURE_E16.LABEL1' | translate"
      [required]="controlIsRequired(descriptionFC)"
      [invalid]="descriptionFC.touched && descriptionFC.invalid">
      <span sub-label class="sub-label">BT-1351-Procedure</span>
      <div class="textarea" fxLayout="row" fxLayoutAlign="start end" fxLayoutGap="10px">
        <mat-form-field appearance="outline" class="full-width" fxFlex="85">
        <textarea matInput #descriptionTextArea
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="5"
          cdkAutosizeMaxRows="10"
          [maxlength]="maxLengthDescription"
          [formControl]="descriptionFC"
          class="text-area-not-rised">
        </textarea>
        </mat-form-field>
        <span [inputCounter]="descriptionTextArea" [total]="true" style="white-space: nowrap;"></span>
      </div>
    </app-field-edition>
  </div>
  