import { Component, OnInit, Inject, ElementRef } from '@angular/core';
import { AbstractAncComponent } from '../abstract-anc-component';
import { TOKEN_ANC_BLOCK } from '@atline/core/models/pa/annonce/anc-token-inject.model';
import { ComponentDisplay } from '@atline/core/models/pa/annonce/form-display.model';
import { ManagerPaEditionAnnonceService } from '../../manager-pa-edition-annonce.service';

@Component({
  selector: 'anc-legal-basis-procedure',
  templateUrl: './anc-legal-basis-procedure.component.html',
  styleUrls: ['./anc-legal-basis-procedure.component.scss']
})
export class AncLegalBasisProcedureComponent extends AbstractAncComponent implements OnInit {

  readonly legalBasisProceFC = this.manager.controls.base_juridique;

  isEform = this.manager.isEforms;

  readonly options = [
    { value: '31985R2137', libelle: 'Regulation (CEE) No 2137/85' },
    { value: '32001R2157', libelle: 'Regulation (CE) No 2157/2001'},
    { value: '32003R1435', libelle: 'Regulation (CE) No 1435/2003'},
    { value: '32007R1370', libelle: 'Regulation (CE) No 1370/2007'},
    { value: '32009L0081', libelle: 'Directive 2009/81/CE'},
    { value: '32014L0023', libelle: 'Directive 2014/23/UE'},
    { value: '32014L0024', libelle: 'Directive 2014/24/UE'},
    { value: '32014L0025', libelle: 'Directive 2014/25/UE'},
    { value: '32018R1046', libelle: 'Regulation (UE, Euratom) 2018/1046' },
    { value: 'other', libelle: 'Autre' },
  ] as const;

  constructor(
    @Inject(TOKEN_ANC_BLOCK) display: ComponentDisplay,
    private readonly manager: ManagerPaEditionAnnonceService,
    { nativeElement }: ElementRef<HTMLElement>,
  ) { super(display, nativeElement); }

  ngOnInit(): void {
    this.legalBasisProceFC?.setValidators([this.createValidatorRequired('legalBasisProcedure')]);
  }

}
