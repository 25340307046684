import { Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { TOKEN_ANC_BLOCK } from '@atline/core/models/pa/annonce/anc-token-inject.model';
import { ComponentDisplay } from '@atline/core/models/pa/annonce/form-display.model';
import { ManagerPaEditionAnnonceService } from '../../manager-pa-edition-annonce.service';
import { Departement } from '@atline/core/models/api/responses/department.response';
import { FormArray, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { AbstractAncComponent } from '../abstract-anc-component';

@Component({
  selector: 'anc-department-prestation',
  templateUrl: './anc-department-prestation.component.html',
  styleUrls: ['./anc-department-prestation.component.scss']
})
export class AncDepartmentPrestationComponent extends AbstractAncComponent implements OnInit, OnDestroy {

  readonly departementFC = this.manager.controls.departement_pub;
  readonly departementsFC = this.manager.controls.departement_pub_rappel;
  readonly departementsSelectedFC = new FormArray([]);

  private readonly subs = new Subscription();

  readonly departements: Departement[] = [];

  constructor(
    @Inject(TOKEN_ANC_BLOCK) display: ComponentDisplay,
    private readonly manager: ManagerPaEditionAnnonceService,
    { nativeElement }: ElementRef<HTMLElement>,
  ) { super(display, nativeElement); }

  ngOnInit(): void {

    this.manager.getDepartements().subscribe(d => this.departements.push(...d));

    const departementsAnnonceChange = (value?: string): void => {
      this.departementsSelectedFC.clear();
      if (value) {
        for (const i of value.split(';')) {
          if (i) this.departementsSelectedFC.push(new FormControl(i));
        }
      }
      this.departementsSelectedFC.push(new FormControl());
    };

    departementsAnnonceChange(this.departementsFC?.value);
    const departementPubRappelChange = this.departementsFC?.valueChanges.subscribe(departementsAnnonceChange);
    this.subs.add(departementPubRappelChange);

    const departementsChange = this.departementsSelectedFC.valueChanges.subscribe((values: string[]) => {
      const str = values.filter(i => i?.trim()).join(';');
      this.departementsFC?.setValue(str, { emitEvent: false });
    });
    this.subs.add(departementsChange);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  haveDefaultDepartement(): boolean {
    return !!this.composition?.defaultDepartement;
  }

  haveOtherDepartment(): boolean {
    return !!this.composition?.otherDepartment;
  }

  triggerDepartementsSelect(code?: string): string {
    const departement = _.find(this.departements, ['code_departement', code]);
    if (!departement) { return ''; }
    return departement.code_departement + (departement.nom_departement ? ` - ${departement.nom_departement}` : '');
  }

  updateSelectorDep(): void {
    if (!this.departementsSelectedFC.controls.some(el => _.isEmpty(el.value))) {
      this.departementsSelectedFC.push(new FormControl());
    }
  }

}
