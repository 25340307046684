import * as _ from 'lodash';
import { ComponentDisplay, ParamsComp, ValueParamsComp } from '@atline/core/models/pa/annonce/form-display.model';
import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

export abstract class AbstractAncComponent {

  constructor(readonly display: ComponentDisplay, readonly element: HTMLElement) {
    this.element.classList.add('anc-component');
    this.element.classList.add(_.kebabCase(display.component.name));
    if (this.required) Object.entries(this.required).forEach(([key, value]) => {
      if (value) this.element.classList.add(`anc-component-required-${_.kebabCase(key)}`);
    });

    if (this.composition) Object.entries(this.composition).forEach(([key, value]) => {
      if (value) this.element.classList.add(`anc-component-composition-${_.kebabCase(key)}`);
    })
  }

  get required(): ParamsComp | undefined {
    return this.display.required;
  }

  get composition(): ParamsComp | undefined {
    return this.display.composition;
  }

  get labels(): any | undefined {
    return this.display.labels;
  }

  isRequired(arg: ValueParamsComp | undefined): boolean {
    return _.isBoolean(arg) && arg;
  }

  controlIsRequired(control: AbstractControl): boolean {
    return !!control.validator && !!control.validator({} as AbstractControl)?.required;
  }

  createValidatorRequired(arg: string, ...args: string[]): ValidatorFn {
    const values = [arg, ...args];
    return (control: AbstractControl) => {
      if (this.required) {
        let value: ParamsComp | boolean | undefined = this.required;
        for (const attr of values) {
          value = value[attr];
          if (_.isNil(value) || _.isBoolean(value)) return value ? Validators.required(control) : null;
        }
        return null;
      }
      return null;
    };
  }
}
