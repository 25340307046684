import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DePlateformeType } from '@atline-shared/enums/dePlateforms.enum';
import { Icons } from '@atline-shared/enums/icons.enum';
import { ShareDeData } from '../../../core/services/share-de-data.service';
import { Router } from '@angular/router';
import { RoutingEnum } from '@atline-shared/enums/routing.enum';
import { RoutingService } from '@atline/core/services/routing.service';

@Component({
  selector: 'app-search-form-de',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss']
})
export class SearchFormComponent implements OnInit {

  @Output()
  formData = new EventEmitter();

  searchDeForm!: FormGroup;

  plateforms = [
    { labelKey: 'MS', code: DePlateformeType.MS, isActive: true },
    { labelKey: 'AIFE', code: DePlateformeType.AIFE, isActive: true },
    { labelKey: 'PES', code: DePlateformeType.PES, isActive: true }
  ];

  icons = {
    target: Icons.TargetBlue,
    pin: Icons.Pin,
    arrow: Icons.Arrow2,
    calendar: Icons.Calendar
  };


  constructor(
    private fb: FormBuilder,
    private readonly shareDeData: ShareDeData,
    private readonly router: Router,
    private readonly routingService: RoutingService
  ) { }

  ngOnInit(): void {
    this.shareDeData.resetDe();
    this.searchDeForm = this.fb.group({
      plateform: new FormControl(DePlateformeType.MS),
    });
    this.setFormControl();
  }

  get displaySiretInput(): boolean {
    return true;
  }

  get displayDepartmentsInput(): boolean {
    return [DePlateformeType.MS, DePlateformeType.AIFE].includes(this.searchDeForm.value.plateform);
  }

  get displayDatePickers(): boolean {
    return [DePlateformeType.MS, DePlateformeType.AIFE].includes(this.searchDeForm.value.plateform);
  }

  get displayObject(): boolean {
    return [DePlateformeType.MS, DePlateformeType.AIFE].includes(this.searchDeForm.value.plateform);
  }

  get isAifePlateform(): boolean {
    return this.searchDeForm.value.plateform === DePlateformeType.AIFE;
  }

  submit(): void {
    this.shareDeData.setParams(this.searchDeForm.value, false);
    this.router.navigate([this.routingService.firstFragment, RoutingEnum.DE, 'list']);
  }

  setFormControl(): void {
    if (this.searchDeForm.value.plateform === DePlateformeType.MS) {
      this.setBaseFormControl();
    }

    if (this.searchDeForm.value.plateform === DePlateformeType.PES) {
      this.setBaseFormControl(false);
      this.searchDeForm.addControl('identifier', new FormControl('', Validators.required));
    }

    if (this.searchDeForm.value.plateform === DePlateformeType.AIFE) {
      this.setBaseFormControl();
      this.searchDeForm.addControl('natureMarket', new FormControl());
      this.searchDeForm.addControl('procedure', new FormControl());
      this.searchDeForm.addControl('cpv', new FormControl());
      this.searchDeForm.addControl('rum', new FormControl());
      this.searchDeForm.addControl('rasionSociale', new FormControl());
    }
  }

  setBaseFormControl(defaultField = true): void {
    this.searchDeForm = this.fb.group({
      plateform: this.searchDeForm.controls.plateform ?? new FormControl(DePlateformeType.MS),
    });


    const isRequired = this.isAifePlateform ? [Validators.required] : [];

    if (defaultField) {
      this.searchDeForm.addControl('startDate', new FormControl('', isRequired));
      this.searchDeForm.addControl('endDate', new FormControl('', isRequired));
      this.searchDeForm.addControl('target', new FormControl());
      this.searchDeForm.addControl('department', new FormControl());
      this.searchDeForm.addControl('identifier', new FormControl('', isRequired));
    }
  }
}
