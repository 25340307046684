import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { ReplaceFunction } from 'lodash';

@Pipe({
  name: 'replaceAll',
})
export class ReplaceAllPipe implements PipeTransform {

  transform(value: string, pattern?: string, replace?: ReplaceFunction | string): string {
    return _.replace(value, new RegExp(pattern ?? '', 'g'), replace ?? '');
  }

}
