<mat-radio-group fxLayoutGap="10px" [disabled]="typeDisabled" [(ngModel)]="type" (ngModelChange)="typeChange.emit($event); onTouch()">
  <mat-radio-button color="primary" [value]="enumTypeCPV.PRINCIPAL">
    {{'APP.MARCHES_SECURISES.CPV.SEARCH_CPV.PRIMARY' | translate}}
  </mat-radio-button>
  <mat-radio-button color="primary" [value]="enumTypeCPV.SECONDAIRE">
    {{'APP.MARCHES_SECURISES.CPV.SEARCH_CPV.SECOND' | translate}}
  </mat-radio-button>
</mat-radio-group>

<mat-form-field appearance="outline">
  <input matInput type="text"
    [(ngModel)]="recherche"
    (ngModelChange)="rechercheChange.emit($event); onTouch()"
    (keyup.enter)="search()"
    [disabled]="rechercheDisabled"/>
  <button type="button" mat-icon-button matSuffix style="cursor: pointer; user-select: none;" (click)="search()" [disabled]="rechercheDisabled">
    <mat-icon>search</mat-icon>
  </button>
</mat-form-field>

<div class="list-cpv" fxLayout="column nowrap">
  <div class="container-items-cpv" #containerItemsCPV>
    <span class="empty-msg" *ngIf="searchFirst && !results?.length">
      {{ 'APP.MARCHES_SECURISES.CPV.SEARCH_CPV.NO-FIND' | translate: { value: lastRecherche } }}
    </span>
    <div class="item-cpv" *ngFor="let cpv of results"
      fxLayout="row nowrap"
      fxLayoutGap="10px"
      (click)="onTouch(); value = cpv; valueChange.emit(cpv); onChange(cpv)">
      <div class="code-cpv" fxFlex="nogrow">{{cpv.code_cpv}}</div>
      <small class="label-cpv" fxFlex="grow">{{cpv.label_cpv}}</small>
    </div>
  </div>
</div>
