import { Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { Icons } from '@atline-shared/enums/icons.enum';
import { SoumissionnaireAnnonce } from '@atline/core/models/api/responses/annonce/soumissionnairesList.response';
import * as _ from 'lodash';
import { AbstractAncComponent } from '../abstract-anc-component';
import { Subscription } from 'rxjs';
import { TOKEN_ANC_BLOCK } from '@atline/core/models/pa/annonce/anc-token-inject.model';
import { ComponentDisplay } from '@atline/core/models/pa/annonce/form-display.model';
import { ManagerPaEditionAnnonceService } from '../../manager-pa-edition-annonce.service';
import { AnnoncesService } from '@atline/core/services/annonces.service';
import { Debounce } from '@atline/core/decorators/debounce.decorator';
import { DialogsService } from '@atline/core/services/dialogs.service';

@Component({
  selector: 'app-anc-soumissionnaires',
  templateUrl: './anc-soumissionnaires.component.html',
  styleUrls: ['./anc-soumissionnaires.component.scss']
})
export class AncSoumissionnairesComponent extends AbstractAncComponent implements OnInit, OnDestroy {

  private readonly subs = new Subscription();
  readonly soumissionnaires: (SoumissionnaireAnnonce | undefined)[] = [];
  readonly Icons = Icons;


  constructor(
    @Inject(TOKEN_ANC_BLOCK) display: ComponentDisplay,
    private readonly manager: ManagerPaEditionAnnonceService,
    private readonly dialogService: DialogsService,
    private readonly annoncesService: AnnoncesService,
    { nativeElement }: ElementRef<HTMLElement>,
  ) {
    super(display, nativeElement);
  }

  ngOnInit(): void {
    const soumissionnairesChange = this.manager.soumissionnairesChange.subscribe(soumissionnaires => {
      this.soumissionnaires.length = 0;
      this.soumissionnaires.push(...soumissionnaires.filter(s => !_.isNil(s.cle)));
    });
    this.subs.add(soumissionnairesChange);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  addSoumissionnaire(): void {
    this.dialogService.openInputFieldDialog('APP.DIALOG.INPUT_FIELD.SOUMISSIONNAIRE.LABEL', 'APP.DIALOG.INPUT_FIELD.SOUMISSIONNAIRE.TITLE', (res) => {
      if (this.manager.cle_annonce && res)
          this.annoncesService.editSoumissionnaire({ cle_annonce: this.manager.cle_annonce, cle_etab: this.manager.cleEtab, cle_pa: this.manager.clePa, nom_partie_soumissionnaire: res }).subscribe({
            next: (res) => {
              this.soumissionnaires.push(res);
            },
            error: () => {
              this.dialogService.createInfoDialog('Erreur', 'La creéation d\'un soumissionnaire a échoué. Veuillez réessayer ultérieurement.', 'OK');
            }
          })
    })

    // if (!this.soumissionnaires.some(_.isNil)) {
    //   this.soumissionnaires.push(undefined);
    // }
  }

  soumissionnaireChange(index: number, soumissionnaire: SoumissionnaireAnnonce): void {
    this.soumissionnaires[index] = soumissionnaire;
    this.syncSoumissionnaireArray();
  }

  removeAllSoumissionnaires(): void {
    if (!this.manager.cle_annonce) return;
    for (const soumissionnaire of this.soumissionnaires) {
      if (soumissionnaire && soumissionnaire.cle) {
        this.annoncesService.deleteSoumissionnaire({
          cle_pa: this.manager.clePa,
          cle_etab: this.manager.cleEtab,
          cle_soumissionnaire: soumissionnaire.cle,
          cle_annonce: this.manager.cle_annonce || ''
        }).subscribe(() => {
          const index = _.findIndex(this.soumissionnaires);
          this.removeSoumissionnaire(index);
        });
      }
    }
  }

  removeSoumissionnaire(index: number): void {
    this.soumissionnaires.splice(index, 1);
    if (index > -1 && index < this.manager.soumissionnaires.length) this.syncSoumissionnaireArray();
  }

  @Debounce(100)
  private syncSoumissionnaireArray(): void {
    this.manager.soumissionnaires.length = 0;
    this.manager.soumissionnaires.push(...this.soumissionnaires.filter(value => !_.isNil(value)) as SoumissionnaireAnnonce[]);
    this.manager.refreshVerifyRulesCall();
  }
}
