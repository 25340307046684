import { Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { Icons } from '@atline-shared/enums/icons.enum';
import { AttributionAnnonce } from '@atline/core/models/api/responses/annonce/attributionsList.response';
import * as _ from 'lodash';
import { AbstractAncComponent } from '../abstract-anc-component';
import { TOKEN_ANC_BLOCK } from '@atline/core/models/pa/annonce/anc-token-inject.model';
import { ManagerPaEditionAnnonceService } from '../../manager-pa-edition-annonce.service';
import { ComponentDisplay } from '@atline/core/models/pa/annonce/form-display.model';
import { Subscription } from 'rxjs';
import { Debounce } from '@atline/core/decorators/debounce.decorator';
import { AnnoncesService } from '@atline/core/services/annonces.service';
import { DialogsService } from '@atline/core/services/dialogs.service';

@Component({
  selector: 'app-anc-results-lots',
  templateUrl: './anc-results-lots.component.html',
  styleUrls: ['./anc-results-lots.component.scss']
})
export class AncResultsLotsComponent extends AbstractAncComponent implements OnInit, OnDestroy {

  private readonly subs = new Subscription();
  readonly attributions: (AttributionAnnonce | undefined)[] = [];
  readonly Icons = Icons;

  constructor(
    @Inject(TOKEN_ANC_BLOCK) display: ComponentDisplay,
    private readonly manager: ManagerPaEditionAnnonceService,
    private readonly dialogService: DialogsService,
    private readonly annoncesService: AnnoncesService,
    { nativeElement }: ElementRef<HTMLElement>,
  ) {
    super(display, nativeElement);
  }
  ngOnInit(): void {
    const attributionsChange = this.manager.attributionsChange.subscribe(attributions => {
      this.attributions.length = 0;
      this.attributions.push(...attributions);
    });
    this.subs.add(attributionsChange);

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  addAttribution(): void {
    if (this.manager.cle_annonce)
      this.annoncesService.editAttribution({ cle_annonce: this.manager.cle_annonce, cle_etab: this.manager.cleEtab, cle_pa: this.manager.clePa }).subscribe({
        next: (res) => {
          this.attributions.push(res);
        },
        error: () => {
          this.dialogService.createInfoDialog('Erreur', 'La creéation d\'une offre a échoué. Veuillez réessayer ultérieurement.', 'OK');
        }
      })
  }

  attributionChange(index: number, attribution: AttributionAnnonce): void {
    this.attributions[index] = attribution;
    this.syncAttributionArray();
  }

  removeAllAttributions(): void {
    if (!this.manager.cle_annonce) return;
    for (const attribution of this.attributions) {
      if (attribution) {
        this.annoncesService.deleteAttribution({
          cle_pa: this.manager.clePa,
          cle_etab: this.manager.cleEtab,
          cle_attribution: attribution.cle_attribution,
          cle_annonce: this.manager.cle_annonce || ''
        }).subscribe(() => {
          const index = _.findIndex(this.attributions);
          this.removeAttribution(index);
        });
      }
    }
  }

  removeAttribution(index: number): void {
    this.attributions.splice(index, 1);
    if (index > -1 && index < this.manager.attributions.length) this.syncAttributionArray();
  }

  @Debounce(100)
  private syncAttributionArray(): void {
    this.manager.attributions.length = 0;
    this.manager.attributions.push(...this.attributions.filter(value => !_.isNil(value)) as AttributionAnnonce[]);
    this.manager.refreshVerifyRulesCall();
  }
}
