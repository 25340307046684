<div class="anc-component" *ngIf="composition">
  <app-field-edition *ngIf="composition.description && descriptionFC"
    [label]="'APP.ANNONCES.PROCEDURE_E16.LABEL5' | translate"
    [required]="controlIsRequired(descriptionFC)"
    [invalid]="descriptionFC.touched && descriptionFC.invalid">
    <span class="sub-label" sub-label>BT-300-Procedure</span>
    <div class="textarea" fxLayout="row" fxLayoutAlign="start end" fxLayoutGap="10px">
      <mat-form-field appearance="outline" class="full-width" fxFlex="85">
        <textarea matInput #descriptionTextArea
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="5"
          cdkAutosizeMaxRows="10"
          [maxlength]="maxLengthDescription"
          [formControl]="descriptionFC"
          class="text-area-not-rised">
        </textarea>
      </mat-form-field>
      <span [inputCounter]="descriptionTextArea" [total]="true" style="white-space: nowrap;"></span>
    </div>
  </app-field-edition>
</div>
