<div>
    <div fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="10px">
        <a atlineLink (click)="addRectification()">Ajouter une rectification</a>
        <a atlineLink (click)="removeAll()">Supprimer touts les rectifications</a>
    </div>
    <div>
        <ng-container *ngFor="let rectification of rectifications">
          <anc-rectificatif-item [rectification]="rectification"
            (removeRectification)="removeRectification(rectification)"
            (rectificationChange)="udapteRectificationOnArray($event)"></anc-rectificatif-item>
        </ng-container>
        
       
    </div>
</div>