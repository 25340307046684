import { Pagination } from '../../pagination.model';
import { NutsItem } from './nuts.response';

export class NutsListResponse {
  // tslint:disable:variable-name
  nuts_list: NutsItem[] = [];
  nb_total = 0;
  pagination = new Pagination();
  // tslint:enable:variable-name
}
