<div class="anc-component" *ngIf="composition" gdColumns="1fr 1fr" gdColumns.lt-md="1fr" gdGap="0px 20px">
    <div fxLayout="column" fxLayoutAlign="stretch stretch">
        <app-field-edition *ngIf="valTousMarchesAttribFC && composition?.valMarchesAtt"
          [label]="'APP.ANNONCES.RESULTAT.VAL-MARCHES-ATTRIB' | translate"
          [required]="false" [invalid]="false">
          <span class="sub-label" sub-label>BT-161-NoticeResult</span>
          <mat-form-field appearance="outline">
            <input matInput type="number" [formControl]="valTousMarchesAttribFC"/>
          </mat-form-field>
      </app-field-edition>
      <app-field-edition *ngIf="valMaxAccordCadresFC && composition?.valMaxAccord"
        [label]="'APP.ANNONCES.RESULTAT.VAL-MAX-CADRES' | translate"
        [required]="false" [invalid]="false">
        <span class="sub-label" sub-label>BT-118-NoticeResult</span>
        <mat-form-field appearance="outline">
          <input matInput type="number" [formControl]="valMaxAccordCadresFC"/>
        </mat-form-field>
      </app-field-edition>
      <app-field-edition *ngIf="valApproxAccordCadresFC && composition?.valApproxAccord"
          [label]="'APP.ANNONCES.RESULTAT.VAL-APPROX-CADRES' | translate"
          [required]="false" [invalid]="false">
          <span class="sub-label" sub-label>BT-1118-NoticeResult</span>
          <mat-form-field appearance="outline">
            <input matInput type="number" [formControl]="valApproxAccordCadresFC"/>
          </mat-form-field>
      </app-field-edition>
    </div>
    <div fxLayout="column" fxLayoutGap="65px">
        <app-field-edition *ngIf="composition?.currency && tousDeviseFC"
        [label]="'APP.ANNONCES.LOTS.CURRENCY' | translate"
        [required]="false" [invalid]="false">
        <mat-form-field appearance="outline">
            <mat-select [formControl]="tousDeviseFC">
              <mat-option *ngFor="let devise of currencies" [value]="devise.iso">
                {{ devise.name }} - {{ devise.country }}
              </mat-option>
            </mat-select>
          </mat-form-field>
      </app-field-edition>
      <div class= "devise2">
        <app-field-edition *ngIf="composition?.currency && maxDeviseFC"
          [label]="'APP.ANNONCES.LOTS.CURRENCY' | translate"
          [required]="false" [invalid]="false">
          <mat-form-field appearance="outline">
              <mat-select [formControl]="maxDeviseFC">
                <mat-option *ngFor="let devise of currencies" [value]="devise.iso">
                  {{ devise.name }} - {{ devise.country }}
                </mat-option>
              </mat-select>
            </mat-form-field>
        </app-field-edition>
      </div>
      <div>
        <app-field-edition *ngIf="composition?.currency && approxDeviseFC"
          [label]="'APP.ANNONCES.LOTS.CURRENCY' | translate"
          [required]="false" [invalid]="false">
          <mat-form-field appearance="outline">
              <mat-select [formControl]="approxDeviseFC">
                <mat-option *ngFor="let devise of currencies" [value]="devise.iso">
                  {{ devise.name }} - {{ devise.country }}
                </mat-option>
              </mat-select>
            </mat-form-field>
        </app-field-edition>
      </div>
  </div>
</div>