<div class="anc-component" fxLayout="column nowrap">
  <app-field-edition *ngIf="haveDefaultDepartement() && departementFC"
    [label]="'APP.ANNONCES.DEPARTMENT_PUB.DEP_TITLE' | translate"
    [required]="isRequired(required?.defaultDepartement)"
    [invalid]="false">
    <mat-form-field appearance="outline">
      <mat-select [formControl]="departementFC">
        <mat-option *ngFor="let dept of departements" [value]="dept.code_departement">
          {{dept.code_departement}} - {{dept.nom_departement}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <span class="text-it">{{ 'APP.ANNONCES.DEPARTMENT_PUB.INFO.MORE_FEES' | translate }}</span>
  </app-field-edition>

  <app-field-edition *ngIf="haveOtherDepartment() && departementsFC"
    [label]="'APP.ANNONCES.DEPARTMENT_PUB.OTHER_DEPT' | translate"
    [required]="isRequired(required?.otherDepartment)"
    [invalid]="false">
    <div fxLayout="row wrap" fxLayoutGap="10px">
      <div *ngFor="let control of departementsSelectedFC.controls; let index = index"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
        class="departement input-btn">
        <mat-form-field appearance="outline">
          <mat-select [formControl]="$any(control)" (selectionChange)="updateSelectorDep()">
            <ng-container *ngFor="let departement of departements">
              <ng-container *ngLet="triggerDepartementsSelect(departement.code_departement) as triggerDepartement">
                <mat-option #optionDep
                  [value]="departement.code_departement"
                  *ngLet="triggerDepartement | replaceAll: ' ' | lowercase as strDepartement"
                  [disabled]="departementsSelectedFC.value.includes(optionDep.value)"
                  [id]="'departement-option-' + strDepartement"
                  [ngClass]="['departement-option', strDepartement]">
                  {{ triggerDepartement }}
                </mat-option>
              </ng-container>
            </ng-container>
          </mat-select>
        </mat-form-field>
        <button [style.visibility]="control.value ? 'visible' : 'hidden'" mat-icon-button
          (click)="departementsSelectedFC.removeAt(index)" fxFlex="5">
          <mat-icon svgIcon="bin"></mat-icon>
        </button>
      </div>
    </div>
    <span class="text-it">{{ 'APP.ANNONCES.DEPARTMENT_PUB.DISPLAY_DEPARTMENT' | translate }}</span>
  </app-field-edition>
</div>
