import { Pagination } from './../../pagination.model';
import { CpvItem } from './../../cvpItem.model';

export class GetCPVCodeResponse {

    // tslint:disable:variable-name


    public cpv_list: CpvItem[] = [new CpvItem()];

    /**
     * nb total code cpv retrieved
     */
    public nb_total = 0;

    /**
     * pagination for API response
     */
    public pagination: Pagination = new Pagination();

    // tslint:enable:variable-name

}
