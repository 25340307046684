<div class="anc-component" [ngSwitch]="template" fxLayout="column">
  <ng-container *ngSwitchCase="'full'">
    <app-field-edition *ngIf="composition?.infoData && infoComp"
      [label]="'APP.ANNONCES.JURIDIC-BASE.DESCRIP_BASEJ'| translate"
      [required]="isRequired(required?.infoData)"
      [invalid]="false">
      <span class="sub-label" sub-label>BT-01(f)-Procedure</span>
      <div class="textarea" fxLayout="row">
        <mat-form-field appearance="outline" fxFlex="90">
            <textarea matInput
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="5"
              cdkAutosizeMaxRows="10"
              [formControl]="infoComp"
              class="text-area-not-rised">
            </textarea>
        </mat-form-field>
      </div>
    </app-field-edition>
  </ng-container>

  <ng-container *ngSwitchCase="'partiel'">
    <div class="textarea" fxLayout="row">
      <mat-form-field appearance="outline" fxFlex="90">
          <textarea matInput
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="5"
            cdkAutosizeMaxRows="10"
            class="text-area-not-rised">
          </textarea>
      </mat-form-field>
    </div>
  </ng-container>
</div>
