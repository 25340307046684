import { catchError } from 'rxjs/operators';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiErrorResponse } from '@atline/core/models/api/responses/api.response';
import { Attributaire } from '@atline/core/models/api/responses/attributaire.reponse';
import { AttributaireService } from '@atline/core/services/attributaires.service';
import { throwError } from 'rxjs';
import * as _ from 'lodash';
import { ConsultationsService } from '@atline/core/services/consultations.service';
import { ConsultationLotsListRequest } from '@atline/core/models/api/requests/consultationLotsList.request';
import { Lot } from '@atline/core/models/api/responses/consultationLotsList.reponse';
import { ConsulterTitulaireRequest } from '@atline/core/models/api/requests/consultation.request';
import { Router } from '@angular/router';
import { RoutingService } from '@atline/core/services/routing.service';

interface DataDialog {
  cleDce: string;
  cleEtab: string;
  clePa: string;
  hasLot: boolean;
  groups: Array<any>;
  codeRestreinte: string;
}

interface AttributairesExtended extends Attributaire {
  isSelected: boolean;
}

@Component({
  selector: 'app-consultation-holder',
  templateUrl: './consultation-holder.component.html',
  styleUrls: ['./consultation-holder.component.scss']
})
export class ConsultationHolderComponent implements OnInit {

  private attributaire?: Array<AttributairesExtended>;
  public lots?: Array<Lot>;
  private errors: { attributaire?: ApiErrorResponse } = {};
  private loading = { attributaires: false, lots: false, sendData: false };
  public attributairesFiltered: Array<AttributairesExtended> = [];
  public groups: Array<any> = [];

  public lotSelected!: string;

  constructor(
    private readonly router: Router,
    private readonly attributaireService: AttributaireService,
    private readonly consultationService: ConsultationsService,
    public dialogRef: MatDialogRef<ConsultationHolderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DataDialog,
    private readonly routingService: RoutingService
  ) { }

  ngOnInit(): void {
    this.loading.attributaires = true;
    this.attributaireService.list({
      cle_dce: this.data.cleDce,
      cle_etab: this.data.cleEtab,
      cle_pa: this.data.clePa
    }).pipe(
      catchError(err => {
        this.loading.attributaires = false;
        const apiError = err.error as ApiErrorResponse;
        if (apiError) this.errors.attributaire = apiError;
        return throwError(err);
      })
    ).subscribe(res => {
      this.loading.attributaires = false;
      this.attributaire = res.attributaires as Array<AttributairesExtended>;
      this.attributairesFiltered = this.attributaire.map(a => ({ ...a, isSelected: true, }));
    });

    if (this.data.hasLot) {
      this.loading.lots = true;
      const req: ConsultationLotsListRequest = {
        cle_dce: this.data.cleDce,
        cle_etab: this.data.cleEtab,
      };
      if (this.data.codeRestreinte) req.code_restreinte = this.data.codeRestreinte;
      this.consultationService.consultationLotsList(req).pipe(
        catchError(err => {
          this.loading.lots = false;
          const apiError = err.error as ApiErrorResponse;
          if (apiError) this.errors.attributaire = apiError;
          return throwError(err);
        })
      ).subscribe(resLots => {
        this.loading.lots = false;
        this.lots = _.filter(resLots.lots, (l) => !_.isEmpty(l.libelle_ordre));
      });
    }

    if (this.data.groups.length > 0) {
      this.groups = this.data.groups.map(g => ({ ...g, isSelected: true }));
    }

  }

  get hasAttributaireWhitoutEmail(): boolean {
    return _.filter(
      this.attributairesFiltered,
      (o: Attributaire) => _.isNil(o.email_contact) || _.isEmpty(o.email_contact)
    ).length > 0;
  }

  get hasAtrributaires(): boolean {
    if (this.attributaire)
      return this.attributaire?.length > 0;
    return false;
  }

  get isLoading(): boolean {
    return this.loading.attributaires || this.loading.lots;
  }

  get hasGroups(): boolean {
    return this.data.groups.length > 0;
  }

  changeLots(): void {
    this.attributairesFiltered = _.filter(this.attributaire, ['cle_lot', this.lotSelected]);
    this.attributairesFiltered = this.attributairesFiltered.map(a => ({ ...a, isSelected: true, }));
  }

  sendRequest(): void {
    const request = new ConsulterTitulaireRequest();
    request.cle_etab = this.data.cleEtab;
    request.cle_pa = this.data.clePa;
    request.cle_parent_dce = this.data.cleDce;
    request.cles_attributaires = this.attributairesFiltered.filter(a => a.isSelected === true).map(at => at.cle).join(';');

    if (this.lotSelected)
      request.cle_parent_lot = this.lotSelected;

    request.cles_groupes = this.groups.filter(g => g.isSelected === true).map(gr => gr.cle_gr).join(';');
    this.loading.sendData = true;
    this.consultationService.postConsulterTitulaire(request).pipe(
      catchError(err => {
        this.loading.sendData = false;
        const apiError = err.error as ApiErrorResponse;
        if (apiError) this.errors.attributaire = apiError;
        return throwError(err);
      })
    ).subscribe(resSend => {
      this.loading.sendData = false;
      this.dialogRef.close();
      this.router.navigate([this.routingService.firstFragment, 'pa', 'consultation', resSend.cle_dce, 'edit']);
    });
  }

}
