import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetCPVCodeRequest } from '../models/api/requests/getCPVCode.request';
import { GetCPVCodeResponse } from '../models/api/responses/getCPV.response';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CpvService {

  constructor(private readonly apiService: ApiService) { }

  /**
   * Get list of code CPV
   * @param req request params
   * @returns list of cpv code
   */
  getCodeCPV(req: GetCPVCodeRequest): Observable<GetCPVCodeResponse>{
    return this.apiService.post(GetCPVCodeResponse, req, 'cpvs_list');
  }

}
