import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { AuthenticateService } from '../../../../core/services/authenticate.service';
import { Router } from '@angular/router';
import { GlobalDataService } from 'src/app/core/services/data-service.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-user-item',
  templateUrl: './user-item.component.html',
  styleUrls: ['./user-item.component.scss']
})
export class UserItemComponent implements OnInit {

  public username = '';
  public etablissementName = '';
  public entrepriseName = '';
  public isPa!: Observable<any>;

  constructor(
    private readonly authService: AuthenticateService,
    private readonly router: Router,
    private globalDataService: GlobalDataService,
    private utilsService: UtilsService
  ) {
    this.username = this.authService.getSessionData().user_name;
  }

  ngOnInit(): void {
    this.globalDataService.isLogged.subscribe(res => {
      if (res) {
        this.username = this.authService.getSessionData().user_name;
        this.etablissementName = this.utilsService.getEtablissementName();
        this.entrepriseName = this.utilsService.getEntrepriseName();
      }
    });
  }


  get isOnPaSide(): boolean {
    const urlParsed = this.router.parseUrl(this.router.url).root.children.primary?.segments;
    return !_.isUndefined(_.find(urlParsed, ['path', 'pa']));
  }

  get isLogged(): boolean {
    return this.authService.logged;
  }

  get hasPin(): boolean {
    return true;
  }

  get notificationsLength(): number {
    return 0;
  }

  logout(): void {
    // this.authService.generateTokenForClient().subscribe(res => {

    this.authService.logout();
    // });
  }
}
