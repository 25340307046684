import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'kebabcase'
})
export class KebabcasePipe implements PipeTransform {

  transform(value: string): string {
    return _.kebabCase(value);
  }

}
