<ng-container *ngIf="loading">
    <app-loading-spinner></app-loading-spinner>
</ng-container>


<ng-container *ngIf="error; else elseTemplate">
    Vos données utilisteurs n'ont pas été récupérées. Veillez vous reconnecter.
    <p>Redirection vers la page d'accueil dans {{secondsBeforeRedirection}} sec</p>
</ng-container>
<ng-template #elseTemplate>
</ng-template>
