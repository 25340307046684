<div class="anc-component" fxLayout="row" style="gap: 15px">

  <app-field-edition *ngIf="composition?.value && tvaFC" [label]="'APP.ANNONCES.ESTIMATED_VALUE.LABEL' | translate"
    [required]="isRequired(required?.value)" [invalid]="false">
    <span class="sub-label" sub-label>BT-27-Procedure</span>
    <div fxLayout="row nowrap" fxLayoutAlign="start center" fxLayoutGap="5px">
      <mat-form-field class="input-text-field" appearance="outline">
        <input matInput [formControl]="tvaFC">
      </mat-form-field>
      <mat-form-field appearance="outline" *ngIf="composition?.currency && currencyFC">
        <mat-select [formControl]="currencyFC">
          <mat-option *ngFor="let currency of currencies" [value]="currency.iso">
            {{ currency.name }} - {{ currency.country }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </app-field-edition>

  <app-field-edition *ngIf="composition?.valuemax && tvamaxFC" [label]="'APP.ANNONCES.MAX_VALUE.LABEL' | translate"
    [required]="isRequired(required?.currency)" [invalid]="false">
    <span class="sub-label" sub-label>BT-271-Procedure</span>
    <div fxLayout="row nowrap" fxLayoutAlign="start center" fxLayoutGap="5px">
      <mat-form-field class="input-text-field" appearance="outline">
        <input matInput [formControl]="tvamaxFC">
      </mat-form-field>
      <mat-form-field appearance="outline" *ngIf="composition?.currency && currencymaxFC">
        <mat-select [formControl]="currencymaxFC">
          <mat-option *ngFor="let currency of currencies" [value]="currency.iso">
            {{ currency.name }} - {{ currency.country }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </app-field-edition>
</div>