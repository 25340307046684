export enum ConstEnum {
    DEFAULT_PAGE = 1,
    DEFAULT_NB_PAR_PAGE = 10,
    MARCHE = 'MARCHE',
    PUBLIQUE_CT_ORGA_ASSIMILES = 'PUBLIQUE_CT_ORGA_ASSIMILES',
    PUBLIQUE_CT_ORGA_ASSIMILES_WS_VALUE = 'public',
    POUVOIR_ADJUDICATEUR = 'PA',
    AAPC_CODE = 'aapc',
    CADES_ATT = 'CADESATTACHEE',
    DEFAULT_PASSASSION = 'MPO2',
    DEFAULT_EN_LIGNE = 'MiseEnLigneDossier',
    DEFAULT_DCE_PAYMENT = 'DcePayment',
    MAX_NAME_FILE_LENGTH = 200,
    MAX_SIZE_FILE = 2000000000,
    DEFAULT_SIGN_TOOL = 'FormatSignature',
    AAPC_CODE_LABEL = 'piece_la',
    STEP_BASE_INFO = 0,
    STEP_INFO_GENERAL = 1,
    STEP_MORE_INFO = 2,
    STEP_MODALITE = 3,
    MAX_CONSULTATION_STEP = 4,
    FRANCE_ISO_CODE = 'FR',
    FRANCE_LABEL = 'France',
    ANNUAIRE_BASE_URL_ESTABLISHMENT = 'https://annuaire-entreprises.data.gouv.fr/etablissement/'

}

