import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { AbstractAncComponent } from '../abstract-anc-component';
import { TOKEN_ANC_BLOCK } from '@atline/core/models/pa/annonce/anc-token-inject.model';
import { ComponentDisplay } from '@atline/core/models/pa/annonce/form-display.model';
import { ManagerPaEditionAnnonceService } from '../../manager-pa-edition-annonce.service';

@Component({
  selector: 'anc-support-facultatif-message',
  templateUrl: './anc-support-facultatif-message.component.html',
  styleUrls: ['./anc-support-facultatif-message.component.scss']
})
export class AncSupportFacultatifMessageComponent extends AbstractAncComponent implements OnInit {

  readonly infoJalFC = this.manager.controls.info_jal;

  constructor(
    @Inject(TOKEN_ANC_BLOCK) display: ComponentDisplay,
    private readonly manager: ManagerPaEditionAnnonceService,
    { nativeElement }: ElementRef<HTMLElement>,
  ) { super(display, nativeElement); }

  ngOnInit(): void {
  }

  haveMessage(): boolean {
    return !!this.composition?.message;
  }

}
