import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { ComponentDisplay} from '@atline/core/models/pa/annonce/form-display.model';
import { TOKEN_ANC_BLOCK } from '@atline/core/models/pa/annonce/anc-token-inject.model';
import { AbstractAncComponent } from '../abstract-anc-component';
import { ManagerPaEditionAnnonceService } from '../../manager-pa-edition-annonce.service';
import { Validators } from '@angular/forms';
import * as _ from 'lodash';
import { CurrencyItem } from '@atline/core/models/api/responses/currenciesList.response';

@Component({
  selector: 'app-anc-result',
  templateUrl: './anc-result.component.html',
  styleUrls: ['./anc-result.component.scss']
})
export class AncResultComponent extends AbstractAncComponent implements OnInit {

  
  readonly valTousMarchesAttribFC =  this.manager.controls.valeur_tous_marches;
  readonly valMaxAccordCadresFC =  this.manager.controls.valeur_max_accords_cadres;
  readonly valApproxAccordCadresFC =  this.manager.controls.valeur_approx_accords_cadres;
  readonly tousDeviseFC =  this.manager.controls.valeur_tous_marches_devise;
  readonly maxDeviseFC =  this.manager.controls.valeur_max_accords_cadres_devise;
  readonly approxDeviseFC =  this.manager.controls.valeur_approx_accords_cadres_devise;
  readonly currencies: CurrencyItem[] = [];  
  constructor(
    @Inject(TOKEN_ANC_BLOCK) display: ComponentDisplay,
    private readonly manager: ManagerPaEditionAnnonceService,
    { nativeElement }: ElementRef<HTMLElement>,
  ) { super(display, nativeElement); }
  
  ngOnInit(): void {
    this.manager.getCurrencies().subscribe(c => this.currencies.push(...c));
    this.initValidator();
  }

  private initValidator(): void {
    this.valTousMarchesAttribFC?.setValidators([Validators.maxLength(11), this.createValidatorRequired('valMarchesAtt')]);
    this.valMaxAccordCadresFC?.setValidators([Validators.maxLength(11), this.createValidatorRequired('valMaxAccord')]);
    this.valApproxAccordCadresFC?.setValidators([Validators.maxLength(11), this.createValidatorRequired('valApproxAccord')]);
    
  }

}
