
export class OffreAnnonceItem {
    // tslint:disable:variable-name
    public cle = '';
  
    public cleAnnonce = '';

    public numero = 0;

    public identifiant = '';
    
    public nom = '';

    public organisation: {
        cle: string;
        reference: string;
    } | undefined;

    public isOffreClassee: boolean| null = null;

    public placeListeLaureat = 0;

    public isOffreVariante: boolean| null = null;

    public valeurResultat = 0;

    public valeurResultatDevise = 0;

    public lot: {
        cle: string;
        identifiant: string;
    } | undefined;

    public soumissionnaire: {
        cle: string;
        identifiant: string;
    } | undefined;

    public isSousTraitance: boolean| null = null;

    public valeurSousTraitance = 0;

    public valeurSousTraitanceDevise = 0;

    public descriptionSousTraitance = '';

    public descriptionSousTraitanceDevise = '';

    public pourcentageSousTraitance = 0;

    public isValeurSousTraitanceConnue: boolean| null = null;
    
    public isPourcentageSousTraitanceConnue: boolean| null = null;

    public sousTraitance!: string | null

}