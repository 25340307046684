<div class="container">
    <div class="content">
        <section class="header">
            <h2>{{'APP.MARCHES_SECURISES.RESET_PWD.HEADER' | translate}}</h2>
        </section>

        <section class="body">

            <ng-container *ngIf="!isLoaded; then loadingTemplate; else formTemplate"></ng-container>
            <ng-template #loadingTemplate>
                <div fxLayout="row" fxLayoutAlign="center center">
                    <app-loading-spinner></app-loading-spinner>
                </div>

            </ng-template>
            <ng-template #formTemplate>
                <ng-container *ngIf="error; else elseTemplate">
                    <span [ngSwitch]="error">
                        <p *ngSwitchCase="4041">
                            {{'APP.MARCHES_SECURISES.RESET_PWD.ERRORS.NO_TOKEN' | translate}}
                        </p>
                        <p *ngSwitchCase="4091">
                            {{'APP.MARCHES_SECURISES.RESET_PWD.ERRORS.ALREADY_USED' | translate}}
                        </p>
                        <p *ngSwitchCase="4011">
                            {{'APP.MARCHES_SECURISES.RESET_PWD.ERRORS.EXPIRED' | translate}}
                        </p>
                        <p *ngSwitchCase="2001">
                            {{'APP.MARCHES_SECURISES.RESET_PWD.ERRORS.SUCCESS' | translate}}
                        </p>
                    </span>
                </ng-container>
                <ng-template #elseTemplate>
                    <form [formGroup]="changeMdpForm">
                        <app-field-edition [label]="'APP.MARCHES_SECURISES.RESET_PWD.EMAIL' | translate"
                            [required]="true" [invalid]="emailFC.touched && emailFC.invalid">
                            <mat-form-field appearance="outline" class="full-width">
                                <input matInput [formControl]="emailFC" />
                            </mat-form-field>
                        </app-field-edition>
                        <app-field-edition [label]="'APP.MARCHES_SECURISES.RESET_PWD.PWD' | translate" [required]="true"
                            [invalid]="pwdFC.touched && pwdFC.invalid">
                            <mat-form-field appearance="outline" class="full-width">
                                <input matInput [formControl]="pwdFC" type="password"/>
                            </mat-form-field>
                        </app-field-edition>
                        <app-field-edition [label]="'APP.MARCHES_SECURISES.RESET_PWD.CONFIRM' | translate"
                            [required]="true" [invalid]="confirmPwdFC.touched && confirmPwdFC.invalid && (pwdFC.value != confirmPwdFC.value)">
                            <mat-form-field appearance="outline" class="full-width">
                                <input matInput [formControl]="confirmPwdFC" type="password"/>
                            </mat-form-field>
                        </app-field-edition>
                    </form>
                </ng-template>
            </ng-template>

        </section>
        <div class="container-btn-redirect" fxLayoutAlign="center center" *ngIf="!error && isLoaded">
            <app-button (clickButton)="resetPwd()" [disabled]="!changeMdpForm.valid">{{
                'APP.MARCHES_SECURISES.RESET_PWD.BTN_VALIDATE' | translate }}</app-button>
        </div>
    </div>
</div>