import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TypeConfirmDialog } from '@atline-shared/enums/typeConfirmDialog.enum';

@Component({
  selector: 'app-deleted-confirmation-dialog',
  templateUrl: './deleted-confirmation-dialog.component.html',
  styleUrls: ['./deleted-confirmation-dialog.component.scss']
})
export class DeletedConfirmationDialogComponent implements OnInit {
  public title = '';
  public description = '';
  public informations?: { [name: string]: string | number };

  constructor(public dialogRef: MatDialogRef<DeletedConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.title = data.title || TypeConfirmDialog.DEFAULT;
    this.description = data.description || '';
    this.informations = data.informations;
   }

  ngOnInit(): void {    
  }

  onConfirm(): void {
    this.dialogRef.close(true); // Close the dialog, return true
  }

  onDismiss(): void {
    this.dialogRef.close(false); // Close the dialog, return false
  }

}
