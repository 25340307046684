<div class="anc-component" fxLayout="column">
  <app-field-edition *ngIf="titreFC && composition?.selectBase"
  [label]="'APP.ANNONCES.JURIDIC-BASE.BASEJ' | translate"
  [required]="controlIsRequired(titreFC)"
  [invalid]="titreFC.touched && titreFC.invalid">
  <span class="sub-label" sub-label>BT-01(c)-Procedure</span>
  <mat-form-field appearance="outline">
    <input matInput type="text" [formControl]="titreFC">
  </mat-form-field>
</app-field-edition>
  <ng-container>
    <app-field-edition *ngIf="composition?.infoData && infoComp"
      [label]="'APP.ANNONCES.JURIDIC-BASE.DESCRIP_BASEJ'| translate"
      [required]="isRequired(required?.infoData)"
      [invalid]="false">
      <span class="sub-label" sub-label>BT-01(d)-Procedure</span>
      <div class="textarea" fxLayout="row">
        <mat-form-field appearance="outline" fxFlex="90">
            <textarea matInput
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="5"
              cdkAutosizeMaxRows="10"
              [formControl]="infoComp"
              class="text-area-not-rised">
            </textarea>
        </mat-form-field>
      </div>
    </app-field-edition>
  </ng-container>
</div>
