import * as _ from "lodash";

export default class RequestHelper {

  static formatObjectToStringForRequest(obj: Object): string {
    const arr = [];
    for (const [key, value] of Object.entries(obj)) {
      if (value) {
        arr.push(key);
      }
    }
    return _.join(arr, ',');
  }

  static buildParams(params: { [key: string]: any }): { [key: string]: string } {
    const paramsObj: { [key: string]: any } = {
    };
    for (const [key, value] of Object.entries(params)) {
      if (!_.isNil(value) && key !== 'consultationDate') {

        if (typeof value === 'string') {
          if (value.trim().length > 0)
            paramsObj[key] = value;
        } else {
          if (typeof value === 'boolean') {
            paramsObj[key] = value ? 1 : 0;
          } else {
            paramsObj[key] = value as string;
          }
        }
      }
    }
    return paramsObj;
  }
}
