import { Component, Inject, OnDestroy, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { AbstractAncComponent } from '../abstract-anc-component';
import { TOKEN_ANC_BLOCK } from '@atline/core/models/pa/annonce/anc-token-inject.model';
import { ManagerPaEditionAnnonceService } from '../../manager-pa-edition-annonce.service';
import { ComponentDisplay } from '@atline/core/models/pa/annonce/form-display.model';
import { Observable, Subscription } from 'rxjs';
import { pairwise, filter, map } from 'rxjs/operators';
import * as _ from 'lodash';
import { AbstractControl, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import ResizeObserver from 'resize-observer-polyfill';
import { TranslateService } from '@ngx-translate/core';

const keyLanguages = 'languages';

function createFormLangues(...args: string[]): { [key: string]: AbstractControl } {
  return args.reduce(
    (form, value) => ({ ...form, [value]: new FormControl(false) }),
    {} as { [key: string]: AbstractControl }
  );
}

function formRequired(anc: AbstractAncComponent): ValidatorFn {
  return (control: AbstractControl) => {
    if (anc.isRequired(anc.required?.[keyLanguages])) {
      return _.isObjectLike(control.value) && _.some(Object.values(control.value))
        ? null
        : { required: 'one language should be selected' };
    }
    return null;
  }
}

class ResizeObservable<T extends HTMLElement = HTMLElement> extends Observable<ResizeObserverEntry> {
  private ro!: ResizeObserver;

  constructor(readonly element: T) {
    super(subscriber => {
      this.ro = new ResizeObserver(entries => subscriber.next(entries[0]));
      this.ro.observe(element);
      return (): void => this.ro.unobserve(element);
    });
  }
}

@Component({
  selector: 'anc-conditions-language',
  templateUrl: './anc-conditions-language.component.html',
  styleUrls: ['./anc-conditions-language.component.scss']
})
export class AncConditionsLanguageComponent extends AbstractAncComponent implements OnInit, AfterViewInit, OnDestroy {

  readonly languagesFC = this.manager.controls.conditions_langue;

  @ViewChild('containerCheckbox')
  private containerCheckbox!: ElementRef<HTMLDivElement>;
  private resizeContainerCheckbox!: Observable<number>;

  readonly languages = [
    'FRA',
    'SPA',
    'DAN',
    'DEU',
    'ELL',
    'ENG',
    'ITA',
    'NLD',
    'POR',
    'FIN',
    'SWE',
    'CES',
    'EST',
    'HUN',
    'LAV',
    'LIT',
    'MLT',
    'POL',
    'SLK',
    'SLV',
    'RON',
    'GLE',
    'BUL',
    'AFR',
  ] as const;

  readonly keyAnc = keyLanguages;

  // readonly languagesFC = new FormControl();
  readonly formLanguages = new FormGroup(createFormLangues(...this.languages), { validators: formRequired(this) });

  private readonly subs = new Subscription();

  // @ts-ignore
  public displayNames!: Intl.DisplayNames;

  constructor(
    @Inject(TOKEN_ANC_BLOCK) display: ComponentDisplay,
    private readonly manager: ManagerPaEditionAnnonceService,
    { nativeElement }: ElementRef<HTMLElement>,
    translate: TranslateService
  ) {
    super(display, nativeElement);
    // @ts-ignore
    this.displayNames = new Intl.DisplayNames(translate.currentLang, { type: 'language' });
    // @ts-ignore
    const langChange = translate.onLangChange.asObservable().subscribe(e => this.displayNames = new Intl.DisplayNames(e.lang, { type: 'language' }));
    this.subs.add(langChange);
  }

  ngOnInit(): void {
    this.languagesFC?.setValidators(this.createValidatorRequired(keyLanguages))
    this.languagesFC?.value.split(";").forEach((key: string) => this.formLanguages.get(key)?.setValue(true));
    const conditionsLangueChange = this.languagesFC?.valueChanges.subscribe(value => {
      if (_.isString(value)) value.split(";").forEach(key => this.formLanguages.get(key)?.setValue(true));
      else this.languagesFC?.setValue('', { emitEvent: false });
    });
    this.subs.add(conditionsLangueChange);

    const formLanguagesChange = this.formLanguages.valueChanges.subscribe(values => {
      this.formLanguages.markAsTouched();
      const langs = Object.entries(values).reduce((arr, [key, value]) => value ? [...arr, key] : arr, [] as string[]);
      this.languagesFC?.setValue(langs.join(';'), { emitEvent: false });
    });
    this.subs.add(formLanguagesChange);
  }

  ngAfterViewInit(): void {
    const resize = (n?: number): void => {
      n = n ?? this.containerCheckbox.nativeElement.offsetWidth;
      this.containerCheckbox.nativeElement.style.maxHeight = `${166000 / n}px`;
    }
    this.resizeContainerCheckbox = new ResizeObservable(this.containerCheckbox.nativeElement).pipe(
      map(({ contentRect }) => contentRect.width),
      pairwise(),
      filter(([previous, current]) => previous != current),
      map(([, current]) => current),
    );

    resize();
    const sizeContainerChange = this.resizeContainerCheckbox.subscribe(resize);
    this.subs.add(sizeContainerChange);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  toogleLang(lang: string, value: boolean) {
    for (const [key,] of Object.entries(this.formLanguages.controls)) {
      this.formLanguages.get(key)?.setValue(false);
    }
    this.formLanguages.get(lang)?.setValue(value)
  }
}
