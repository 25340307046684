<div class="anc-component" *ngIf="composition" fxLayout="column" fxLayoutGap="10px">
  <app-field-edition *ngIf="classificationFC && composition.principal"
    [label]="'APP.ANNONCES.CPV-CLASSIFICATION.CLASSIFICATION' | translate"
    [required]="controlIsRequired(classificationFC)"
    [invalid]="classificationFC.touched && classificationFC.invalid">
    <span sub-label>{{ 'APP.ANNONCES.CPV-CLASSIFICATION.TYPE-CLASSIFICATION' | translate }}</span>
    <mat-form-field appearance="outline">
      <mat-select [formControl]="classificationFC">
        <mat-option value="COMMON_PRO_VOCAB">{{ 'APP.ANNONCES.CPV-CLASSIFICATION.COMMON_PRO_VOCAB' | translate }}</mat-option>
      </mat-select>
    </mat-form-field>
  </app-field-edition>

  <app-field-edition *ngIf="haveCpv()"
    [label]="'APP.ANNONCES.MARKET_SPACE.CPV.CPV_CLASS' | translate"
    [required]="haveCpvRequired()"
    [invalid]="false">
      <div fxLayout="row" fxLayoutGap="10px">
        <div fxLayout="column nowrap" fxFlex="50" fxLayoutGap="10px">
          <div fxLayout="column nowrap" *ngIf="composition.cpv && cpvFC">
            <span>{{ 'APP.ANNONCES.CPV-CLASSIFICATION.CPV-PRINCIPAL' | translate }}</span>
            <ng-container *ngIf="composition.cpv && cpvFC" [ngTemplateOutlet]="cpvInput" [ngTemplateOutletContext]="{ $implicit: cpvFC }"></ng-container>
            <span class="hint">{{ 'APP.ANNONCES.CPV-CLASSIFICATION.CPV-PRINCIPAL-EXEMPLE' | translate: { value: '30200000-CB21-JA01-RC03' } }}</span>
            <!-- {{cpvFC.value}} -->
          </div>

          <div fxLayout="column nowrap" *ngIf="composition.cpv1 && cpv1FC || composition.cpv2 && cpv2FC || composition.cpv3 && cpv3FC">
            <span *ngIf="composition.cpv1 && cpv1FC || composition.cpv2 && cpv2FC || composition.cpv3 && cpv3FC">
              {{ 'APP.ANNONCES.CPV-CLASSIFICATION.CPV-SUP' | translate }}
            </span>
            <ng-container *ngIf="composition.cpv1 && cpv1FC" [ngTemplateOutlet]="cpvInput" [ngTemplateOutletContext]="{ $implicit: cpv1FC }"></ng-container>
            <ng-container *ngIf="composition.cpv2 && cpv2FC" [ngTemplateOutlet]="cpvInput" [ngTemplateOutletContext]="{ $implicit: cpv2FC }"></ng-container>
            <ng-container *ngIf="composition.cpv3 && cpv3FC" [ngTemplateOutlet]="cpvInput" [ngTemplateOutletContext]="{ $implicit: cpv3FC }"></ng-container>
          </div>
        </div>
        <list-cpv fxFlex="50" (valueChange)="selectCpvItem($event)"></list-cpv>
      </div>
  </app-field-edition>
</div>

<ng-template #cpvInput let-control>
  <input-code-separator separator=";" [formControl]="control">
    <mat-form-field inputCodeField appearance="outline" fxFlex="50">
      <input matInput type="text" mask="0{8}" >
    </mat-form-field>
    <mat-form-field inputCodeField appearance="outline" fxFlex="15">
      <input matInput uppercaseValue type="text" mask="SS00">
    </mat-form-field>
    <mat-form-field inputCodeField appearance="outline" fxFlex="15">
      <input matInput uppercaseValue type="text" mask="SS00">
    </mat-form-field>
    <mat-form-field inputCodeField appearance="outline" fxFlex="15">
      <input matInput uppercaseValue type="text" mask="SS00">
    </mat-form-field>
  </input-code-separator>
</ng-template>
