<div class="anc-component" *ngIf="composition" [ngSwitch]="template">
  <app-field-edition *ngIf="composition.identifiantInterne && identifiantInterneFC"
    [label]="'APP.ANNONCES.JURIDIC-BASE.IDENTINT' | translate"
    [required]="controlIsRequired(identifiantInterneFC)"
    [invalid]="identifiantInterneFC.touched && identifiantInterneFC.invalid">
    <span class="sub-label" sub-label>BT-22-Procedure</span>
    <mat-form-field appearance="outline">
      <input matInput type="text" [formControl]="identifiantInterneFC">
    </mat-form-field>
  </app-field-edition>

  <app-field-edition *ngIf="composition.titre && titreFC"
    [label]="'APP.ANNONCES.MARKET_SPACE.LABEL' | translate"
    [required]="controlIsRequired(titreFC)"
    [invalid]="titreFC.touched && titreFC.invalid">
    <span class="sub-label" sub-label>BT-21-Procedure</span>
    <mat-form-field appearance="outline">
      <input matInput type="text" [formControl]="titreFC">
    </mat-form-field>
  </app-field-edition>

  <app-field-edition *ngIf="composition.natureContract && natureContractFC"
    [label]="'APP.ANNONCES.MARKET_SPACE.MARKET_TYPE' | translate"
    [required]="controlIsRequired(natureContractFC)"
    [invalid]="natureContractFC.touched && natureContractFC.invalid">
    <span class="sub-label" sub-label>BT-23-Procedure</span>
    <div fxLayout="row" fxLayoutGap="10px"  fxLayoutAlign="start center">
      <mat-radio-group [formControl]="natureContractFC" fxLayout="row" fxLayoutGap="10px">
        <mat-radio-button *ngFor="let key of naturesContract" [value]="'APP.ANNONCES.MARKET_SPACE.TYPE_MARCHE.' + key | translate | lowercase" color="primary" class="radio">
          {{'APP.ANNONCES.MARKET_SPACE.TYPE_MARCHE.' + key | translate}}
        </mat-radio-button>
      </mat-radio-group>
      <button mat-icon-button type="button" *ngIf="!controlIsRequired(natureContractFC)" (click)="natureContractFC.setValue(null)">
        <mat-icon svgIcon="bin"></mat-icon>
      </button>
    </div>
  </app-field-edition>

  <app-field-edition *ngIf="composition.natureContract && additionalNatureContractFC"
    [label]="'APP.ANNONCES.MARKET_SPACE.MARKET_TYPE_ADDITIONAL' | translate"
    [required]="controlIsRequired(additionalNatureContractFC)"
    [invalid]="additionalNatureContractFC.touched && additionalNatureContractFC.invalid">
    <span class="sub-label" sub-label>BT-531-Procedure</span>
    <div fxLayout="row" fxLayoutGap="10px"  fxLayoutAlign="start center">
      <mat-radio-group [formControl]="additionalNatureContractFC" fxLayout="row" fxLayoutGap="10px">
        <mat-radio-button *ngFor="let key of naturesContract" [value]="'APP.ANNONCES.MARKET_SPACE.TYPE_MARCHE.' + key | translate | lowercase" color="primary" class="radio">
          {{'APP.ANNONCES.MARKET_SPACE.TYPE_MARCHE.' + key | translate}}
        </mat-radio-button>
      </mat-radio-group>
      <button mat-icon-button class="icon-radio" type="button" *ngIf="!controlIsRequired(additionalNatureContractFC)" (click)="additionalNatureContractFC.setValue(null)">
        <mat-icon svgIcon="bin"></mat-icon>
      </button>
    </div>
  </app-field-edition>
  <!-- objet du marche -->
  <ng-container *ngSwitchCase="'full'">
    <app-field-edition *ngIf="composition?.infoData && infoComp"
      [label]="'APP.ANNONCES.DESCRIPTION-PROCE.OBJECT'| translate"
      [required]="isRequired(required?.infoData)"
      [invalid]="false">
      <span class="sub-label" sub-label>BT-24-Procedure</span>
      <div class="textarea" fxLayout="row">
        <mat-form-field appearance="outline" fxFlex="90">
            <textarea matInput
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="5"
              cdkAutosizeMaxRows="10"
              [formControl]="infoComp"
              class="text-area-not-rised">
            </textarea>
        </mat-form-field>
      </div>
    </app-field-edition>
  </ng-container>
  <ng-container *ngSwitchCase="'partiel'">
    <div class="textarea" fxLayout="row">
      <mat-form-field appearance="outline" fxFlex="90">
          <textarea matInput
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="5"
            cdkAutosizeMaxRows="10"
            class="text-area-not-rised">
          </textarea>
      </mat-form-field>
    </div>
  </ng-container>
</div>
