import { Pagination } from '../../pagination.model';

export class CurrencyItem {
  // tslint:disable:variable-name
  iso = '';
  name = '';
  country = '';
  // tslint:enable:variable-name
}

export class CurrenciesListResponse {
  // tslint:disable:variable-name
  currencies: CurrencyItem[] = [];
  nb_total = 0;
  pagination = new Pagination();
  // tslint:enable:variable-name
}
