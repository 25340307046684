import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { TOKEN_ANC_BLOCK } from '@atline/core/models/pa/annonce/anc-token-inject.model';
import { ComponentDisplay } from '@atline/core/models/pa/annonce/form-display.model';
import { ManagerPaEditionAnnonceService } from '../../manager-pa-edition-annonce.service';
import { AbstractAncComponent } from '../abstract-anc-component';
import { Validators } from '@angular/forms';

@Component({
  selector: 'anc-info-exclu-acheteur',
  templateUrl: './anc-info-exclu-acheteur.component.html',
  styleUrls: ['./anc-info-exclu-acheteur.component.scss']
})
export class AncInfoExcluAcheteurComponent extends AbstractAncComponent implements OnInit {

  readonly isGroupLeaderFC = this.manager.controls.is_group_leader;
  readonly isCentralPurchasingFC = this.manager.controls.is_central_purchasing_body_public;
  readonly isCentralPurchasingBodySuppliersFC = this.manager.controls.is_central_purchasing_body_supplies_services;

  constructor(
    @Inject(TOKEN_ANC_BLOCK) display: ComponentDisplay,
    private readonly manager: ManagerPaEditionAnnonceService,
    { nativeElement }: ElementRef<HTMLElement>,
  ) { super(display, nativeElement); }

  ngOnInit(): void {
    this.isGroupLeaderFC?.setValidators([this.createValidatorRequired('description')]);
    this.isCentralPurchasingFC?.setValidators([this.createValidatorRequired('description')]);
    this.isCentralPurchasingBodySuppliersFC?.setValidators([this.createValidatorRequired('description')]);

    if (this.required?.isGroupLeader) this.isGroupLeaderFC?.setValidators(Validators.required);
    else this.isGroupLeaderFC?.clearValidators();

    if (this.required?.isCentralPurchasing) this.isCentralPurchasingFC?.setValidators(Validators.required);
    else this.isCentralPurchasingFC?.clearValidators();

    if (this.required?.isCentralPurchasingBodySuppliers) this.isCentralPurchasingBodySuppliersFC?.setValidators(Validators.required);
    else this.isCentralPurchasingBodySuppliersFC?.clearValidators();
  }

}
