import { AccessConsultation, BaseConsultation } from "./BaseConsultation.model";
import { PriceDce, Registre } from "./ConsultationDetailItem.model";
import * as _ from "lodash";
import { ConsultationFinaliteMarche } from "@atline-shared/enums/consultation.enum";
import * as BaseCnnsultation from './BaseConsultation.model';



export class ConsultationPaItem extends BaseConsultation {
  private cle_unpublished_dce!: string;
  private nb_depots!: number;
  private nb_depots_papier!: number;
  private nb_retraits!: number;
  private nb_retraits_papier!: number;
  private cle_pa!: string
  private code_restreinte?: string;
  private infos_tarifs!: string | null;
  private prix!: number;
  private date_envoi_publication!: number;
  private date_envoi_publication_f_s!: string;
  private date_envoi_publication_f!: string;
  private finalite_marche!: ConsultationFinaliteMarche;
  private nb_dce!: number;
  private cle_entreprise!: string;
  private libre_ms!: boolean;
  private type_pa!: string;
  private favoris!: boolean;
  private cao_access!: boolean;
  private signature_obligatoire!: boolean;
  private format_signature!: string;
  private etat_dce!: number;
  private mpsCons!: boolean;
  private mps_id!: string | null;
  private mps_pin!: number | null;
  private hubal_envoi!: string | null;
  private hubal_retour!: string | null;
  private option_mps!: boolean;
  private invisibleCons!: boolean;
  private mail_cloture!: boolean;
  private archiveCons!: boolean;
  private archivage!: boolean;
  private is_cloture!: boolean;


  date_suppression!: number;
  estimationCons!: number;
  dce_groupes!: Array<string>;


  constructor(consultation: any) {
    super(consultation);

    this.etat_dce = consultation.etat_dce;
    this.code_restreinte = consultation.passe;
    this.cle_pa = consultation.cle_pa;
    this.cle_unpublished_dce = consultation.cle_unpublished_dce;
    this.nb_depots = consultation.nb_depots;
    this.nb_depots_papier = consultation.nb_depots_papier;
    this.finalite_marche = consultation.finalite_marche;
    this.nb_retraits = consultation.nb_retraits;
    this.nb_retraits_papier = consultation.nb_retraits_papier;
    this.prix = consultation.prix;
    this.infos_tarifs = !_.isNil(consultation.infos_tarifs) ? !_.isEmpty(consultation.infos_tarifs) ? consultation.infos_tarifs : null : null;
    this.cle_entreprise = !_.isNil(consultation.cle_entreprise) ? !_.isEmpty(consultation.cle_entreprise) ? consultation.cle_entreprise : null : null;
    this.signature_obligatoire = !_.isNil(consultation.signature_obligatoire) ? (consultation.signature_obligatoire == 1) ? true : false : false;
    this.format_signature = !_.isNil(consultation.format_signature) ? !_.isEmpty(consultation.format_signature) ? consultation.format_signature : null : null;
    this.mps_id = !_.isNil(consultation.mps_id) ? !_.isEmpty(consultation.mps_id) ? consultation.mps_id : null : null;
    this.mps_pin = !_.isNil(consultation.mps_pin) ? !_.isEmpty(consultation.mps_pin) ? consultation.mps_pin : null : null;
    this.hubal_envoi = !_.isNil(consultation.hubal_envoi) ? !_.isEmpty(consultation.hubal_envoi) ? consultation.hubal_envoi : null : null;
    this.hubal_retour = !_.isNil(consultation.hubal_retour) ? !_.isEmpty(consultation.hubal_retour) ? consultation.hubal_retour : null : null;
    this.date_envoi_publication = consultation.date_envoi_publication;
    this.date_envoi_publication_f = consultation.date_envoi_publication_f;
    this.date_envoi_publication_f_s = consultation.date_envoi_publication_f_s;
    this.nb_dce = consultation.nb_dce;
    this.libre_ms = !_.isNil(consultation.libre_ms) ? (consultation.libre_ms == 1) ? true : false : false;
    this.favoris = !_.isNil(consultation.favoris) ? (consultation.favoris == 1) ? true : false : false;
    this.cao_access = !_.isNil(consultation.cao_access) ? (consultation.cao_access == 1) ? true : false : false;
    this.option_mps = !_.isNil(consultation.option_mps) ? (consultation.option_mps == 1) ? true : false : false;
    this.is_cloture = !_.isNil(consultation.is_cloture) ? (consultation.is_cloture == 1) ? true : false : false;
    this.invisibleCons = !_.isNil(consultation.invisible) ? (consultation.invisible == 1) ? true : false : false;
    this.mail_cloture = !_.isNil(consultation.mail_cloture) ? (consultation.mail_cloture == 1) ? true : false : false;
    this.archivage = !_.isNil(consultation.archivage) ? (consultation.archivage == 1) ? true : false : false;
    this.archiveCons = !_.isNil(consultation.archive) ? (consultation.archive == 1) ? true : false : false;
    this.option_mps = !_.isNil(consultation.option_mps) ? (consultation.option_mps == 1) ? true : false : false;
    this.option_mps = !_.isNil(consultation.option_mps) ? (consultation.option_mps == 1) ? true : false : false;
    this.mpsCons = !_.isNil(consultation.mps) ? (consultation.mps == 1) ? true : false : false;
    this.type_pa = consultation.type_pa;
    this.date_suppression = consultation.date_suppression;
    this.estimationCons = consultation.estimationCons;
    this.dce_groupes = consultation.dce_groupes;
  }

  get dateSuppression(): DateSuppression {
    return {
      timestamp: this.date_suppression
    }
  }

  get isCloture(): boolean {
    return this.is_cloture;
  }

  get estimation(): number {
    return this.estimationCons;
  }

  get groups(): string[] {
    return this.dce_groupes;
  }

  get mailCloture(): boolean {
    return this.mail_cloture;
  }

  get invisible(): boolean {
    return this.invisibleCons;
  }

  get archive(): Archive {
    return {
      isArchive: this.archiveCons,
      archivage: this.archivage
    }
  }

  get mps(): MpsConsultation {
    return {
      isActive: this.mpsCons,
      pin: this.mps_pin,
      id: this.mps_id,
      option: this.option_mps
    }
  }

  get hubal(): Hubal {
    return {
      envoi: this.hubal_envoi,
      retour: this.hubal_retour
    }
  }

  get etatDce(): number {
    return this.etat_dce;
  }

  get response(): ResponseConsultation {
    return {
      signFormat: this.format_signature,
      mandatorySign: this.signature_obligatoire,
    }
  }

  get isOnFavoris(): boolean {
    return this.favoris
  }

  get caoAccess(): boolean {
    return this.cao_access;
  }

  get typePa(): string {
    return this.type_pa;
  }

  get libreMs(): boolean {
    return this.libre_ms;
  }

  get access(): AccessConsultation {
    return {
      ...this.accessBase,
      code: this.code_restreinte ?? null
    }
  }

  get cleEnt(): string | null {
    return this.cle_entreprise;
  }

  get nbDce(): number {
    return this.nb_dce;
  }

  get finaliteMarche(): ConsultationFinaliteMarche {
    return this.finalite_marche;
  }

  get dateEnvoi(): DateEnvoi {
    return {
      timestamp: this.date_envoi_publication,
      dateFormated: this.date_envoi_publication_f,
      dateFormatedWithSecond: this.date_envoi_publication_f_s
    }
  }

  get price(): PriceDce {
    return {
      infos: this.infos_tarifs,
      price: this.prix
    }
  }

  get unpublishedDceKey(): string {
    return this.cle_unpublished_dce;
  }

  get clePa(): string {
    return this.cle_pa
  }


  get registres(): Registre {
    return {
      depot: {
        electronic: this.nb_depots,
        paper: this.nb_depots_papier
      },
      retrait: {
        electronic: this.nb_retraits,
        paper: this.nb_retraits_papier
      }
    }
  }
}

export interface DateEnvoi {
  timestamp: number;
  dateFormated: string;
  dateFormatedWithSecond: string;
}

export interface DateSuppression {
  timestamp: number;
}

export interface ResponseConsultation extends BaseCnnsultation.ResponseConsultation {
  signFormat: string;
  mandatorySign: boolean;
}

export interface MpsConsultation {
  id: string | null;
  pin: number | null;
  isActive: boolean;
  option: boolean
}

export interface Hubal {
  envoi: string | null;
  retour: string | null;
}

export interface Archive {
  archivage: boolean;
  isArchive: boolean
}
