export enum AnnonceEnum {
  DEFAULT_TARGET_AVIS = 'STANDARD',
  REDUCE_DELAI = 'REDUCTION_DELAI',
  PROCEDURE_ADAPTEE_OUVERTE = 'procedureAdapteeO',
  PROCEDURE_ADAPTEE_RESTREINTE = 'procedureAdapteeR',
  ACCORD_CADRE = 'accordCadre',
  SAD = 'sad',
  QUALIFICATION_SYSTHEM = 'sysQuali',
  CONCOURS = 'concours',
  ELECTRONIC_CATALOG = 'catalogueElec',
  ELECTRONIC_AUCTIONS = 'enchereElec',
  OTHERS = 'autres',
  MANDATORY= 'exigee',
  AUTORIZED = 'autorisee',
  NOT_AUTHORIZED = 'interdite',
  URL_PROFIL_ACHETEUR = 'urlProfilAch',
  URL_DOCUMENT_CONSULTATION = 'urlDocConsul',
  URL_OTHER = 'autre',
  ENTREPRISE_ADAPTE = 'reservationMarche_entrepriseAdaptee',
  INSERTION_STRUCTURE = 'structureInsertion',
  SOCIAL_SERVICES = 'servicesSociaux',

  ETAT_CREATION = 'CREA',
  ETAT_MODELE = 'MO'
}
