<div class="anc-component" fxLayout="column nowrap">
  <app-field-edition *ngIf="haveEnvoieJoue() && envoiJoueFC"
    [label]="'APP.ANNONCES.PUBLICATION.RECEIVER' | translate"
    [required]="isRequired(required?.destinataire)"
    [invalid]="false">
    <mat-radio-group [formControl]="envoiJoueFC" fxLayoutGap="20px">
      <mat-radio-button color="primary" [value]="'0'">
        <div fxLayout="row nowrap" fxLayoutAlign="start stretch">
          <img class="flag" style="margin-right: 10px;" src="/assets/icons/svg/flag-fr-marianne.svg" alt="flag fr" height="20px">
          <span class="text">{{ 'APP.ANNONCES.PUBLICATION.JOUE_BOAMP' | translate }}&nbsp;</span>
          <span class="text text-it">{{ 'APP.ANNONCES.PUBLICATION.INFO.JOUE_BOAMP' | translate }}</span>
        </div>
      </mat-radio-button>
      <mat-radio-button color="primary" [value]="'1'">
        <div fxLayout="row nowrap" fxLayoutAlign="start stretch">
          <img class="flag" style="margin-right: 10px;" src="/assets/icons/svg/flag-ue.svg" alt="flag eu" height="20px">
          <span class="text">{{ 'APP.ANNONCES.PUBLICATION.JOUE' | translate }}&nbsp;</span>
          <span class="text text-it">{{ 'APP.ANNONCES.PUBLICATION.INFO.JOUE' | translate }}</span>
        </div>
      </mat-radio-button>
    </mat-radio-group>
  </app-field-edition>

  <app-field-edition *ngIf="haveBoampAccount() && cleCompteFC"
    [label]="'APP.ANNONCES.PUBLICATION.BOAMP_ACCOUNT' | translate"
    [required]="isRequired(required?.compteBoamp)"
    [invalid]="false">
    <mat-form-field appearance="outline">
      <mat-select [formControl]="cleCompteFC">
        <mat-option *ngFor="let account of accounts" [value]="account.cle_compte">
          {{ account.compte_libelle }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </app-field-edition>

  <app-field-edition *ngIf="haveLanguage() && languageFC"
    [label]="'APP.ANNONCES.PUBLICATION.LANGUAGE' | translate"
    [required]="isRequired(required?.langue)"
    [invalid]="false">
    <div fxLayout="row">
      <mat-form-field appearance="outline">
        <mat-select [formControl]="languageFC">
          <mat-option *ngFor="let lang of langues" [value]="lang">
            {{ 'APP.ANNONCES.LANGUAGE.' + lang | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="text-it" style="margin-left: 20px;">
        {{ 'APP.ANNONCES.PUBLICATION.INFO.LANGUAGE' | translate }}
      </div>
    </div>
  </app-field-edition>

  <app-field-edition *ngIf="havePublish() && dontPublishFC"
    [label]="'APP.ANNONCES.PUBLICATION.PUBLISH_ONLY_JOUE' | translate"
    [required]="isRequired(required?.publish)"
    [invalid]="false">
    <app-round-checkbox [checked]="!!+dontPublishFC.value" (checkedChange)="dontPublishFC.setValue(+$event + '')">
      <div class="text-it">
        {{ 'APP.ANNONCES.PUBLICATION.INFO.PUBLISH_ONLY_JOUE' | translate }}
      </div>
    </app-round-checkbox>
  </app-field-edition>
</div>
