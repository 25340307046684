import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { AbstractAncComponent } from '../abstract-anc-component';
import { ComponentDisplay } from '@atline/core/models/pa/annonce/form-display.model';
import { TOKEN_ANC_BLOCK } from '@atline/core/models/pa/annonce/anc-token-inject.model';
import { ManagerPaEditionAnnonceService } from '../../manager-pa-edition-annonce.service';
import { Validators } from '@angular/forms';
import { CurrencyItem } from '@atline/core/models/api/responses/currenciesList.response';

@Component({
  selector: 'anc-tva-marche',
  templateUrl: './anc-tva-marche.component.html',
  styleUrls: ['./anc-tva-marche.component.scss']
})
export class AncTvaMarcheComponent extends AbstractAncComponent implements OnInit {

  readonly tvaFC = this.manager.controls.estimation_valeur;
  readonly tvamaxFC = this.manager.controls.estimation_valeur_haute;
  readonly currencyFC = this.manager.controls.estimation_devise;
  readonly currencymaxFC = this.manager.controls.estimation_devise;

  readonly currencies: CurrencyItem[] = [];

  constructor(
    @Inject(TOKEN_ANC_BLOCK) display: ComponentDisplay,
    private readonly manager: ManagerPaEditionAnnonceService,
    { nativeElement }: ElementRef<HTMLElement>,
  ) { super(display, nativeElement); }

  ngOnInit(): void {
    this.manager.getCurrencies().subscribe(c => this.currencies.push(...c));

    if (this.required) {
      if (this.required.value) this.tvaFC?.setValidators(Validators.required);
      else this.tvaFC?.clearValidators();

      if (this.required.valuemax) this.tvamaxFC?.setValidators(Validators.required);
      else this.tvamaxFC?.clearValidators();

      if (this.required.currency) this.currencymaxFC?.setValidators(Validators.required);
      else this.currencymaxFC?.clearValidators();

      if (this.required.currency) this.currencyFC?.setValidators(Validators.required);
      else this.currencyFC?.clearValidators();
    }
  }

}
