import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent implements OnInit {

  @Input()
  key?: string;

  @Input()
  size?: number = 75;

  @Input()
  stroke?: number = 5;

  @Input()
  margin?: { top?: number, right?: number, bottom?: number, left?: number }

  constructor() { }

  ngOnInit(): void {
  }

  getMargin(side: 'left' | 'right' | 'bottom' | 'top') {
    switch (side) {
      case 'bottom':
        return this.margin?.bottom ?? 'auto'

      case 'left':
        return this.margin?.left ?? 'auto'

      case 'right':
        return this.margin?.right ?? 'auto';

      case 'top':
        return this.margin?.top ?? 'auto';
    }
  }

}
