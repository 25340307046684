 <div class="anc-component" *ngIf="composition">
  <div fxLayout="column" fxLayoutAlign="right">
    <app-field-edition *ngIf="reservedMarketFC && situationFC"
      [label]="'APP.ANNONCES.CONTRATS.LABEL1' | translate"
      [invalid]="false">
    </app-field-edition>
    
    <div fxLayout="column" fxLayoutAlign="right">
      <app-round-checkbox name="adapted-structure"
        [disabled]="false"
        [checked]="isAdaptedStructureChecked"
        (change)="toggleAdaptedStructure()"
        (changeState)="toggleAdaptedStructure()">
      </app-round-checkbox>
    </div>

    <div *ngIf="isAdaptedStructureChecked">
      <app-field-edition *ngIf="isAdaptedStructureChecked && contactPointFC "
        [required]="controlIsRequired(contactPointFC)"
        [label]="'APP.ANNONCES.PROCEDURE_E16.LABEL9' | translate"
        [invalid]="contactPointFC.touched && contactPointFC.invalid">
        <span class="sub-label" sub-label>BT-09(b)-Procedure</span>
        <mat-form-field appearance="outline">
          <input matInput type="text" [formControl]="contactPointFC"/>
        </mat-form-field>
      </app-field-edition>
    </div>
  </div>
</div>

