import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NutsListResponse } from '../models/api/responses/nutsList.response';
import { ApiService } from './api.service';
import { NutsListRequest } from '../models/api/requests/nutsList.request';

@Injectable({
  providedIn: 'root'
})
export class NutsService {

  constructor(private readonly api: ApiService) { }

  getNuts(request: NutsListRequest = {}): Observable<NutsListResponse> {
    return this.api.post(NutsListResponse, request, 'nuts_list');
  }
}
