import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { UtilsService } from '../services/utils.service';
import { AuthenticateService } from '../services/authenticate.service';
import { RoutingEnum } from '@atline-shared/enums/routing.enum';
import { environment } from 'src/environments/environment';
import { RoutingService } from '../services/routing.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    public router: Router,
    public authenticationService: AuthenticateService,
    public utils: UtilsService,
    private readonly routingService: RoutingService
  ) { }

  // tslint:disable-next-line: variable-name
  canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
    const { role, redirect } = route.data;

    if (route.routeConfig?.path === RoutingEnum.PA && !environment.activation.pa)
      return !this.router.navigate(redirect ?? [this.routingService.firstFragment]);;

    if (this.authenticationService.logged) {
      if (role) { return role !== this.utils.getRole() ? !this.router.navigate(redirect ?? [RoutingEnum.HOME]) : true; }
      return true;
    }
    return !this.router.navigate(redirect ?? [RoutingEnum.HOME]);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(childRoute, state);
  }




}



