import { Component, Inject, OnInit, OnDestroy, ElementRef, Input, EventEmitter, Output } from '@angular/core';
import { AbstractAncComponent } from '../abstract-anc-component';
import { TOKEN_ANC_BLOCK } from '@atline/core/models/pa/annonce/anc-token-inject.model';
import { ComponentDisplay } from '@atline/core/models/pa/annonce/form-display.model';
import { ManagerPaEditionAnnonceService } from '../../manager-pa-edition-annonce.service';
import { TypeCPV } from '@atline-shared/enums/typeCpv.enum';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { CpvItem } from '@atline/core/models/cvpItem.model';
import { LotAnnonce } from '@atline/core/models/api/responses/annonce/lotsList.response';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'anc-cpv-classification',
  templateUrl: './anc-cpv-classification.component.html',
  styleUrls: ['./anc-cpv-classification.component.scss']
})
export class AncCpvClassificationComponent extends AbstractAncComponent implements OnInit, OnDestroy {

  @Input()
  classificationFC = this.manager.controls.classificationtype;

  @Input()
  lot?: LotAnnonce;

  cpvFC?: FormControl;
  cpv1FC?: FormControl;
  cpv2FC?: FormControl;
  cpv3FC?: FormControl;

  @Output() cpvFCChange = new EventEmitter<string>();
  @Output() cpv1FCChange = new EventEmitter<string>();
  @Output() cpv2FCChange = new EventEmitter<string>();
  @Output() cpv3FCChange = new EventEmitter<string>();

  readonly typesCPV = TypeCPV;
  private readonly subs = new Subscription();

  constructor(
    @Inject(TOKEN_ANC_BLOCK) display: ComponentDisplay,
    private readonly manager: ManagerPaEditionAnnonceService,
    { nativeElement }: ElementRef<HTMLElement>,
  ) { super(display, nativeElement); }

  ngOnInit(): void {    

    this.cpvFC = !_.isNil(this.lot) ? new FormControl(this.lot.cpv_1) : this.manager.controls.cpv;
    this.cpv1FC = !_.isNil(this.lot) ? new FormControl(this.lot.cpv_2) : this.manager.controls.cpv_1;
    this.cpv2FC = !_.isNil(this.lot) ? new FormControl(this.lot.cpv_3) : this.manager.controls.cpv_2;
    this.cpv3FC = !_.isNil(this.lot) ? new FormControl(this.lot.cpv_4) : this.manager.controls.cpv_3;

    this.classificationFC?.setValidators([this.createValidatorRequired('principal')]);
    this.cpvFC?.setValidators([this.createValidatorRequired('cpv')]);
    this.cpv1FC?.setValidators([this.createValidatorRequired('cpv1')]);
    this.cpv2FC?.setValidators([this.createValidatorRequired('cpv2')]);
    this.cpv3FC?.setValidators([this.createValidatorRequired('cpv3')]);

    this.subs.add(this.cpvFC?.valueChanges.subscribe(res => this.cpvFCChange.emit(res)));
    this.subs.add(this.cpv1FC?.valueChanges.subscribe(res => this.cpv1FCChange.emit(res)));
    this.subs.add(this.cpv2FC?.valueChanges.subscribe(res => this.cpv2FCChange.emit(res)));
    this.subs.add(this.cpv3FC?.valueChanges.subscribe(res => this.cpv3FCChange.emit(res)));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  isObject(arg: any): boolean {
    return _.isObjectLike(arg);
  }

  selectCpvItem(item: CpvItem | null | undefined): void {
    if (item) navigator.clipboard.writeText(item.code_cpv);
  }

  haveCpv(): boolean {
    const { cpv, cpv1, cpv2, cpv3 } = this.composition ?? {};
    return cpv || cpv1 || cpv2 || cpv3 ? true : false;
  }

  haveCpvRequired(): boolean {
    const { cpv, cpv1, cpv2, cpv3 } = this.required ?? {};
    return cpv || cpv1 || cpv2 || cpv3 ? true : false;
  }
}
