import { Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractAncComponent } from '../abstract-anc-component';
import { TOKEN_ANC_BLOCK } from '@atline/core/models/pa/annonce/anc-token-inject.model';
import { ComponentDisplay } from '@atline/core/models/pa/annonce/form-display.model';
import { ManagerPaEditionAnnonceService } from '../../manager-pa-edition-annonce.service';
import { Validators } from '@angular/forms';
// import { Validators} from '@angular/forms';

const reservationsMarche = ['entrepriseAdaptee', 'structureInsertion', 'servicesSociaux'] as const;
type TypeReservationMarche = typeof reservationsMarche[number];

function isTypeReservationMarche(arg: any): arg is TypeReservationMarche {
  return reservationsMarche.includes(arg);
}

function splitTypeReservationMarche(arg?: string): TypeReservationMarche[] {
  return arg?.split(';').filter(isTypeReservationMarche) || [];
}

@Component({
  selector: 'anc-droits-transf',
  templateUrl: './anc-droits-transf.component.html',
  styleUrls: ['./anc-droits-transf.component.scss']
})
export class AncDroitsTransfComponent extends AbstractAncComponent implements OnInit, OnDestroy {

  readonly situationFC = this.manager.controls.droit_transfrontalier;
  readonly contactPointFC = this.manager.controls.droit_transfrontalier_desc;
  readonly typesSelected = new Set(splitTypeReservationMarche(this.manager.values.reservationMarche));
  readonly reservedMarketFC = this.manager.controls.reservationMarche;

  readonly situationChange = this.situationFC?.valueChanges.subscribe(value => {
    if (!value) this.reservedMarketFC?.setValue(null);
  });

  readonly reservedChange = this.reservedMarketFC?.valueChanges.subscribe(value => {
    this.typesSelected.clear();
    splitTypeReservationMarche(value).forEach(item => this.typesSelected.add(item));
  });

  isCheckboxDisabled: boolean = true;
  isAdaptedStructureChecked = false;


  constructor(
    @Inject(TOKEN_ANC_BLOCK) display: ComponentDisplay,
    private readonly manager: ManagerPaEditionAnnonceService,
    { nativeElement }: ElementRef<HTMLElement>,
  ) { super(display, nativeElement); }

  ngOnInit(): void {
    this.initValidator();
  }

  ngOnDestroy(): void {
    this.reservedChange?.unsubscribe();
  }

  private initValidator(): void {
    this.contactPointFC?.setValidators([Validators.maxLength(400), this.createValidatorRequired('contactPoint')]);
    this.isAdaptedStructureChecked = this.situationFC?.value == '1';
  }

  toggleAdaptedStructure() {
    this.isAdaptedStructureChecked = !this.isAdaptedStructureChecked;
    this.situationFC?.setValue(this.isAdaptedStructureChecked);
  }

  setReserved(type: TypeReservationMarche, remove = false): void {
    if (!this.isCheckboxDisabled) {
      if (remove) {
        this.typesSelected.delete(type);
      } else {
        this.typesSelected.add(type);
      }

      this.reservedMarketFC?.setValue([...this.typesSelected].join(';'), { emitEvent: false });
    }
  }
}
