import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({ selector: 'input[uppercaseValue]' })
export class InputUppercaseDirective {

  private lastValue?: string;
  private readonly inputTarget!: HTMLInputElement;

  constructor(elementRef: ElementRef<HTMLInputElement>) {
    this.inputTarget = elementRef.nativeElement;
  }

  @HostListener('input', ['$event'])
  onInput(event: InputEvent): void {
    console.log('INPUT UPPERCASE', event);
    const target = event.target as HTMLInputElement;
    const { selectionStart, selectionEnd } = target;

    target.value = target.value.toUpperCase();
    target.setSelectionRange(selectionStart ?? 0, selectionEnd ?? 0);
    event.preventDefault();

    if (!this.lastValue || (this.lastValue && target.value.length > 0 && this.lastValue !== target.value)) {
      this.lastValue = this.inputTarget.value = target.value;
      const evt = new InputEvent('input', { bubbles: true, cancelable: true });
      target.dispatchEvent(evt);
    }
  }
}
