<div class="anc-component" fxLayout="column nowrap" style="gap: 10px">
  <div class="actions-organisation">
    <div class="action">
      <app-button size="medium" [icon]="Icons.Plus" (click)="addOrganisation()"></app-button>
      {{'APP.ANNONCES.ORGANISATION.ADD' | translate | uppercase}}
    </div>
    <div class="action">
      <app-button size="medium" [icon]="Icons.Bin" (click)="removeAllOrganisation()"></app-button>
      {{'APP.ANNONCES.ORGANISATION.DELETE_ALL' | translate | uppercase}}
    </div>
  </div>

  <ng-container *ngIf="!isLoading; else spinnerTemplate">
    <div class="lots-list" fxLayout="column nowrap" fxLayoutGap="20px">
      <anc-organisation-eform-item *ngFor="let org of organisations; let i = index" [organisation]="org"
        [required]="required" [composition]="composition" (organisationChange)="organisationChange(i, $event)"
        (cancel)="removeOrganisation(org)" (deleted)="removeOrganisation(org)">
      </anc-organisation-eform-item>
      <div *ngIf="!organisations.length" fxLayoutAlign="center center">
        {{'APP.ANNONCES.ORGANISATION.EMPTY' | translate }}
      </div>
    </div>
  </ng-container>
</div>

<ng-template #spinnerTemplate>
  <div fxLayout="row" fxLayoutAlign="center center">
    <app-loading-spinner [size]="30"></app-loading-spinner>
  </div>
</ng-template>