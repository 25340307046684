import { ApiRequest } from './api.request';

export class ProceduresListRequest extends ApiRequest {
    cle_etab!: string;
    cle_utilisateur?: string;
    cle_group?: string;
    type_pa!: string;
    finalite!: string;

}
