import { Component, OnInit, Inject, ElementRef } from '@angular/core';
import { AbstractAncComponent } from '../abstract-anc-component';
import { TOKEN_ANC_BLOCK } from '@atline/core/models/pa/annonce/anc-token-inject.model';
import { ComponentDisplay } from '@atline/core/models/pa/annonce/form-display.model';
import { ManagerPaEditionAnnonceService } from '../../manager-pa-edition-annonce.service';
import { CpvItem } from '@atline/core/models/cvpItem.model';
import * as _ from 'lodash';

@Component({
  selector: 'anc-description-word',
  templateUrl: './anc-description-word.component.html',
  styleUrls: ['./anc-description-word.component.scss']
})
export class AncDescriptionWordComponent extends AbstractAncComponent implements OnInit {

  readonly motsClef = this.manager.controls.mot_clef;

  readonly descriptionWord: CpvItem[] = [];
  readonly selected: CpvItem[] = [];

  constructor(
    @Inject(TOKEN_ANC_BLOCK) display: ComponentDisplay,
    private readonly manager: ManagerPaEditionAnnonceService,
    { nativeElement }: ElementRef<HTMLElement>,
  ) { super(display, nativeElement); }

  ngOnInit(): void {
    this.manager.getMotsDescripteur().subscribe(md => {
      this.descriptionWord.length = 0;
      this.descriptionWord.push(...md);
      this.getDescriptionFromStr(this.motsClef?.value || '');
    });
  }

  private getDescriptionFromStr(value: string): void {
    this.selected.length = 0;
    if (!value.trim()) return;
    for (const code of value.split(';')) {
      const found = this.descriptionWord.find(({ code_cpv }) => {
        if (_.isNumber(code_cpv)) return code_cpv === +code;
        return code_cpv === code;
      });
      if (found) this.selected.push(found);
    }
  }

  private descriptionsToStr(values: CpvItem[]): void {
    const lastIndex = values.length > 0 ? values.length - 1 : 0;
    const descriptions = values.reduce((str, cpv, index) => {
      const code = cpv.code_cpv + (index !== lastIndex ? ';' : '');
      return str + code;
    }, '');
    this.motsClef?.setValue(descriptions);
  }

  add(cpv: CpvItem): void {
    if (cpv && !_.some(this.selected, ['id', cpv.id])) {
      this.selected.push(cpv);
      this.descriptionsToStr(this.selected);
    }
  }

  remove(index: number): void {
    if (index > -1) {
      this.selected.splice(index, 1);
      this.descriptionsToStr(this.selected);
    }
  }

}
