import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DictAdressRequest } from '@atline/core/models/api/requests/dict-adress-request';
import { DictAdressResponse } from '@atline/core/models/api/responses/dict-adress.response';
import { AnnoncesService } from '@atline/core/services/annonces.service';
import { UtilsService } from '@atline/core/services/utils.service';

@Component({
  selector: 'app-book-grey',
  templateUrl: './book-grey.component.html',
  styleUrls: ['./book-grey.component.scss']
})
export class BookGreyComponent implements OnInit {
  addressForm!: FormGroup;
  readonly cleEtab = this.utils.cleEtab || '';
  readonly cleAnnonceur = this.utils.cleAnnonceur || '';
  cleAnnonce: string | undefined = this.data.cleAnnonce;
  readonly clePa = this.utils.clePa || '';
  isLoading = false;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<BookGreyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private annoncesService: AnnoncesService,
    private readonly utils: UtilsService
  ) { }

  ngOnInit(): void {
    
    console.log('cleAnnonce book-grey comp ', this.cleAnnonce);
    this.initForm();
  }

  initForm() {
    this.addressForm = this.fb.group({
      addressTitle: ['', Validators.required],
      denomination: [''],
      contactPoint: [''],
      serviceOrRecipient: [''],
      buildingZone: [''],
      streetNumber: [''],
      specialMention: [''],
      country: ['FRANCE'],
      email: [''],
      website: [''],
      phone: [''],
      fax: [''],
      siret: [''],
      vat: [''],
      profileClass: ['ddd'],
      city: '',
      postalCode: ''
    });
  }

  onSubmit(): void {
    if (this.addressForm.valid) {
      this.isLoading = true;
      const dictAdressRequest: DictAdressRequest = {
        ...this.addressForm.value,
        cle_etab: this.cleEtab,
        cle_pa: this.clePa,
        cle_annonce: this.cleAnnonce,
        cle_annonceur: this.cleAnnonceur
      };

      console.log('cleEtab ', this.cleEtab);
      console.log('clePa ', this.clePa);
      console.log('cleAnnonceur ', this.cleAnnonceur);
      console.log('cleAnnonce ', this.cleAnnonce);
      
      this.annoncesService.getDictAdressService(dictAdressRequest).subscribe(
        (response: DictAdressResponse) => {
          this.isLoading = false;
          this.dialogRef.close(response);
        },
        (error: any) => {
          this.isLoading = false;
          console.error('Erreur lors de l\'envoi des données:', error);
        }
      );
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
  
}
