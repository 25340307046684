<div class="dialog-container">
  <div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center" class="header">
    <ng-container>
      <h1 class="h1 title"><ng-content select="[header]"></ng-content></h1>
    </ng-container>


    <button mat-icon-button (click)="close()" *ngIf="cross" id="close">
      <mat-icon [svgIcon]="icons.Close"></mat-icon>
    </button>
    <!-- <a *ngIf="cross" atlineLink  class="close"></a> -->
  </div>
  <div mat-dialog-content class="body">
    <ng-content select="[body]"></ng-content>
  </div>

  <div mat-dialog-actions class="footer">
    <ng-content select="[footer]"></ng-content>
  </div>

</div>