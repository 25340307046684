<div class="anc-component" fxLayout="column nowrap" style="gap: 10px">
    <div class="actions-lot">
      <div class="action">
        <app-button size="medium" [icon]="Icons.Plus" (click)="addAttribution()"></app-button>
        {{'APP.ANNONCES.ATTRIBUTIONS.ADD' | translate | uppercase}}
      </div>
      <div class="action">
        <app-button size="medium" [icon]="Icons.Bin" (click)="removeAllAttributions()"></app-button>
        {{'APP.ANNONCES.ATTRIBUTIONS.DELETE_ALL' | translate | uppercase}}
      </div>
    </div>
    <div class="lots-list" fxLayout="column nowrap" fxLayoutGap="20px">
      <app-attribution-annonce *ngFor="let attribution of attributions; let i = index" [attribution]="attribution"
      (attributionChange)="attributionChange(i, $event)"
      (cancel)="removeAttribution(i)"
      (deleted)="removeAttribution(i)">
      </app-attribution-annonce>
      <div *ngIf="!attributions.length" fxLayoutAlign="center center">
        {{"APP.ANNONCES.ATTRIBUTIONS.EMPTY" | translate }}
      </div>
    </div>
</div>
  