import { ApiRequest } from './api.request';

export class DeleteProcedureRequest extends ApiRequest{

  cle_etab!: string
  cle_group?: string
  cle_utilisateur?: string
  linkId?: string 
}

