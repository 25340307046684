export class PartItem {
    cle_annonce!: string;
    cle_pa!: string;
    cle_etab!: string;
    clePartie?: string;
    sad?: boolean;
    additionalInfo?: string;
    internalIdentifier?: string;
    title?: string;
    description?: string;
    procurementPme?: boolean;
    otherRestrictionPlace?: string;
    isProcurementGouv?: boolean;
    additionalInfoPurpose?: string;
    frameworkAgrement?: string;
    reservedParticipationTenderingTerms?: string;
    executionContractFramework?: string;
    ids?: PartiesIdsItem;
    dates?: {
        start?: DatePartItem;
        end?: DatePartItem;
    }
    estimatedValue?: EstimatedValuePartItem;
    cpvs?: {
        principal?: CpvPartItem
        other1?: CpvPartItem
        other2?: CpvPartItem
        other3?: CpvPartItem
        other4?: CpvPartItem
    }
    address?: AddressPartItem;
    duration?: DurationPartItem
    doc?: DocPartItem;
    adHoc?: AdHocPartItme;
}

export class PartiesIdsItem {
    notice?: string;
    partNotice?: string;
    noticePub?: string;
    taxLegislation?: string;
    reviewOrg?: string;
    mediatorPart?: string;
    env?: {
        employ?: EnvEmployeeItem;
        legislation?: EnvLegislationItem;
        tender?: EnvTenderPartItem
    };
    providers?: ProviderPartItem;
}

export class ProviderPartItem {
    fiscal?: string;
    additionalInfo?: string;
    doc?: StaticRange;
    reviewInfo?: string;
}

export class EnvLegislationItem {
    provider?: string;
    url?: string;
    documents?: string;
}

export class EnvEmployeeItem {
    provider?: string;
    url?: string;
    documents?: string;
}

export class DatePartItem {
    date?: string;
    timezone?: string; 
}

export class EnvTenderPartItem {
    recipient?: string;
    evaluator?: string;
}

export class ContactPartItem {
    nature?: StaticRange;
    additionnalNature?: string;
}

export class EstimatedValuePartItem {
    value?: number;
    devise?: string
}

export class CpvPartItem {
    value?: string;
    classification?: string;
}

export class AddressPartItem {
    address1?: string;
    address2?: string;
    address3?: string;
    town?: string;
    codePostal?: string;
    country?: CountryPartItem;
}

export class CountryPartItem {
    subEntity?: string;
    value?: string;
}

export class DurationPartItem {
    value?: string;
    type?: string;
    other?: string;
}

export class DocLangPartItem {
    official?: string;
    unofficial?: string;
}

export class DocPartItem {
    lang?: DocLangPartItem;
    accessProcurementRestricted?: string;
    justifyAccess?: string;
    statut?: string;
    addressProcurement?: string;
    infoRestrictedAvailable?: string;
    ids?: {
        procurement?: string;
    }
}

export class AdHocPartItme {
    name?: string;
    url ?: string;
}