export class SoumissionnairesListAnnonceResponse {
    // tslint:disable:variable-name
    public soumissionnaires: SoumissionnaireAnnonce[] = [];
    public nb_total = 0;
    // tslint:enable:variable-name
}

export class SoumissionnaireAnnonce {
    // tslint:disable:variable-name
    public cle?: string;
  
    public cle_annonce?: string;

    public numero = '';

    public reference?: string;

    public nomPartie = '';

    public organisation?: {
        cle: string;
        reference: string;
    };

    public isChefDeFile?: boolean| null = null;

    public sousTraitant?: {
        cleOrg: string;
        refOrg: string;
    };

    public contractantPrincipal?: {
        cleOrg: string;
        refOrg: string;
    };

}