import { Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractAncComponent } from '../abstract-anc-component';
import { Subscription } from 'rxjs';
import { Icons } from '@atline-shared/enums/icons.enum';
import { ComponentDisplay } from '@atline/core/models/pa/annonce/form-display.model';
import { TOKEN_ANC_BLOCK } from '@atline/core/models/pa/annonce/anc-token-inject.model';
import { ManagerPaEditionAnnonceService } from '../../manager-pa-edition-annonce.service';
import { AnnoncesService } from '@atline/core/services/annonces.service';
import * as _ from 'lodash';
import { Debounce } from '@atline/core/decorators/debounce.decorator';
import { DialogsService } from '@atline/core/services/dialogs.service';
import { OffreAnnonceItem } from '@atline/core/models/OffreAnnonceItem.model';

@Component({
  selector: 'app-anc-offres',
  templateUrl: './anc-offres.component.html',
  styleUrls: ['./anc-offres.component.scss']
})
export class AncOffresComponent extends AbstractAncComponent implements OnInit, OnDestroy {
  
  private readonly subs = new Subscription();

  readonly offres: (OffreAnnonceItem | undefined)[] = [];

  readonly Icons = Icons;

  constructor(
    @Inject(TOKEN_ANC_BLOCK) display: ComponentDisplay,
    private readonly manager: ManagerPaEditionAnnonceService,
    private readonly dialogService: DialogsService,
    private readonly annoncesService: AnnoncesService,
    { nativeElement }: ElementRef<HTMLElement>,
  ) {
    super(display, nativeElement);
  }

  ngOnInit(): void {
    const offresChange = this.manager.offresChange.subscribe(offres => {
      this.offres.length = 0;
      this.offres.push(...offres);
    });
    this.subs.add(offresChange);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  addOffre(): void {
    this.dialogService.openInputFieldDialog('APP.DIALOG.INPUT_FIELD.OFFERS.LABEL', 'APP.DIALOG.INPUT_FIELD.OFFERS.TITLE', (res) => {
      if (this.manager.cle_annonce && res)
          this.annoncesService.editOffre({ cle_annonce: this.manager.cle_annonce, cle_etab: this.manager.cleEtab, cle_pa: this.manager.clePa, nom: res }).subscribe({
            next: (res) => {
              this.offres.push(res);
            },
            error: () => {
              this.dialogService.createInfoDialog('Erreur', 'La creéation d\'une offre a échoué. Veuillez réessayer ultérieurement.', 'OK');
            }
          })
    });
  }

  offreChange(index: number, offre: OffreAnnonceItem): void { 
    this.offres[index] = offre;
    this.syncOffreArray();
  }

  removeAllOffre(): void {
    if (!this.manager.cle_annonce) return;
    for (const offre of this.offres) {
      if (offre) {
        this.annoncesService.deleteOffre({
          cle_pa: this.manager.clePa,
          cle_etab: this.manager.cleEtab,
          cle_offre: offre.cle,
          cle_annonce: this.manager.cle_annonce || ''
        }).subscribe(() => {
          const index = _.findIndex(this.offres);
          this.removeOffre(index);
        });
      }
    }
  }

  removeOffre(index: number): void {
    this.offres.splice(index, 1);
    if (index > -1 && index < this.manager.offres.length) this.syncOffreArray();
  }

  @Debounce(100)
  private syncOffreArray(): void {
    this.manager.offres.length = 0;
    this.manager.offres.push(...this.offres.filter(value => !_.isNil(value)) as OffreAnnonceItem[]);
    this.manager.refreshVerifyRulesCall();
  }
}
