<div class="anc-component" fxLayout="column">
  <ng-container *ngIf="attribOffresInitiales?.value">   
    <div class="anc-component"  style="gap: 15px">
      <app-field-edition *ngIf="composition?.repartitionLots && repartitionLotsFC"
      [label]="'APP.ANNONCES.PROCEDURE_E16.LABEL6' | translate"
      [required]="controlIsRequired(repartitionLotsFC)"
      [invalid]="repartitionLotsFC.touched && repartitionLotsFC.invalid">
      <span class="sub-label" sub-label>BT-763-Procedure</span>
      <mat-form-field appearance="outline">
        <mat-select [formControl]="repartitionLotsFC">
          <mat-option *ngFor="let opt of options" [value]="opt.value">{{ opt.libelle }}</mat-option>
        </mat-select>
      </mat-form-field>
      </app-field-edition>
      <app-field-edition *ngIf="composition?.value && tvaFC"
            [label]="'APP.ANNONCES.PROCEDURE_E16.LABEL7' | translate"
            [required]="isRequired(required?.value)"
            [invalid]="false">
            <span class="sub-label" sub-label>BT-31-Procedure</span>
            <mat-form-field class="input-text-field" appearance="outline">
              <input matInput [formControl]="tvaFC">
            </mat-form-field>
      </app-field-edition>

      <app-field-edition *ngIf="composition?.valuemax && tvamaxFC"
            [label]="'APP.ANNONCES.PROCEDURE_E16.LABEL8' | translate"
            [required]="isRequired(required?.valuemax)"
            [invalid]="false">
            <span class="sub-label" sub-label>BT-33-Procedure</span>
            <mat-form-field class="input-text-field" appearance="outline">
              <input matInput [formControl]="tvamaxFC">
            </mat-form-field>
      </app-field-edition>
    </div>
  </ng-container>
</div>
