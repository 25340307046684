import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { DialogsService } from '../../../core/services/dialogs.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {


  constructor(private readonly dialog: DialogsService) {
    // This is intentional
  }

  ngOnInit(): void {
    // This is intentional
  }

  /**
   * Get current year
   * @returns number
   */
  get year(): number {
    return new Date().getFullYear();
  }

  get appVersion(): string {
    return environment.version;
  }

  openDialogLogin(): void {
    this.dialog.openSignUpDialog();
  }

  openRgpdDialog(): void {
    this.dialog.createInfoDialog('APP.DIALOG.RGPD.TITLE', 'APP.DIALOG.RGPD.CONTENT', 'OK');
  }

  openLegalNoticeDialog(): void {
    this.dialog.openLegalNotice();
  }
}
