<div class="rectification-annonce" fxLayout="column nowrap">
    <div class="base-rectification-annonce" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="label-rectification-annonce" fxLayout="row" fxLayoutAlign="space-between start" fxFlex="100">
            <span class="text-bl" fxFlex="50">{{rectification.modification?.type}}</span>
            <span class="text-it small" fxFlex="50">{{ rectification.section }}</span>
        </div>
        <div class="actions-rectification-annonce" fxLayout="row" fxLayoutAlign="start center">
            <ng-container *ngIf="rectification">
                <button type="button" mat-icon-button (click)="openEdit = !openEdit">
                    <ng-container *ngIf="!openEdit; else crossTemplate">
                        <mat-icon [svgIcon]="icons.Pencil"></mat-icon>
                    </ng-container>
                    <ng-template #crossTemplate>
                        <mat-icon [svgIcon]="icons.Close"></mat-icon>
                    </ng-template>
                </button>
                <button type="button" mat-icon-button (click)="remove()">
                    <mat-icon svgIcon="bin"></mat-icon>
                </button>
            </ng-container>
        </div>
    </div>
    <anc-rectificatif-edit class="add-rectification" *ngIf="openEdit || !rectification"
        (saved)="rectificationSaved($event)" [rectification]="rectification">
    </anc-rectificatif-edit>
</div>