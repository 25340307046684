import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TypeCPV } from '@atline-shared/enums/typeCpv.enum';
import { CpvItem } from '@atline/core/models/cvpItem.model';
import { FilterCodeCpvService } from '@atline/core/services/filter-code-cpv.service';

@Component({
  selector: 'list-cpv',
  templateUrl: './list-cpv.component.html',
  styleUrls: ['./list-cpv.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: ListCpvComponent, multi: true }]
})
export class ListCpvComponent implements OnChanges, OnInit, ControlValueAccessor {

  @Input()
  public recherche = '';
  @Output()
  readonly rechercheChange = new EventEmitter<string>();

  @Input()
  public type: TypeCPV = TypeCPV.PRINCIPAL;
  @Output()
  readonly typeChange = new EventEmitter<TypeCPV>();

  @Input()
  public rechercheDisabled = false;
  @Input()
  public typeDisabled = false;
  @Input()
  public disabled = false;

  @Input()
  public value: CpvItem | null | undefined = null;
  @Output()
  readonly valueChange = new EventEmitter<CpvItem | null | undefined>();

  private init = false;
  public searchFirst = false;
  readonly enumTypeCPV = TypeCPV;
  public results: CpvItem[] = [];
  public lastRecherche = this.recherche;

  constructor(private readonly filterCpvService: FilterCodeCpvService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) this.onChange(changes.value.currentValue);
    if (this.init) {
      if (changes.value || changes.recherche || changes.type) this.onTouch();
    }
  }

  ngOnInit(): void {
    this.init = true;
  }

  onChange: any = () => { /* This is intentional */ };
  onTouch: any = () => { /* This is intentional */ };

  writeValue(obj: any): void {
    this.value = obj;
    this.valueChange.emit(obj);
    this.onChange(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  search(): void {
    this.searchFirst = true
    this.lastRecherche = this.recherche;
    this.filterCpvService.filterByLabel(this.recherche, this.type).subscribe(result => this.results = result);
  }
}
