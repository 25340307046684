import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  forwardRef,
  Input,
  QueryList,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { InputCodeFieldDirective } from './input-code-field.directive';
import { map } from 'rxjs/operators';

type FunctionValueSetted = (value: string) => any;

@Component({
  selector: 'input-code-separator',
  templateUrl: './input-code-separator.component.html',
  styleUrls: ['./input-code-separator.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputCodeSeparatorComponent),
      multi: true,
    },
  ],
})
export class InputCodeSeparatorComponent implements AfterContentInit, ControlValueAccessor, Validator {

  @ContentChildren(InputCodeFieldDirective)
  inputPiecesId!: QueryList<InputCodeFieldDirective>;

  @Input()
  separator?: string;

  @Input()
  label = '';

  @Input()
  toSeparate?: (value: any, separator?: string) => any[];

  private isInit = false;
  private tmp: string | undefined = undefined;

  // tslint:disable-next-line: variable-name
  private _value = '';

  formGroup!: FormGroup;
  formGroupChange!: Subscription;

  onChange: FunctionValueSetted = () => {};
  onTouch: FunctionValueSetted = () => {};

  constructor(private cdref: ChangeDetectorRef ) {}

  ngAfterContentInit(): void {        
    this.formGroup = new FormGroup(
      this.inputPiecesId.reduce((a: { [key: number]: AbstractControl }, { control }, i) => ({ ...a, [i]: control }), {})
    );    
    this.formGroupChange = this.formGroup.valueChanges
      .pipe(map((values) => this.stringWithSeparator(Object.values(values))))
      .subscribe(value => this.value = value);
    this.isInit = true;
    this.writeValue(this.tmp ?? '');
    this.tmp = undefined;
    this.cdref.detectChanges();
  }

  get value(): string {
    return this._value;
  }

  set value(arg: string) {
    this._value = arg;
    this.onChange(arg);
    this.onTouch(arg);
  }

  writeValue(obj: any): void {
    this.value = obj;
    this.disValues(obj);
  }

  registerOnChange(fn: any): void {
    if (!_.isFunction(fn)) return;
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    if (!_.isFunction(fn)) return;
    this.onTouch = fn;
  }

  private stringWithSeparator(values: string[]): string {
    return values.filter(value => !!value).join(this.separator ?? '-');
  }

  private disValues(arg: string): void {
    if (!this.isInit) { this.tmp = arg; return; }
    const values = !!this.toSeparate
      ? this.toSeparate(arg, this.separator)
      : !!this.separator
      ? arg.split(this.separator)
      : [arg];
    Object.entries(this.formGroup.controls).forEach(([n, control]) => control.setValue(values[Number(n)] ?? ''));
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!!this.inputPiecesId) {
      for (const input of this.inputPiecesId.toArray()) {
        const error = input.validate();
        if (!!error) {
          return error;
        }
      }
    }
    if (!this.formGroup?.validator) {
      return null;
    }
    return this.formGroup.validator(control);
  }
}
