import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { MotsDescripteursResponse } from '../models/api/responses/motsDescripteur.response';

@Injectable({
  providedIn: 'root'
})
export class MotsDescripteurService {

  constructor(private readonly apiService: ApiService) { }

  public getMotsDescripteur(): Observable<MotsDescripteursResponse> {
    return this.apiService.post(MotsDescripteursResponse, {}, 'mots_descripteurs_list');
  }
}
