import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

type KeyObject<T extends { [key: string]: any }> = keyof T;
export type TypeFormGroup<T extends { [key: string]: any }> = { [Key in KeyObject<T>]: FormControl };
type ControlsToAdd<T extends { [key: string]: any }> = { [Key in KeyObject<T>]?: FormControl };
type ValuesToSet<T extends { [key: string]: any }> = { [Key in KeyObject<T>]?: T[Key] };

export const TYPE_FORM_GROUP = new InjectionToken<TypeFormGroup<any>>('typeFormGroup');

@Injectable({
  providedIn: 'root'
})
export class ManagerPaEditionService<T extends { [key: string]: any } = any> {

  protected previous!: T;
  protected readonly onSaveSubject = new Subject<T>();
  private form!: FormGroup;

  readonly onSave = this.onSaveSubject.pipe(
    map((current) => {
      const previous = this.previous;
      if (current) this.previous = current;
      return { previous, current: current as T };
    }),
  );

  constructor(@Optional() @Inject(TYPE_FORM_GROUP) controls?: TypeFormGroup<T> | null) {
    this.initForm(controls);
  }

  protected initForm(controls?: TypeFormGroup<T> | null): void {
    this.form = new FormGroup(controls ?? { local: new FormControl('') });
  }

  add(name: KeyObject<T>, control: FormControl): void {
    this.form.addControl(name.toString(), control);
  }

  addMany(controls: ControlsToAdd<T>): void {
    for (const [name, control] of Object.entries(controls)) {
      if (control) { this.add(name, control); }
    }
  }

  get valuesChanges(): Observable<T> {
    return this.form.valueChanges;
  }

  get values(): T {
    return this.form.value;
  }

  setValues(values: ValuesToSet<T>): void {
    Object.entries(values).forEach(([name, item]) => this.controls[name]?.setValue(item));
  }

  get isValid(): boolean {
    return this.form.valid;
  }

  getControl(name: KeyObject<T>): AbstractControl | undefined {
    return this.form.get(name.toString()) ?? undefined;
  }

  get controls(): TypeFormGroup<T> {
    return this.form.controls as TypeFormGroup<T>;
  }

  save(): void {
    this.onSaveSubject.next(this.values);
  }

  isSave(): boolean {
    return _.isEqual(this.values, this.previous);
  }
}
