import { Pagination } from '../../pagination.model';


export interface Departement {
  id: number;
  code_departement: string;
  nom_departement: string;
  region_departement: string;
  chef_lieu_departement: string;
}

export class DepartmentResponse {

  // tslint:disable:variable-name
  departments: Departement[] = [{
    id: 0,
    code_departement: '',
    nom_departement: '',
    region_departement: '',
    chef_lieu_departement: ''
  }];
  nb_total = 0;
  pagination = new Pagination();
  // tslint:enable:variable-name

}
