import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RoutingEnum } from '@atline-shared/enums/routing.enum';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PieceHelper } from 'src/app/core/helpers/piece.helper';
import { ApiErrorResponse } from 'src/app/core/models/api/responses/api.response';
import { PieceItem } from 'src/app/core/models/pieceItem2.model';
import { AuthenticateService } from 'src/app/core/services/authenticate.service';
import { DialogsService } from 'src/app/core/services/dialogs.service';
import { LoginComponent } from '@atline-shared/components/dialog/login/login.component';
import { LoaderComponent } from 'src/app/shared/components/loader/loader.component';
import { ConsultationState } from 'src/app/shared/enums/consultation.enum';
import { RoutingService } from '@atline/core/services/routing.service';

@Component({
  selector: 'app-connected-anonymous',
  templateUrl: './connected-anonymous.component.html',
  styleUrls: ['./connected-anonymous.component.scss']
})
export class ConnectedAnonymousComponent implements OnInit {

  public signInForm!: FormGroup;
  public arrPieces: Array<PieceItem> = [];
  public dialogOutput: any = { step: '' };
  public isLoading = false;
  public errorDescription?: string;
  public rememberMe = false;


  passwordFC = new FormControl('', [Validators.required, Validators.minLength(3)]);
  usernameFC = new FormControl('', [Validators.required, Validators.minLength(3)]);

  @ViewChild('closeMatDialog')
  el!: ElementRef;

  constructor(
    private readonly formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { cle_etab: string, cle_dce: string, cle_dc: string, codeRestreint?: string },
    public dialog: MatDialog,
    private readonly router: Router,
    private readonly authenticator: AuthenticateService,
    private readonly loaderService: DialogsService,
    public loginDialogRef: MatDialogRef<LoginComponent>,
    private readonly pieceHelper: PieceHelper,
    private readonly routingService: RoutingService
  ) {
    this.signInForm = this.formBuilder.group(
      {
        username: this.usernameFC,
        password: this.passwordFC,
        rememberMe: this.rememberMe
      }
    );
  }

  ngOnInit(): void {
    // this.consultationService.getConsultationPieces({ cle_dce: this.data.cle_dce, cle_etab: this.data.cle_etab }).pipe(
    //   catchError(err => {
    //     this.errorDescription = err.errorMessage;
    //     const apiError = err.error as ApiErrorResponse;
    //     if (apiError) {
    //       this.errorDescription = apiError.error_description;
    //     }
    //     return throwError(err);
    //   })
    // ).subscribe(res => {
    //   this.arrPieces = this.pieceHelper
    //     .convertsToPiecesItems(res.pieces)
    //     .filter(({ type }) => type === TypePiece.DCE);
    // });
  }

  get codeRestreint(): string | undefined {
    return this.data.codeRestreint;
  }

  get pieceDc(): string | undefined {
    return this.data.cle_dc;
  }

  get cleEtab(): string | null {
    return this.data.cle_etab;
  }

  openLogin(): void {
    const dialogRef = this.dialog.open(LoginComponent
      , {
        panelClass: 'login-dialog',
        width: '100%',
        position: {
          top: '0px'
        }
      }
    );

    // à la fermuture de la fenetre de dialogue login
    dialogRef.afterClosed().subscribe(() => {
      this.el.nativeElement.click();
      this.dialogOutput.step = ConsultationState.DETAILS;
    });
  }

  downloadDCE(): void {
    if (this.pieceDc)
      this.pieceHelper.downloadPieceGet(this.data.cle_dce, this.data.cle_etab, this.pieceDc, this.codeRestreint);
  }


  onSubmit(signInForm: any): void {
    if (!signInForm.invalid) {
      const loaderRef: MatDialogRef<LoaderComponent> = this.loaderService.openLoader('APP.ACTION.LOADING');
      this.authenticator.login(signInForm.value).pipe(
        catchError(err => {
          this.isLoading = false;
          loaderRef.close();
          let errorDescription = err.errorMessage;
          const apiError = err.error as ApiErrorResponse;
          if (apiError) {
            errorDescription = apiError.error_description;
          }
          this.loaderService.createInfoDialog('APP.LOGIN.FAILED', errorDescription, 'OK');
          return throwError(err);
        })
      ).subscribe((result => {
        this.isLoading = false;
        if (result) {
          loaderRef.close();
          this.loginDialogRef.close();
          this.router.navigate([this.routingService.firstFragment, RoutingEnum.CONSULTATION, this.data.cle_dce, RoutingEnum.DCE]);
        } else {
          loaderRef.close();
          this.loaderService.createInfoDialog('APP.LOGIN.FAILED', 'APP.LOGIN.ERROR', 'OK');
        }
      })
      );
    }
  }

  onSignupClick(): void {
    this.loginDialogRef.close('signup');
  }

  /**
   * Get the error message of the password form control validation
   */
  getPasswordErrorMessage(): string {
    if (this.passwordFC.hasError('required')) {
      return 'APP.LOGIN.PASSWORD_ERROR';
    }
    return this.passwordFC.hasError('minlength') ? 'APP.LOGIN.PASSWORD_ERROR' : '';
  }


}
