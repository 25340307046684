import { DonnesEssenciellesComponent } from './donnes-essencielles.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SoonAvalaibleModule } from '@atline-shared/components/soon-avalaible/soon-avalaible.module';
import { SearchFormComponent } from './search-form/search-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@atline/core/material/material.module';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { DesignModule } from '@atline-shared/design/design.module';
import { DonnesEssenciellesRoutingModule } from './donnes-essencielles-routing.module';
import { PaginationModule } from '@atline-shared/components/pagination/pagination.module';
import { DirectiveModule } from '@atline-shared/directive/directive.module';
import { ShareDeData } from '@atline/core/services/share-de-data.service';
import { DeModule } from '@atline-shared/components/de/de.module';
import { ListModule } from '@atline-shared/components/list/list.module';
import { PageTitleModule } from '@atline-shared/components/page-title/page-title.module';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [DonnesEssenciellesComponent, SearchFormComponent],
  imports: [
    CommonModule,
    SoonAvalaibleModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    TranslateModule,
    FlexModule,
    FlexLayoutModule,
    DesignModule,
    DonnesEssenciellesRoutingModule,
    PaginationModule,
    DirectiveModule,
    DeModule,
    ListModule,
    PageTitleModule,
  ],
  providers: [ShareDeData],
  exports: [DonnesEssenciellesComponent],
})
export class DonnesEssenciellesModule {}
