import { NgModule } from "@angular/core";
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  imports: [NgxMaskModule.forRoot({
    patterns: {
      '0': { pattern: /\d/ },
      '9': { pattern: /\d?/ },
      'U': { pattern: /[A-Z]/ },
      'L': { pattern: /[a-z]/ },
      'A': { pattern: /[a-zA-Z0-9]/ },
      'S': { pattern: /[a-zA-Z]/ }
    }
  })],
  exports: [NgxMaskModule],
})
export class MaskModule {}
