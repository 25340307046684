import { Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { Icons } from '@atline-shared/enums/icons.enum';
import { ComponentDisplay } from '@atline/core/models/pa/annonce/form-display.model';
import { Subscription } from 'rxjs';
import { ManagerPaEditionAnnonceService } from '../../manager-pa-edition-annonce.service';
import { DialogsService } from '@atline/core/services/dialogs.service';
import { AbstractAncComponent } from '../abstract-anc-component';
import { RectificationItem } from '@atline/core/models/rectificationItem.Model';
import { TOKEN_ANC_BLOCK } from '@atline/core/models/pa/annonce/anc-token-inject.model';
import { AnnoncesService } from '@atline/core/services/annonces.service';

@Component({
  selector: 'app-anc-rectificatif',
  templateUrl: './anc-rectificatif.component.html',
  styleUrls: ['./anc-rectificatif.component.scss']
})
export class AncRectificatifComponent extends AbstractAncComponent implements OnInit, OnDestroy {

  private readonly subs = new Subscription();

  rectifications: (RectificationItem)[] = [];

  Icons = Icons;

  errors = { getList: false, add: false, remove: false, removeAll: false };
  errorGetList = false;

  constructor(
    @Inject(TOKEN_ANC_BLOCK) display: ComponentDisplay,
    private readonly manager: ManagerPaEditionAnnonceService,
    { nativeElement }: ElementRef<HTMLElement>,
    private readonly dialogService: DialogsService,
    private readonly annonceService: AnnoncesService
  ) {
    super(display, nativeElement);
  }

  ngOnInit(): void {
    if (this.manager.cle_annonce)
      this.subs.add(this.annonceService.getRectifications({ cle_etab: this.manager.cleEtab, cle_pa: this.manager.clePa, cle_annonce: this.manager.cle_annonce }).subscribe({
        next: (res) => {          
          this.rectifications = res.rectifications;
          console.log(this.rectifications);
          
        },
        error: () => {
          this.errors.getList = true;
        }
      }))
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  removeRectification(rectification: RectificationItem): void {
    this.errors.remove = false;
    this.dialogService.openConfirmationDialog({
      title: 'Suppression rectification', description: 'Confirmez vous la suppression d\'une rectification', callback: (result) => {
        if (result)
          this.subs.add(this.annonceService.deleteRectification({ cle_etab: this.manager.cleEtab, cle_pa: this.manager.clePa, cle_annonce: rectification.cleAnnonce, cle_rectification: rectification.cle }).subscribe({
            next: () => {
              const rectificationIndex = this.rectifications.findIndex((r) => r.cle === rectification.cle);
              this.rectifications.splice(rectificationIndex, 1);
            },
            error: () => {
              this.errors.remove = true;
            }
          }))
      }
    })
  }

  removeAll(): void {
    this.errors.removeAll = false;
    this.dialogService.openConfirmationDialog({
      title: 'Suppression rectifications', description: 'Confirmez vous la suppression de toutes les rectifications', callback: (result) => {
        if (result && this.manager.cle_annonce)
          this.subs.add(this.annonceService.deleteRectification({ cle_etab: this.manager.cleEtab, cle_pa: this.manager.clePa, cle_annonce: this.manager.cle_annonce }).subscribe({
            next: () => {
              this.rectifications = [];
            },
            error: () => {
              this.errors.removeAll = true;
            }
          }))
      }
    })
  }

  addRectification(): void {
    this.errors.add = false;
    if (this.manager.cle_annonce)
      this.subs.add(this.annonceService.createUpdateRectification({ cle_etab: this.manager.cleEtab, cle_pa: this.manager.clePa, cle_annonce: this.manager.cle_annonce }).subscribe({
        next: (res) => {
          this.rectifications.push(...res.rectifications);
        },
        error: () => {
          this.errors.add = true;
        }
      }))
  }

  udapteRectificationOnArray(rectification: RectificationItem): void {
    const rectificationIndex = this.rectifications.findIndex((r) => r.cle === rectification.cle);
    this.rectifications[rectificationIndex] = rectification;
  }



}
