import { AncChorusComponent } from '../../components/anc-chorus/anc-chorus.component';
import { AncDepartmentPrestationComponent } from '../../components/anc-department-prestation/anc-department-prestation.component';
import { AncFacturationAdressComponent } from '../../components/anc-facturation-adress/anc-facturation-adress.component';
import { AncInfoComponent } from '../../components/anc-info/anc-info.component';
import { AncOptionEnvoiComponent } from '../../components/anc-option-envoi/anc-option-envoi.component';
// import { AncPublicationSupportComponent } from '../../components/anc-publication-support/anc-publication-support.component';
// import { AncSupportFacultatifMessageComponent } from '../../components/anc-support-facultatif-message/anc-support-facultatif-message.component';
import { FormDisplay } from '../../../../../../core/models/pa/annonce/form-display.model';
import { AncTypePaComponent } from '@atline/marches-securises/pa/edition/edition-annonce/components/anc-type-pa/anc-type-pa.component';
import { AncOrganisationEformComponent } from '../../components/anc-organisation-eform/anc-organisation-eform.component';
import { AncLotsComponent } from '../../components/anc-lots/anc-lots.component';
import { AncPrincipalActivityComponent } from '../../components/anc-principal-activity/anc-principal-activity.component';
import { AncLegalBasisProcedureComponent } from '../../components/anc-legal-basis-procedure/anc-legal-basis-procedure.component';
import { AncAutreBaseJuridiqueComponent } from '../../components/anc-autre-base-juridique/anc-autre-base-juridiquecomponent';
import { AncBaseSansIdentifiantComponent } from '../../components/anc-base-sans-identifiant/anc-base-sans-identifiant.component';
import { AncDescriptionBaseJuridiqueComponent } from '../../components/anc-description-base-juridique/anc-description-base-juridique.component';
import { AncTvaMarcheComponent } from '../../components/anc-tva-marche/anc-tva-marche.component';
import { AncAdditionalInfosExecussionPlaceComponent } from '../../components/anc-additional-infos-execussion-place/anc-additional-infos-execussion-place.component';
import { AncConditionsContratsComponent } from '../../components/anc-conditions-contrats/anc-conditions-contrats.component';
import { AncDroitsTransfComponent } from '../../components/anc-droits-transf/anc-droits-transf.component';
import { AncProcedureComponent } from '../../components/anc-procedure/anc-procedure.component';
// import { AncProcedureAccelereeComponent } from '../../components/anc-procedure-acceleree/anc-procedure-acceleree.component';
import { AncDivisionLotsComponent } from '../../components/anc-division-lots/anc-division-lots.component';
import { AncInformationsComplComponent } from '../../components/anc-informations-compl/anc-informations-compl.component';
import { AncReferencePubComponent } from '../../components/anc-reference-pub/anc-reference-pub.component';
import { AncObjetMarcheComponent } from '../../components/anc-objet-marche/anc-objet-marche.component';
import { AncDataContactComponent } from '../../components/anc-data-contact/anc-data-contact.component';
import { AncProcedureAccelereeComponent } from '../../components/anc-procedure-acceleree/anc-procedure-acceleree.component';
import { AncCpvClassificationComponent } from '../../components/anc-cpv-classification/anc-cpv-classification.component';
import { AncDescriptionWordComponent } from '../../components/anc-description-word/anc-description-word.component';
import { AncRectifInfoOriginalComponent } from '../../components/anc-rectif-info-original/anc-rectif-info-original.component';
import { AncRectificatifComponent } from '../../components/anc-rectificatif/anc-rectificatif.component';


const eForm: FormDisplay = {
  0: {
    id: 'pub',
    text: 'APP.ANNONCES.DISPLAYS.EFORM_18.0.TEXT',
    title: 'APP.ANNONCES.DISPLAYS.EFORM_18.0.TITLE',
    blocs: {
      bloc1: {
        title: 'APP.ANNONCES.DISPLAYS.EFORM_18.0.BLOCKS.1.TITLE',
        expansion: true,
        comps: [
          {
            component: AncOptionEnvoiComponent,
            required: {
              compteBoamp: true,
              destinataire: true,
              langue: true,
            },
            composition: {
              destinataire: true,
              compteBoamp: true,
              langue: true
            }
          }
        ]
      },
      bloc2: {
        title: 'APP.ANNONCES.DISPLAYS.EFORM_18.0.BLOCKS.2.TITLE',
        expansion: true,
        comps: [
          {
            component: AncDepartmentPrestationComponent,
            required: {
              defaultDepartement: true,
              otherDepartment: false,
              info: true
            },
            composition: {
              defaultDepartement: true,
              otherDepartment: true,
              info: true
            }
          }
        ]
      },
      // bloc3: {
      //   title: 'APP.ANNONCES.DISPLAYS.EFORM_18.0.BLOCKS.3.TITLE',
      //   expansion: true,
      //   comps: [
      //     {
      //       component: AncSupportFacultatifMessageComponent,
      //       required: {
      //         message: false
      //       },
      //       composition: {
      //         message: true
      //       }
      //     },
      //     {
      //       component: AncPublicationSupportComponent,
      //       required: {
      //         list: false
      //       },
      //       composition: {
      //         list: true
      //       }
      //     }
      //   ]
      // }
    }
  },
  1: {
    title: 'APP.ANNONCES.DISPLAYS.EFORM_18.1.TITLE',
    text: "APP.ANNONCES.DISPLAYS.EFORM_18.1.TITLE",
    blocs: {
      bloc1: {
        title: 'APP.ANNONCES.DISPLAYS.EFORM_18.1.BLOCKS.1.TITLE',
        comps: [
          {
            component: AncOrganisationEformComponent,
            required: {
              name: true,
              department: false,
              siret: true,
              country: true,
              ville: true,
              mel: true
            },
            composition: {
              name: true,
              department: true,
              siret: true,
              contact: true,
              address1: true,
              address2: true,
              address3: true,
              cp: true,
              ville: true,
              country: true,
              phone: true,
              fax: true,
              mel: true,
              url: true,
              exchangeUrl: true,
              isPhysicalPeople: true,
              sizeOe: false,
              laureat: true,
              chefDeFil: true,
              central1: true,
              central2: true
            }
          }
        ],
      }
    }
  },
  2: {
    title: 'APP.ANNONCES.DISPLAYS.EFORM_18.2.TITLE',
    text: "APP.ANNONCES.DISPLAYS.EFORM_18.2.TITLE",
    blocs: {
      bloc1: {
        title: 'APP.ANNONCES.DISPLAYS.EFORM_18.2.BLOCKS.1.TITLE',
        comps: [
          {
            component: AncDataContactComponent,
            required: {
              typePA: true,
              autreOrguanisme: true
            },
            composition: {
              nameOrganisation: true,
              contactPoint: true,
              departement: true,
              street: {
                name: true,
                additional: true,
                line: true
              },
              zipcode: true,
              country: true,
              codeNUTS: true,
              siret: true,
              phone: true,
              fax: true,
              mail: true,
              webSite: true,
              webSitePA: true,
            }
          }
        ]
      },
      bloc2: {
        title: 'Acheteur',
        comps: [
          {
            component: AncTypePaComponent,
            required: {
              typePA: true,
              autreOrguanisme: true
            },
            composition: {
              typePA: true,
              autreOrguanisme: true
            }
          },
          {
            component: AncPrincipalActivityComponent,
            required: {
              activities: true
            },
            composition: {
              activities: true
            }
          }
        ]
      }
    }
  },
  3: {
    title: 'APP.ANNONCES.DISPLAYS.EFORM_18.3.TITLE',
    text: "APP.ANNONCES.DISPLAYS.EFORM_18.3.TITLE",
    tabs: {
      tab1: {
        title: 'APP.ANNONCES.DISPLAYS.EFORM_18.3.TABS.1.TITLE',
        blocs: {
          bloc1: {
            title: 'APP.ANNONCES.DISPLAYS.EFORM_18.3.TABS.1.BLOCKS.1.TITLE',
            comps: [
              {
                component: AncReferencePubComponent,
                composition: { previousTender: true },
                required: { previousTender: false },
              }
            ]
          },
          bloc2: {
            title: 'APP.ANNONCES.DISPLAYS.EFORM_18.3.TABS.1.BLOCKS.2.TITLE',
            comps: [
              {
                component: AncLegalBasisProcedureComponent,
                composition: { legalBasisProcedure: true },
                required: { legalBasisProcedure: true },
              }
            ]
          },
          bloc3: {
            title: 'APP.ANNONCES.DISPLAYS.EFORM_18.3.TABS.1.BLOCKS.3.TITLE',
            comps: [
              {
                component: AncAutreBaseJuridiqueComponent,
                composition: { infoData: true, selectBase: true },
                required: { infoData: false, selectBase: false },
              }
            ]
          },
          bloc4: {
            title: 'APP.ANNONCES.DISPLAYS.EFORM_18.3.TABS.1.BLOCKS.4.TITLE',
            comps: [
              {
                component: AncBaseSansIdentifiantComponent,
                composition: { infoData: true },
                required: { infoData: false },
              },
            ]
          },
          bloc5: {
            title: 'APP.ANNONCES.DISPLAYS.EFORM_18.3.TABS.1.BLOCKS.5.TITLE',
            comps: [
              {
                component: AncDescriptionBaseJuridiqueComponent,
                composition: { identifiantInterne: true, titre: true, natureContract: true, infoData: true },
                required: { identifiantInterne: false, titre: true, natureContract: true, infoData: false },
              },
              {
                component: AncObjetMarcheComponent,
                composition: { intitule: true },
                required: { intitule: false },
              },
            ]
          },
          bloc6: {
            title: 'APP.ANNONCES.DISPLAYS.EFORM_18.3.TABS.1.BLOCKS.6.TITLE',
            comps: [
              {
                component: AncTvaMarcheComponent,
                composition: { value: true, currency: true, valuemax: true },
                required: { value: false, currency: false, valuemax: false },
              },
              {
                component: AncCpvClassificationComponent,
                composition: { principal: true, cpv: true, cpv1: true, cpv2: true, cpv3: true },
                required: { principal: true, cpv: true, cpv1: true, cpv2: true, cpv3: true }
              },
              {
                component: AncDescriptionWordComponent,
                required: {
                  select: true
                },
                composition: {
                  select: true
                }
              },
            ]
          },
          bloc7: {
            title: 'APP.ANNONCES.DISPLAYS.EFORM_18.3.TABS.1.BLOCKS.7.TITLE',
            comps: [
              {
                component: AncAdditionalInfosExecussionPlaceComponent,
                composition: { adr1: true, adr2: true, adr3: true, country: true, nuts: true, cp: true, city: true, restrict: true, infos_comp_marche: true, infos_comp_procedure: true },
                required: { adr1: false, adr2: false, adr3: false, country: false, nuts: false, cp: false, city: false, restrict: false, infos_comp_marche: false, infos_comp_procedure: false },
              },
            ]
          }
        }
      },
      tab2: {
        title: 'APP.ANNONCES.DISPLAYS.EFORM_18.3.TABS.2.TITLE',
        blocs: {
          bloc1: {
            title: 'APP.ANNONCES.DISPLAYS.EFORM_18.3.TABS.2.BLOCKS.1.TITLE',
            comps: [
              {
                component: AncConditionsContratsComponent,
                composition: {
                  codeNational: true,
                  codeFaillite: true,
                  codeCorruption: true,
                  codeCreanciers: true,
                  codeOrg: true,
                  codeDistorsion: true,
                  codeEnv: true,
                  codeBlanchiment: true,
                  codeFraude: true,
                  codeTraf: true,
                  codeInsolv: true,
                  codeTravail: true,
                  codeLiquid: true,
                  codeFausse: true,
                  codeNationaux: true,
                  codeConflits: true,
                  codePrep: true,
                  codeFaute: true,
                  codeSanction: true,
                  codeOblig: true,
                  codePay: true,
                  codeActiv: true,
                  codeImpots: true,
                  codeInter: true,
                },
                required: {
                  codeNational: true,
                  codeFaillite: true,
                  codeCorruption: true,
                  codeCreanciers: true,
                  codeOrg: true,
                  codeDistorsion: true,
                  codeEnv: true,
                  codeBlanchiment: true,
                  codeFraude: true,
                  codeTraf: true,
                  codeInsolv: true,
                  codeTravail: true,
                  codeLiquid: true,
                  codeFausse: true,
                  codeNationaux: true,
                  codeConflits: true,
                  codePrep: true,
                  codeFaute: true,
                  codeSanction: true,
                  codeOblig: true,
                  codePay: true,
                  codeActiv: true,
                  codeImpots: true,
                  codeInter: true,
                }
              }
            ]
          },
          bloc2: {
            title: 'APP.ANNONCES.DISPLAYS.EFORM_18.3.TABS.2.BLOCKS.2.TITLE',
            comps: [
              {
                component: AncDroitsTransfComponent,
                composition: {

                }
              }
            ]
          }
        }
      },
      tab3: {
        title: 'APP.ANNONCES.DISPLAYS.EFORM_18.3.TABS.3.TITLE',
        blocs: {
          bloc1: {
            title: 'APP.ANNONCES.DISPLAYS.EFORM_18.3.TABS.3.BLOCKS.1.TITLE',
            comps: [
              {
                component: AncProcedureComponent,
                composition: {
                  description: true,
                  procedure: true,
                  relanceInfructeux: true,
                },
                required: {
                  description: false,
                  procedure: true,
                  relanceInfructeux: false,
                }
              }
            ]
          },
          bloc2: {
            title: "APP.ANNONCES.DISPLAYS.EFORM_18.3.TABS.3.BLOCKS.2.TITLE",
            comps: [
              {
                component: AncProcedureAccelereeComponent
              }
            ]
          },
          bloc3: {
            title: 'APP.ANNONCES.DISPLAYS.EFORM_18.3.TABS.3.BLOCKS.2.TITLE',
            comps: [
              {
                component: AncInformationsComplComponent,
                composition: {
                  description: true,
                },
                required: {
                  description: false,
                }
              }
            ]
          }
        }
      },
      tab4: {
        title: 'APP.ANNONCES.DISPLAYS.EFORM_18.3.TABS.4.TITLE',
        blocs: {
          bloc1: {
            title: 'APP.ANNONCES.DISPLAYS.EFORM_18.3.TABS.4.BLOCKS.1.TITLE',
            comps: [
              {
                component: AncDivisionLotsComponent,
                required: {
                  value: true,
                  valuemax: false,
                  repartitionLots: true,
                },
                composition: {
                  value: true,
                  valuemax: true,
                  repartitionLots: true,
                }
              }
            ]
          }
        }
      }
    },
  },
  4: {
    title: 'APP.ANNONCES.DISPLAYS.EFORM_18.4.TITLE',
    text: "APP.ANNONCES.DISPLAYS.EFORM_18.4.TITLE",
    blocs: {
      blocs1: {
        title: 'Mes Lots',
        comps: [
          {
            component: AncLotsComponent
          }
        ]
      }
    }
  },
  5: {
    id: 'modif',
    title: 'APP.ANNONCES.DISPLAYS.EFORM_RECTIFICATION.ATTRIBUTION.TITLE',
    text: 'APP.ANNONCES.DISPLAYS.EFORM_RECTIFICATION.ATTRIBUTION.TITLE',
    rectif: true,
    blocs: {
      block1: {
        title: 'APP.ANNONCES.DISPLAYS.EFORM_RECTIFICATION.ATTRIBUTION.BLOCS.1.TITLE',
        comps: [
          {
            component: AncRectifInfoOriginalComponent,
            composition: {
              rectifInfo: true,
            },
            required: {
              rectifInfo: true,
            }
          },
          
        ]
      },
      block2: {
        title: 'APP.ANNONCES.DISPLAYS.EFORM_RECTIFICATION.ATTRIBUTION.BLOCS.2.TITLE',
        comps: [
          {
            component: AncRectificatifComponent
          }
        ]
      }
    }
  },
  6: {
    id: 'fac',
    title: 'APP.ANNONCES.DISPLAYS.EFORM_18.5.TITLE',
    text: '€',
    blocs: {
      bloc1: {
        title: 'APP.ANNONCES.DISPLAYS.EFORM_18.5.BLOCKS.1.TITLE',
        comps: [
          {
            component: AncInfoComponent,
            translateKey: 'FACTURATION',
            etat: 'warning',
            required: {},
            composition: {}
          },
          {
            component: AncChorusComponent,
            required: {
              siret: false,
              tvaIntra: false,
              engagement: false
            },
            composition: {
              siret: true,
              tvaIntra: true,
              engagement: true
            }
          }
        ]
      },
      bloc2: {
        title: 'APP.ANNONCES.DISPLAYS.EFORM_18.5.BLOCKS.2.TITLE',
        comps: [{
          component: AncFacturationAdressComponent,
          required: {
            profil: false,
            name: false,
            recipant: true,
            building: true,
            road: true,
            special: true,
            cp: true,
            city: true

          },
          composition: {
            profil: false,
            name: true,
            recipant: true,
            building: true,
            road: true,
            special: true,
            cp: true,
            city: true
          }
        }]
      }
    }
  }
};

export default eForm;
