import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { AbstractAncComponent } from '../abstract-anc-component';
import { ComponentDisplay } from '@atline/core/models/pa/annonce/form-display.model';
import { ManagerPaEditionAnnonceService } from '../../manager-pa-edition-annonce.service';
import { TOKEN_ANC_BLOCK } from '@atline/core/models/pa/annonce/anc-token-inject.model';
import { Validators } from '@angular/forms';
import { CountryItem } from '@atline/core/models/countryItem.model';
import { NutsItem } from '@atline/core/models/api/responses/nuts.response';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'anc-additional-infos-execussion-place',
  templateUrl: './anc-additional-infos-execussion-place.component.html',
  styleUrls: ['./anc-additional-infos-execussion-place.component.scss']
})
export class AncAdditionalInfosExecussionPlaceComponent extends AbstractAncComponent implements OnInit {

  readonly livAdr1FC = this.manager.controls.exec_liv_adr_1;
  readonly livAdr2FC = this.manager.controls.exec_liv_adr_2;
  readonly livAdr3FC = this.manager.controls.exec_liv_adr_3;
  readonly adrVilleFC = this.manager.controls.exec_liv_ville;
  readonly adrCpFC = this.manager.controls.exec_liv_cp;
  readonly lieuNutsFC = this.manager.controls.exec_liv_codeNUTS;
  readonly livPaysFC = this.manager.controls.exec_liv_pays;
  readonly livAutresRestrictFC = this.manager.controls.autres_restrictions_liv;
  readonly infosCompFC = this.manager.controls.infosComplementaires;
  readonly infosCompProcedureFC = this.manager.controls.infosComplementaires_procedure;

  readonly countries: CountryItem[] = [];
  readonly nuts: NutsItem[] = [];
  readonly restrictions = [];

  isEform = this.manager.isEforms;

  readonly filterNuts = this.lieuNutsFC?.valueChanges.pipe(
    startWith(''),
    map((value: string) => {
      if (value) return this.nuts.filter(item => item.code_nuts.toLowerCase().includes(value.toLowerCase()));
      return this.nuts;
    })
  );

  readonly restrictionOptions = ['anyw', 'anyw-cou', 'anyw-eea'] as const;

  constructor(
    @Inject(TOKEN_ANC_BLOCK) display: ComponentDisplay,
    private readonly manager: ManagerPaEditionAnnonceService,
    { nativeElement }: ElementRef<HTMLElement>,
  ) { super(display, nativeElement); }

  ngOnInit(): void {
    this.livAdr1FC?.setValidators([this.createValidatorRequired('adr1'), Validators.maxLength(400)]);
    this.livAdr2FC?.setValidators([this.createValidatorRequired('adr2'), Validators.maxLength(400)]);
    this.livAdr3FC?.setValidators([this.createValidatorRequired('adr3'), Validators.maxLength(400)]);
    this.adrCpFC?.setValidators([this.createValidatorRequired('cp'), Validators.maxLength(400)]);
    this.adrVilleFC?.setValidators([this.createValidatorRequired('city'), Validators.maxLength(400)]);
    this.livPaysFC?.setValidators([this.createValidatorRequired('country')]);
    this.lieuNutsFC?.setValidators([this.createValidatorRequired('nuts')]);
    this.livAutresRestrictFC?.setValidators([this.createValidatorRequired('restrict')]);
    this.infosCompFC?.setValidators([this.createValidatorRequired('infos_comp_marche'), Validators.maxLength(400)]);
    this.infosCompProcedureFC?.setValidators([this.createValidatorRequired('infos_comp_procedure'), Validators.maxLength(400)])

    this.manager.getCountries().subscribe(countries => this.countries.push(...countries));
    this.manager.getNuts().subscribe(nuts => {
      this.nuts.push(...nuts);
      this.lieuNutsFC?.setValue(this.lieuNutsFC.value);
    });

    this.livAutresRestrictFC?.setValue(this.livAutresRestrictFC.value || null);
  }

}
