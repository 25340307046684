import { Component, OnInit, Inject, ElementRef } from '@angular/core';
import { AbstractAncComponent } from '../abstract-anc-component';
import { TOKEN_ANC_BLOCK } from '@atline/core/models/pa/annonce/anc-token-inject.model';
import { ComponentDisplay } from '@atline/core/models/pa/annonce/form-display.model';

@Component({
  selector: 'anc-info',
  templateUrl: './anc-info.component.html',
  styleUrls: ['./anc-info.component.scss']
})
export class AncInfoComponent extends AbstractAncComponent implements OnInit {

  constructor(@Inject(TOKEN_ANC_BLOCK) display: ComponentDisplay, { nativeElement }: ElementRef<HTMLElement>) {
    super(display, nativeElement);
  }

  ngOnInit(): void {
  }

  get translateKey(): string {
    return this.display.translateKey || '';
  }

  get etat(): 'warning' | 'info' {
    return this.display.etat || 'warning';
  }

  get svgIcon(): string {
    switch (this.etat) {
      case 'info':
        return 'info_green';
      case 'warning':
        return 'info';
      default:
        return '';
    }
  }

}
