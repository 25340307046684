import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-input-field-dialog',
  templateUrl: './input-field-dialog.component.html',
  styleUrls: ['./input-field-dialog.component.scss']
})
export class InputFieldDialogComponent implements OnInit {

  formGroup!: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<InputFieldDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { inputLabel: string, title: string },
    private readonly fb: FormBuilder
  ) {

  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      content: new FormControl(null, [Validators.required])
    });
  }

  validate(): void {
    if (this.formGroup.valid) {
      this.dialogRef.close(this.formGroup.controls['content'].value);
    }
  }

  close() {
    this.dialogRef.close();
  }

}
