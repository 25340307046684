import { AncChorusComponent } from '../../components/anc-chorus/anc-chorus.component';
import { AncDepartmentPrestationComponent } from '../../components/anc-department-prestation/anc-department-prestation.component';
import { AncFacturationAdressComponent } from '../../components/anc-facturation-adress/anc-facturation-adress.component';
import { AncInfoComponent } from '../../components/anc-info/anc-info.component';
import { AncOptionEnvoiComponent } from '../../components/anc-option-envoi/anc-option-envoi.component';
import { AncPublicationSupportComponent } from '../../components/anc-publication-support/anc-publication-support.component';
import { AncSupportFacultatifMessageComponent } from '../../components/anc-support-facultatif-message/anc-support-facultatif-message.component';
import { FormDisplay } from '../../../../../../core/models/pa/annonce/form-display.model';
import { AncTypePaComponent } from '@atline/marches-securises/pa/edition/edition-annonce/components/anc-type-pa/anc-type-pa.component';
import { AncPrincipalActivityComponent } from '@atline/marches-securises/pa/edition/edition-annonce/components/anc-principal-activity/anc-principal-activity.component';
import { AncLegalBasisProcedureComponent } from '@atline/marches-securises/pa/edition/edition-annonce/components/anc-legal-basis-procedure/anc-legal-basis-procedure.component';
import { AncCpvClassificationComponent } from '@atline/marches-securises/pa/edition/edition-annonce/components/anc-cpv-classification/anc-cpv-classification.component';
import { AncAdditionalInfosExecussionPlaceComponent } from '@atline/marches-securises/pa/edition/edition-annonce/components/anc-additional-infos-execussion-place/anc-additional-infos-execussion-place.component';
import { AncDataContactComponent } from '../../components/anc-data-contact/anc-data-contact.component';
import { AncOrganisationEformComponent } from '../../components/anc-organisation-eform/anc-organisation-eform.component';
import { AncAutreBaseJuridiqueComponent } from '../../components/anc-autre-base-juridique/anc-autre-base-juridiquecomponent';
import { AncBaseSansIdentifiantComponent } from '../../components/anc-base-sans-identifiant/anc-base-sans-identifiant.component';
import { AncObjetMarcheComponent } from '../../components/anc-objet-marche/anc-objet-marche.component';
import { AncDescriptionProcedureComponent } from '../../components/anc-description-procedure/anc-description-procedure.component';
import { AncInformationsComplComponent } from '../../components/anc-informations-compl/anc-informations-compl.component';

const eForm: FormDisplay = {
  0: {
    id: 'pub',
    text: 'APP.ANNONCES.DISPLAYS.EFORM_3.0.TEXT',
    title: 'APP.ANNONCES.DISPLAYS.EFORM_3.0.TITLE',
    blocs: {
      bloc1: {
        title: 'APP.ANNONCES.DISPLAYS.EFORM_3.0.BLOCKS.1.TITLE',
        expansion: true,
        comps: [
          {
            component: AncOptionEnvoiComponent,
            required: {
              compteBoamp: true,
              destinataire: true,
              langue: true,
            },
            composition: {
              destinataire: true,
              compteBoamp: true,
              publish: true,
              langue: true
            }
          }
        ]
      },
      bloc2: {
        title: 'APP.ANNONCES.DISPLAYS.EFORM_3.0.BLOCKS.2.TITLE',
        expansion: true,
        comps: [
          {
            component: AncDepartmentPrestationComponent,
            required: {
              defaultDepartement: true,
              otherDepartment: false,
              info: true
            },
            composition: {
              defaultDepartement: true,
              otherDepartment: true,
              info: true
            }
          }
        ]
      },
      bloc3: {
        title: 'APP.ANNONCES.DISPLAYS.EFORM_3.0.BLOCKS.3.TITLE',
        expansion: true,
        comps: [
          {
            component: AncSupportFacultatifMessageComponent,
            required: {
              message: false
            },
            composition: {
              message: true
            }
          },
          {
            component: AncPublicationSupportComponent,
            required: {
              list: false
            },
            composition: {
              list: true
            }
          }
        ]
      }
    }
  },
  1: {
    title: 'APP.ANNONCES.DISPLAYS.EFORM_3.1.TITLE',
    text: "APP.ANNONCES.DISPLAYS.EFORM_3.1.TITLE",
    blocs: {
      bloc1: {
        title: 'APP.ANNONCES.DISPLAYS.EFORM_3.1.BLOCKS.1.TITLE',
        comps: [
          {
            component: AncOrganisationEformComponent,
            required: {
              name: true,
              department: false,
              siret: true,
              country: true,
              ville: true,
              mel: true
            },
            composition: {
              name: true,
              department: true,
              siret: true,
              contact: true,
              address1: true,
              address2: true,
              address3: true,
              cp: true,
              ville: true,
              country: true,
              phone: true,
              fax: true,
              mel: true,
              url: true,
              exchangeUrl: true,
              isPhysicalPeople: true,
              sizeOe: false,
              laureat: true,
              chefDeFil: true,
              central1: true,
              central2: true
            }
          }
        ],
      }
    }
  },
  2: {
    title: 'APP.ANNONCES.DISPLAYS.EFORM_3.2.TITLE',
    text: 'APP.ANNONCES.DISPLAYS.EFORM_3.2.TITLE',
    blocs: {
      bloc1: {
        title: 'Acheteur',
        comps: [
          {
            component: AncDataContactComponent,
            required: {
              typePA: true,
              autreOrguanisme: true
            },
            composition: {
              nameOrganisation: true,
              contactPoint: true,
              departement: true,
              street: {
                name: true,
                additional: true,
                line: true
              },
              zipcode: true,
              country: true,
              codeNUTS: true,
              siret: true,
              phone: true,
              fax: true,
              mail: true,
              webSite: true,
              webSitePA: true,
            }
          }
        ]
      },
      bloc2: {
        title: 'Acheteur',
        comps: [
          {
            component: AncTypePaComponent,
            required: {
              typePA: true,
              autreOrguanisme: true
            },
            composition: {
              typePA: true,
              autreOrguanisme: true
            }
          },
          {
            component: AncPrincipalActivityComponent,
            required: {
              activities: true
            },
            composition: {
              activities: true
            }
          }
        ]
      }
    }
  },
  3: {
    title: 'APP.ANNONCES.DISPLAYS.EFORM_3.3.TITLE',
    text: 'APP.ANNONCES.DISPLAYS.EFORM_3.3.TITLE',
    tabs: {
      tab1: {
        title: 'APP.ANNONCES.DISPLAYS.EFORM_3.3.TABS.1.TITLE',
        blocs: {
          bloc1: {
            title: 'APP.ANNONCES.DISPLAYS.EFORM_3.3.TABS.1.BLOCS.1.TITLE',
            comps: [
              {
                component: AncLegalBasisProcedureComponent,
                composition: { legalBasisProcedure: true },
                required: { legalBasisProcedure: true },
              }
            ]
          },
          bloc3: {
            title: 'Autre base juridique avec un identifiant (GR-Procedure-LocalLegalBasisWithID)',
            comps: [
              {
                component: AncAutreBaseJuridiqueComponent,
                composition: { infoData: true, selectBase: true },
                required: { infoData: false, selectBase: false },
              }
            ]
          },
          bloc4: {
            title: 'Autre base juridique sans identifiant connu (GR-Procedure-LocalLegalBasisNoID)',
            comps: [
              {
                component: AncBaseSansIdentifiantComponent,
                composition: { infoData: true },
                required: { infoData: false },
              },
            ]
          },
          bloc5: {
            title: 'Description (GR-Procedure-Description)',
            comps: [
              {
                component: AncDescriptionProcedureComponent,
                composition: { identifiantInterne: true, titre: true, natureContract: true, natureAdditionalContract: false, infoData: true },
                required: { identifiantInterne: false, titre: true, infoData: false },
              },
              {
                component: AncObjetMarcheComponent,
                composition: { intitule: true },
                required: { intitule: false },
              },
            ]
          },
          bloc6: {
            title: 'Étendue du marché (GR-Procedure-Scope)',
            comps: [
              {
                component: AncCpvClassificationComponent,
                composition: { principal: false, cpv: true, cpv1: true, cpv2: true, cpv3: true },
                required: {  cpv: true, cpv1: true, cpv2: true, cpv3: true }
              }
            ]
          },
          bloc7: {
            title: 'Informations supplémentaires sur le lieu d’exécution (GR-Procedure-PlaceOfPerformance)',
            comps: [
              {
                component: AncAdditionalInfosExecussionPlaceComponent,
                composition: { adr1: false, adr2: false, adr3: false, country: true, nuts: false, cp: false, city: false, restrict: true, infos_comp_marche: false, infos_comp_procedure: false },
                required: { country: true, restrict: false},
              },
            ]
          }
        }
      },
      tab2: {
        title: 'APP.ANNONCES.DISPLAYS.EFORM_3.3.TABS.2.TITLE',
        blocs: {
          bloc1: {
            title: 'Référence de l\'avis précédent (GR-Previous-Notice)',
            comps: [
              {
                component: AncInformationsComplComponent,
                composition: {
                  description: true,
                },
                required: {
                  description: false,
                }
              }
            ]
          },
        }
      },
    }

  },
  4: {
    id: 'fac',
    title: 'APP.ANNONCES.DISPLAYS.EFORM_3.5.TITLE',
    text: '€',
    blocs: {
      bloc1: {
        title: 'APP.ANNONCES.DISPLAYS.EFORM_3.5.BLOCKS.1.TITLE',
        comps: [
          {
            component: AncInfoComponent,
            translateKey: 'FACTURATION',
            etat: 'warning',
            required: {},
            composition: {}
          },
          {
            component: AncChorusComponent,
            required: {
              siret: false,
              tvaIntra: false,
              engagement: false
            },
            composition: {
              siret: true,
              tvaIntra: true,
              engagement: true
            }
          }
        ]
      },
      bloc2: {
        title: 'APP.ANNONCES.DISPLAYS.EFORM_3.5.BLOCKS.2.TITLE',
        comps: [{
          component: AncFacturationAdressComponent,
          required: {
            profil: false,
            name: false,
            recipant: true,
            building: true,
            road: true,
            special: true,
            cp: true,
            city: true

          },
          composition: {
            profil: false,
            name: true,
            recipant: true,
            building: true,
            road: true,
            special: true,
            cp: true,
            city: true
          }
        }]
      }
    }
  }
};

export default eForm;
