export enum RoutingEnum {
  ANNNOUNCE = 'joue-BOAMP-JAL',
  MARCHES_SECURISES = 'marches-securises',
  PA = 'pa',
  CONSULTATION = 'consultation',
  DCE = 'dce',
  ELRAR = 'elrar',
  DEMANDE = 'demande',
  NOTIFICATION = 'notification',
  ADMINISTRATION = 'administration',
  HISTORIQUE = 'historique',
  DEMANDE_COMP = 'demande-complementaire',
  ADM_USERS = 'utilisateurs',
  ADM_GROUPS = 'groupes',
  ADM_KEYS_ENCRYPT = 'cles-chiffrements',
  ADM_CONSULTATION_MANAGMENT = 'gestion-consultations',
  ADM_CONFIG = 'configuration',
  ADM_CONFIG_ELRAR = 'configuration-elrar',
  ADM_CONFIG_EO = 'configuration-easyopen',
  ADM_OE_MESSAGE = 'messages-informatifs-oe',
  ADM_REG_OFFICE = 'guichet-enregistrement',
  ADM_COORDINATES = 'mes-coordonnees',
  EDIT = 'edit',
  SEM = 'sem',
  FAVORIS = 'favoris',
  RETRAIT_LIST = 'retrait-list',
  RESPONSE = 'response',
  DUME = 'dume',
  LRAR = 'lrar',
  MESSAGES_ENVOYES = 'messages-envoyes',
  MESSAGES_RECUS = 'messages-recus',
  DEMANDE_EXECUTION = 'demande-execution',
  RESSOURCES = 'ressources',
  SIGNATURE = 'certificat-signature',
  PARTENAAIRE_REFERENCE = 'patenaires-references',
  FAQ = 'faq',
  WHO_WE_ARE = 'qui-sommes-nous',
  USEFULL_LINKS = 'liens-utiles',
  INSCRIPTION = 'inscription',
  // AVIS_ATTRIBUTION = 'attribution',
  AVIS_ATTRIBUTION = 'avis-attribution',
  DONNEES_ESSENTIELLES = 'donnees-essentielles',
  CERTIFICAT_SIGNAUTRE = 'CertificatSignature',
  SIGNATURE_BOUTIQUE = 'SignatureBoutique',
  WEBSIGN = 'webSign',
  TELECHARGEMENT_MANDAT = 'TelechargementMandat',
  RENOUVEL_CSE = 'RenouvelCse',
  COMMAND_CSE = 'CommandCse',
  ELECTRO_WEBSIGN = 'electro-websign',
  GUICHET_ENREGISTREMEMENT = 'guichet-enregistrement',
  LIST_AFFAIRES = 'list-affaires',
  VISIONNEUSE_DUME = 'visioneuse-dume',
  LIST_DE = 'list-de',
  ANNUAIRE = 'annuaire',
  STATS = 'stats',
  EXTERNALISATION_DCE = 'externalisation-dce',
  DEMANDE_COMPLEMENTAIRE = 'demande-complementaire',
  CERTIFICAT_SIGNAUTRE_MAIN = 'certificat-signature',
  DE = 'donnees-essentielles',
  COMMANDCSE = 'CommandCse',
  RENOUVELLEMENTCSE = 'RenouvelCse',
  TELEMANDAT = 'TelechargementMandat',
  AFFAIRES = 'affaires',
  OUR_SOLUTIONS = 'ourSolutions',
  PROJECTS = 'projets',
  DOC_BDC = 'documentation-BDC',
  ERROR_404 = '404',
  EDITION = 'edition',
  HOME = 'home',
  RESET_PASSWORD = 'reinitialisation_pwd'
}
