import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FavorisComponent } from './favoris.component';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { DirectiveModule } from '@atline-shared/directive/directive.module';
import { LoadingSpinnerModule } from '@atline-shared/design/loading-spinner/loading-spinner.module';



@NgModule({
  declarations: [
    FavorisComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FlexModule,
    FlexLayoutModule,
    MatIconModule,
    DirectiveModule,
    LoadingSpinnerModule
  ],
  exports: [
    FavorisComponent
  ]
})
export class FavorisModule { }
