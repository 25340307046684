import { Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractAncComponent } from '../abstract-anc-component';
import { TOKEN_ANC_BLOCK } from '@atline/core/models/pa/annonce/anc-token-inject.model';
import { ComponentDisplay } from '@atline/core/models/pa/annonce/form-display.model';
import { ManagerPaEditionAnnonceService } from '../../manager-pa-edition-annonce.service';
import { Validators } from '@angular/forms';

const reservationsMarche = ['entrepriseAdaptee', 'structureInsertion', 'servicesSociaux'] as const;
type TypeReservationMarche = typeof reservationsMarche[number];

function isTypeReservationMarche(arg: any): arg is TypeReservationMarche {
  return reservationsMarche.includes(arg);
}

function splitTypeReservationMarche(arg?: string): TypeReservationMarche[] {
  return arg?.split(';').filter(isTypeReservationMarche) || [];
}

@Component({
  selector: 'anc-conditions-contrats',
  templateUrl: './anc-conditions-contrats.component.html',
  styleUrls: ['./anc-conditions-contrats.component.scss']
})
export class AncConditionsContratsComponent extends AbstractAncComponent implements OnInit, OnDestroy {

  readonly situationFC = this.manager.controls.situation_marche_reserve;
  readonly codeNationalFC = this.manager.controls.critere_exclu_faillite_national_desc;
  readonly codeFailliteFC = this.manager.controls.critere_exclu_faillite_desc;
  readonly codeCorruptionFC = this.manager.controls.critere_exclu_corruption_desc;
  readonly codeCreanciersFC = this.manager.controls.critere_exclu_creanciers_desc;
  readonly codeOrgFC = this.manager.controls.critere_exclu_org_crim_desc;
  readonly codeDistorsionFC = this.manager.controls.critere_exclu_distorsion_desc;
  readonly codeEnvFC = this.manager.controls.critere_exclu_env_desc;
  readonly codeBlanchimentFC = this.manager.controls.critere_exclu_blanchiment_desc;
  readonly codeFraudeFC = this.manager.controls.critere_exclu_fraude_desc;
  readonly codeTrafFC = this.manager.controls.critere_exclu_traf_hum_desc;
  readonly codeInsolvFC = this.manager.controls.critere_exclu_insolv_desc;
  readonly codeTravailFC = this.manager.controls.critere_exclu_droit_travail_desc;
  readonly codeLiquidFC = this.manager.controls.critere_exclu_liquid_desc;
  readonly codeFausseFC = this.manager.controls.critere_exclu_fausse_dec_desc;
  readonly codeNationauxFC = this.manager.controls.critere_exclu_nationaux_desc;
  readonly codeConflitsFC = this.manager.controls.critere_exclu_conflits_desc;
  readonly codePrepFC = this.manager.controls.critere_exclu_conflits_prep_desc;
  readonly codeFauteFC = this.manager.controls.critere_exclu_faute_prof_desc;
  readonly codeSanctionFC = this.manager.controls.critere_exclu_sanction_desc;
  readonly codeObligFC = this.manager.controls.critere_exclu_viol_oblig_desc;
  readonly codePayFC = this.manager.controls.critere_exclu_pay_secu_desc;
  readonly codeActivFC = this.manager.controls.critere_exclu_activ_commer_susp_desc;
  readonly codeImpotsFC = this.manager.controls.critere_exclu_pay_impots_desc;
  readonly codeInterFC = this.manager.controls.critere_exclu_inter_terro_desc;

  readonly typesSelected = new Set<TypeReservationMarche>();
  readonly reservedMarketFC = this.manager.controls.reservationMarche;
  readonly situationChange = this.situationFC?.valueChanges.subscribe(value => {
    if (!value) this.reservedMarketFC?.setValue(null);
  });

  readonly reservedChange = this.reservedMarketFC?.valueChanges.subscribe(value => {
    this.typesSelected.clear();
    splitTypeReservationMarche(value).forEach(item => this.typesSelected.add(item));
  });

  isCheckboxDisabled: boolean = true;

  constructor(
    @Inject(TOKEN_ANC_BLOCK) display: ComponentDisplay,
    private readonly manager: ManagerPaEditionAnnonceService,
    { nativeElement }: ElementRef<HTMLElement>,
  ) { super(display, nativeElement); }

  ngOnInit(): void {
    this.codeNationalFC?.setValidators([Validators.maxLength(200), this.createValidatorRequired('codeNational')]);
    this.codeFailliteFC ?.setValidators([Validators.maxLength(200), this.createValidatorRequired('codeFaillite')]);
    this.codeCorruptionFC ?.setValidators([Validators.maxLength(200), this.createValidatorRequired('codeCorruption')]);
    this.codeCreanciersFC ?.setValidators([Validators.maxLength(200), this.createValidatorRequired('codeCreanciers')]);
    this.codeOrgFC ?.setValidators([Validators.maxLength(200), this.createValidatorRequired('codeOrg')]);
    this.codeDistorsionFC ?.setValidators([Validators.maxLength(200), this.createValidatorRequired('codeDistorsion')]);
    this.codeEnvFC ?.setValidators([Validators.maxLength(200), this.createValidatorRequired('codeEnv')]);
    this.codeBlanchimentFC ?.setValidators([Validators.maxLength(200), this.createValidatorRequired('codeBlanchiment')]);
    this.codeFraudeFC ?.setValidators([Validators.maxLength(200), this.createValidatorRequired('codeFraude')]);
    this.codeTrafFC ?.setValidators([Validators.maxLength(200), this.createValidatorRequired('codeTraf')]);
    this.codeInsolvFC ?.setValidators([Validators.maxLength(200), this.createValidatorRequired('codeInsolv')]);
    this.codeTravailFC ?.setValidators([Validators.maxLength(200), this.createValidatorRequired('codeTravail')]);
    this.codeLiquidFC ?.setValidators([Validators.maxLength(200), this.createValidatorRequired('codeLiquid')]);
    this.codeFausseFC ?.setValidators([Validators.maxLength(200), this.createValidatorRequired('codeFausse')]);
    this.codeNationauxFC ?.setValidators([Validators.maxLength(200), this.createValidatorRequired('codeNationaux')]);
    this.codeConflitsFC ?.setValidators([Validators.maxLength(200), this.createValidatorRequired('codeConflits')]);
    this.codePrepFC ?.setValidators([Validators.maxLength(200), this.createValidatorRequired('codePrep')]);
    this.codeFauteFC ?.setValidators([Validators.maxLength(200), this.createValidatorRequired('codeFaute')]);
    this.codeSanctionFC ?.setValidators([Validators.maxLength(200), this.createValidatorRequired('codeSanction')]);
    this.codeObligFC ?.setValidators([Validators.maxLength(200), this.createValidatorRequired('codeOblig')]);
    this.codePayFC ?.setValidators([Validators.maxLength(200), this.createValidatorRequired('codePay')]);
    this.codeActivFC ?.setValidators([Validators.maxLength(200), this.createValidatorRequired('codeActiv')]);
    this.codeImpotsFC ?.setValidators([Validators.maxLength(200), this.createValidatorRequired('codeImpots')]);
    this.codeInterFC ?.setValidators([Validators.maxLength(200), this.createValidatorRequired('codeInter')]);
  }

  ngOnDestroy(): void {
    this.reservedChange?.unsubscribe();
  }

  setReserved(type: TypeReservationMarche, remove = false): void {
    if (!this.isCheckboxDisabled) {
      if (remove) {
        this.typesSelected.delete(type);
      } else {
        this.typesSelected.add(type);
      }
      
      this.reservedMarketFC?.setValue([...this.typesSelected].join(';'), { emitEvent: false });
    }
  }

}
