import { Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractAncComponent } from '../abstract-anc-component';
import { ComponentDisplay } from '@atline/core/models/pa/annonce/form-display.model';
import { TOKEN_ANC_BLOCK } from '@atline/core/models/pa/annonce/anc-token-inject.model';
import { ManagerPaEditionAnnonceService } from '../../manager-pa-edition-annonce.service';
import * as _ from 'lodash';

@Component({
  selector: 'anc-type-pa',
  templateUrl: './anc-type-pa.component.html',
  styleUrls: ['./anc-type-pa.component.scss']
})
export class AncTypePaComponent extends AbstractAncComponent implements OnInit, OnDestroy {

  readonly typePaFC = this.manager.controls.classification_organisme_eform;
  readonly autreOrguanismeFC = this.manager.controls.classification_organisme_autre;

  readonly typePaList = [
    { value: 'body-pl', libelle: 'Organisme de droit public' },
    { value: 'body-pl-cga', libelle: 'Organisme de droit public, contrôlé par une autorité gouvernementale centrale' },
    { value: 'body-pl-la', libelle: 'Organisme de droit public, contrôlé par une collectivité locale'},
    { value: 'body-pl-ra', libelle: 'Organisme de droit public, contrôlé par une autorité régionale'},
    { value: 'cga', libelle: 'Autorité du gouvernement central'},
    { value: 'def-cont', libelle: 'Entrepreneur du secteur de la défense'},
    { value: 'eu-ins-bod-ag', libelle: 'Institution, organisme ou agence de l’UE'},
    { value: 'grp-p-aut', libelle: 'Groupe d’autorités publiques'},
    { value: 'int-org', libelle: 'Organisation internationale'},
    { value: 'la', libelle: 'Autorité locale'},
    { value: 'org-sub', libelle: 'Organisation attribuant un marché subventionné par un pouvoir adjudicateur'},
    { value: 'org-sub-cga', libelle: 'Organisation attribuant un marché subventionné par une autorité centrale gouvernementale'},
    { value: 'org-sub-la', libelle: 'Organisme attributaire d’un marché subventionné par une collectivité locale'},
    { value: 'org-sub-ra', libelle: 'Organisme attributaire d’un marché subventionné par une autorité régionale'},
    { value: 'pub-undert', libelle: 'Entreprise publique, contrôlée par une autorité gouvernementale centrale'},
    { value: 'pub-undert-la', libelle: 'Entreprise publique, contrôlée par une collectivité locale'},
    { value: 'pub-undert-ra', libelle: 'entreprise publique, contrôlée par une autorité régionale'},
    { value: 'ra', libelle: 'Autorité régionale'},
    { value: 'spec-rights-entity', libelle: 'spec-rights-entity'}
  ] as const;

  private typePaChange = this.typePaFC?.valueChanges.subscribe(value => {
    if (value !== '7') this.autreOrguanismeFC?.setValue(undefined);
  });

  constructor(
    @Inject(TOKEN_ANC_BLOCK) display: ComponentDisplay,
    private readonly manager: ManagerPaEditionAnnonceService,
    { nativeElement }: ElementRef<HTMLElement>,
  ) { super(display, nativeElement); }

  ngOnInit(): void {
    if (this.typePaFC && !this.typePaFC.value) this.typePaFC.setValue(undefined);
    if (this.autreOrguanismeFC && !this.autreOrguanismeFC.value) this.autreOrguanismeFC.setValue(undefined);
    this.typePaFC?.setValidators([this.createValidatorRequired('typePA')]);
  }

  ngOnDestroy(): void {
    this.typePaChange?.unsubscribe();
  }

  get isCenter(): boolean {
    return this.typePaFC?.value === 7;
  }

}
