import { TypePiece } from 'src/app/shared/enums/typePiece.enum';
import { EnveloppeService } from './../services/enveloppe.service';
import { DialogsService } from '../services/dialogs.service';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { MatDialogRef } from '@angular/material/dialog';
import { ConsultationGetPieceRequest } from '../models/api/requests/consultation.request';
import {  map } from 'rxjs/operators';
import { FileMS } from '../models/file.model';
import { Injectable } from '@angular/core';
import { PieceItem, PieceItem2 } from '../models/pieceItem2.model';
import { ConsultationsService } from '../services/consultations.service';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { HttpEventType, HttpHeaders } from '@angular/common/http';
import { GetFileResponseRequest } from '../models/api/requests/demandeComplementaire.request';
import { DemandeCompService } from '../services/demande-comp.service';
import { PaDumeGetRequest } from '../models/api/requests/dume/paDumeGet.request';
import { DumeService } from '../services/dume.service';
import { DepotService } from '../services/depot.service';
import { NotificationService } from '../services/history/notification.service';
import { NotificationsService } from '../services/notifications.service';
import { HistoryDumeService } from '../services/history/history-dume.service';
import { DemandeExecutionService } from '../services/history/demande-execution.service';
import { FileItem } from '../models/api/responses/fileItem.reponse';
import { LogsExportService } from '../services/logs-export.service';
import { GetNotificationPieceRequest } from '../models/api/requests/notifications.request';
import { AwardNotificationService } from '../services/award-notification.service';
import { QuestionAnwsersService } from '../services/question-anwsers.service';
import { AnnoncesService } from '../services/annonces.service';
import { AnnonceXMLRequest } from '../models/api/requests/annonce/annonceXML.request';
import { StatistiqueDowloadRequest } from '../models/api/requests/statistique.request';
import { StatistiqueService } from '../services/statistique.service';
import { Icons } from '@atline-shared/enums/icons.enum';

const sizes = ['octect', 'Ko', 'Mo', 'Go', 'To'] as const;
type Sizes = typeof sizes[number];

@Injectable({
  providedIn: 'root'
})
export class PieceHelper {

  private FILE_REG_EXP = '(filename=".+\.{1}[A-Za-z]+")';
  private fileIconsExtensionAvalaible = [
    {
      extension: 'docx',
      icon: Icons.DOC
    },
    {
      extension: 'doc',
      icon: Icons.DOC
    },
    {
      extension: 'xslx',
      icon: Icons.XLS
    },
    {
      extension: 'xls',
      icon: Icons.XLS
    },
    {
      extension: 'zip',
      icon: Icons.ZIP
    },
    {
      extension: 'pdf',
      icon: Icons.PDF
    }
  ];

  readonly sizes = sizes;
  loaderRef?: MatDialogRef<LoaderComponent>;

  constructor(
    private readonly consultationService: ConsultationsService,
    private readonly serviceDialog: DialogsService,
    private readonly demandeCompService: DemandeCompService,
    private readonly dumeService: DumeService,
    private readonly depotService: DepotService,
    private readonly historyNotificationService: NotificationService,
    private readonly historyDumeService: HistoryDumeService,
    private readonly demandeExecutionService: DemandeExecutionService,
    private readonly enveloppeService: EnveloppeService,
    private readonly logsExport: LogsExportService,
    private readonly notificationsService: NotificationsService,
    private readonly qAndRService: QuestionAnwsersService,
    private readonly awardNotificationService: AwardNotificationService,
    private readonly annonceServices: AnnoncesService,
    private readonly statistiqueService: StatistiqueService,
  ) { }


  /**
   * Récupérer les pièces qui sont de type AAPC
   * @param pieceList array de type PieceItem de pièces de la consultation
   * @returns un array avec l'ensemble des pièce qui sont de type AAPC
   */
  public getAAPC(pieceList: PieceItem[]): PieceItem[] {
    return pieceList.filter(piece => piece.type === TypePiece.AAPC);
  }

  /**
   * Récupérer les pièces qui sont de type dossier complet
   * @param pieceList array de type PieceItem de pièces de la consultation
   * @returns un array avec l'ensemble des pièce qui sont de type dossier complet
   */
  public getDossierComplet(pieceList: PieceItem[]): PieceItem[] {
    return pieceList.filter(piece => piece.type === TypePiece.DCE);
  }

  /**
   * Récupérer les pièces qui sont de type pièces communes
   * @param pieceList array de type PieceItem de pièces de la consultation
   * @returns un array avec l'ensemble des pièce qui sont de type pièces communes
   */
  public getPiecesCommunes(pieceList: PieceItem[]): PieceItem[] {
    return pieceList.filter(piece => piece.type === TypePiece.AUTRE);
  }

  /**
   * Recupérer la marque du pluriels sur plusieurs pièces sont présentes
   * @param a array de type PieceItem
   * @returns revoie s si il y a plusieurs pièces
   */
  public getPurialForPieces(a: PieceItem[]): string {
    return a.length > 1 ? 's' : '';
  }

  openLoaderDialog() {
    if (_.isUndefined(this.loaderRef))
      this.loaderRef = this.serviceDialog.openLoader('APP.SPINNER.LOADING');
  }

  closeLoadingDialog() {
    if (this.loaderRef)
      this.loaderRef.close();
  }

  catchErrorDownloadFile(): void {
    this.closeLoadingDialog();
    this.serviceDialog.createInfoDialog('', 'APP.DIALOG.DOWNLOAD_FILE_FAILED', 'APP.CLOSE');
  }

  displayPieceView(res: any, name?: string): void {
    this.closeLoadingDialog();
    const url = window.URL.createObjectURL(res.body);
    const a = document.createElement('a');
    a.href = url;
    a.download = name ?? this.getFilenameFromHeader(res.headers);
    a.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  /**
   * Get size of all of pieces
   * @param pieceList array de type PieceItem
   * @returns size of pieces
   */
  public getSizeOfPC(pieceList: (PieceItem | PieceItem2)[]): string {
    let tmp = 0;
    pieceList.forEach(elt => {
      tmp += elt.taille ? parseInt(elt.taille, 10) : 0;
    });
    if (tmp === 0) { return '0 octect'; }
    const i = Math.floor(Math.log(tmp) / Math.log(1024));
    return Math.round(tmp / Math.pow(1024, i)) + ' ' + this.sizes[i];
  }



  /**
   * Get size of all of pieces
   * @param size size of file
   * @param round round size result
   * @returns detail size of pieces
   */
  public getSizeOfPieceDetails(size: number | string, round = 0, base: 1000 | 1024 = 1024): [number, Sizes] {
    const tmp = parseInt(_.toString(size), 10);
    if (tmp === 0) { return [0, this.sizes[0]]; }
    const nRound = Math.pow(10, Math.abs(round));
    const i = Math.floor(Math.log(tmp) / Math.log(base));
    return [Math.round((tmp / Math.pow(base, i)) * nRound) / nRound, this.sizes[i]];
  }


  /**
   * Get size of all of pieces
   * @param size size of file
   * @param round round size result
   * @returns size of pieces
   */
  public getSizeOfPiece(size: number | string, round = 0, base: 1000 | 1024 = 1024): string {
    const [value, unit] = this.getSizeOfPieceDetails(size, round, base);
    return `${value} ${unit}`;
  }

  /**
   * get file extension from a File type
   * @param f file
   * @returns file extension
   */
  public getExtension(f: FileMS | File | FileItem): string {
    if (f instanceof File) {
      const fileNameSplited = f.name.split('.');
      return _.lowerCase(fileNameSplited[fileNameSplited.length - 1]);
    }
    return !_.isUndefined(f.type_fich) ? _.lowerCase(f.type_fich) : '';
  }

  /**
   *
   * @param cleDce cle dce de la consultation
   * @param cleEtab cle etab du pa
   * @param clePiece cle de la piece
   * @param codeRestreinte code pour acceder à la pièce si la consultation est restreinte
   */
  downloadPiece(req: ConsultationGetPieceRequest): void {
    this.openLoaderDialog();
    this.consultationService.getPiece(req).subscribe({
      next: (res) => {
        this.displayPieceView(res)
      },
      error: () => {
        this.catchErrorDownloadFile();
      }
    })
  }

  /**
   *
   * @param cleDce cle dce de la consultation
   * @param cleEtab cle etab du pa
   * @param clePiece cle de la piece
   * @param codeRestreinte code pour acceder à la pièce si la consultation est restreinte
   */
  downloadPieceGet(cleDce: string, cleEtab: string, clePiece: string, codeRestreinte?: string): void {
    const link = this.consultationService.getPieceLink({
      cle_dce: cleDce,
      cle_etab: cleEtab,
      cle_piece: clePiece,
      code_restreinte: codeRestreinte,
    });

    const a = document.createElement('a');
    a.href = link;
    a.download = '';
    a.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
    a.remove();
  }

  /**
   * @param cle_etab cle etab du pa
   * @param cle_pa cle pa du pa
   * @param cle_dce cle dce de la consultation
   * @param requested_dume_format chose pdf or xml
   * //@param demo if is user demo
   */
  dowloadDume(
    // tslint:disable:variable-name
    cle_etab: string,
    cle_pa: string, cle_dce: string,
    requested_dume_format: 'pdf' | 'xml',
    // demo = 0,
    filename?: string
    // tslint:enable:variable-name
  ): void {
    filename = filename !== undefined ? `DUME_${filename}` : 'DUME';
    const request: PaDumeGetRequest = {
      cle_etab,
      cle_pa,
      cle_dce,
      requested_dume_format,
      // demo
    };
    this.dumeService.paDumeGet(request).subscribe({
      next: (res) => {
        this.displayPieceView(res)
      },
      error: () => {
        this.catchErrorDownloadFile();
      }
    });
  }

  public downloadDepotReport(
    cleDce: string,
    cleRep: string,
  ): void {
    this.openLoaderDialog();
    this.depotService
      .websendReportPdf({
        cle_dce: cleDce,
        cle_rep: cleRep,
      })
      .subscribe({
        next: (res) => {
          this.displayPieceView(res, 'rapport.pdf')
        },
        error: () => {
          this.catchErrorDownloadFile();
        }
      });
  }


  /**
   *
   * @param cleDce cle dce de la consultation
   * @param cleEtab cle etab du pa
   * @param clePiece cle de la piece
   * @param report true is file retrieved is report
   * @param codeRestreinte code pour acceder à la pièce si la consultation est restreinte
   */
  downloadDcPiece(
    cleDce: string,
    cleDemande: string,
    cleReponse: string,
    asPa: boolean,
    report = false,
    cleEtab: string | null = null
  ): void {
    this.openLoaderDialog();
    const downloadPieceRequest = new GetFileResponseRequest();
    downloadPieceRequest.cle_dce = cleDce;
    downloadPieceRequest.cle_reponse = cleReponse;
    downloadPieceRequest.cle_demande = cleDemande;
    asPa ? downloadPieceRequest.as_pa = 1 : downloadPieceRequest.as_pa = 0;
    if (!_.isNil(cleEtab))
      downloadPieceRequest.cle_etab = cleEtab;

    this.demandeCompService.getResponseFile(downloadPieceRequest, report).subscribe({
      next: (res) => {
        this.displayPieceView(res)
      },
      error: () => {
        this.catchErrorDownloadFile();
      }
    });
  }

  /**
   *
   * @param cleDce cle dce de la consultation
   * @param cleEtab cle etab du pa
   * @param clePiece cle de la piece
   * @param codeRestreinte code pour acceder à la pièce si la consultation est restreinte
   */
  downloadDcPieceUploaded(cleDce: string, cleDemande: string, cleEtab: string): void {
    this.openLoaderDialog();
    const downloadPieceRequest = new GetFileResponseRequest();
    downloadPieceRequest.cle_dce = cleDce;
    downloadPieceRequest.cle_demande = cleDemande;
    downloadPieceRequest.cle_etab = cleEtab;

    this.demandeCompService.getFileDc(downloadPieceRequest).subscribe({
      next: (res) => {
        this.displayPieceView(res)
      },
      error: () => {
        this.catchErrorDownloadFile();
      }
    });
  }


  /**
   * extrait le nom du fichier depuis le header
   * @param headers httpHeader of response
   * @returns nom du fichier au format string
   */
  private getFilenameFromHeader(headers: HttpHeaders): string {
    const regExp = new RegExp(this.FILE_REG_EXP, 'g');
    const cd = headers.get('content-disposition');
    if (!_.isNull(cd)) {
      const matchReg = cd.match(regExp);
      if (!_.isNull(matchReg)
      ) {
        return matchReg[0].split('\"')[1];
      }
    }
    return '';
  }


  public isPieceAlreadyExist(pieceLib: string, pieceArr: (FileMS | FileItem)[]): boolean {
    return !_.isUndefined(_.find(pieceArr, ['nom_fich', pieceLib]));
  }


  public isPieceAlreadyExistCreate(pieceLib: string, pieceArr: FileMS[]): boolean {
    return !_.isUndefined(_.find(pieceArr, ['file_name', pieceLib]));
  }


  public getFileIconExtension(f: FileMS | File | FileItem): string {
    const temp = _.find(this.fileIconsExtensionAvalaible, ['extension', this.getExtension(f)]);
    return _.isUndefined(temp) ? 'file' : temp.icon;
  }


  public getFileIconExtensionFromString(f: string): Icons {
    const temp = _.find(this.fileIconsExtensionAvalaible, ['extension', f]);
    return _.isNil(temp) ? Icons.File : temp.icon;
  }


  /**
   * convert a file to a base64 string
   * @param f file
   */
  /* tslint:disable */
  public convertToBase64(f: File): any {
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(f);
      reader.onload = (): void => resolve(reader.result);
      reader.onerror = (error): void => reject(error);
    });

    const fileReader = new FileReader();
    fileReader.onloadend = (): string | ArrayBuffer | null => {
      return fileReader.result;
    };
  }

  /* tslint:enable */

  downloadNotificationPiece(
    cleEnt: string,
    cleDce: string,
    cleNotification: string,
  ): void {
        this.openLoaderDialog();

    this.historyNotificationService
      .websendNotificationFile({
        cle_ent: cleEnt,
        cle_dce: cleDce,
        cle_notification: cleNotification,
      })
      .subscribe({
        next: (res) => {
          this.displayPieceView(res);

        },
        error: () => {
          this.catchErrorDownloadFile();
        }
      })
  }

  downloadExportsExchangeWithOe(
    cleEtab: string,
    clePa: string,
    cleDce: string,
    typeFormat: 1 | 2,
  ): void {
        this.openLoaderDialog();

    this.logsExport
      .getExchangeWithOe({
        cle_etab: cleEtab,
        cle_pa: clePa,
        type_format: typeFormat,
        cle_dce: cleDce,
      }).subscribe({
        next: (res) => {
          this.displayPieceView(res)
        },
        error: () => {
          this.catchErrorDownloadFile();
        }
      });
  }

  downloadDemandeLrarReceipt(
    cleEtab: string,
    cleDce: string,
    cleDemande: string,
    clePa: string
  ): void {
        this.openLoaderDialog();

    this.demandeCompService
      .getLrarReciept({
        cle_etab: cleEtab,
        cle_dce: cleDce,
        cle_demande: cleDemande,
        cle_pa: clePa
      })
      .subscribe({
        next: (res) => {
          this.displayPieceView(res)
        },
        error: () => {
          this.catchErrorDownloadFile();
        }
      });
  }

  downloadQandRAttachment(
    cleEtab: string,
    cleDce: string,
    cleMail: string,
    clePa: string
  ): void {
        this.openLoaderDialog();

    this.qAndRService
      .getAttachment({
        cle_etab: cleEtab,
        cle_dce: cleDce,
        cle_mail: cleMail,
        cle_pa: clePa
      }).subscribe({
        next: (res) => {
          this.displayPieceView(res)
        },
        error: () => {
          this.catchErrorDownloadFile();
        }
      });
  }


  downloadNotificationPiecePa(
    param: GetNotificationPieceRequest
  ): void {
        this.openLoaderDialog();

    this.notificationsService
      .getPiece(param)
      .subscribe({
        next: (res) => {
          this.displayPieceView(res)
        },
        error: () => {
          this.catchErrorDownloadFile();
        }
      });
  }

  downloadStatistiqueCSV(
    param: StatistiqueDowloadRequest
  ): void {
        this.openLoaderDialog();

    this.statistiqueService
      .getCSV(param)
      .subscribe({
        next: (res) => {
          this.displayPieceView(res)
        },
        error: () => {
          this.catchErrorDownloadFile();
        }
      });
  }

  downloadNotificationLrarReceipt(
    cleEtab: string,
    cleDce: string,
    clePa: string,
    cleNotification: string,
  ): void {
        this.openLoaderDialog();

    this.notificationsService
      .getLrarReciept({
        cle_etab: cleEtab,
        cle_dce: cleDce,
        cle_notification: cleNotification,
        cle_pa: clePa
      })
      .subscribe({
        next: (res) => {
          this.displayPieceView(res)
        },
        error: () => {
          this.catchErrorDownloadFile();
        }
      });
  }

  downloadConsultationJournal(
    cleEtab: string,
    cleDce: string,
    clePa: string,
    format = 1 as 1 | 2
  ): void {
        this.openLoaderDialog();

    this.consultationService
      .getJournalHistorique({
        cle_etab: cleEtab,
        cle_dce: cleDce,
        cle_pa: clePa,
        type_format: format
      })
      .subscribe({
        next: (res) => {
          this.displayPieceView(res)
        },
        error: () => {
          this.catchErrorDownloadFile();
        }
      });
  }
  downloadConsultationJournalExanche(
    cleEtab: string,
    cleDce: string,
    clePa: string,
    format = 1 as 1 | 2
  ): void {
        this.openLoaderDialog();

    this.consultationService
      .getJournalExanche({
        cle_etab: cleEtab,
        cle_dce: cleDce,
        cle_pa: clePa,
        type_format: format
      })
      .subscribe({
        next: (res) => {
          this.displayPieceView(res)
        },
        error: () => {
          this.catchErrorDownloadFile();
        }
      });
  }
  downloadConsultationJournalDepots(
    cleEtab: string,
    cleDce: string,
    clePa: string,
    format = 1 as 1 | 2
  ): void {
        this.openLoaderDialog();

    this.consultationService
      .getJournalDepots({
        cle_etab: cleEtab,
        cle_dce: cleDce,
        cle_pa: clePa,
        type_format: format
      })
      .subscribe({
        next: (res) => {
          this.displayPieceView(res)
        },
        error: () => {
          this.catchErrorDownloadFile();
        }
      });
  }
  downloadConsultationJournalRetrait(
    cleEtab: string,
    cleDce: string,
    clePa: string,
    format = 1 as 1 | 2
  ): void {
        this.openLoaderDialog();

    this.consultationService
      .getJournalRetraits({
        cle_etab: cleEtab,
        cle_dce: cleDce,
        cle_pa: clePa,
        type_format: format
      })
      .subscribe({
        next: (res) => {
          this.displayPieceView(res)
        },
        error: () => {
          this.catchErrorDownloadFile();
        }
      });
  }

  downloadConsultationJournalRetraitCSV(
    cleEtab: string,
    cleDce: string,
    clePa: string,
    format = 1 as 1 | 2
  ): void {
        this.openLoaderDialog();

    this.consultationService
      .getJournalRetraitsCsv({
        cle_etab: cleEtab,
        cle_dce: cleDce,
        cle_pa: clePa,
        type_format: format
      })
      .subscribe({
        next: (res) => {
          this.displayPieceView(res)
        },
        error: () => {
          this.catchErrorDownloadFile();
        }
      });
  }

  downloadAwardNotificationFile(
    cleAvis: string,
  ): void {
        this.openLoaderDialog();

    this.awardNotificationService
      .getFile({
        cle_avis: cleAvis,
      }).subscribe({
        next: (res) => {
          this.displayPieceView(res)
        },
        error: () => {
          this.catchErrorDownloadFile();
        }
      });
  }

  downloadHistoryDume(
    idDume: string,
    cleEnt: string,
    dumeFormat: 'pdf' | 'xml',
  ): void {
        this.openLoaderDialog();

    this.historyDumeService
      .websendDumeFile({
        id_dume: idDume,
        cle_ent: cleEnt,
        dume_format: dumeFormat,
      })
      .subscribe({
        next: (res) => {
          this.displayPieceView(res)
        },
        error: () => {
          this.catchErrorDownloadFile();
        }
      });
  }

  downloadHistoryDemandePiece(
    cleEnt: string,
    cleDemande: string
  ): void {
        this.openLoaderDialog();

    this.demandeExecutionService
      .websendDemandePiece({
        cle_ent: cleEnt,
        cle_demande: cleDemande,
      })
      .subscribe({
        next: (res) => {
          this.displayPieceView(res)
        },
        error: () => {
          this.catchErrorDownloadFile();
        }
      })
  }


  downloadComplementaryReport(
    cleDce: string,
    cleResponse: string,
    cleDemande: string
  ): void {
        this.openLoaderDialog();

    this.demandeCompService
      .getResponseFile({
        cle_dce: cleDce,
        cle_reponse: cleResponse,
        cle_demande: cleDemande,
        as_pa: 0
      }, true)
      .subscribe({
        next: (res) => {
          this.displayPieceView(res)
        },
        error: () => {
          this.catchErrorDownloadFile();
        }
      });
  }

  downloadPlis(
    cleEtab: string,
    clePa: string,
    cleDce: string,
    cleReponse: string,
    cleEnveloppe: string,
    type = 'zip',
    callback?: () => any, // utilisé dans ouverture-plis.component telechargerDepot()
    error?: (err: any) => any,
  ): void {
    this.enveloppeService.getEnvelopeGet({
      cle_etab: cleEtab,
      cle_pa: clePa,
      cle_dce: cleDce,
      cle_reponse: cleReponse,
      cle_enveloppe: cleEnveloppe,
      type: type === 'p7m' ? 'p7m' : 'zip'
    }).subscribe((res) => {
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(res.body);
      a.download = this.getFilenameFromHeader(res.headers);
      a.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
      window.URL.revokeObjectURL(a.href);
      a.remove();
      if (callback) callback();
    }, error);
  }

  downloadPlisFichier(
    cleEtab: string,
    clePa: string,
    cleDce: string,
    cleReponse: string,
    cleEnveloppe: string,
    repertoireFichier: string,
    idFichier: string,
    callback: () => any,
    error?: (err: any) => any
  ): void {
    this.enveloppeService.getEnveloppeFileGet({
      cle_etab: cleEtab,
      cle_pa: clePa,
      cle_dce: cleDce,
      cle_reponse: cleReponse,
      cle_enveloppe: cleEnveloppe,
      repertoire_fichier: repertoireFichier,
      id_file: idFichier
    }).subscribe(res => {
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(res.body);
      a.download = this.getFilenameFromHeader(res.headers);
      a.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
      window.URL.revokeObjectURL(a.href);
      a.remove();
      if (callback) callback();
    }, error);
  }

  downloadAnnonceXML(params: AnnonceXMLRequest & { filename?: string }): void {
    const filename = params.filename || params.cle_annonce;
    this.annonceServices.annonceXML(params).subscribe(xmlStr => {
      const a = document.createElement('a');
      a.href = `data:text/plain;charset=utf-8,${encodeURIComponent(xmlStr)}`;
      a.download = `${filename}.xml`;
      a.click();
      a.remove();
    });
  }

  downloadAnnonceXMLProgress(params: AnnonceXMLRequest & { filename?: string }): Observable<number | string> {
    const filename = params.filename || params.cle_annonce;
    return this.annonceServices.annonceXML({ ...params, progress: true }).pipe(map(e => {
      switch (e.type) {
        case HttpEventType.DownloadProgress:
          return e.total ? Math.ceil(e.loaded / e.total * 100) : 0;
        case HttpEventType.Response:
          if (e.body) {
            const a = document.createElement('a');
            a.href = `data:text/plain;charset=utf-8,${encodeURIComponent(e.body)}`;
            a.download = `${filename}.xml`;
            a.click();
            a.remove();
          }
          return e.body ?? '';
        default:
          return 0;
      }
    }));
  }

  convertToPieceItem(piece: PieceItem2): PieceItem {
    const { extention, taille, type_mime, is_aapc, libelle, lien, cle_piece, cle, nom } = piece;
    return {
      extension: extention,
      taille,
      type: is_aapc ? TypePiece.AAPC : TypePiece.DCE,
      type_mime,
      is_aapc: !_.isNaN(Number(is_aapc)) ? Number(is_aapc) : undefined,
      libelle,
      lien,
      cle: cle || cle_piece,
      nom,
    };
  }

  convertsToPiecesItems(pieces: PieceItem2[]): PieceItem[] {
    return pieces.map(piece => this.convertToPieceItem(piece));
  }

  getLibelle(file: File): string {
    return file.name;
  }
}
