import { NotificationsModel } from '../../notificationItem.model';
import { Pagination } from '../../pagination.model';

// tslint:disable:variable-name
export class NotificationResponse {
  public is_user_certified!: boolean;
  public certification_date_accept?: string;
  public dce_notifications!: NotificationsModel[];
  public nb_total!: number;
  public pagination!: Pagination;
}
// tslint:enable:variable-name
