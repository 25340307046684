<div class="anc-component" fxLayout="column nowrap">
  <app-field-edition *ngIf="composition?.profil && profilFC"
    [label]="'APP.ANNONCES.FACTURATION.ADRESS.PROFIL' | translate"
    [required]="isRequired(required?.profil)"
    [invalid]="profilFC.touched && profilFC.invalid">
    <div fxLayout="row">
      <mat-form-field fxFlex="50" appearance="outline">
        <mat-select [formControl]="profilFC">
          <mat-option *ngFor="let facturation of facturations" [value]="facturation.fact_classe_profit">
            {{ facturation.fact_denomination | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </app-field-edition>

  <app-field-edition *ngIf="composition?.name && nameFC"
    [label]="'APP.ANNONCES.FACTURATION.ADRESS.NAME' | translate"
    [required]="isRequired(required?.name)"
    [invalid]="nameFC.touched && nameFC.invalid">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <mat-form-field fxFlex="50" appearance="outline">
        <input matInput [formControl]="nameFC">
      </mat-form-field>
      <button mat-icon-button disabled><mat-icon svgIcon="book_grey"></mat-icon></button>
    </div>
  </app-field-edition>

  <app-field-edition *ngIf="composition?.recipant && recipantFC"
    [label]="'APP.ANNONCES.FACTURATION.ADRESS.RECIPIANT' | translate"
    [required]="isRequired(required?.recipant)"
    [invalid]="recipantFC.touched && recipantFC.invalid">
    <div fxLayout="row nowrap" fxLayoutAlign="start center" fxLayoutGap="10px">
      <mat-form-field fxFlex="50" appearance="outline">
        <input matInput [formControl]="recipantFC">
      </mat-form-field>
      <span class="text-it">{{ 'APP.ANNONCES.FACTURATION.EXEMPLE.RECIPIANT' | translate }}</span>
    </div>
  </app-field-edition>

  <app-field-edition *ngIf="composition?.building && buildingFC"
    [label]="'APP.ANNONCES.FACTURATION.ADRESS.BULDING' | translate"
    [required]="isRequired(required?.building)"
    [invalid]="buildingFC.touched && buildingFC.invalid">
    <div fxLayout="row nowrap" fxLayoutAlign="start center" fxLayoutGap="10px">
      <mat-form-field fxFlex="50" appearance="outline">
        <input matInput [formControl]="buildingFC">
      </mat-form-field>
      <span class="text-it">{{ 'APP.ANNONCES.FACTURATION.EXEMPLE.BUILDING' | translate }}</span>
    </div>
  </app-field-edition>

  <app-field-edition *ngIf="composition?.road && roadFC"
    [label]="'APP.ANNONCES.FACTURATION.ADRESS.ROAD' | translate"
    [required]="isRequired(required?.road)"
    [invalid]="roadFC.touched && roadFC.invalid">
    <div fxLayout="row nowrap" fxLayoutAlign="start center" fxLayoutGap="10px">
      <mat-form-field fxFlex="50" appearance="outline">
        <input matInput [formControl]="roadFC">
      </mat-form-field>
      <span class="text-it">{{ 'APP.ANNONCES.FACTURATION.EXEMPLE.ROAD' | translate }}</span>
    </div>
  </app-field-edition>

  <app-field-edition *ngIf="composition?.special && specialFC"
    [label]="'APP.ANNONCES.FACTURATION.ADRESS.SPECIAL' | translate"
    [required]="isRequired(required?.special)"
    [invalid]="specialFC.touched && specialFC.invalid">
    <div fxLayout="row nowrap" fxLayoutAlign="start center" fxLayoutGap="10px">
      <mat-form-field fxFlex="50" appearance="outline">
        <input matInput [formControl]="specialFC">
      </mat-form-field>
      <span class="text-it">{{ 'APP.ANNONCES.FACTURATION.EXEMPLE.SPECIAL' | translate }}</span>
    </div>
  </app-field-edition>

  <app-field-edition *ngIf="composition && (composition.cp || composition.city) && (cpFC || cityFC)"
    [label]="'APP.ANNONCES.FACTURATION.ADRESS.CITY' | translate"
    [required]="isRequired(required?.cp && required?.city)"
    [invalid]="false">
    <div fxLayout="row">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" fxFlex="50">
        <mat-form-field appearance="outline" id="cp" *ngIf="composition.cp && cpFC" fxFlex="30">
          <input matInput [formControl]="cpFC">
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="composition.city && cityFC" fxFlex="70">
            <input matInput [formControl]="cityFC">
        </mat-form-field>
      </div>
    </div>
  </app-field-edition>

  <app-field-edition *ngIf="countryFC"
    [label]="'APP.ANNONCES.FACTURATION.ADRESS.COUNTRY' | translate"
    [required]="false"
    [invalid]="false">
    <div fxLayout="row">
      <mat-form-field fxFlex="50" appearance="outline">
        <mat-select [formControl]="countryFC">
          <mat-option *ngFor="let country of countries" [value]="country.iso">
            {{ country.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </app-field-edition>
</div>
