import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { ComponentDisplay } from '@atline/core/models/pa/annonce/form-display.model';
import { ManagerPaEditionAnnonceService } from '../../manager-pa-edition-annonce.service';
import { TOKEN_ANC_BLOCK } from '@atline/core/models/pa/annonce/anc-token-inject.model';
import { AbstractAncComponent } from '../abstract-anc-component';

@Component({
  selector: 'anc-base-sans-identifiant',
  templateUrl: './anc-base-sans-identifiant.component.html',
  styleUrls: ['./anc-base-sans-identifiant.component.scss']
})
export class AncBaseSansIdentifiantComponent extends AbstractAncComponent implements OnInit {

  readonly infoComp = this.manager.controls.autre_base_juridique_sans_ident_desc;

  constructor(
    @Inject(TOKEN_ANC_BLOCK) display: ComponentDisplay,
    private readonly manager: ManagerPaEditionAnnonceService,
    { nativeElement }: ElementRef<HTMLElement>,
  ) { super(display, nativeElement); }

  ngOnInit(): void {

  }

  get template(): string {
    return this.display.template || 'full';
  }

}
