import { EmailPaItem } from './PaEmailItem.model';

// tslint:disable:variable-name

export class NotificationsModel {

  public entreprise!: string;
  public cle_entreprise!: string;
  public date_depot!: number;
  public date_depot_f!: string;
  public is_attributaire!: boolean;
  public attributaires_data!: [];
  public ent_notifications!: NotificationItem[];
}

export class NotificationItem {
  public id!: number;
  public cle_notification!: string;
  public cle_dce!: string;
  public cle_utilisateur!: string;
  public email_utilisateur!: string;
  public date_envoi!: number;
  public date_envoi_f!: string;
  public emplacement!: string;
  public fichier!: string;
  public extension!: string;
  public md5_fichier!: string;
  public type!: number;
  public etat!: string;
  public lrar!: string;
  public cle_entreprise!: string;
  public denomination_ent!: string;
  public email_destinataire!: Array<EmailPaItem>;
  public date_acces!: number;
  public date_refus!: number;
  public date_ouverture!: number;
  public code_acces!: string;
  public envoi_recap!: number;
  public envois_notification!: NotificationSentItem[];
  public date_creation_f!: string;
  public date_acces_f!: string;
  public date_refus_f!: string;
  public date_ouverture_f!: string;
}

export class NotificationSentItem {
  public id!: number;
  public cle_notification_envoi!: string;
  public cle_utilisateur!: string;
  public cle_etablissement!: string;
  public reference_dce!: string;
  public nom_utilisateur!: string;
  public date_envoi!: number;
  public email_destinataire!: string;
  public lrar!: boolean;
  public reference_lrar!: string;
  public certification!: string;
  public date_envoi_f!: string;
}

    // tslint:enable:variable-name
