import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { forkJoin, from, Observable, of } from 'rxjs';
import { filter, map, take, toArray } from 'rxjs/operators';
import { TypeCPV } from 'src/app/shared/enums/typeCpv.enum';
import { CpvItem, isCpvItem } from '../models/cvpItem.model';
import { CpvService } from './cpv.service';
import { AbstractControl, ValidationErrors } from '@angular/forms';

type ListCpvByType = { [type in TypeCPV]: CpvItem[] };

@Injectable({
  providedIn: 'root'
})
export class FilterCodeCpvService {

  readonly lists: ListCpvByType = {
    [TypeCPV.PRINCIPAL]: [],
    [TypeCPV.SECONDAIRE]: [],
  };

  constructor(cpvService: CpvService) {
    forkJoin({
      [TypeCPV.PRINCIPAL]: cpvService.getCodeCPV({ type: TypeCPV.PRINCIPAL })
        .pipe(map(({ cpv_list }) => cpv_list.map(c => {return {...c, typeCpv: TypeCPV.PRINCIPAL}}))),
      [TypeCPV.SECONDAIRE]: cpvService.getCodeCPV({ type: TypeCPV.SECONDAIRE })
        .pipe(map(({ cpv_list }) => cpv_list.map(c => {return {...c, typeCpv: TypeCPV.SECONDAIRE}}))),
    }).subscribe(res => Object.assign(this.lists, res));
  }

  filter(recherche: string, type: TypeCPV, max = 100): Observable<CpvItem[]> {
    const value = recherche.toLowerCase().trim();
    if (!value) return of([]);
    return from(this.lists[type]).pipe(
      filter(({ label_cpv, code_cpv }) => _.flow(
        (o: {label_cpv: string, code_cpv: string}) => _.identity(o),
        _.values,
        _.join,
        _.toLower,
        (value: string) => value.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
        _.partialRight(_.includes, value.normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
      )({ label_cpv, code_cpv })),
      take(max),
      toArray(),
    );
  }

  filterByLabel(recherche: string, type = TypeCPV.PRINCIPAL, max = 100): Observable<CpvItem[]> {
    const value = recherche.toLowerCase().trim();
    if (!value) return of([]);
    return from(this.lists[type]).pipe(
      filter(({ label_cpv }) => _.flow(
        (o: {label_cpv: string}) => _.identity(o),
        _.values,
        _.join,
        _.toLower,

        (value: string) => value.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
        _.partialRight(_.includes, value.normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
      )({ label_cpv })),
      take(max),
      toArray(),
    );
  }

  valideCpv(cpv: string | CpvItem): boolean {
    const cpvList = [...this.lists.principal, ...this.lists.secondaire];
    if (isCpvItem(cpv)) return cpvList.some(({ code_cpv }) => code_cpv === cpv.code_cpv);
    else if (_.isString(cpv)) return cpvList.some(({ code_cpv }) => code_cpv === cpv);
    return false;
  }

  validatorsCpv(control: AbstractControl): ValidationErrors | null {
    return this.valideCpv(control.value) ? null : { invalidCPV: 'CPV doesn\'t exist.' };
  }
}
