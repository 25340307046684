<div class="anc-component" fxLayout="column nowrap" style="gap: 10px">
  <div class="actions-lot" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutGap="20px">
      <div class="action">
        <app-button size="medium" [icon]="Icons.Plus" (click)="addLot()"></app-button>
        {{'APP.ANNONCES.LOTS.ADD' | translate | uppercase}}
      </div>
      <div class="action">
        <app-button size="medium" [icon]="Icons.Bin" (click)="removeAllLots()" [disabled]="!hasLots"></app-button>
        {{'APP.ANNONCES.LOTS.DELETE_ALL' | translate | uppercase}}
      </div>
    </div>

    <div>
      <mat-icon class="mat-icon-action-link" [ngClass]="{'animate-loading': lotLoading}" (click)="refreshLots()"
        [svgIcon]="Icons.Refresh"></mat-icon>
    </div>

  </div>
  <div class="lots-list" fxLayout="column nowrap" fxLayoutGap="20px">
    <ng-container *ngIf="lotLoading; then laodingTemplate; else lotsTemplate"></ng-container>
    <ng-template #laodingTemplate>
      <div fxLayout="column" fxLayoutAlign="center center">
        <app-loading-spinner [size]="25"></app-loading-spinner>
        {{'APP.ANNONCES.LOTS.LOADING' | translate }}
      </div>
     
    </ng-template>
    <ng-template #lotsTemplate>
      <app-lot-annonce *ngFor="let lot of lots; let i = index" [lot]="lot" (lotChange)="lotChange(i, $event)"
        (cancel)="removeLot(i)" (deleted)="removeLot(i)">
      </app-lot-annonce>
      <div *ngIf="!lots.length" fxLayoutAlign="center center">
        {{'APP.ANNONCES.LOTS.EMPTY' | translate }}
      </div>
    </ng-template>
  </div>
</div>