<div class="anc-component" gdColumns="1fr 1fr" gdGap="0px 20px" *ngIf="composition">
  <div fxLayout="column nowrap">

    <!-- ADR 1 -->
    <app-field-edition *ngIf="composition.adr1 && livAdr1FC"
      [label]="'APP.ANNONCES.ADDITIONAL-INFOS-EXECUSSION-PLACE.ADR1' | translate"
      [required]="controlIsRequired(livAdr1FC)"
      [invalid]="livAdr1FC.touched && livAdr1FC.invalid">
      <span *ngIf="isEform" class="sub-label" sub-label>BT-5101(a)-Procedure</span>
      <mat-form-field appearance="outline">
        <textarea matInput
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="5"
          cdkAutosizeMaxRows="10"
          [formControl]="livAdr1FC"
          class="text-area-not-rised">
        </textarea>
      </mat-form-field>
    </app-field-edition>

    <!-- ADR 2 -->
    <app-field-edition *ngIf="composition.adr2 && livAdr2FC"
      [label]="'APP.ANNONCES.ADDITIONAL-INFOS-EXECUSSION-PLACE.ADR1' | translate"
      [required]="controlIsRequired(livAdr2FC)"
      [invalid]="livAdr2FC.touched && livAdr2FC.invalid">
      <span *ngIf="isEform" class="sub-label" sub-label>BT-5101(b)-Procedure</span>
      <mat-form-field appearance="outline">
        <textarea matInput
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="5"
          cdkAutosizeMaxRows="10"
          [formControl]="livAdr2FC"
          class="text-area-not-rised">
        </textarea>
      </mat-form-field>
    </app-field-edition>

    <!-- ADR 3 -->
    <app-field-edition *ngIf="composition.adr3 && livAdr3FC"
      [label]="'APP.ANNONCES.ADDITIONAL-INFOS-EXECUSSION-PLACE.ADR3' | translate"
      [required]="controlIsRequired(livAdr3FC)"
      [invalid]="livAdr3FC.touched && livAdr3FC.invalid">
      <span *ngIf="isEform" class="sub-label" sub-label>BT-5101(c)-Procedure</span>
      <mat-form-field appearance="outline">
        <textarea matInput
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="5"
          cdkAutosizeMaxRows="10"
          [formControl]="livAdr3FC"
          class="text-area-not-rised">
        </textarea>
      </mat-form-field>
    </app-field-edition>
  </div>
  <div fxLayout="column nowrap">
    <!-- PAYS -->
    <app-field-edition *ngIf="composition.country && livPaysFC"
      [label]="'APP.ANNONCES.ADDITIONAL-INFOS-EXECUSSION-PLACE.COUNTRY' | translate"
      [required]="controlIsRequired(livPaysFC)"
      [invalid]="livPaysFC.touched && livPaysFC.invalid">
      <span class="sub-label" sub-label>BT-5141-Procedure</span>
      <mat-form-field appearance="outline">
        <mat-select [formControl]="livPaysFC">
          <mat-option *ngFor="let country of countries" [value]="country.iso">
            {{ country.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </app-field-edition>

    <!-- SOUS ENTITÉ PAYS -->
    <app-field-edition *ngIf="composition.nuts && lieuNutsFC"
      [label]="'APP.ANNONCES.ADDITIONAL-INFOS-EXECUSSION-PLACE.NUTS' | translate"
      [required]="controlIsRequired(lieuNutsFC)"
      [invalid]="lieuNutsFC.touched && lieuNutsFC.invalid">
      <span *ngIf="isEform" class="sub-label" sub-label>BT-5071-Procedure</span>
      <mat-form-field appearance="outline">
        <input matInput #lieuNuts [formControl]="lieuNutsFC" (input)="lieuNuts.value = lieuNuts.value.toUpperCase()" [matAutocomplete]="lieuNutsAuto"/>
        <mat-autocomplete #lieuNutsAuto="matAutocomplete">
          <mat-option *ngFor="let item of filterNuts | async" [value]="item.code_nuts">
            {{ item.label_nuts }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </app-field-edition>

    <!-- CODE POSTAL / VILLE -->
    <app-field-edition *ngIf="(composition.cp || composition.city) && (adrCpFC || adrVilleFC)"
      [label]="composition.cp && composition.city ? ('APP.ANNONCES.ADDITIONAL-INFOS-EXECUSSION-PLACE.ZIPCODE' | translate) + ' / ' + ('APP.ANNONCES.ADDITIONAL-INFOS-EXECUSSION-PLACE.CITY' | translate) : (composition.cp ? ('APP.ANNONCES.ADDITIONAL-INFOS-EXECUSSION-PLACE.ZIPCODE' | translate) : ('APP.ANNONCES.ADDITIONAL-INFOS-EXECUSSION-PLACE.CITY' | translate) )"
      [required]="(!!adrCpFC && controlIsRequired(adrCpFC)) || (!!adrVilleFC && controlIsRequired(adrVilleFC))"
      [invalid]="(adrCpFC?.touched && adrCpFC?.invalid) || (adrVilleFC?.touched && adrVilleFC?.invalid)">
      <span *ngIf="isEform" class="sub-label" sub-label>BT-5121-Procedure / BT-5131-Procedure</span>
      <div fxLayout="row nowrap" fxLayoutGap="10px">
        <mat-form-field appearance="outline" fxFlex="30" *ngIf="adrCpFC">
          <input matInput [formControl]="adrCpFC"/>
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="adrVilleFC">
          <input matInput [formControl]="adrVilleFC"/>
        </mat-form-field>
      </div>
    </app-field-edition>
    <!-- AUTRE -->
    <app-field-edition *ngIf="composition.restrict && livAutresRestrictFC" gdColumn="1 / span 2"
      [label]="'APP.ANNONCES.ADDITIONAL-INFOS-EXECUSSION-PLACE.RESTRICTION' | translate"
      [required]="controlIsRequired(livAutresRestrictFC)"
      [invalid]="livAutresRestrictFC.touched && livAutresRestrictFC.invalid">
      <span *ngIf="isEform" class="sub-label" sub-label>BT-727-Procedure</span>
      <mat-form-field appearance="outline">
        <mat-select [formControl]="livAutresRestrictFC">
          <mat-option [value]="null"></mat-option>
          <mat-option *ngFor="let option of restrictionOptions" [value]="option">
            {{ 'APP.ANNONCES.ADDITIONAL-INFOS-EXECUSSION-PLACE.' + (option | uppercase) | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </app-field-edition>
  </div>

  <div fxLayout="column nowrap" gdColumn="1 / span 2">
    <app-field-edition *ngIf="composition.infos_comp_marche && infosCompFC"
      [label]="'APP.ANNONCES.ADDITIONAL-INFOS-EXECUSSION-PLACE.INFOS-COMP-MARCHE' | translate"
      [required]="controlIsRequired(infosCompFC)"
      [invalid]="infosCompFC.touched && infosCompFC.invalid">
      <span *ngIf="isEform" class="sub-label" sub-label>BT-728-Procedure</span>
      <div fxLayout="row nowrap" fxLayoutAlign="start end" fxLayoutGap="10px">
        <mat-form-field appearance="outline" fxFlex="85">
          <textarea matInput #infosCompMarche
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="5"
            cdkAutosizeMaxRows="10"
            [formControl]="infosCompFC"
            maxlength="400"
            class="text-area-not-rised">
          </textarea>
        </mat-form-field>
        <span [inputCounter]="infosCompMarche" [total]="true" class="text-nowrap"></span>
      </div>
    </app-field-edition>
  </div>
</div>
