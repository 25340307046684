<div class="anc-component">

    <app-field-edition [label]="'APP.ANNONCES.ORGANISATION.ISCHEFGROUP.LABEL' | translate" [invalid]="false"
        *ngIf="isGroupLeaderFC && composition && composition.isGroupLeader">
        <span class="sub-label" sub-label>OPP-050-Organization</span>
        <app-yes-no-radio-button [value]="isGroupLeaderFC.value"
            (valueChange)="isGroupLeaderFC.setValue($event)"></app-yes-no-radio-button>
    </app-field-edition>
    <app-field-edition [label]="'APP.ANNONCES.ORGANISATION.ISCENTRALPUBLIC.LABEL' | translate" [invalid]="false"
        *ngIf="isCentralPurchasingFC && composition && composition.isCentralPurchasing">
        <span class="sub-label" sub-label>OPP-051-Organization</span>
        <app-yes-no-radio-button [value]="isCentralPurchasingFC.value"
            (valueChange)="isCentralPurchasingFC.setValue($event)"></app-yes-no-radio-button>
    </app-field-edition>
    <app-field-edition [label]="'APP.ANNONCES.ORGANISATION.ISCENTRALSERVICE.LABEL' | translate" [invalid]="false"
        *ngIf="isCentralPurchasingBodySuppliersFC && composition && composition.isCentralPurchasingBodySuppliers">
        <span class="sub-label" sub-label>OPP-052-Organization</span>
        <app-yes-no-radio-button [value]="isCentralPurchasingBodySuppliersFC.value"
            (valueChange)="isCentralPurchasingBodySuppliersFC.setValue($event)"></app-yes-no-radio-button>
    </app-field-edition>

</div>