<app-dialog [dialogRef]="dialogRef">

  <ng-container header>
    {{ title | translate: informations }}
  </ng-container>


  <ng-container body>
    <p>{{ description | translate: informations }}</p>
  </ng-container>

  <ng-container footer>
    <div fxLayout="row" fxLayoutAlign="end center" fxFlex="100">
      <button color="secondary" mat-raised-button [mat-dialog-close]="false" (click)="onDismiss()">{{'APP.NO' |
        translate
        | uppercase}}</button>
      <button color="primary" mat-raised-button mat-dialog-close [mat-dialog-close]="true"
        (click)="onConfirm()">{{'APP.YES' | translate | uppercase}}</button>
    </div>
  </ng-container>

</app-dialog>