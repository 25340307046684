import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import * as _ from 'lodash';

interface OnlyNumberConfig {
  decimalActive?: boolean;
  ctrlKeyActive?: boolean;
  shiftKeyActive?: boolean;
  metaKeyActive?: boolean;
  min?: number;
  max?: number;
}

const touchException = ['Delete', 'Backspace', 'Tab', 'Escape', 'Enter', 'NumLock', 'ArrowLeft', 'ArrowRight', 'End', 'Home'];

@Directive({
  selector: 'input[onlyNumber]',
})
export class OnlyNumberDirective implements OnlyNumberConfig {

  @Input()
  onlyNumber: any = true;

  @Input()
  decimalActive?: boolean;

  @Input()
  ctrlKeyActive?: boolean;

  @Input()
  shiftKeyActive?: boolean;

  @Input()
  metaKeyActive?: boolean;

  @Input()
  min?: number;

  @Input()
  max?: number;

  private readonly inputTarget!: HTMLInputElement;

  constructor(elementRef: ElementRef<HTMLInputElement>) {
    this.inputTarget = elementRef.nativeElement;
  }

  @HostListener('keypress', ['$event'])
  keypressed(event: KeyboardEvent): void {
    if (_.isString(this.onlyNumber) || this.onlyNumber) {
      const { ctrlKey, metaKey, shiftKey, key } = event;
      const valid = touchException.includes(key) ||
        (key === '.' && (this.decimalActive ?? true)) ||
        (key === '-' && ((event.target as any)?.selectionStart === 0)) ||
        (key === 'a' && (((this.ctrlKeyActive ?? true) && ctrlKey) || ((this.metaKeyActive ?? true) && metaKey))) ||
        (key === 'c' && (((this.ctrlKeyActive ?? true) && ctrlKey) || ((this.metaKeyActive ?? true) && metaKey))) ||
        (key === 'v' && (((this.ctrlKeyActive ?? true) && ctrlKey) || ((this.metaKeyActive ?? true) && metaKey))) ||
        (key === 'x' && (((this.ctrlKeyActive ?? true) && ctrlKey) || ((this.metaKeyActive ?? true) && metaKey)));
      if (valid) return;
      if (((this.shiftKeyActive ?? true) && shiftKey) || !_.range(0, 10).includes(+key)) {
        event.preventDefault();
      }
    }
  }

  @HostListener('input', ['$event'])
  input(event: InputEvent): void {
    const target = event.target as HTMLInputElement | null;
    if (!target || _.isEmpty(target?.value)) return;

    const value = Number(target?.value) || 0;
    if (!_.isNil(this.min) && this.min > value) this.inputTarget.value = this.min.toString();
    if (!_.isNil(this.max) && this.max < value) this.inputTarget.value = this.max.toString();
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    const value = Number(event.clipboardData?.getData('text/plain'));
    if (_.isNaN(value)) event.preventDefault();
  }

}
