import { Component, OnInit, Inject, OnDestroy, ElementRef } from '@angular/core';
import { AbstractAncComponent } from '../abstract-anc-component';
import { Subscription } from 'rxjs';
import { JalItem } from '@atline/core/models/api/responses/supportsList.response';
import { TOKEN_ANC_BLOCK } from '@atline/core/models/pa/annonce/anc-token-inject.model';
import { ComponentDisplay } from '@atline/core/models/pa/annonce/form-display.model';
import { ManagerPaEditionAnnonceService } from '../../manager-pa-edition-annonce.service';

@Component({
  selector: 'anc-publication-support',
  templateUrl: './anc-publication-support.component.html',
  styleUrls: ['./anc-publication-support.component.scss']
})
export class AncPublicationSupportComponent extends AbstractAncComponent implements OnInit, OnDestroy {

  readonly DEFAULT_IMG = 'https://www.marches-securises.fr/pa/?module=script|afficher_logo_s&cle_entreprise=1645989173mdeesdmmto3y';

  public bases64: (string | undefined)[] = [];
  private readonly subs = new Subscription();

  constructor(
    @Inject(TOKEN_ANC_BLOCK) display: ComponentDisplay,
    private readonly manager: ManagerPaEditionAnnonceService,
    // private readonly logoService: LogoService,
    // private readonly activatedRoute: ActivatedRoute,
    { nativeElement }: ElementRef<HTMLElement>,
    ) { super(display, nativeElement); }

  ngOnInit(): void {
    // this.initLoadingLogos();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  get supports(): JalItem[] {
    return this.manager.supports;
  }

  /* TODO
  private initLoadingLogos(): void {
    const loadLogo = (cle: string): Observable<string| undefined> => {
      return this.logoService.loadLogoEnt({ cle_etab: this.manager.cleEtab, cle_ent: cle });
    };

    const event = merge(this.activatedRoute.paramMap, this.manager.annonceChange).pipe(
      mergeMap(() => forkJoin(this.supports.map(({ entreprise }) => loadLogo(entreprise))))
    ).subscribe(res => this.bases64 = res);
    this.subs.add(event);
  } */

}
