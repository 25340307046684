import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { AbstractAncComponent } from '../abstract-anc-component';
import { TOKEN_ANC_BLOCK } from '@atline/core/models/pa/annonce/anc-token-inject.model';
import { ComponentDisplay } from '@atline/core/models/pa/annonce/form-display.model';
import { ManagerPaEditionAnnonceService } from '../../manager-pa-edition-annonce.service';
import { CountryItem } from '@atline/core/models/countryItem.model';

@Component({
  selector: 'anc-facturation-adress',
  templateUrl: './anc-facturation-adress.component.html',
  styleUrls: ['./anc-facturation-adress.component.scss']
})
export class AncFacturationAdressComponent extends AbstractAncComponent implements OnInit {

  readonly profilFC   = this.manager.controls.fact_classe_profit;
  readonly nameFC     = this.manager.controls.fact_denomination;
  readonly recipantFC = this.manager.controls.fact_adr1;
  readonly buildingFC = this.manager.controls.fact_adr2;
  readonly roadFC     = this.manager.controls.fact_adr3;
  readonly specialFC  = this.manager.controls.fact_adr4;
  readonly cpFC       = this.manager.controls.fact_cp;
  readonly cityFC     = this.manager.controls.fact_ville;
  readonly countryFC  = this.manager.controls.fact_pays;

  readonly countries: CountryItem[] = [];

  constructor(
    @Inject(TOKEN_ANC_BLOCK) display: ComponentDisplay,
    private readonly manager: ManagerPaEditionAnnonceService,
    { nativeElement }: ElementRef<HTMLElement>,
  ) { super(display, nativeElement); }

  ngOnInit(): void {
    this.manager.getCountries().subscribe(c => this.countries.push(...c));
  }

  get facturations(): {fact_classe_profit: string, fact_denomination: string}[] {
    return this.manager.facturations;
  }

}
