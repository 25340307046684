import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Icons } from '@atline-shared/enums/icons.enum';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnChanges {

  @Input() dialogRef!: MatDialogRef<any>;
  @Input() cross = true;
  @Input() callback?: (p: any) => void;

  icons = Icons;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dialogRef) this.dialogRef.addPanelClass('app-dialog');
  }

  close(): void {
    this.dialogRef.close();
  }

}
