import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { ResetPasswordResponse } from "../models/api/responses/resertPassword.response";
import { ChangePasswordRequest } from "../models/api/requests/changePassword.request";

@Injectable({
    providedIn: 'root'
  })
export class ResetPaswordService {

    constructor(
        private readonly apiService: ApiService
    ) {}

    resetPassword(mail: string, locale: string) {
       return this.apiService.post(ResetPasswordResponse, {identifiant: mail, locale}, 'reset_password'); 
    }

    openLink(token: string) {
        return this.apiService.post(ResetPasswordResponse, {token}, 'open_form_pwd');
    }

    changePassword(req: ChangePasswordRequest) {
        return this.apiService.post(ResetPasswordResponse, req, 'change_password');
    }

}