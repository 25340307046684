import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { ComponentDisplay, ValueParamsComp } from '@atline/core/models/pa/annonce/form-display.model';
import { TOKEN_ANC_BLOCK } from '@atline/core/models/pa/annonce/anc-token-inject.model';
import { AbstractAncComponent } from '../abstract-anc-component';
import { ManagerPaEditionAnnonceService } from '../../manager-pa-edition-annonce.service';
import { ValidationErrors, Validators } from '@angular/forms';
import { TypesStructure } from '@atline/core/models/typesStructure.model';
import { EMAIL_REGEX, URL_REGEX } from '@atline/core/models/constant-variable/regex.model';
import * as _ from 'lodash';
import { CountryItem } from '@atline/core/models/countryItem.model';
import { NutsItem } from '@atline/core/models/api/responses/nuts.response';
import { startWith, map } from 'rxjs/operators';
import { BookGreyComponent } from '../book-grey/book-grey.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'anc-data-contact',
  templateUrl: './anc-data-contact.component.html',
  styleUrls: ['./anc-data-contact.component.scss']
})
export class AncDataContactComponent extends AbstractAncComponent implements OnInit {

  readonly nameOrguanisationFC =  this.manager.controls.acheteur_public;
  readonly contactPointFC =       this.manager.controls.point_contact;
  readonly streetNameFC =         this.manager.controls.adr_voie_nomvoie;
  readonly streetAdditionalFC =   this.manager.controls.adr_voie_nomvoie_1;
  readonly streetLineFC =         this.manager.controls.adr_voie_nomvoie_2;
  readonly zipcodeFC =            this.manager.controls.adr_cp;
  readonly cityFC =               this.manager.controls.adr_ville;
  readonly livPaysFC =            this.manager.controls.adr_pays;
  readonly siretFC =              this.manager.controls.codeIdentificationNational;
  readonly phoneFC =              this.manager.controls.coord_tel;
  readonly faxFC =                this.manager.controls.coord_fax;
  readonly mailFC =               this.manager.controls.coord_mel;
  readonly webSiteFC =            this.manager.controls.coord_url;
  readonly webSitePaFC =          this.manager.controls.url_profil_acheteur;
  readonly departementFC=         this.manager.controls.acheteur_departement;
 
  readonly countries: CountryItem[] = [];
 
  readonly maskPhoneNumber = '0 00 00 00 00||(0)0 00 00 00 00';

  readonly codes: NutsItem[] = [];

  readonly codeNutsFC = this.manager.controls.codeNUTS;

  readonly filterObs = this.codeNutsFC?.valueChanges.pipe(
    startWith(''),
    map((value: string) => this.codes.filter(item => item.code_nuts.toLowerCase().includes(value.toLowerCase())))
  );
  constructor(
    @Inject(TOKEN_ANC_BLOCK) display: ComponentDisplay,
    private readonly manager: ManagerPaEditionAnnonceService,
    private dialog: MatDialog,
    { nativeElement }: ElementRef<HTMLElement>,
  ) { super(display, nativeElement); }

  ngOnInit(): void {

    this.manager.getCountries().subscribe(c => this.countries.push(...c));
    this.initValidator();

    this.manager.getNuts().subscribe(nuts => {
      this.codes.push(...nuts);
      this.codeNutsFC?.setValue(this.codeNutsFC.value);
    });

    this.codeNutsFC?.setValidators([
      this.createValidatorRequired('codeNUTS'),
      (control): ValidationErrors | null => _.some(this.codes, ['code_nuts', control.value]) ? null : { notFound: 'code not found' },
    ]);
  }

  private initValidator(): void {
    this.nameOrguanisationFC?.setValidators([Validators.maxLength(400), this.createValidatorRequired('nameOrganisation')]);
    this.contactPointFC?.setValidators([Validators.maxLength(400), this.createValidatorRequired('contactPoint')]);
    this.streetNameFC?.setValidators([Validators.maxLength(400), this.createValidatorRequired('street', 'name')]);
    this.streetAdditionalFC?.setValidators([Validators.maxLength(400), this.createValidatorRequired('street', 'additional')]);
    this.streetLineFC?.setValidators([Validators.maxLength(400), this.createValidatorRequired('street', 'line')]);
    this.zipcodeFC?.setValidators([Validators.maxLength(400), this.createValidatorRequired('zipcode')]);
    this.cityFC?.setValidators([Validators.maxLength(400), this.createValidatorRequired('city')]);
    this.livPaysFC?.setValidators([this.createValidatorRequired('country')]);
    this.departementFC?.setValidators([Validators.maxLength(400), this.createValidatorRequired('departement')]);
    this.siretFC?.setValidators([Validators.pattern(TypesStructure.SIRET.pattern || ''), this.createValidatorRequired('siret')]);
    this.phoneFC?.setValidators([Validators.maxLength(30), this.createValidatorRequired('phone')]);
    this.faxFC?.setValidators([Validators.maxLength(30), this.createValidatorRequired('fax')]);
    this.mailFC?.setValidators([Validators.maxLength(400), Validators.pattern(EMAIL_REGEX), this.createValidatorRequired('mail')]);
    this.webSiteFC?.setValidators([Validators.maxLength(400), Validators.pattern(URL_REGEX), this.createValidatorRequired('webSite')]);
    this.webSitePaFC?.setValidators([Validators.maxLength(400), Validators.pattern(URL_REGEX), this.createValidatorRequired('webSitePA')]);
  }

  isObjectValueParamsComp(arg: any): arg is { [key: string]: ValueParamsComp } {
    return _.isObjectLike(arg);
  }

  navigateToBookGrey(): void {
    const before = () => {
      console.log('Before opening popup');
    };

    const after = (result: any) => {
      console.log('After closing popup', result);
    };

    before();
    
    const dialogRef = this.dialog.open(BookGreyComponent, {
      width: '800px', 
      data: {} 
    });

    dialogRef.afterClosed().subscribe(result => {
      after(result);
    });
  }

}
