import { Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractAncComponent } from '../abstract-anc-component';
import { TOKEN_ANC_BLOCK } from '@atline/core/models/pa/annonce/anc-token-inject.model';
import { ComponentDisplay } from '@atline/core/models/pa/annonce/form-display.model';
import { ManagerPaEditionAnnonceService } from '../../manager-pa-edition-annonce.service';
import { LotAnnonce } from '@atline/core/models/api/responses/annonce/lotsList.response';
import { forkJoin, Observable, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { AnnoncesService } from '@atline/core/services/annonces.service';
import { Debounce } from '@atline/core/decorators/debounce.decorator';
import { Icons } from '@atline-shared/enums/icons.enum';
import { DialogsService } from '@atline/core/services/dialogs.service';
import { MessageResponse } from '@atline/core/models/api/responses/message.response';
// import { AnnoncesService } from '@atline/core/services/annonces.service';

@Component({
  selector: 'anc-lots',
  templateUrl: './anc-lots.component.html',
  styleUrls: ['./anc-lots.component.scss']
})
export class AncLotsComponent extends AbstractAncComponent implements OnInit, OnDestroy {

  readonly lots: (LotAnnonce | undefined)[] = [];

  private readonly subs = new Subscription();

  readonly Icons = Icons;
  lotLoading = false;

  numberAlreadyUsed!: number[];

  constructor(
    @Inject(TOKEN_ANC_BLOCK) display: ComponentDisplay,
    private readonly manager: ManagerPaEditionAnnonceService,
    private readonly annoncesService: AnnoncesService,
    private readonly dialogService: DialogsService,
    { nativeElement }: ElementRef<HTMLElement>,
  ) { super(display, nativeElement); }

  ngOnInit(): void {
    const lotsChange = this.manager.lotsChange.subscribe(lots => {
      this.lots.length = 0;
      this.lots.push(...lots);
    });
    const lotsLoadingChange = this.manager.loadingLotChange.subscribe(res => {
      this.lotLoading = res;
    });

    this.subs.add(    this.manager.lotsChange.subscribe(l => this.numberAlreadyUsed = l.map(lot => lot.numero_lot)))

    this.subs.add(lotsChange);
    this.subs.add(lotsLoadingChange);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  get hasLots(): boolean {
    return this.lots.length > 0;
  }

  addLot(): void {
    this.manager.addLot();
    // this.dialogService.openInputFieldDialog('APP.DIALOG.INPUT_FIELD.LOT.LABEL', 'APP.DIALOG.INPUT_FIELD.LOT.TITLE', (res) => {

      
    // if (this.numberAlreadyUsed.findIndex((l) => l == res) != -1) {
    //   this.dialogService.createInfoDialog('Numéro lot déjà existant', 'Le numéro de lot que vous avez saisie existe déjà. Veuillez le modifier avant de sauvegarder votre lot', 'Fermer');
    //   return;
    // }

    //   if (this.manager.cle_annonce && !_.isNil(res))
    //     this.annoncesService.editLot({ cle_annonce: this.manager.cle_annonce, cle_etab: this.manager.cleEtab, cle_pa: this.manager.clePa, numero_lot: res }).subscribe({
    //       next: (res) => {
    //         this.lots.push(res);
    //         this.manager.lot
    //       },
    //       error: () => {
    //         this.dialogService.createInfoDialog('Organisation', 'Une erreur est survenu dans la création du lot. Veuillez réessayer ultérieurement', 'OK');
    //       }
    //     })
    // })
  }

  removeAllLots(): void {
    this.dialogService.openConfirmationDialog({
      title: 'APP.DIALOG.CONFIRMATION.DELETE_ANNONCE_LOT_ALL',
      callback: (res) => {
        if (res) {
          const observableApi: Observable<MessageResponse>[] = [];
          this.lots.forEach(l => {
            if (l) observableApi.push(
              this.annoncesService.deleteLot({
                cle_pa: this.manager.clePa,
                cle_etab: this.manager.cleEtab,
                cle_annonce: l.cle_annonce,
                cle_lot_annonce: l.cle_lot_boamp
              }))
          });
          forkJoin(observableApi).subscribe({ error: () => this.dialogService.createInfoDialog('APP.DIALOG.ERRORS.ALL_LOT.TITLE', 'APP.DIALOG.ERRORS.ALL_LOT.MESSAGE', 'APP.DIALOG.ERRORS.ALL_LOT.BUTTON') });
        }
      },
      description: 'APP.DIALOG.CONFIRMATION.DELETE_ANNONCE_LOT_ALL_CONTENT'
    })
  }

  removeLot(index: number): void {
    this.lots.splice(index, 1);
    if (index > -1 && index < this.manager.lots.length) this.syncLotsArray();
  }

  lotChange(index: number, lot: LotAnnonce): void {
    this.lots[index] = lot;
    this.syncLotsArray();
  }

  refreshLots(): void {
    if (!this.lotLoading)
      this.manager.refreshLots();
  }

  @Debounce(100)
  private syncLotsArray(): void {
    this.manager.lots.length = 0;
    this.manager.lots.push(...this.lots.filter(value => !_.isNil(value)) as LotAnnonce[]);
    this.manager.refreshVerifyRulesCall();
  }

}
