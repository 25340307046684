<div class="anc-component" *ngIf="composition" >
    <app-field-edition *ngIf="reservedMarketFC && situationFC"
        [label]="'APP.ANNONCES.CONTRATS.LABEL' | translate"
        [invalid]="false">
    </app-field-edition>

    <app-round-checkbox name="adapted-structure"
        [disabled]="true"
        [checked]="true">
        <label name="adapted-structure">{{ 'APP.ANNONCES.CONTRATS.CONDITION_1' | translate }}</label>
        <span class="sub-label" sub-label>BT-67(a)-Procedure</span>
    </app-round-checkbox>
    <div class="a" fxLayoutAlign="start center" fxLayoutGap="5px">
        <app-field-edition *ngIf="codeNationalFC && composition.codeNational"
            [required]="controlIsRequired(codeNationalFC)"
            [label]="'APP.ANNONCES.PROCEDURE_E16.LABEL9' | translate"
            [invalid]="codeNationalFC.touched && codeNationalFC.invalid">
            <span class="sub-label" sub-label>BT-67(b)-Procedure</span>
            <mat-form-field appearance="outline">
                <input matInput type="text" [formControl]="codeNationalFC"/>
            </mat-form-field>
        </app-field-edition>
    </div>
    
    <app-round-checkbox name="adapted-structure"
        [disabled]="true"
        [checked]="true">
        <label name="adapted-structure">{{ 'APP.ANNONCES.CONTRATS.CONDITION_2' | translate }}</label>
        <span class="sub-label" sub-label>BT-67(a)-Procedure</span>
    </app-round-checkbox>
    <div class="a" fxLayoutAlign="start center" fxLayoutGap="5px">
    <app-field-edition *ngIf="codeFailliteFC && composition.codeFaillite"
        [required]="controlIsRequired(codeFailliteFC)"
        [label]="'APP.ANNONCES.PROCEDURE_E16.LABEL9' | translate"
        [invalid]="codeFailliteFC.touched && codeFailliteFC.invalid">
        <span class="sub-label" sub-label>BT-67(b)-Procedure</span>
        <mat-form-field appearance="outline">
            <input matInput type="text" [formControl]="codeFailliteFC"/>
        </mat-form-field>
    </app-field-edition>
    </div>
   
    <app-round-checkbox name="adapted-structure"
        [disabled]="true"
        [checked]="true">
        <label name="adapted-structure">{{ 'APP.ANNONCES.CONTRATS.CONDITION_3' | translate }}</label>
        <span class="sub-label" sub-label>BT-67(a)-Procedure</span>
    </app-round-checkbox>
    <div class="a" fxLayoutAlign="start center" fxLayoutGap="5px">
    <app-field-edition *ngIf="codeCorruptionFC && composition.codeCorruption"
    [required]="controlIsRequired(codeCorruptionFC)"
    [label]="'APP.ANNONCES.PROCEDURE_E16.LABEL9' | translate"
    [invalid]="codeCorruptionFC.touched && codeCorruptionFC.invalid">
    <span class="sub-label" sub-label>BT-67(b)-Procedure</span>
    <mat-form-field appearance="outline">
        <input matInput type="text" [formControl]="codeCorruptionFC"/>
    </mat-form-field>
    </app-field-edition>
    </div>

    <app-round-checkbox name="adapted-structure"
    [disabled]="true"
    [checked]="true">
    <label name="adapted-structure">{{ 'APP.ANNONCES.CONTRATS.CONDITION_4' | translate }}</label>
    <span class="sub-label" sub-label>BT-67(a)-Procedure</span>
    </app-round-checkbox>
    <div class="a" fxLayoutAlign="start center" fxLayoutGap="5px">
    <app-field-edition *ngIf="codeCreanciersFC && composition.codeCreanciers"
    [required]="controlIsRequired(codeCreanciersFC)"
    [label]="'APP.ANNONCES.PROCEDURE_E16.LABEL9' | translate"
    [invalid]="codeCreanciersFC.touched && codeCreanciersFC.invalid">
    <span class="sub-label" sub-label>BT-67(b)-Procedure</span>
    <mat-form-field appearance="outline">
        <input matInput type="text" [formControl]="codeCreanciersFC"/>
    </mat-form-field>
    </app-field-edition>
    </div>
  
    <app-round-checkbox name="adapted-structure"
    [disabled]="true"
    [checked]="true">
    <label name="adapted-structure">{{ 'APP.ANNONCES.CONTRATS.CONDITION_5' | translate }}</label>
    <span class="sub-label" sub-label>BT-67(a)-Procedure</span>
    </app-round-checkbox>
    <div class="a" fxLayoutAlign="start center" fxLayoutGap="5px">
    <app-field-edition *ngIf="codeOrgFC && composition.codeOrg"
    [required]="controlIsRequired(codeOrgFC)"
    [label]="'APP.ANNONCES.PROCEDURE_E16.LABEL9' | translate"
    [invalid]="codeOrgFC.touched && codeOrgFC.invalid">
    <span class="sub-label" sub-label>BT-67(b)-Procedure</span>
    <mat-form-field appearance="outline">
        <input matInput type="text" [formControl]="codeOrgFC"/>
    </mat-form-field>
    </app-field-edition>
    </div>
  
    <app-round-checkbox name="adapted-structure"
    [disabled]="true"
    [checked]="true">
    <label name="adapted-structure">{{ 'APP.ANNONCES.CONTRATS.CONDITION_6' | translate }}</label>
    <span class="sub-label" sub-label>BT-67(a)-Procedure</span>
    </app-round-checkbox>
    <div class="a" fxLayoutAlign="start center" fxLayoutGap="5px">
    <app-field-edition *ngIf="codeDistorsionFC && composition.codeDistorsion"
    [required]="controlIsRequired(codeDistorsionFC)"
    [label]="'APP.ANNONCES.PROCEDURE_E16.LABEL9' | translate"
    [invalid]="codeDistorsionFC.touched && codeDistorsionFC.invalid">
    <span class="sub-label" sub-label>BT-67(b)-Procedure</span>
    <mat-form-field appearance="outline">
        <input matInput type="text" [formControl]="codeDistorsionFC"/>
    </mat-form-field>
    </app-field-edition>
    </div>
  
    <app-round-checkbox name="adapted-structure"
    [disabled]="true"
    [checked]="true">
    <label name="adapted-structure">{{ 'APP.ANNONCES.CONTRATS.CONDITION_7' | translate }}</label>
    <span class="sub-label" sub-label>BT-67(a)-Procedure</span>
    </app-round-checkbox>
    <div class="a" fxLayoutAlign="start center" fxLayoutGap="5px">
    <app-field-edition *ngIf="codeEnvFC && composition.codeEnv"
    [required]="controlIsRequired(codeEnvFC)"
    [label]="'APP.ANNONCES.PROCEDURE_E16.LABEL9' | translate"
    [invalid]="codeEnvFC.touched && codeEnvFC.invalid">
    <span class="sub-label" sub-label>BT-67(b)-Procedure</span>
    <mat-form-field appearance="outline">
        <input matInput type="text" [formControl]="codeEnvFC"/>
    </mat-form-field>
    </app-field-edition>
    </div>

    <app-round-checkbox name="adapted-structure"
    [disabled]="true"
    [checked]="true">
    <label name="adapted-structure">{{ 'APP.ANNONCES.CONTRATS.CONDITION_8' | translate }}</label>
    <span class="sub-label" sub-label>BT-67(a)-Procedure</span>
    </app-round-checkbox>
    <div class="a" fxLayoutAlign="start center" fxLayoutGap="5px">
    <app-field-edition *ngIf="codeBlanchimentFC && composition.codeBlanchiment"
    [required]="controlIsRequired(codeBlanchimentFC)"
    [label]="'APP.ANNONCES.PROCEDURE_E16.LABEL9' | translate"
    [invalid]="codeBlanchimentFC.touched && codeBlanchimentFC.invalid">
    <span class="sub-label" sub-label>BT-67(b)-Procedure</span>
    <mat-form-field appearance="outline">
        <input matInput type="text" [formControl]="codeBlanchimentFC"/>
    </mat-form-field>
    </app-field-edition>
    </div>
 
    <app-round-checkbox name="adapted-structure"
    [disabled]="true"
    [checked]="true">
    <label name="adapted-structure">{{ 'APP.ANNONCES.CONTRATS.CONDITION_9' | translate }}</label>
    <span class="sub-label" sub-label>BT-67(a)-Procedure</span>
    </app-round-checkbox>
    <div class="a" fxLayoutAlign="start center" fxLayoutGap="5px">
    <app-field-edition *ngIf="codeFraudeFC && composition.codeFraude"
    [required]="controlIsRequired(codeFraudeFC)"
    [label]="'APP.ANNONCES.PROCEDURE_E16.LABEL9' | translate"
    [invalid]="codeFraudeFC.touched && codeFraudeFC.invalid">
    <span class="sub-label" sub-label>BT-67(b)-Procedure</span>
    <mat-form-field appearance="outline">
        <input matInput type="text" [formControl]="codeFraudeFC"/>
    </mat-form-field>
    </app-field-edition>
    </div>

    <app-round-checkbox name="adapted-structure"
    [disabled]="true"
    [checked]="true">
    <label name="adapted-structure">{{ 'APP.ANNONCES.CONTRATS.CONDITION_10' | translate }}</label>
    <span class="sub-label" sub-label>BT-67(a)-Procedure</span>
    </app-round-checkbox>
    <div class="a" fxLayoutAlign="start center" fxLayoutGap="5px">
    <app-field-edition *ngIf="codeTrafFC && composition.codeTraf"
    [required]="controlIsRequired(codeTrafFC)"
    [label]="'APP.ANNONCES.PROCEDURE_E16.LABEL9' | translate"
    [invalid]="codeTrafFC.touched && codeTrafFC.invalid">
    <span class="sub-label" sub-label>BT-67(b)-Procedure</span>
    <mat-form-field appearance="outline">
        <input matInput type="text" [formControl]="codeTrafFC"/>
    </mat-form-field>
    </app-field-edition>
    </div>
    
    <app-round-checkbox name="adapted-structure"
    [disabled]="true"
    [checked]="true">
    <label name="adapted-structure">{{ 'APP.ANNONCES.CONTRATS.CONDITION_11' | translate }}</label>
    <span class="sub-label" sub-label>BT-67(a)-Procedure</span>
    </app-round-checkbox>
    <div class="a" fxLayoutAlign="start center" fxLayoutGap="5px">
    <app-field-edition *ngIf="codeInsolvFC && composition.codeInsolv"
    [required]="controlIsRequired(codeInsolvFC)"
    [label]="'APP.ANNONCES.PROCEDURE_E16.LABEL9' | translate"
    [invalid]="codeInsolvFC.touched && codeInsolvFC.invalid">
    <span class="sub-label" sub-label>BT-67(b)-Procedure</span>
    <mat-form-field appearance="outline">
        <input matInput type="text" [formControl]="codeInsolvFC"/>
    </mat-form-field>
    </app-field-edition>
    </div>
    
    <app-round-checkbox name="adapted-structure"
    [disabled]="true"
    [checked]="true">
    <label name="adapted-structure">{{ 'APP.ANNONCES.CONTRATS.CONDITION_11' | translate }}</label>
    <span class="sub-label" sub-label>BT-67(a)-Procedure</span>
    </app-round-checkbox>
    <div class="a" fxLayoutAlign="start center" fxLayoutGap="5px">
    <app-field-edition *ngIf="codeTravailFC && composition.codeTravail"
    [required]="controlIsRequired(codeTravailFC)"
    [label]="'APP.ANNONCES.PROCEDURE_E16.LABEL9' | translate"
    [invalid]="codeTravailFC.touched && codeTravailFC.invalid">
    <span class="sub-label" sub-label>BT-67(b)-Procedure</span>
    <mat-form-field appearance="outline">
        <input matInput type="text" [formControl]="codeTravailFC"/>
    </mat-form-field>
    </app-field-edition>
    </div>

    <app-round-checkbox name="adapted-structure"
    [disabled]="true"
    [checked]="true">
    <label name="adapted-structure">{{ 'APP.ANNONCES.CONTRATS.CONDITION_12' | translate }}</label>
    <span class="sub-label" sub-label>BT-67(a)-Procedure</span>
    </app-round-checkbox>
    <div class="a" fxLayoutAlign="start center" fxLayoutGap="5px">
    <app-field-edition *ngIf="codeLiquidFC && composition.codeLiquid"
    [required]="controlIsRequired(codeLiquidFC)"
    [label]="'APP.ANNONCES.PROCEDURE_E16.LABEL9' | translate"
    [invalid]="codeLiquidFC.touched && codeLiquidFC.invalid">
    <span class="sub-label" sub-label>BT-67(b)-Procedure</span>
    <mat-form-field appearance="outline">
        <input matInput type="text" [formControl]="codeLiquidFC"/>
    </mat-form-field>
    </app-field-edition>
    </div>
   
    <app-round-checkbox name="adapted-structure"
    [disabled]="true"
    [checked]="true">
    <label name="adapted-structure">{{ 'APP.ANNONCES.CONTRATS.CONDITION_13' | translate }}</label>
    <span class="sub-label" sub-label>BT-67(a)-Procedure</span>
    </app-round-checkbox>
    <div class="a" fxLayoutAlign="start center" fxLayoutGap="5px">
    <app-field-edition *ngIf="codeFausseFC && composition.codeFausse"
    [required]="controlIsRequired(codeFausseFC)"
    [label]="'APP.ANNONCES.PROCEDURE_E16.LABEL9' | translate"
    [invalid]="codeFausseFC.touched && codeFausseFC.invalid">
    <span class="sub-label" sub-label>BT-67(b)-Procedure</span>
    <mat-form-field appearance="outline">
        <input matInput type="text" [formControl]="codeFausseFC"/>
    </mat-form-field>
    </app-field-edition>
    </div>
    
    <app-round-checkbox name="adapted-structure"
    [disabled]="true"
    [checked]="true">
    <label name="adapted-structure">{{ 'APP.ANNONCES.CONTRATS.CONDITION_14' | translate }}</label>
    <span class="sub-label" sub-label>BT-67(a)-Procedure</span>
    </app-round-checkbox>
    <div class="a" fxLayoutAlign="start center" fxLayoutGap="5px">
    <app-field-edition *ngIf="codeNationauxFC && composition.codeNationaux"
    [required]="controlIsRequired(codeNationauxFC)"
    [label]="'APP.ANNONCES.PROCEDURE_E16.LABEL9' | translate"
    [invalid]="codeNationauxFC.touched && codeNationauxFC.invalid">
    <span class="sub-label" sub-label>BT-67(b)-Procedure</span>
    <mat-form-field appearance="outline">
        <input matInput type="text" [formControl]="codeNationauxFC"/>
    </mat-form-field>
    </app-field-edition>
    </div>

    <app-round-checkbox name="adapted-structure"
    [disabled]="true"
    [checked]="true">
    <label name="adapted-structure">{{ 'APP.ANNONCES.CONTRATS.CONDITION_15' | translate }}</label>
    <span class="sub-label" sub-label>BT-67(a)-Procedure</span>
    </app-round-checkbox>
    <div class="a" fxLayoutAlign="start center" fxLayoutGap="5px">
    <app-field-edition *ngIf="codeConflitsFC && composition.codeConflits"
    [required]="controlIsRequired(codeConflitsFC)"
    [label]="'APP.ANNONCES.PROCEDURE_E16.LABEL9' | translate"
    [invalid]="codeConflitsFC.touched && codeConflitsFC.invalid">
    <span class="sub-label" sub-label>BT-67(b)-Procedure</span>
    <mat-form-field appearance="outline">
        <input matInput type="text" [formControl]="codeConflitsFC"/>
    </mat-form-field>
    </app-field-edition>
    </div>

    <app-round-checkbox name="adapted-structure"
    [disabled]="true"
    [checked]="true">
    <label name="adapted-structure">{{ 'APP.ANNONCES.CONTRATS.CONDITION_16' | translate }}</label>
    <span class="sub-label" sub-label>BT-67(a)-Procedure</span>
    </app-round-checkbox>
    <div class="a" fxLayoutAlign="start center" fxLayoutGap="5px">
    <app-field-edition *ngIf="codePrepFC && composition.codePrep"
    [required]="controlIsRequired(codePrepFC)"
    [label]="'APP.ANNONCES.PROCEDURE_E16.LABEL9' | translate"
    [invalid]="codePrepFC.touched && codePrepFC.invalid">
    <span class="sub-label" sub-label>BT-67(b)-Procedure</span>
    <mat-form-field appearance="outline">
        <input matInput type="text" [formControl]="codePrepFC"/>
    </mat-form-field>
    </app-field-edition>
    </div>
 
    <app-round-checkbox name="adapted-structure"
    [disabled]="true"
    [checked]="true">
    <label name="adapted-structure">{{ 'APP.ANNONCES.CONTRATS.CONDITION_17' | translate }}</label>
    <span class="sub-label" sub-label>BT-67(a)-Procedure</span>
    </app-round-checkbox>
    <div class="a" fxLayoutAlign="start center" fxLayoutGap="5px">
    <app-field-edition *ngIf="codeFauteFC && composition.codeFaute"
    [required]="controlIsRequired(codeFauteFC)"
    [label]="'APP.ANNONCES.PROCEDURE_E16.LABEL9' | translate"
    [invalid]="codeFauteFC.touched && codeFauteFC.invalid">
    <span class="sub-label" sub-label>BT-67(b)-Procedure</span>
    <mat-form-field appearance="outline">
        <input matInput type="text" [formControl]="codeFauteFC"/>
    </mat-form-field>
    </app-field-edition>
    </div>
 
    <app-round-checkbox name="adapted-structure"
    [disabled]="true"
    [checked]="true">
    <label name="adapted-structure">{{ 'APP.ANNONCES.CONTRATS.CONDITION_18' | translate }}</label>
    <span class="sub-label" sub-label>BT-67(a)-Procedure</span>
    </app-round-checkbox>
    <div class="a" fxLayoutAlign="start center" fxLayoutGap="5px">
    <app-field-edition *ngIf="codeSanctionFC && composition.codeSanction"
    [required]="controlIsRequired(codeSanctionFC)"
    [label]="'APP.ANNONCES.PROCEDURE_E16.LABEL9' | translate"
    [invalid]="codeSanctionFC.touched && codeSanctionFC.invalid">
    <span class="sub-label" sub-label>BT-67(b)-Procedure</span>
    <mat-form-field appearance="outline">
        <input matInput type="text" [formControl]="codeSanctionFC"/>
    </mat-form-field>
    </app-field-edition>
    </div>

    <app-round-checkbox name="adapted-structure"
    [disabled]="true"
    [checked]="true">
    <label name="adapted-structure">{{ 'APP.ANNONCES.CONTRATS.CONDITION_19' | translate }}</label>
    <span class="sub-label" sub-label>BT-67(a)-Procedure</span>
    </app-round-checkbox>
    <div class="a" fxLayoutAlign="start center" fxLayoutGap="5px">
    <app-field-edition *ngIf="codeObligFC && composition.codeOblig"
    [required]="controlIsRequired(codeObligFC)"
    [label]="'APP.ANNONCES.PROCEDURE_E16.LABEL9' | translate"
    [invalid]="codeObligFC.touched && codeObligFC.invalid">
    <span class="sub-label" sub-label>BT-67(b)-Procedure</span>
    <mat-form-field appearance="outline">
        <input matInput type="text" [formControl]="codeObligFC"/>
    </mat-form-field>
    </app-field-edition>
    </div>
 
    <app-round-checkbox name="adapted-structure"
    [disabled]="true"
    [checked]="true">
    <label name="adapted-structure">{{ 'APP.ANNONCES.CONTRATS.CONDITION_20' | translate }}</label>
    <span class="sub-label" sub-label>BT-67(a)-Procedure</span>
    </app-round-checkbox>
    <div class="a" fxLayoutAlign="start center" fxLayoutGap="5px">
    <app-field-edition *ngIf="codePayFC && composition.codePay"
    [required]="controlIsRequired(codePayFC)"
    [label]="'APP.ANNONCES.PROCEDURE_E16.LABEL9' | translate"
    [invalid]="codePayFC.touched && codePayFC.invalid">
    <span class="sub-label" sub-label>BT-67(b)-Procedure</span>
    <mat-form-field appearance="outline">
        <input matInput type="text" [formControl]="codePayFC"/>
    </mat-form-field>
    </app-field-edition>
    </div>
  
    <app-round-checkbox name="adapted-structure"
    [disabled]="true"
    [checked]="true">
    <label name="adapted-structure">{{ 'APP.ANNONCES.CONTRATS.CONDITION_21' | translate }}</label>
    <span class="sub-label" sub-label>BT-67(a)-Procedure</span>
    </app-round-checkbox>
    <div class="a" fxLayoutAlign="start center" fxLayoutGap="5px">
    <app-field-edition *ngIf="codeActivFC && composition.codeActiv"
    [required]="controlIsRequired(codeActivFC)"
    [label]="'APP.ANNONCES.PROCEDURE_E16.LABEL9' | translate"
    [invalid]="codeActivFC.touched && codeActivFC.invalid">
    <span class="sub-label" sub-label>BT-67(b)-Procedure</span>
    <mat-form-field appearance="outline">
        <input matInput type="text" [formControl]="codeActivFC"/>
    </mat-form-field>
    </app-field-edition>
    </div>

    <app-round-checkbox name="adapted-structure"
    [disabled]="true"
    [checked]="true">
    <label name="adapted-structure">{{ 'APP.ANNONCES.CONTRATS.CONDITION_22' | translate }}</label>
    <span class="sub-label" sub-label>BT-67(a)-Procedure</span>
    </app-round-checkbox>
    <div class="a" fxLayoutAlign="start center" fxLayoutGap="5px">
    <app-field-edition *ngIf="codeImpotsFC && composition.codeImpots"
    [required]="controlIsRequired(codeImpotsFC)"
    [label]="'APP.ANNONCES.PROCEDURE_E16.LABEL9' | translate"
    [invalid]="codeImpotsFC.touched && codeImpotsFC.invalid">
    <span class="sub-label" sub-label>BT-67(b)-Procedure</span>
    <mat-form-field appearance="outline">
        <input matInput type="text" [formControl]="codeImpotsFC"/>
    </mat-form-field>
    </app-field-edition>
    </div>
   
    <app-round-checkbox name="adapted-structure"
    [disabled]="true"
    [checked]="true">
    <label name="adapted-structure">{{ 'APP.ANNONCES.CONTRATS.CONDITION_23' | translate }}</label>
    <span class="sub-label" sub-label>BT-67(a)-Procedure</span>
    </app-round-checkbox>
    <div class="a" fxLayoutAlign="start center" fxLayoutGap="5px">
    <app-field-edition *ngIf=" codeImpotsFC && composition.codeInter"
    [required]="controlIsRequired(codeImpotsFC)"
    [label]="'APP.ANNONCES.PROCEDURE_E16.LABEL9' | translate"
    [invalid]="codeImpotsFC.touched && codeImpotsFC.invalid">
    <span class="sub-label" sub-label>BT-67(b)-Procedure</span>
    <mat-form-field appearance="outline">
        <input matInput type="text" [formControl]="codeImpotsFC"/>
    </mat-form-field>
    </app-field-edition>
    </div> 
</div>