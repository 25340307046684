import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { TOKEN_ANC_BLOCK } from '@atline/core/models/pa/annonce/anc-token-inject.model';
import { ComponentDisplay } from '@atline/core/models/pa/annonce/form-display.model';
import { ManagerPaEditionAnnonceService } from '../../manager-pa-edition-annonce.service';
import { AbstractAncComponent } from '../abstract-anc-component';

@Component({
  selector: 'anc-informations-compl',
  templateUrl: './anc-informations-compl.component.html',
  styleUrls: ['./anc-informations-compl.component.scss']
})
export class AncInformationsComplComponent extends AbstractAncComponent implements OnInit {

  readonly descriptionFC = this.manager.controls.infosComplementaires_procedure;
  readonly maxLengthDescription = 6000;
  readonly naturesContract = ['TVX', 'SVC', 'FNT'] as const;

  constructor(
    @Inject(TOKEN_ANC_BLOCK) display: ComponentDisplay,
    private readonly manager: ManagerPaEditionAnnonceService,
    { nativeElement }: ElementRef<HTMLElement>,
  ) { super(display, nativeElement); }

  ngOnInit(): void {
    this.descriptionFC?.setValidators([this.createValidatorRequired('description'), Validators.maxLength(this.maxLengthDescription)]);
  }

}
