<div class="anc-component" fxLayout="column nowrap" style="gap: 10px">
    <div class="actions-offre">
      <div class="action">
        <app-button size="medium" [icon]="Icons.Plus" (click)="addOffre()"></app-button>
        {{'APP.ANNONCES.OFFRES.ADD' | translate | uppercase}}
      </div>
      <div class="action">
        <app-button size="medium" [icon]="Icons.Bin" (click)="removeAllOffre()"></app-button>
        {{'APP.ANNONCES.OFFRES.DELETE_ALL' | translate | uppercase}}
      </div>
    </div>
    <div class="lots-list" fxLayout="column nowrap" fxLayoutGap="20px">
      <app-offre-annonce *ngFor="let offre of offres; let i = index" [offre]="offre"
        (offreChange)="offreChange(i, $event)"
        (cancel)="removeOffre(i)"
        (deleted)="removeOffre(i)">
      </app-offre-annonce>
      <div *ngIf="!offres.length" fxLayoutAlign="center center">
        {{'APP.ANNONCES.OFFRES.EMPTY' | translate }}
      </div>
    </div>
  </div>
  