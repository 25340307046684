import { Observable } from 'rxjs';
import { DepartmentResponse } from '../models/api/responses/department.response';
import { DepartmentRequest } from './../models/api/requests/department-request';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {

  constructor(private readonly apiService: ApiService) { }


  public getDepartment(departmentRequest?: DepartmentRequest): Observable<DepartmentResponse>{
    return this.apiService.post(DepartmentResponse, departmentRequest, 'departments_list');
  }
}
