import { Directive, ElementRef, Renderer2, HostListener, OnChanges, OnInit, Input, SimpleChanges } from '@angular/core';
import { Color } from '@atline-shared/enums/color.enum';

@Directive({
  selector: '[atlineLink]'
})
export class LinkDirective implements OnChanges, OnInit {

  private linkElemnt!: HTMLLinkElement;

  @Input()
  public disabled = false;

  @Input()
  public colorDefault: string = Color.BLUE;

  @Input()
  public colorHover: string = Color.TENDER;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnChanges({ disabled }: SimpleChanges): void {
    if (disabled && this.linkElemnt) {
      this.renderer.setStyle(this.linkElemnt, 'cursor', this.disabled ? 'default' : 'pointer');
      this.renderer.setStyle(this.linkElemnt, 'opacity', this.disabled ? '.75' : '1');
    }
  }

  ngOnInit(): void {
    this.linkElemnt = this.el.nativeElement;
    this.renderer.setStyle(this.linkElemnt, 'color', this.colorDefault);
    this.renderer.setStyle(this.linkElemnt, 'text-decoration', 'none');
    this.renderer.setStyle(this.linkElemnt, 'display', 'block');
    this.renderer.setStyle(this.linkElemnt, 'cursor', this.disabled ? 'default' : 'pointer');
    this.renderer.setStyle(this.linkElemnt, 'opacity', this.disabled ? '.75' : '1');
  }

  @HostListener('click', ['$event']) onClick(event: MouseEvent): void {
    if (this.disabled) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  @HostListener('mouseleave')
  // @ts-ignore
  private styleDefault(): void {
    if (this.disabled) return;
    this.renderer.setStyle(this.linkElemnt, 'color', this.colorDefault);
    this.renderer.setStyle(this.linkElemnt, 'font-family', '');
  }

  @HostListener('mousedown')
  // @ts-ignore
  private styleMouseDown(): void {
    this.renderer.setStyle(this.linkElemnt, 'font-family', 'Nobel Bold');
    this.renderer.setStyle(this.linkElemnt, 'color', this.colorDefault);
  }

  @HostListener('mouseup')
  @HostListener('mouseover')
  // @ts-ignore
  private styleHover(): void {
    if (this.disabled) return;
    this.renderer.setStyle(this.linkElemnt, 'color', this.colorHover);
    this.renderer.setStyle(this.linkElemnt, 'font-family', 'Nobel Bold');
  }

}
