import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Icons } from '@atline-shared/enums/icons.enum';
import { SsoPlatformEnum } from '@atline-shared/enums/ssoPlateform.enum';
import { AuthenticateService } from '@atline/core/services/authenticate.service';
import { DialogsService } from '@atline/core/services/dialogs.service';
import { SsoService } from '@atline/core/services/sso.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public incorrectCredentials = false;

  miscrosoftIcon = Icons.Microsoft;
  ssoPlateform = SsoPlatformEnum;
  ssoActiviation = environment.sso.activation;

  constructor(
    private readonly authenticator: AuthenticateService,
    private readonly ssoService: SsoService,
    public dialogsService: DialogsService,
    public loginDialogRef: MatDialogRef<LoginComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { before: () => any, error: (err: any) => any, after: (result: any) => any },
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void {
  }

  ssoLogin(context: SsoPlatformEnum) {
    this.ssoService.authorize({
      client_id: environment.sso[context].clientId,
      redirect_uri: environment.sso[context].redirectUri,
      response_mode: environment.sso[context].response.mode,
      response_type: environment.sso[context].response.code,
      scope: environment.sso[context].scope,
      state: environment.sso[context].state
    }).subscribe({
      next: (res) => {
        this.document.location.href = res.authUrl;
      },
      error: () => { }
    })
  }

  /**
   * Submit the form by logging in the user
   * Redirect to dashboard when is success
   */
  onSubmit(formData: FormGroup): void {
    const { before, after } = this.data;
    if (formData.valid) {
      before();
      this.authenticator.login(formData.value).subscribe({
        next: result => {
          this.loginDialogRef.close();
          after(result);
        },
        error: () => {
          this.incorrectCredentials = true;
          this.dialogsService.closeLoader();
        }
      });
    }
  }
}
