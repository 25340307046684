import { ApiErrorResponse } from './../../core/models/api/responses/api.response';
import { catchError } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ElrarService } from '@atline/core/services/elrar.service';
import { throwError } from 'rxjs';
import { isUndefined, isNil, isNull } from 'lodash';
import { ElrarResponse, ElRarValidityAndCheckResponse } from '@atline/core/models/api/responses/elrar.response';
import { UtilsService } from '@atline/core/services/utils.service';
import { DialogsService } from '@atline/core/services/dialogs.service';
import { Debounce } from '@atline/core/decorators/debounce.decorator';
import { RoutingEnum } from '@atline-shared/enums/routing.enum';
import { RoutingService } from '@atline/core/services/routing.service';

const elrarActions = ['accepter', 'refuser'] as const;
type ElrarAction = typeof elrarActions[number];

@Component({
  selector: 'app-elrar-acceptance',
  templateUrl: './elrar-acceptance.component.html',
  styleUrls: ['./elrar-acceptance.component.scss']
})
export class ElrarAcceptanceComponent implements OnInit {

  private cleDce!: string;
  private key!: string;
  private response?: ElrarResponse;
  private context!: 'demande' | 'notification';
  private elrarStatus?: ElRarValidityAndCheckResponse;

  public error?: string;
  public displayRefusedSelect = false;
  public choice = 0;
  public actionFromUrl!: ElrarAction;


  constructor(
    private readonly elrarService: ElrarService,
    private readonly utils: UtilsService,
    private readonly dialogs: DialogsService,
    private readonly router: Router,
    activatedRoute: ActivatedRoute,
    private readonly routingService: RoutingService
  ) {
    const { cleDce, key, context, action } = activatedRoute.snapshot.params;
    this.cleDce = cleDce;
    this.key = key;
    this.context = context;
    this.actionFromUrl = action;
  }

  ngOnInit(): void {
    this.elrarService.checkELRARvalidityAndStates({
      action: this.actionFromUrl,
      cle: this.key,
      cle_dce: this.cleDce,
      contexte: this.context
    }).pipe(
      catchError((err: { error: ApiErrorResponse; }) => {
        const apiError = err.error;
        if (apiError.error_description) {
          this.error = err.error.error_description;
        }
        return throwError(err);
      })
    ).subscribe(res => {
      this.elrarStatus = res;

      if (isNull(this.elrarStatus.action) && this.actionFromUrl === 'accepter')
        this.callToWS();

      if (isNull(this.elrarStatus.action) && this.actionFromUrl === 'refuser')
        this.displayRefusedSelect = true;
    });
  }

  private callToWS(): void {
    if (this.cleDce && this.key && this.context && this.action) {
      this.displayRefusedSelect = false;
      this.elrarService.elrarAction({
        action: this.actionFromUrl,
        cle: this.key,
        cle_dce: this.cleDce,
        contexte: this.context
      }).pipe(
        catchError((err: { error: ApiErrorResponse; }) => {
          const apiError = err.error;
          if (apiError.error_description) {
            this.error = err.error.error_description;
          }
          return throwError(err);
        })
      ).subscribe(res => this.response = res);
    }
  }

  getContext(): 'demande' | 'notification' {
    return this.context;
  }

  get elrarValidity(): boolean {
    return !!this.elrarStatus && (this.elrarStatus.action_validite || !isNull(this.elrarStatus.date_action));
  }

  get confirmRefusTranslationKey(): string {
    return this.elrarStatus?.action === 'refused' ? '_REFUS' : '';
  }

  get dateSent(): string | undefined {
    return this.elrarStatus?.date_action ?? this.response?.date_envoi;
  }

  get actionDate(): string | undefined {
    return this.elrarStatus?.date_action ?? this.response?.date_action;
  }

  get displayResponseMessage(): boolean {
    return !isUndefined(this.response) || (!isUndefined(this.elrarStatus) && !isNil(this.elrarStatus.date_action));
  }

  get action(): ElrarAction {
    if (!isNil(this.elrarStatus) && !isNil(this.elrarStatus?.date_action)) {
      return this.elrarStatus.action === 'accepted' ? 'accepter' : 'refuser';
    }
    return this.actionFromUrl;
  }

  refusElrar(): void {
    if (this.choice === 1)
      this.callToWS();
  }

  @Debounce(100)
  accessContext(): void {
    if (this.context && this.key) {
      const redirection = [
        this.routingService.firstFragment,
        RoutingEnum.HISTORIQUE,
        this.context === 'demande' ? RoutingEnum.DEMANDE_COMP : RoutingEnum.NOTIFICATION,
        this.key,
      ];
      if (this.utils.isLoggedWithAuthorizationCode) this.router.navigate(redirection);
      else this.dialogs.openLoginDialog({ redirection });
    }
  }
}
