export class AffaireResponse {

    // tslint:disable:variable-name


    id = 0;
    cle = '';
    cle_pa = '';
    objet = '';
    date_creation = 0;
    date_creation_f = '';
    nb_dce = 0;
    nb_projet = 0;
    etat_affaire = 0;
    cloture = 0;
    cle_etab = '';

    // tslint:enable:variable-name

}
