import { Component, OnInit, Input } from '@angular/core';
import {
  EssentialDataItem,
  EssentialDataTitulaireItem,
} from '@atline/core/models/essentialDataItem.model';
import { Icons } from '@atline-shared/enums/icons.enum';
import { DialogsService } from '@atline/core/services/dialogs.service';
import { DePlateformeType } from '@atline-shared/enums/dePlateforms.enum';
import { ShareDeData } from '../../../core/services/share-de-data.service';
import { ConstEnum } from '@atline-shared/enums/const.enum';

enum Action {
  ATTRIBUTAIRE,
  PDF,
  JSON,
}

@Component({
  selector: 'app-de',
  templateUrl: './de.component.html',
  styleUrls: ['./de.component.scss'],
})
export class DeComponent implements OnInit {
  @Input() hideActions = false;

  @Input() de!: EssentialDataItem;

  error = null;
  public readonly Icons = Icons;

  readonly actions = Action;

  constructor(
    private readonly dialogService: DialogsService,
    private readonly shareDe: ShareDeData
  ) {}

  ngOnInit(): void {}

  goTo(action: Action): void {
    if (action === Action.ATTRIBUTAIRE && this.de.titulaires) {
      let titulaires = this.de.titulaires;

      if (this.plateformAIFE)
        titulaires = this.de.titulaires.map<EssentialDataTitulaireItem>(t => ({
          ident: t.id,
          type_ident: t.typeIdentifiant,
          type_ident_id: t.id,
          denomination: t.denominationSociale
        }));

      this.dialogService.openAttributaireDialog(titulaires);
    }
  }

  get plateform(): DePlateformeType {
    return this.shareDe.params.plateform;
  }

  get plateformMS(): boolean {
    return this.plateform === DePlateformeType.MS;
  }

  get plateformPES(): boolean {
    return this.plateform === DePlateformeType.PES;
  }

  get plateformAIFE(): boolean {
    return this.plateform === DePlateformeType.AIFE;
  }

  get hasTitulaires(): boolean {
    if (this.de.titulaires) return this.de.titulaires.length > 0;
    return false;
  }

  get annuaireLink(): string {
    return `${ConstEnum.ANNUAIRE_BASE_URL_ESTABLISHMENT}${this.de.titulaireSiret}`;
  }
}
