import { CpvItem } from '../../cvpItem.model';

export class MotsDescripteursResponse {

    // tslint:disable:variable-name

    public mots_descripteurs_list: CpvItem[] = [];

    // tslint:enable:variable-name

}
