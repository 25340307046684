import { Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { TOKEN_ANC_BLOCK } from '@atline/core/models/pa/annonce/anc-token-inject.model';
import { ComponentDisplay } from '@atline/core/models/pa/annonce/form-display.model';
import { ManagerPaEditionAnnonceService } from '../../manager-pa-edition-annonce.service';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { AbstractAncComponent } from '../abstract-anc-component';
import { AccountItem } from '@atline/core/models/accounts.model';

@Component({
  selector: 'anc-option-envoi',
  templateUrl: './anc-option-envoi.component.html',
  styleUrls: ['./anc-option-envoi.component.scss']
})
export class AncOptionEnvoiComponent extends AbstractAncComponent implements OnInit, OnDestroy {

  readonly envoiJoueFC = this.manager.controls.envoi_joue;
  readonly cleCompteFC = this.manager.controls.cle_compte;
  readonly languageFC = this.manager.controls.language;
  readonly dontPublishFC = this.manager.controls.nePasPublierBOAMP;

  private subs = new Subscription();

  readonly langues = ['FR', 'EN'];

  constructor(
    @Inject(TOKEN_ANC_BLOCK) display: ComponentDisplay,
    private readonly manager: ManagerPaEditionAnnonceService,
    { nativeElement }: ElementRef<HTMLElement>,
  ) { super(display, nativeElement); }

  ngOnInit(): void {
    this.subs.add(this.envoiJoueFC?.valueChanges.subscribe(value => {
      if (_.isNumber(value)) {
        value = value.toString();
        this.envoiJoueFC?.setValue(value, { emitEvent: false });
      }
      if ((_.isString(value) ? +value : value)) {
        this.cleCompteFC?.setValue(this.accounts[0]?.cle_compte);
        this.dontPublishFC?.setValue(0);
      }
      else this.languageFC?.setValue(this.langues[0]);
    }));
  }

  ngOnDestroy(): void {
      this.subs.unsubscribe();
  }

  get accounts(): AccountItem[] {
    return this.manager.comptes;
  }

  haveEnvoieJoue(): boolean {
    return !!this.composition?.destinataire;
  }

  haveBoampAccount(): boolean {
    return !!this.composition?.compteBoamp && this.envoiJoueFC?.value === '0';
  }

  haveLanguage(): boolean {
    return !!this.composition?.langue && this.envoiJoueFC?.value === '1';
  }

  havePublish(): boolean {
    return !!this.composition?.publish && this.envoiJoueFC?.value === '0';
  }

}
