import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from 'src/app/core/material/material.module';
import { TranslateModule as NgxTranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HotlineComponent } from './hotline.component';



@NgModule({
  declarations: [HotlineComponent],
  imports: [
    CommonModule,
    NgxTranslateModule,
    MaterialModule,
    FlexLayoutModule
  ],
  exports: [HotlineComponent]
})
export class HotlineModule { }
