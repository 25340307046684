<div class="anc-component" fxLayout="column">
  <app-field-edition *ngIf="composition?.previousTender && publicationFC"
  [label]="'APP.ANNONCES.REFERENCE_PUB.LABEL' | translate"
  [required]="controlIsRequired(publicationFC)"
  [invalid]="publicationFC.touched && publicationFC.invalid">
  <span class="sub-label" sub-label>OPP-090-Procedure</span>
  <mat-form-field appearance="outline">
    <input matInput type="text" [formControl]="publicationFC">
  </mat-form-field>
</app-field-edition>
</div>