<div class="anc-component" *ngIf="composition">
  <app-field-edition *ngIf="composition.activities"
    [label]="'APP.ANNONCES.ACTIVITIES.LABEL' | translate"
    [required]="false"
    [invalid]="false">
    <span *ngIf="isEform" class="sub-label" sub-label>BT-10-Procedure-Buyer</span>
    <div gdColumns="1fr 1fr" gdGap="5px 5px">
      <app-round-checkbox *ngFor="let opt of options"
        [checked]="!!+formActivities[opt]?.value"
        (changeState)="formActivities[opt]?.setValue($event ? '1' : '0')">
        <ng-container *ngLet="opt | kebabcase | uppercase as optionLabel">
          {{ 'APP.ANNONCES.ACTIVITIES.OPTIONS.' + optionLabel | translate }}
        </ng-container>
      </app-round-checkbox>
      <app-round-checkbox *ngIf="otherFC" [(checked)]="other" (changeState)="!other ? otherFC?.setValue('') : undefined">
        {{ 'APP.ANNONCES.ACTIVITIES.OTHER' | translate }}
      </app-round-checkbox>
      <div class="container-other-field" gdColumn="1 / span 2" fxLayout="column nowrap" *ngIf="other && otherFC">
        <label>{{ 'APP.ANNONCES.ACTIVITIES.PRECISION' | translate }}</label>
        <mat-form-field appearance="outline">
          <input matInput type="text" [formControl]="otherFC" />
        </mat-form-field>
      </div>
    </div>
  </app-field-edition>
  <span>{{formActivities.value | json}}</span>
</div>
