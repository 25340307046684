<div class="anc-component" *ngIf="composition">
  <app-field-edition *ngIf="composition.legalBasisProcedure && legalBasisProceFC"
    [label]="'APP.ANNONCES.JURIDIC-BASE.LABEL' | translate"
    [required]="controlIsRequired(legalBasisProceFC)"
    [invalid]="legalBasisProceFC.touched && legalBasisProceFC.invalid">
    <span *ngIf="isEform" class="sub-label" sub-label>BT-01-notice</span>
    <mat-form-field appearance="outline">
      <mat-select [formControl]="legalBasisProceFC">
        <mat-option *ngFor="let opt of options" [value]="opt.value">{{ opt.libelle }}</mat-option>
      </mat-select>
    </mat-form-field>
  </app-field-edition>
</div>
