<app-dialog class="create-annonce-dialog" [dialogRef]="dialogRef">
  <div header>
    {{ 'APP.ANNONCES.INFOS_LABEL.NEW_ANNONCE' | translate }}
  </div>
  <div *ngIf="loading" body fxLayoutAlign="center center" class="container">
    <mat-spinner></mat-spinner>
  </div>
  <form *ngIf="!loading && !annonceCreated" [formGroup]="form" body gdColumns=".75fr 1.5fr" gdGap="10px 0">
    <!-- PA -->

    <label>{{ 'APP.ANNONCES.INFOS_LABEL.PA' | translate }}</label>
    <mat-form-field appearance="outline">
      <mat-select formControlName="pa">
        <mat-option *ngFor="let pa of listPa" [value]="pa.cle_pa">
          {{ pa.denomination_pa }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!-- NA -->
    <label>{{ 'APP.ANNONCES.INFOS_LABEL.NEW_AFFAIRE' | translate }}</label>
    <mat-checkbox [(ngModel)]="na" (ngModelChange)="naChange()" [ngModelOptions]="{standalone: true}" color="primary"></mat-checkbox>

    <!-- INTITULE Aff -->
    <ng-container *ngIf="na">
      <label>{{ 'APP.ANNONCES.INFOS_LABEL.INTITULE_AFFAIRE' | translate }}</label>
      <mat-form-field appearance="outline">
        <input matInput type="text" formControlName="intituleAff">
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="!na">
      <label>{{ 'APP.ANNONCES.INFOS_LABEL.INTITULE_AFFAIRE' | translate }}</label>
      <mat-form-field appearance="outline">
        <mat-select formControlName="intituleAff">
          <mat-option [value]="''">Sélectionnez une affaire existante</mat-option>
          <mat-option *ngFor="let affaire of listAffaires" [value]="affaire.cle">
            {{ affaire.objet }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <!-- TYPE FORM -->
    <label>{{ 'APP.ANNONCES.INFOS_LABEL.FORM' | translate }}</label>
    <mat-form-field appearance="outline">
      <mat-select formControlName="typeForm">
        <mat-option [disabled]="!typeFormAvailable(f.ref_formulaire)" *ngFor="let f of listFormulaires" [value]="f.ref_formulaire">
          {{ f.nom_formulaire }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- COMPTE BOAMP -->
    <label>{{ 'APP.ANNONCES.INFOS_LABEL.BOAMP' | translate }}</label>
    <mat-form-field appearance="outline">
      <mat-select formControlName="compteBoamp">
        <mat-option *ngFor="let account of listAccounts" [value]="account.cle_compte">
          {{ account.compte_libelle }}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </form>
  <div *ngIf="!loading && !annonceCreated" footer fxLayout="row nowrap" fxLayoutAlign="end stretch" fxLayoutGap="10px" style="padding: 10px 0">
    <button mat-flat-button color="primary" (click)="submit()" [disabled]="form.invalid">Créer l'annonce</button>
    <button mat-flat-button color="warn" mat-dialog-close>Annuler</button>
  </div>

  <div body *ngIf="annonceCreated" fxLayout="column nowrap" fxLayoutAlign="center center" fxLayoutGap="30px" class="container">
    <span>{{ 'APP.ANNONCES.ANNONCES_CREATED.CREATED' | translate: { 'id': annonceCreated.identifiant_annonce } }}</span>
    <button mat-flat-button color="primary" (click)="redirection()">{{ 'APP.ANNONCES.ANNONCES_CREATED.REDIRECTION' | translate }}</button>
  </div>
</app-dialog>
