import { Observable } from 'rxjs';
import { ProceduresListRequest } from './../models/api/requests/proceduresList.request';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { ProcedureReponse } from '../models/api/responses/procedure.reponse';
import { AssignProcedureRequest } from '../models/api/requests/AssignProcedureRequest';
import { DeleteProcedureRequest } from '../models/api/requests/DeleteProcedureRequest';

@Injectable({
  providedIn: 'root'
})
export class ProcedureService {

  constructor(
    private readonly apiService: ApiService,
    ) { }

  getProcedures(procedureRequest: ProceduresListRequest): Observable<ProcedureReponse>{
    return this.apiService.post(ProcedureReponse, procedureRequest, 'procedures_actives_list');
  }
  addProcedures(addProcedureRequest: AssignProcedureRequest): Observable<ProcedureReponse>{
    return this.apiService.post(ProcedureReponse, addProcedureRequest, 'procedure_link');
  }
  deleteProcedures(deleteProcedureRequest: DeleteProcedureRequest): Observable<ProcedureReponse>{
    return this.apiService.post(ProcedureReponse, deleteProcedureRequest, 'retirer_procedures');
  }
}
