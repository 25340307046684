<div class="anc-component" fxLayout="column nowrap" style="gap: 10px">
    <div class="actions-soumissionnaire">
      <div class="action">
        <app-button size="medium" [icon]="Icons.Plus" (click)="addSoumissionnaire()"></app-button>
        {{'APP.ANNONCES.SOUMISSIONNAIRES.ADD' | translate | uppercase}}
      </div>
      <div class="action">
        <app-button size="medium" [icon]="Icons.Bin" (click)="removeAllSoumissionnaires()"></app-button>
        {{'APP.ANNONCES.SOUMISSIONNAIRES.DELETE_ALL' | translate | uppercase}}
      </div>
    </div>
    <div class="lots-list" fxLayout="column nowrap" fxLayoutGap="20px">
      <app-soumissionnaire-annonce *ngFor="let soumissionnaire of soumissionnaires; let i = index" [soumissionnaire]="soumissionnaire"
        (soumissionnaireChange)="soumissionnaireChange(i, $event)"
        (cancel)="removeSoumissionnaire(i)"
        (deleted)="removeSoumissionnaire(i)">
      </app-soumissionnaire-annonce>
      <div *ngIf="!soumissionnaires.length" fxLayoutAlign="center center">
        {{'APP.ANNONCES.SOUMISSIONNAIRES.EMPTY' | translate }}
      </div>
    </div>
  </div>
  