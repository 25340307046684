import { ApiRequest } from './api.request';

export class AssignProcedureRequest extends ApiRequest{

  cle_etab!: string
  cle_group?: string
  cle_utilisateur?: string
  type_pa!: 'prive' | 'public' | 'ordonnance2005'
  finalite!: 'autres' | 'dsp' | 'marche' | 'sem'
  id_passation?: string 

}

