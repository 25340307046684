import { Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractAncComponent } from '../abstract-anc-component';
import { Icons } from '@atline-shared/enums/icons.enum';
import { TOKEN_ANC_BLOCK } from '@atline/core/models/pa/annonce/anc-token-inject.model';
import { ComponentDisplay } from '@atline/core/models/pa/annonce/form-display.model';
import { ManagerPaEditionAnnonceService } from '../../manager-pa-edition-annonce.service';
import { AnnoncesService } from '@atline/core/services/annonces.service';
import { Subscription } from 'rxjs';
import { MarcheAnnonce } from '@atline/core/models/api/responses/annonce/marchesList.response';
import * as _ from 'lodash';
import { Debounce } from '@atline/core/decorators/debounce.decorator';
import { DialogsService } from '@atline/core/services/dialogs.service';

@Component({
  selector: 'app-anc-marches',
  templateUrl: './anc-marches.component.html',
  styleUrls: ['./anc-marches.component.scss']
})
export class AncMarchesComponent extends AbstractAncComponent implements OnInit, OnDestroy {
  
  private readonly subs = new Subscription();
  readonly marches: (MarcheAnnonce | undefined)[] = [];
  readonly Icons = Icons;

  constructor(
    @Inject(TOKEN_ANC_BLOCK) display: ComponentDisplay,
    private readonly manager: ManagerPaEditionAnnonceService,
    private readonly dialogService: DialogsService,
    private readonly annoncesService: AnnoncesService,
    { nativeElement }: ElementRef<HTMLElement>,
  ) {
    super(display, nativeElement);
  }
  

  ngOnInit(): void {
    const marchesChange = this.manager.marchesChange.subscribe(marches => {
      this.marches.length = 0;
      this.marches.push(...marches);
    });
    this.subs.add(marchesChange);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  addMarche(): void {
    this.dialogService.openInputFieldDialog('APP.DIALOG.INPUT_FIELD.MARCHES.LABEL', 'APP.DIALOG.INPUT_FIELD.MARCHES.TITLE', (res) => {
      if (this.manager.cle_annonce && res)
          this.annoncesService.editMarche({ cle_annonce: this.manager.cle_annonce, cle_etab: this.manager.cleEtab, cle_pa: this.manager.clePa, identifiant: res }).subscribe({
            next: (res) => {
              this.marches.push(res);
            },
            error: () => {
              this.dialogService.createInfoDialog('Erreur', 'La creéation d\'un marché a échoué. Veuillez réessayer ultérieurement.', 'OK');
            }
          })
    })
  }

  marcheChange(index: number, marche: MarcheAnnonce): void {
    this.marches[index] = marche;
    this.syncMarcheArray();
  }

  removeAllMarche(): void {
    if (!this.manager.cle_annonce) return;
    for (const marche of this.marches) {
      if (marche) {
        this.annoncesService.deleteMarche({
          cle_pa: this.manager.clePa,
          cle_etab: this.manager.cleEtab,
          cle_marche: marche.cleMarche,
          cle_annonce: this.manager.cle_annonce || ''
        }).subscribe(() => {
          const index = _.findIndex(this.marches);
          this.removeMarche(index);
        });
      }
    }
  }
  
  removeMarche(index: number): void {
    this.marches.splice(index, 1);
    if (index > -1 && index < this.manager.marches.length) this.syncMarcheArray();
  }

  @Debounce(100)
  private syncMarcheArray(): void {
    this.manager.marches.length = 0;
    this.manager.marches.push(...this.marches.filter(value => !_.isNil(value)) as MarcheAnnonce[]);
    this.manager.refreshVerifyRulesCall();
  }
}
