import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { AbstractAncComponent } from '../abstract-anc-component';
import { ComponentDisplay } from '@atline/core/models/pa/annonce/form-display.model';
import { ManagerPaEditionAnnonceService } from '../../manager-pa-edition-annonce.service';
import { TOKEN_ANC_BLOCK } from '@atline/core/models/pa/annonce/anc-token-inject.model';
import { Validators } from '@angular/forms';

@Component({
  selector: 'anc-objet-marche',
  templateUrl: './anc-objet-marche.component.html',
  styleUrls: ['./anc-objet-marche.component.scss']
})
export class AncObjetMarcheComponent extends AbstractAncComponent implements OnInit {

  readonly object = this.manager.controls.objet_marche;

  isEform = this.manager.isEforms;

  constructor(
    @Inject(TOKEN_ANC_BLOCK) display: ComponentDisplay,
    private readonly manager: ManagerPaEditionAnnonceService,
    { nativeElement }: ElementRef<HTMLElement>,
  ) { super(display, nativeElement); }

  ngOnInit(): void {
    if (this.required?.intitule) this.object?.setValidators(Validators.required);
    else this.object?.clearValidators();
  }

}
