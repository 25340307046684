<div class="anc-component" *ngIf="composition">
  <app-field-edition *ngIf="composition.typePA && typePaFC"
    [label]="'APP.ANNONCES.TYPE-PA' | translate"
    [required]="controlIsRequired(typePaFC)"
    [invalid]="typePaFC.touched && typePaFC.invalid"
    [center]="isCenter">
    <span class="sub-label" sub-label>BT-11-Procedure-Buyer</span>
    <mat-form-field appearance="outline">
      <mat-select [formControl]="typePaFC">
        <mat-option *ngFor="let type of typePaList" [value]="type.value">{{ type.libelle }}</mat-option>
      </mat-select>
    </mat-form-field>
    <!-- <div fxLayout="row nowrap" fxLayoutGap="10px" *ngIf="typePaFC.value == 7 && autreOrguanismeFC">
      <label fxFlex="noshrink" for="precision">{{ 'APP.ANNONCES.ACTIVITIES.PRECISION' | translate }}</label>
      <span class="sub-label" sub-label>BT-10-Procedure-Buyer</span>
      <mat-form-field appearance="outline" fxFlex="grow">
        <input matInput type="text" [formControl]="autreOrguanismeFC" name="precision">
      </mat-form-field>
    </div> -->
  </app-field-edition>
</div>
