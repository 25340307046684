<form [formGroup]="form" style="width: 100%">
  <mat-form-field appearance="outline" fxFlex="100">
    <mat-select formControlName="adr_pays">
      <mat-option
        *ngFor="let country of countryArr?.countries"
        [value]="country"
      >
        {{ country.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>
