<div class="anc-component" *ngIf="composition">
  <!-- oui non -->
  <app-field-edition *ngIf="composition?.relanceInfructeux && relanceInfructeuxFC" [label]="'APP.ANNONCES.PROCEDURE_E16.LABEL0' | translate"
    [required]="isRequired(required?.visite)" [invalid]="false">
    <span class="sub-label" sub-label>BT-634-Procedure</span>
    <app-yes-no-radio-button [value]="relanceInfructeuxFC.value" (valueChange)="relanceInfructeuxFC.setValue($event)"></app-yes-no-radio-button>
  </app-field-edition>
  <!--  -->
  <app-field-edition *ngIf=" descriptionFC" [label]="'APP.ANNONCES.PROCEDURE_E16.LABEL1' | translate"
    [required]="controlIsRequired(descriptionFC)" [invalid]="descriptionFC.touched && descriptionFC.invalid">
    <span class="sub-label" sub-label>BT-88-Procedure</span>
    <div class="textarea" fxLayout="row" fxLayoutAlign="start end" fxLayoutGap="10px">
      <mat-form-field appearance="outline" class="full-width" fxFlex="85">
        <textarea matInput #descriptionTextArea cdkTextareaAutosize #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="5" cdkAutosizeMaxRows="10" [maxlength]="maxLengthDescription"
          [formControl]="descriptionFC" class="text-area-not-rised">
        </textarea>
      </mat-form-field>
      <span [inputCounter]="descriptionTextArea" [total]="true" style="white-space: nowrap;"></span>
    </div>
  </app-field-edition>
  <app-field-edition *ngIf=" procedureFC" [label]="'APP.ANNONCES.PROCEDURE_E16.LABEL2' | translate"
    [required]="controlIsRequired(procedureFC)"
    [invalid]="procedureFC.touched && procedureFC.invalid">
    <span class="sub-label" sub-label>BT-105-Procedure</span>
    <mat-form-field appearance="outline">
      <mat-select [formControl]="procedureFC">
        <mat-option *ngFor="let opt of options" [value]="opt.value">{{ opt.libelle }}</mat-option>
      </mat-select>
    </mat-form-field>
  </app-field-edition>

</div>