import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { RoutingEnum } from '@atline-shared/enums/routing.enum';
import { RoutingService } from '@atline/core/services/routing.service';
import { SeoService } from '@atline/core/services/seo.service';
import { ShareConsultationOeData } from '@atline/core/services/share-consultations-oe-data.service';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { GlobalDataService } from 'src/app/core/services/data-service.service';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  searchConsultation!: FormGroup;

  public critereState: CriteresState = {
    is_dume: false,
    criteres_environnementaux: false,
    criteres_sociaux: false
  };
  // tslint:enable:variable-name


  public prestationList = [
    { code: 'fnt', name: 'fournitures', key: 'MARCHES_SECURISES.CONSULTATION.SUPPLIES' },
    { code: 'srv', name: 'services', key: 'MARCHES_SECURISES.CONSULTATION.SERVICES' },
    { code: 'tvx', name: 'travaux', key: 'MARCHES_SECURISES.CONSULTATION.WORKS' }
  ];

  public criteresList = [
    { code: 'dume', name: 'is_dume', img: 'dume', title: 'MARCHES_SECURISES.CONSULTATION.DUME' },
    { code: 'csx', name: 'criteres_sociaux', img: 'cs', title: 'MARCHES_SECURISES.CONSULTATION.CRITERES_SOCIAUX' },
    { code: 'cex', name: 'criteres_environnementaux', img: 'ce', title: 'MARCHES_SECURISES.CONSULTATION.CRITERES_ENVIRONNEMENTAUX' }
  ];

  public prestationState: PrestationState = {
    travaux: false,
    services: false,
    fournitures: false
  };

  isAuthenticate = false;
  isLoading = false;

  private subs = new Subscription();

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly globalDataService: GlobalDataService,
    private readonly utils: UtilsService,
    private readonly seoService: SeoService,
    private readonly shareConsultationDataService: ShareConsultationOeData,
    private readonly routingService: RoutingService
  ) {
  }

  ngOnInit(): void {
    this.seoService.setTile('SEARCH');

    this.subs.add(this.globalDataService.token.subscribe(() => {
      this.shareConsultationDataService.setParams({
        demo: this.utils.isDemo,
        sens_ordre: 'ASC'
      });
    }))

    this.subs.add(this.globalDataService.isLogged.subscribe(res => this.isAuthenticate = res));

    this.searchConsultation = this.formBuilder.group(
      {
        motscles: new FormControl(),
        departement: new FormControl(),
        is_dume: new FormControl(),
        criteres_environnementaux: new FormControl(),
        criteres_sociaux: new FormControl(),
        demo: new FormControl(this.utils.isDemo),
        dateEnd: new FormControl(),
        dateStart: new FormControl(),
        type_prestation: new FormControl()
      }
    );
    this.globalDataService.setFormData(this.searchConsultation.value);
    this.subs.add(this.shareConsultationDataService.searchInProgressChange.subscribe(res => this.isLoading = res));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  get nbConsultation(): number | undefined {
    return this.isLoading ? undefined : this.shareConsultationDataService.numberConsultationOeData;
  }

  get dateEnd(): any {
    return this.searchConsultation.controls.dateEnd.value;
  }

  get dateStart(): any {
    return this.searchConsultation.controls.dateStart.value;
  }

  updatePrestationState(prestation: string): void {
    this.prestationState[prestation] = !this.prestationState[prestation];
    const formatForWS = this.formatObjectToStringForRequest(this.prestationState);
    this.searchConsultation.controls.type_prestation.setValue(!_.isEmpty(formatForWS) ? formatForWS : undefined);
  }

  submit(): void {
    this.shareConsultationDataService.setParams({...this.searchConsultation.value, demo: this.utils.isDemo});
    this.router.navigate([this.routingService.firstFragment, RoutingEnum.CONSULTATION]);
  }


  formatObjectToStringForRequest(obj: PrestationState | CriteresState): string {
    let res = '';
    for (const [key, value] of Object.entries(obj)) {
      if (value) {
        if (res !== '') {
          res += ',' + key;
        } else {
          res += key;
        }
      }
    }
    return res;
  }
}


export class PrestationState {
  [key: string]: boolean
  travaux = false;
  services = false;
  fournitures = false;
}

// tslint:disable:variable-name
export class CriteresState {
  [key: string]: boolean
  is_dume = false;
  criteres_environnementaux = false;
  criteres_sociaux = false;
}

// tslint:enable:variable-name

