export class CritereAttributionListAnnonceResponse {
  // tslint:disable:variable-name
  public criteres: CritereAttributionAnnonceItem[] = [];
  public nb_total = 0;

}

export class CritereAttributionAnnonceItem {
  cleCritere!: string;
  cleAnnonce = '';
  cleLot = '';
  type: string = '';
  description: string = '';
  fixe?: {
    type: string,
    value: string
  }
  poids?: {
    type: string,
    value: string
  }
  seuil?: {
    type: string,
    value: string
  }
  nom: string = '';
}
