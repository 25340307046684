import { Observable } from 'rxjs';
import { CountryResponse } from '../models/api/responses/country.response';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  constructor(private readonly apiService: ApiService) { }


  getCountry(): Observable<CountryResponse>{
    return this.apiService.post(CountryResponse, null, 'pays_list');
  }

}
