<div class="container" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="14px" fxFlex="100"
  [style.marginTop.px]="getMargin('top')" [style.marginRight.px]="getMargin('right')"
  [style.marginBottom.px]="getMargin('bottom')" [style.marginLeft.px]="getMargin('left')">

  <mat-spinner [strokeWidth]="stroke" [diameter]="size"></mat-spinner>

  <span class="label" *ngIf="key">
    {{'APP.SPINNER.' + key | translate}}
  </span>

</div>