export class CriteresSelectionListAnnonceResponse {
  // tslint:disable:variable-name
  public criteres: CritereSelectionAnnonceItem[] = [];
  public nb_total = 0;
  // tslint:disable:variable-name
}

export class CritereSelectionAnnonceItem {
  public appSecondeEtape = '';
  public cleAnnonce = '';
  public cle_annonce = '';
  public cleCritere = '';
  public cleLot: string = '';
  public nom: string = '';
  public description: string = '';
  public type: string = '';
  public utilisation: string = '';
  public poids?: {
    type: string,
    value: string
  }
  public seuil?: {
    type: string,
    value: string
  }

}
