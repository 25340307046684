import { Directive, ElementRef, HostBinding, Input } from '@angular/core';
import { ModesBlocksInfos } from './modeBlockInfos.model';

@Directive({
  selector: 'p[infoForBlock]'
})
export class InfoForBlockDirective {

  @HostBinding('class.info-for-block') readonly isInfo = true;
  @HostBinding('class') mode: ModesBlocksInfos = 'warning';

  constructor(readonly ref: ElementRef<HTMLParagraphElement>) { }

  @Input()
  get id(): string {
    return this.ref.nativeElement.id;
  }

  set id(arg: string) {
    this.ref.nativeElement.id = `info-${arg}`;
  }



}
