<app-dialog [dialogRef]="dialogRef" [callback]="dialogService.closeCguDepotDialog">

  <ng-container header>
    {{'APP.MARCHES_SECURISES.CONSULTATION.DEPOT.CGU.TITLE' | translate}}
  </ng-container>

  <ng-container body>

    <div class="sections">
      <div class="section">
        <div class="subtitle">
          {{'APP.MARCHES_SECURISES.CONSULTATION.DEPOT.CGU.PARAPH_1_SUBTITLE' | translate}}
        </div>

        <div class="cguContent" [innerHTML]="'APP.MARCHES_SECURISES.CONSULTATION.DEPOT.CGU.PARAPH_1' | translate"></div>
      </div>

      <div class="section">
        <div class="subtitle">
          {{'APP.MARCHES_SECURISES.CONSULTATION.DEPOT.CGU.PARAPH_2_SUBTITLE' | translate}}
        </div>

        <div class="cguContent" [innerHTML]="'APP.MARCHES_SECURISES.CONSULTATION.DEPOT.CGU.PARAPH_2' | translate"></div>
      </div>

      <div class="section">
        <div class="subtitle">
          {{'APP.MARCHES_SECURISES.CONSULTATION.DEPOT.CGU.PARAPH_3_SUBTITLE' | translate}}
        </div>

        <div class="cguContent" [innerHTML]="'APP.MARCHES_SECURISES.CONSULTATION.DEPOT.CGU.PARAPH_3' | translate"></div>
      </div>

      <div class="section">
        <div class="subtitle">
          {{'APP.MARCHES_SECURISES.CONSULTATION.DEPOT.CGU.PARAPH_4_SUBTITLE' | translate}}
        </div>

        <div class="cguContent" [innerHTML]="'APP.MARCHES_SECURISES.CONSULTATION.DEPOT.CGU.PARAPH_4' | translate"></div>
      </div>

      <div class="section">
        <div class="subtitle">
          {{'APP.MARCHES_SECURISES.CONSULTATION.DEPOT.CGU.PARAPH_5_SUBTITLE' | translate}}
        </div>

        <div class="cguContent" [innerHTML]="'APP.MARCHES_SECURISES.CONSULTATION.DEPOT.CGU.PARAPH_5' | translate"></div>
      </div>
    </div>


  </ng-container>

  <ng-container footer>
    <div class="form" [formGroup]="cguForm" fxLayout="column" fxFlex="100">
      <div class="form-elt" fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayoutAlign="start center">
          <div class="required">
            *
          </div>
          <app-round-checkbox [code]="'accept'" [name]="'accept'" (changeState)="getAcceptCGU($event)">
            <label [for]="'accept'"> {{'APP.MARCHES_SECURISES.CONSULTATION.DEPOT.CGU.ACCEPT' | translate}}
            </label>
          </app-round-checkbox>
        </div>
        <div class="full-width" fxLayout="row" fxLayoutAlign="end center">
          <div class="submit chevron" (click)="startDepot()" fxLayoutAlign="center center" *ngIf="!data.isDume">
            <mat-icon [svgIcon]="icons.Chevron"></mat-icon>
          </div>
        </div>
      </div>

      <div class="form-elt" fxLayout="row" fxLayoutAlign="start center" fxFlex="100" *ngIf="data.isDume">
        <div class="required">
          *
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
          <mat-radio-group formControlName="typeResponse">
            <mat-radio-button value="1" color="primary" class="m-10">
              {{'APP.MARCHES_SECURISES.CONSULTATION.DEPOT.CGU.STANDARD' | translate}}</mat-radio-button>
            <mat-radio-button value="2" color="primary" class="m-10" [disabled]="true" style="filter: grayscale(1);">
              <img src="/assets/images/dume.png" alt="" style="opacity: .5;">
            </mat-radio-button>
          </mat-radio-group>

          <div class="submit chevron" (click)="startDepot()" fxLayoutAlign="center center">
            <mat-icon [svgIcon]="icons.Chevron"></mat-icon>
          </div>
        </div>
      </div>

    </div>
  </ng-container>





</app-dialog>