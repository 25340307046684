<app-dialog>

  <ng-container header>
    {{'APP.MARCHES_SECURISES.CONSULTATION.HOLDERS.HEADER' | translate }}
  </ng-container>

  <ng-container body>
    <ng-container *ngIf="!isLoading; else elseTemplate">

    </ng-container>
    <ng-template #elseTemplate>
      <div class="mail-missing" *ngIf="hasAttributaireWhitoutEmail">
        {{'APP.MARCHES_SECURISES.CONSULTATION.HOLDERS.NO_MAIL' | translate }}
      </div>

      <div class="mail-missing" *ngIf="!hasAtrributaires">
        {{'APP.MARCHES_SECURISES.CONSULTATION.HOLDERS.NO_ATTRIBUTAIRE' | translate }}
      </div>
      <ng-container *ngIf="!data.hasLot">
        <ng-container *ngTemplateOutlet="holdersList"></ng-container>
      </ng-container>


      <ng-container *ngIf="lots">
        <div class="lot" fxLayout="row" fxLayoutAlign="start center">
          <span class="label">{{'APP.MARCHES_SECURISES.CONSULTATION.HOLDERS.CHOOSE_BATCH' | translate }}</span>
          <mat-form-field appearance="outline">
            <mat-select [(ngModel)]="lotSelected" (selectionChange)="changeLots()">
              <mat-option *ngFor="let lot of lots" [value]="lot.cle">
                {{lot.libelle}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <ng-container *ngIf="lotSelected">
          <ng-container *ngTemplateOutlet="holdersList"></ng-container>
          <ng-container *ngTemplateOutlet="groupsList"></ng-container>
        </ng-container>
      </ng-container>


      <div class="actions-button" fxLayout="row" fxLayoutAlign="start center">
        <ng-container *ngIf="lots; then buttonWithLots; else goToConsultation"></ng-container>
        <ng-template #buttonWithLots>
          <app-button [size]="'auto'" (click)="sendRequest()">
            {{'APP.MARCHES_SECURISES.CONSULTATION.HOLDERS.CONSULTER_HOLDERS' | translate}}
          </app-button>
        </ng-template>
        <ng-template #goToConsultation>
          <app-button [size]="'auto'" (click)="sendRequest()">
            {{'APP.MARCHES_SECURISES.CONSULTATION.HOLDERS.GO_TO_CONSULTATION' | translate}}
          </app-button>
        </ng-template>
      </div>
    </ng-template>
  </ng-container>
  <ng-container footer>
  </ng-container>

</app-dialog>


<ng-template #holdersList>
  <div class="attributaires-associated">
    <h3 class="info" fxLayout="row">
      <img class="icon" src="/assets/icons/svg/icons-info.svg" alt="">
      <span>{{'APP.MARCHES_SECURISES.CONSULTATION.HOLDERS.UNSELECT' | translate}}</span>
    </h3>
    <div *ngFor="let att of attributairesFiltered" class="attributaire">
      <mat-checkbox value="att.isSelected" color="primary" [(ngModel)]="att.isSelected">{{att.nom_ent}} -
        {{att.email_contact}}</mat-checkbox>
    </div>
  </div>
</ng-template>

<ng-template #groupsList>
  <div class="groups-associated" *ngIf="lotSelected">
    <h3 class="info" fxLayout="row" *ngIf="groups.length > 0">
      <img class="icon" src="/assets/icons/svg/icons-info.svg" alt="">
      <span>{{'APP.MARCHES_SECURISES.CONSULTATION.HOLDERS.UNSELECT_GROUPS' | translate}}</span>
    </h3>
    <div *ngFor="let group of groups" class="attributaire">
      <mat-checkbox value="group.isSelected" color="primary" [(ngModel)]="group.isSelected">{{group.nom}}</mat-checkbox>
    </div>
  </div>
</ng-template>