import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { RoutingEnum } from '@atline-shared/enums/routing.enum';
import { AddOrDeleteFavorisRequest } from '@atline/core/models/api/requests/favoris.request';
import { DialogsService } from '@atline/core/services/dialogs.service';
import { FavorisService } from '@atline/core/services/favoris.service';
import { RoutingService } from '@atline/core/services/routing.service';
import { UtilsService } from '@atline/core/services/utils.service';
import { BooleanValue } from '@atline/core/types/boleanMs.type';
import * as _ from 'lodash';

@Component({
  selector: 'app-favoris-star',
  templateUrl: './favoris.component.html',
  styleUrls: ['./favoris.component.scss']
})
export class FavorisComponent implements OnInit {

  @Input()
  cleDce?: string;

  @Input()
  isFavorite?: BooleanValue;

  private params!: AddOrDeleteFavorisRequest;
  isLoading = false;

  constructor(
    private readonly router: Router,
    private readonly favorisService: FavorisService,
    private readonly utilsService: UtilsService,
    private readonly dialogService: DialogsService,
    private readonly routingService: RoutingService
  ) { }

  ngOnInit(): void {
    if (this.cleDce)
      this.params = {
        cle_ent: this.utilsService.cleEnt ?? '',
        cle_etab: this.utilsService.cleEnt ?? '',
        cle_dce: this.cleDce
      }
  }

  action(): void {
    console.log(this.hasCleDce);
    
    if (!this.hasCleDce) {
      this.router.navigate([this.routingService.firstFragment, RoutingEnum.HISTORIQUE, RoutingEnum.FAVORIS]);
    } else {
      if (!_.isEmpty(this.params.cle_ent) && !_.isEmpty(this.params.cle_etab)) {
        this.isLoading = true;
        this.isFavorite ? this.deleteConsultationFavorisFromWS() : this.addConsultationFavorisFromWS();
      } else {
        this.dialogService.createInfoDialog('APP.ERROR.MARCHES_SECURISES.FAVORITES.PARAMS', '', 'OK');
      }

    }
  }

  get hasCleDce(): boolean {
    return !_.isNil(this.cleDce);
  }


  private addConsultationFavorisFromWS(): void {
    this.favorisService.addConsultationFavoris(this.params).subscribe({
      next: () => {
        this.isFavorite = !this.isFavorite;
        this.isLoading = false;
      },
      error: () => {
        this.dialogService.createInfoDialog('APP.ERROR.MARCHES_SECURISES.FAVORITES.EDD', '', 'OK');
        this.isLoading = false;
      }
    })
  }

  private deleteConsultationFavorisFromWS(): void {
    this.favorisService.deleteConsultationFavoris(this.params).subscribe({
      next: () => {
        this.isFavorite = !this.isFavorite;
        this.isLoading = false;
      },
      error: () => {
        this.dialogService.createInfoDialog('APP.ERROR.MARCHES_SECURISES.FAVORITES.DELETE', '', 'OK');
        this.isLoading = false;
      }
    })
  }
}
