import { Injectable } from '@angular/core';
import { SessionStorage } from '../../shared/enums/session-storage.enum';
import { UtilsService } from './utils.service';
import { Observable, of, throwError } from 'rxjs';
import { AuthorizeRequest, Login, TokenRequest } from '../models/api/requests/authorize.request';
import { AuthorizeResponse, TokenResponse } from '../models/api/responses/authenticate.response';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { GlobalDataService } from './data-service.service';
import * as _ from 'lodash';
import { ROLE_PA } from '../models/constant-variable/role.model';
import { ROLE } from '../models/constant-variable/role.model';
import { grantType } from '@atline-shared/enums/api.enum';
import { LocalStorage } from '@atline-shared/enums/local-storage.enum';
import { TokenService } from './token.service';
import { RoutingEnum } from '@atline-shared/enums/routing.enum';
import { RoutingService } from './routing.service';
import { TokenProviderEnum } from '@atline-shared/enums/tokenProvider.enum';
import { SsoService } from './sso.service';

@Injectable({
  providedIn: 'root'
})

export class AuthenticateService {

  constructor(
    private readonly utilsService: UtilsService,
    private readonly apiService: ApiService,
    private readonly globalDataService: GlobalDataService,
    private readonly router: Router,
    private readonly tokenService: TokenService,
    private readonly routingService: RoutingService,
    private readonly ssoService: SsoService
  ) { }


  /**
   * Getter logged.
   * @returns boolean
   */
  public get logged(): boolean {
    return this.utilsService.isLoggedWithAuthorizationCode;
  }

  /**
   * Getter Observable logged
   * @returns Observable<boolean>
   */
  public get isLogged(): Observable<boolean> {
    return this.globalDataService.isLogged;
  }

  public get role(): ROLE | undefined {
    return this.utilsService.getRole();
  }

  public get getRole(): Observable<ROLE | undefined> {
    return this.globalDataService.getRole();
  }

  getTokenInfo(token: string): Observable<TokenResponse> {    
    return this.apiService.post(TokenResponse, { token }, 'token_info_get');
  }

  /**
   * Login the user using his login/password in order to get the token
   * Two requests are sent to the API: authorize and token
   * @param formBody having username and password
   */
  login(formBody: Login): Observable<TokenResponse | null> {
    const authRequest = new AuthorizeRequest();
    authRequest.response_type = 'code';
    authRequest.client_id = environment.clientId;
    authRequest.redirect_uri = environment.redirectUrl;
    authRequest.state = 'login';
    authRequest.login = formBody.username;
    authRequest.password = formBody.password;
    // authRequest.locale = this.utilsService.getLocale();

    return this.authorize(authRequest).pipe(
      catchError(err => throwError(err)),
      mergeMap(res => {
        if (res.Location) {
          const codeValue = this.utilsService.getParamValueQueryString(res.Location, 'code');
          if (codeValue) {
            const tokenReq: TokenRequest = {
              grant_type: grantType.AUTHORIZATION_CODE,
              client_id: environment.clientId,
              client_secret: environment.clientSecret,
              code: codeValue,
              redirect_uri: environment.redirectUrl,
              // locale: this.utilsService.getLocale()
            };
            return this.getAccessToken(tokenReq).pipe(
              catchError(err => throwError(err)),
              tap(access => {
                console.log(access);

                this.setSessionData(access);
                this.setLocalData(access);
                this.tokenService.verifyTokenValidity();
                this.globalDataService.setIsLogged(true);
                this.router.navigate(
                  _.upperCase(this.utilsService.getTenderRights()) === ROLE_PA ?
                    ['/', this.routingService.firstFragment, RoutingEnum.PA, RoutingEnum.CONSULTATION] :
                    ['/', this.routingService.firstFragment])
                this.globalDataService.setIsInitialized(true);
              }),
            );
          }
          return of(null);
        }
        return of(null);
      }),
    );
  }

  setData(access: TokenResponse) {
    this.setSessionData(access);
    this.setLocalData(access);
    this.tokenService.verifyTokenValidity();
    this.globalDataService.setIsLogged(true);
    this.router.navigate(
      _.upperCase(this.utilsService.getTenderRights()) === ROLE_PA ?
        ['/', this.routingService.firstFragment, RoutingEnum.PA, RoutingEnum.CONSULTATION] :
        ['/', this.routingService.firstFragment])
    this.globalDataService.setIsInitialized(true);
  }

  /**
   * Call the api with Authorize request
   * @param request AuthorizeRequest
   */
  authorize(request: AuthorizeRequest): Observable<AuthorizeResponse> {
    return this.apiService.post(AuthorizeResponse, request, 'authorize');
  }

  /**
   * Call the api with Token request
   * @param request TokenRequest
   */
  getAccessToken(request: TokenRequest): Observable<TokenResponse> {
    return this.apiService.post(TokenResponse, request, 'token');
  }

  /**
   *
   * @returns get token for client
   */
  generateTokenForClient(): Observable<TokenResponse> {
    const tokenRequest = new TokenRequest();
    tokenRequest.client_id = environment.clientId;
    tokenRequest.client_secret = environment.clientSecret;
    tokenRequest.grant_type = grantType.CLIENT_CREDENTIALS;
    tokenRequest.redirect_uri = environment.redirectUrl;
    return this.getAccessToken(tokenRequest);
  }

  /**
   * Log out by clearing the storage and navigate back to home
   */
  async logout(): Promise<void> {
    if (this.utilsService.getSessionStorage(SessionStorage.USER_SESSION, true).provider !== TokenProviderEnum.MS) {
      this.ssoService.logout();
    }
    this.utilsService.clearLocalStrorage();
    this.utilsService.clearSessionStorage();
    // this.globalDataService.setIsLogged(false);
    const pathBase = this.routingService.firstFragment === '' ? '' : (this.router.url.split('/')[1] || '');
    const newToken = await this.generateTokenForClient().toPromise();
    this.setSessionData(newToken);
    this.setLocalData(newToken);
    this.globalDataService.setToken(newToken.access_token);
    this.globalDataService.setIsLogged(false);
    this.utilsService.setSessionStorage(SessionStorage.USER_SESSION, JSON.stringify(newToken));
    this.setLocalData(this.utilsService.getSessionStorage(SessionStorage.USER_SESSION, true));
    this.router.navigate([pathBase]);
  }

  /**
   * Save the user's session data got from tokenResponse in the sessionStorage
   * @param session TokenResponse
   */
  setSessionData(session: TokenResponse): void {
    session.dateCreation = new Date();
    this.utilsService.setSessionStorage(SessionStorage.USER_SESSION, JSON.stringify(session));
  }

  /**
   * Save token localStorage
   * @param session TokenResponse
   */
  setLocalData(session: TokenResponse): void {
    this.utilsService.setLocalStorage(LocalStorage.USER_SESSION, JSON.stringify({ t: session.access_token }));
  }

  /**
   * get Session data from sessionStorage
   */
  getSessionData(): TokenResponse {
    return this.utilsService.getSessionStorage(SessionStorage.USER_SESSION, true) as TokenResponse;
  }

}
