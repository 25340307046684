export interface Address {
  cle: string;
  nom: string;
  denomination: string;
  contactPoint: string;
  adr1: string;
  adr2: string;
  adr3: string;
  adr4: string;
  contact: string;
  department: string;
  city: string;
  country: string;
  cp: number;
  identification: {
    siret: string;
    tva: string;
  };
  profil: string;
  coord: {
    tel: string;
    fax: string;
    mel: string;
    url: string;
  };
}

export class DictAdressResponse { 
  address: Address[] = [{
    cle: '',
    nom: '',
    denomination: '',
    contactPoint: '',
    adr1: '',
    adr2: '',
    adr3: '',
    adr4: '',
    contact: '',
    department: '',
    city: '',
    country: '',
    cp: 0,
    identification: {
      siret: '',
      tva: '',
    },
    profil: '',
    coord: {
      tel: '',
      fax: '',
      mel: '',
      url: '',
    },
  }];
}
