import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { AbstractAncComponent } from '../abstract-anc-component';
import { TOKEN_ANC_BLOCK } from '@atline/core/models/pa/annonce/anc-token-inject.model';
import { ComponentDisplay } from '@atline/core/models/pa/annonce/form-display.model';
import { ManagerPaEditionAnnonceService } from '../../manager-pa-edition-annonce.service';

@Component({
  selector: 'anc-division-lots',
  templateUrl: './anc-division-lots.component.html',
  styleUrls: ['./anc-division-lots.component.scss']
})
export class AncDivisionLotsComponent extends AbstractAncComponent implements OnInit {

  public attribOffresInitiales = this.manager.controls.candidat_reduction_progessive;
  public tvaFC = this.manager.controls.nbMaxLotOffre;
  public tvamaxFC = this.manager.controls.nbMaxLotAttrib;
  public repartitionLotsFC = this.manager.controls.repartition_reponse_lot_eform;
  private sub = this.attribOffresInitiales?.valueChanges.subscribe(value => {
    if (!value) {
      this.tvaFC?.setValue('');
      this.tvamaxFC?.setValue('');
    }
  });

  readonly options = [
    { value: 'none', libelle: ' ' },
    { value: 'tousLots', libelle: 'Tous les lots' },
  ] as const;
  constructor(
    @Inject(TOKEN_ANC_BLOCK) display: ComponentDisplay,
    private readonly manager: ManagerPaEditionAnnonceService,
    { nativeElement }: ElementRef<HTMLElement>,
  ) { super(display, nativeElement); }

  ngOnInit(): void {
    this.sub?.unsubscribe();
  }

}
