import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { AuthorizeSsoRequest, TokenSsoRequest } from '../models/api/requests/sso.request';
import { AuthorizeSsoResponse } from '../models/api/responses/sso/sso.response';
import { TokenResponse } from '../models/api/responses/authenticate.response';
import { DefaultResponse } from '../models/api/responses/default.response';

@Injectable({
  providedIn: 'root'
})
export class SsoService {

  constructor(private readonly apiService: ApiService) {}

  authorize(req: AuthorizeSsoRequest) {
    return this.apiService.post(AuthorizeSsoResponse, req, 'authorize_sso', false); 
  }

  token(req: TokenSsoRequest) {
    return this.apiService.post(TokenResponse, req, 'token_sso', false); 
  }

  logout() {
    return this.apiService.post(DefaultResponse, {}, 'logout_sso')
  }

}
