import { Directive, ElementRef, Input, OnChanges, OnInit, OnDestroy, SimpleChanges, HostBinding } from '@angular/core';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

function MAP_DEFAULT(value: string, input: HTMLInputElement | HTMLTextAreaElement, total?: number): number | string {
  if (_.isNil(total)) return value.length;

  const t = total > 0 ? total : input.maxLength;
  const n = value.length + '';
  const lengthPad = String(Math.abs(t)).length;
  return `${n.padStart(lengthPad, '\u00A0')}\u00A0/\u00A0${total}`;
}

class MutationsObservable extends Observable<MutationRecord> {

  private observer!: MutationObserver;

  constructor(readonly element: HTMLElement) {
    super(sub => {
      this.observer = new MutationObserver(mutations => sub.next(mutations[0]));
      this.observer.observe(element, { attributes: true });
      return (): void => this.observer.disconnect();
    });
  }
}

@Directive({
  selector: 'span[inputCounter]',
})
export class CounterDirective implements OnChanges, OnInit, OnDestroy {

  @Input('inputCounter')
  public for?: HTMLInputElement | HTMLTextAreaElement;
  @Input()
  public total: number | boolean = false;
  @Input()
  public map?: (value: string, input: HTMLInputElement | HTMLTextAreaElement, total?: number) => number | string;

  readonly element!: HTMLSpanElement;
  readonly fEvent = (event: any): void => this.setText(event.target);

  private init = false;

  private observer?: Subscription;

  public value?: string;

  constructor(readonly elementRef: ElementRef<HTMLSpanElement>) {
    this.element = elementRef.nativeElement;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.for) {
      let el = changes.for.previousValue as HTMLInputElement | HTMLTextAreaElement | undefined;
      el?.removeEventListener('input', this.fEvent);
      this.observer?.unsubscribe();
      this.observer = undefined;

      el = changes.for.currentValue as HTMLTextAreaElement | HTMLTextAreaElement | undefined;
      if (el) {
        el.addEventListener('input', this.fEvent);
        this.observer = new MutationsObservable(el).pipe(filter(m => m.type === 'attributes')).subscribe(() => {
          this.setText(el);
        });
      }
    }
    if (this.init) this.setText(this.for);
  }

  ngOnInit(): void {
    this.init = true;
    this.setText(this.for);
  }

  ngOnDestroy(): void {
    this.observer?.unsubscribe();
  }

  @HostBinding('style.')

  private setText(target?: HTMLInputElement | HTMLTextAreaElement): void {
    if (_.isNil(target)) { this.clear(); return; }

    const total = _.isNumber(this.total) ? this.total : (this.total ? target.maxLength : undefined);
    this.value = (this.map ?? MAP_DEFAULT)(target.value || '', target, total) + '';
    this.element.innerText = this.value ?? '';
  }

  private clear(): void {
    delete this.value;
    this.element.innerText = '';
  }
}
