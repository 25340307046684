import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EMAIL_REGEX, PASSWORD } from '@atline/core/models/constant-variable/regex.model';
import { ResetPaswordService } from '@atline/core/services/resetPassword.service';
import * as _ from 'lodash';
import { catchError, tap } from 'rxjs/operators';

@Component({
  selector: 'app-reset-pwd',
  templateUrl: './reset-pwd.component.html',
  styleUrls: ['./reset-pwd.component.scss']
})
export class ResetPwdComponent implements OnInit {

  private token?: string;

  error?: number;
  changeMdpForm!: FormGroup;
  isLoaded = false;

  pwdFC = new FormControl(null, [Validators.required, Validators.pattern(PASSWORD), Validators.minLength(8)]);
  emailFC = new FormControl(null, [Validators.required, Validators.pattern(EMAIL_REGEX)]);
  confirmPwdFC = new FormControl(null, [Validators.required]);
  tokenFC = new FormControl(null);


  constructor(
    readonly fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    readonly resetPaswordService: ResetPaswordService
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.token = params['token'];
      if (this.token)
        resetPaswordService.openLink(this.token).pipe(
          tap(() => this.isLoaded = true),
          catchError(() => { this.isLoaded = true; throw Error() })
        ).subscribe({
          next: (res) => {
            this.error = res.status === 2001 ? undefined : res.status;
          },
          error: () => {
            this.error = 4041
          }
        })

      this.tokenFC.setValue(this.token);
      if (_.isNil(this.token))
        this.error = 4041;
      this.changeMdpForm = fb.group({
        email: this.emailFC,
        pwd: this.pwdFC,
        confirmPwd: this.confirmPwdFC,
        token: this.tokenFC
      }, { validators: this.checkPasswords })
    });
  }

  ngOnInit(): void { }

  resetPwd() {
    if (this.changeMdpForm.valid)
      this.resetPaswordService.changePassword(this.changeMdpForm.value).subscribe({
        next: (res) => {
          if (res.status === 2001)
            this.error = 2001;
        },
        error: (_err) => {

        }
      })
  }

  checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    let pass = group.get('pwd')?.value;
    let confirmPass = group.get('confirmPwd')?.value;
    console.log(pass, confirmPass);

    console.log(pass === confirmPass ? null : { notSame: true });
    

    return pass === confirmPass ? null : { notSame: true }
  }

}
