import { Directive, HostBinding, HostListener } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Directive({
  selector: 'input[inputPrice]',
})
export class InputPriceDirective {

  constructor(private sanitizer: DomSanitizer) { }

  @HostListener('keypress', ['$event'])
  input(event: KeyboardEvent): boolean {
    const input = event.target as HTMLInputElement;
    const positionCursor = input.selectionEnd ?? -1;
    const char = event.key;
    const charCode = char.charCodeAt(0);
    if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    if (
      input.value.includes('.') &&
      (char === '.' || (input.value.split('.')[1]?.length >= 2) && positionCursor > input.value.indexOf('.'))) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  @HostBinding('style')
  get style(): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle('text-align: right;');
  }

}
