import { DefaultResponse } from '../models/api/responses/default.response';
import { UtilsService } from './utils.service';
import { environment } from './../../../environments/environment';
import { WebsendReportPDFRequest } from '../models/api/requests/websendReportPDF.request';
import { WebSendReportResponse } from '../models/api/responses/websendReport.response';
import { WebsendReportRequest } from '../models/api/requests/websendReport.request';
import { WebSendDeleteResponse } from '../models/api/responses/websendDelete.response';
import { WebSendSendResponse } from '../models/api/responses/websendSend.response';
import { WebsendSendRequest } from '../models/api/requests/websendSend.request';
import { WebsendGetdocumentRequest } from '../models/api/requests/websendGetdocument.request';
import { WebsendGetdocumentResponse } from '../models/api/responses/websendGetdocument.response';
import { WebsendStructureInternResponse } from '../models/api/responses/websendStructureInterne.response';
import { WebSendRemoveFileResponse } from '../models/api/responses/websendRemoveFile.response';
import { WebsendRemoveFileRequest } from '../models/api/requests/websendRemoveFile.request';
import { HttpClient } from '@angular/common/http';
import { WebsendUploadFileRequest } from '../models/api/requests/websendUploadFile.request';
import { WebsendStructureInterneRequest } from '../models/api/requests/websendStructureInterne.request';
import { WebSendFindRequest } from '../models/api/requests/websendFind.request';
import { Observable } from 'rxjs';
import { DepotFindResponse } from './../models/api/responses/depotFind.response';
import { Injectable } from '@angular/core';
import { DepotFindRequest } from '../models/api/requests/depotFind.request';
import { ApiService } from './api.service';
import { WebSendAddRequest } from '../models/api/requests/websendAdd.request';
import { WebsendDeleteRequest } from '../models/api/requests/websendDelete.request';
import { DepotsRetraitListRequest } from '../models/api/requests/depotsRetraitList.request';
import { DepotsListReponse, DepotsParLotsListReponse } from '../models/api/responses/depotsRetraitList.reponse';
import * as _ from 'lodash';
import {
  DcDepotAddRequest,
  DcDepotFindRequest,
  DcGetDepotRequest,
  DcRemoveFileDepotRequest,
  DcUploadFileRequest,
  DcWebSendDepotAddRequest,
  DcWebSendFindRequest,
  DcWebSendSendRequest
} from '../models/api/requests/demandeCompDc.request';
import { DcUploadFileDepotResponse, DcWebSendDepotResponse, DcWebsendFindResponse } from '../models/api/responses/demandeCompDc.response';
import { WebSendDetailResponse } from '../models/api/responses/WebSendDetail.response';
import { DcWebSendDetailRequest } from '../models/api/requests/WebSendDetail.request';
import { MethodRequest } from 'src/app/shared/enums/method-request.enum';
import { DepotPapierRequest, RemoveDepotPapierRequest } from '../models/api/requests/depotPapier.request';
import { DepotPapierResponse } from '../models/api/responses/depotPapier.response';
import { WebsendFindResponse } from '../models/api/responses/websendFind.response';

@Injectable({
  providedIn: 'root'
})
export class DepotService {

  constructor(private readonly apiService: ApiService, private http: HttpClient, private readonly utilService: UtilsService) { }

  depotList(depotsListRequest: DepotsRetraitListRequest): Observable<DepotsListReponse> {
    return this.apiService.post(DepotsListReponse, depotsListRequest, 'depots_list');
  }

  postDepotPapier(req: DepotPapierRequest): Observable<DepotPapierResponse> {
    return this.apiService.post(DepotPapierResponse, req, 'depot_papier_edit');
  }

  depotPapierList(depotsListRequest: DepotsRetraitListRequest): Observable<DepotsListReponse> {
    return this.apiService.post(DepotsListReponse, depotsListRequest, 'depots_papier_list');
  }

  depotsParLotList(depotsListRequest: DepotsRetraitListRequest): Observable<DepotsParLotsListReponse> {
    return this.apiService.post(DepotsParLotsListReponse, depotsListRequest, 'depots_par_lot_list');
  }

  deleteDepotPapier(req: RemoveDepotPapierRequest): Observable<any> {
    return this.apiService.post(DepotsParLotsListReponse, req, 'depot_papier_remove');
  }

  depotFind(depotFindRequest: DepotFindRequest): Observable<DepotFindResponse> {
    return this.apiService.post(DepotFindResponse, depotFindRequest, 'depot_find');
  }

  depotAdd(depotFindRequest: DepotFindRequest): Observable<DepotFindResponse> {
    return this.apiService.post(DepotFindResponse, depotFindRequest, 'depot_add');
  }

  websendFind(depotFindRequest: WebSendFindRequest): Observable<WebsendFindResponse> {
    return this.apiService.post(WebsendFindResponse, depotFindRequest, 'websend_find');
  }

  websendAdd(websendAddRequest: WebSendAddRequest): Observable<WebsendFindResponse> {
    return this.apiService.post(WebsendFindResponse, websendAddRequest, 'websend_add');
  }

  websendStructureInterne(websendStructureInterneRequest: WebsendStructureInterneRequest): Observable<WebsendStructureInternResponse> {
    return this.apiService.post(WebsendStructureInternResponse, websendStructureInterneRequest, 'websend_structure_interne');
  }

  websendUploadFile(websendUploadFileRequest: WebsendUploadFileRequest): Observable<any> {
    // const dirFich =

    const splitedValue = _.split(!_.isEmpty(websendUploadFileRequest.fullPath) ?
      websendUploadFileRequest.fullPath :
      websendUploadFileRequest.fileToUpload.webkitRelativePath, '/');

    splitedValue.pop();
    splitedValue.shift();

    const fileName = websendUploadFileRequest.fileToUpload.name;

    const dirFich = `${websendUploadFileRequest.dir_fich}/${splitedValue.join('/')}/`;

    const formData: FormData = new FormData();
    formData.append('fileToUpload', websendUploadFileRequest.fileToUpload, fileName);
    formData.append('cle_dce', websendUploadFileRequest.cle_dce);
    formData.append('cle_str_env', websendUploadFileRequest.cle_str_env);
    formData.append('cle_rep_ms', websendUploadFileRequest.cle_rep_ms);
    formData.append('local', this.utilService.getLocale());
    formData.append('dir_fich', dirFich);

    return this.http.post(environment.api + 'websend_upload_file', formData, {
      reportProgress: true,
      observe: 'events',
      headers: this.apiService.getHttpOptions(null, MethodRequest.POST).headers,
    });
  }


  websendRemoveFile(websendRemoveFileRequest: WebsendRemoveFileRequest): Observable<WebSendRemoveFileResponse> {
    return this.apiService.delete(WebSendRemoveFileResponse, websendRemoveFileRequest, 'websend_remove_file');
  }

  websendGetdocuments(websendGetdocumentRequest: WebsendGetdocumentRequest): Observable<WebsendGetdocumentResponse> {
    return this.apiService.post(WebsendGetdocumentResponse, websendGetdocumentRequest, 'websend_details_response');
  }

  websendSend(websendSend: WebsendSendRequest): Observable<WebSendSendResponse> {
    return this.apiService.post(WebSendSendResponse, websendSend, 'websend_send');
  }

  websendDelete(websendDelete: WebsendDeleteRequest): Observable<WebSendRemoveFileResponse> {
    return this.apiService.delete(WebSendDeleteResponse, websendDelete, 'websend_delete');
  }

  websendReport(websendreportRequest: WebsendReportRequest): Observable<WebSendReportResponse> {
    return this.apiService.post(WebSendReportResponse, websendreportRequest, 'websend_report');
  }

  getDcDepot(req: DcGetDepotRequest): Observable<DcWebSendDepotResponse> {
    return this.apiService.post(DcWebSendDepotResponse, req, 'demande_comp_oe/dc_depot_get');
  }


  dcFindDcDepot(req: DcDepotFindRequest): Observable<DcWebSendDepotResponse> {
    return this.apiService.post(DcWebSendDepotResponse, req, 'demande_comp_oe/dc_depot_find');
  }


  dcDepotAdd(req: DcDepotAddRequest): Observable<DcWebSendDepotResponse> {
    return this.apiService.post(DcWebSendDepotResponse, req, 'demande_comp_oe/dc_depot_add');
  }

  getWebSendDcDepot(req: DcWebSendFindRequest): Observable<DcWebsendFindResponse> {
    return this.apiService.post(DcWebsendFindResponse, req, 'demande_comp_oe/dc_websend_find');
  }

  addWebSendDcDepot(req: DcWebSendDepotAddRequest): Observable<DcWebsendFindResponse> {
    return this.apiService.post(DcWebsendFindResponse, req, 'demande_comp_oe/dc_websend_add');
  }

  dcWebsendSend(websendSend: DcWebSendSendRequest): Observable<WebSendSendResponse> {
    return this.apiService.post(WebSendSendResponse, websendSend, 'demande_comp_oe/dc_websend_send');
  }

  dcWebSendDetail(req: DcWebSendDetailRequest): Observable<WebSendDetailResponse> {
    return this.apiService.post(WebSendDetailResponse, req, 'demande_comp_oe/dc_websend_details_response');
  }

  dcWebsendUploadFile(websendUploadFileRequest: DcUploadFileRequest): Observable<DcUploadFileDepotResponse> {
    const formData: FormData = new FormData();
    formData.append('cle_demande', websendUploadFileRequest.cle_demande);
    formData.append('cle_reponse', websendUploadFileRequest.cle_reponse);
    formData.append('fileToUpload', websendUploadFileRequest.fileToUpload, websendUploadFileRequest.fileToUpload.name);
    formData.append('local', this.utilService.getLocale());
    formData.append('dir_fich', websendUploadFileRequest.dir_fich);
    return this.apiService.post(DcUploadFileDepotResponse, formData, 'demande_comp_oe/dc_websend_upload_file');
  }

  dcWebSendUploadFileProgress(websendUploadFileRequest: DcUploadFileRequest): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('cle_demande', websendUploadFileRequest.cle_demande);
    formData.append('cle_reponse', websendUploadFileRequest.cle_reponse);
    formData.append('fileToUpload', websendUploadFileRequest.fileToUpload, websendUploadFileRequest.fileToUpload.name);
    formData.append('local', this.utilService.getLocale());
    formData.append('dir_fich', websendUploadFileRequest.dir_fich);
    return this.http.post(environment.api + 'demande_comp_oe/dc_websend_upload_file', formData, {
      reportProgress: true,
      observe: 'events',
      headers: this.apiService.getHttpOptions(null, MethodRequest.POST).headers,
    });
  }

  dcWebsendRemoveFile(websendRemoveFileRequest: DcRemoveFileDepotRequest): Observable<DefaultResponse> {
    return this.apiService.post(DefaultResponse, websendRemoveFileRequest, 'demande_comp_oe/dc_websend_remove_file');
  }

  websendReportPdf(websendReportPDFRequest: WebsendReportPDFRequest): Observable<any> {
    return this.http.post(
      environment.api + 'websend_report_pdf', websendReportPDFRequest,
      {
        headers: this.apiService.getHttpOptions(null, MethodRequest.GET).headers,
        responseType: 'blob',
        observe: 'response'
      }
    );
    // return this.apiService.postClient( , websendReportPDFRequest, 'websend_report_pdf');
  }

  websendReportPdfLink(websendReportPDFRequest: WebsendReportPDFRequest): string {
    return `${environment.api}websend_report_pdf?cle_dce=${websendReportPDFRequest.cle_dce}&cle_rep=${websendReportPDFRequest.cle_rep}&access_token=${this.utilService.token}`;
  }

  dcWebsendDelete(request: { cle_demande: string, cle_reponse: string }): Observable<DefaultResponse> {
    return this.apiService.post(DefaultResponse, request, 'demande_comp_oe/dc_websend_delete');
  }


  // errorMgmt(error: HttpErrorResponse): any {
  //   // let errorMessage = '';
  //   // if (error.error instanceof ErrorEvent) {
  //   //   // Get client-side error
  //   //   errorMessage = error.error.message;
  //   // } else {
  //   //   // Get server-side error
  //   //   errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  //   // }
  //   return throwError(error);
  // }

}
