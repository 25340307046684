import {
  Directive,
  HostBinding,
  OnDestroy,
  AfterContentInit
} from '@angular/core';
import { AbstractControl, FormControl, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';

@Directive({
  selector: 'mat-form-field[inputCodeField]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: InputCodeFieldDirective,
    multi: true,
  }],
})
export class InputCodeFieldDirective implements AfterContentInit, OnDestroy {
  @HostBinding('class')
  inputPieceId = 'input-piece-id';

  private subStatChange!: Subscription;
  private controlValueChange!: Subscription;

  control!: AbstractControl;

  constructor(readonly matFormField: MatFormField) { }

  ngOnInit(): void { }

  ngAfterContentInit(): void {
    
    const { _control } = this.matFormField;    

    if (!_control.ngControl?.control) {
      const validators = [];
      if (_control.required) {
        validators.push(Validators.required);
      }
      this.control = new FormControl('', validators);
      this.subStatChange = _control.stateChanges.subscribe(() => {        
        this.control.setValue(_control.value);
      });
      this.controlValueChange = this.control.valueChanges.subscribe((value) => {
        _control.value = value;
      });
    } else {
      this.control = _control.ngControl.control;
    }
  }

  ngOnDestroy(): void {
    this.subStatChange?.unsubscribe();
    this.controlValueChange?.unsubscribe();
  }

  validate(): ValidationErrors | null {
    if (!this.control?.validator) {
      return null;
    }
    return this.control.validator(this.control);
  }
}
