import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Component, Input, OnInit, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';

export const YES_NO_NO_SELECT = 'NoSelect';

@Component({
  selector: 'app-yes-no-radio-button',
  templateUrl: './yes-no-radio-button.component.html',
  styleUrls: ['./yes-no-radio-button.component.scss']
})
export class YesNoRadioButtonComponent implements OnInit, OnDestroy, OnChanges {

  @Input()
  isRequired!: boolean;

  @Input()
  defaultChecked?: boolean;

  @Input()
  displayBin: boolean = true;

  // @Input() formControl = new FormControl('', []);
  // @Output() formControlChange = new EventEmitter<FormControl>();

  @Input() value!: string | number | null;
  @Output() valueChange = new EventEmitter<string | number | null>();

  @Output() clickYes = new EventEmitter<any>();
  @Output() clickNo = new EventEmitter<any>();

  valueSubs!: Subscription;

  public formGroup!: FormGroup;
  formControl = new FormControl(this.value);

  constructor(private readonly formBuilder: FormBuilder) { }

  ngOnInit(): void {
    if (!_.isNil(this.value)) {      
      if (this.value === YES_NO_NO_SELECT) {
        this.value = '';
      }
      else if ([1, '1', true, 'true'].includes(this.value)) {
        this.value = 1;
      }
      else if ([0, '0', '00', false, 'false'].includes(this.value)) {
        this.value = 0;
      } else {
        this.value = null;
      }
    } else {
      this.value = null;
    }

    this.formControl.setValue(this.value);

    this.formGroup = this.formBuilder.group({
      formControl: this.formControl
    });


    if (this.isRequired) { this.formControl.setValidators(Validators.required); }
    this.valueSubs= this.formControl.valueChanges.subscribe((res) => this.valueChange.emit(res));

  }

  ngOnChanges(changes: SimpleChanges): void {   
    if (changes.value) {
      this.formControl.setValue(changes.value.currentValue)
    }
  }


  ngOnDestroy(): void {
      this.valueSubs?.unsubscribe();
  }
}
