<div class="field-edition" fxFlex="100" fxLayout="row nowrap" fxLayoutGap="10px" [fxLayoutAlign]="fxLayoutAlign">
  <div class="field-edition-container-label" fxLayout="column nowrap" fxLayoutAlign="start stretch">
    <span class="field-edition-label" [class.required]="required" [class.error]="invalid">{{ label | uppercase }}</span>
    <ng-content select="span[sub-label]"></ng-content>
  </div>
  <div class="field-edition-container" fxLayout="row nowrap" fxLayoutAlign="start stretch">
    <div class="field-edition-content" fxFlex="100" fxLayout="column nowrap" fxLayoutAlign="start stretch" fxLayoutGap="5px">
      <ng-content></ng-content>
    </div>
    <div class="field-edition-infos" *ngIf="haveInfo">
     <app-info-bubble [infoBubble]="infos"></app-info-bubble>
    </div>
  </div>
</div>
