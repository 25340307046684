import { CountryItem } from '../../countryItem.model';

export class CountryResponse {

    // tslint:disable:variable-name

    countries: CountryItem[] = [new CountryItem()];

    // tslint:enable:variable-name

}
