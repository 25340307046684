<div class="anc-component">
    <app-field-edition *ngIf="composition?.rectifInfo && rectifInfoFC"
        [label]="'APP.ANNONCES.MODIFICATION_REASONS.LABEL' | translate" [required]="isRequired(required?.rectifInfo)"
        [invalid]="false">
        <span class="sub-label" sub-label>BT-140-notice</span>
        <div fxLayout="row">
            <mat-form-field appearance="outline" fxFlex="40">
                <mat-select [formControl]="rectifInfoFC">
                    <mat-option *ngFor="let item of options; let i = index" [value]="item.value">
                        {{item.libelle}}
                        <!-- {{'APP.ANNONCES.PURSHASING_TECH.ITEM_' + (i + 1) | translate}} -->
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </app-field-edition>
    <app-field-edition *ngIf="composition?.description && descriptionFC"
        [label]="'APP.ANNONCES.MODIFICATION_REASONS.DESCRIPTION_LABEL' | translate"
        [required]="isRequired(required?.rectifInfo)" [invalid]="false">
        <span class="sub-label" sub-label>BT-140-notice</span>
        <div fxLayout="row">
            <mat-form-field appearance="outline" class="full-width form-field" fxFlex="90">
                <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
                    cdkAutosizeMaxRows="10" [formControl]="descriptionFC"></textarea>
            </mat-form-field>
        </div>
    </app-field-edition>
</div>