<div class="anc-component" fxLayout="column nowrap">
  <app-field-edition *ngIf="haveMessage() && infoJalFC"
    [label]="'APP.ANNONCES.SUPPORT_PUB.MESSAGE.TITLE' | translate"
    [required]="isRequired(required?.message)"
    [invalid]="infoJalFC.invalid"
    >
    <div fxLayout="row" fxLayoutAlign="start end" fxLayoutGap="10px">
      <mat-form-field appearance="outline" class="full-width form-field" fxFlex="85">
        <textarea matInput cdkTextareaAutosize #messages
          #autosize="cdkTextareaAutosize"
          maxlength="1000"
          cdkAutosizeMinRows="5"
          cdkAutosizeMaxRows="10"
          [formControl]="infoJalFC"
          class="text-area-not-rised"></textarea>
      </mat-form-field>
      <span [inputCounter]="messages" [total]="true"></span>
    </div>
  </app-field-edition>
</div>
