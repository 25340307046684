import { AfterContentChecked, AfterContentInit, Component, ContentChildren, Input, QueryList } from '@angular/core';
import { InfoForBlockDirective } from './info-for-block.directive';
import { ModesBlocksInfos } from './modeBlockInfos.model';

@Component({
  selector: 'app-block-infos',
  templateUrl: './block-infos.component.html',
  styleUrls: ['./block-infos.component.scss']
})
export class BlockInfosComponent implements AfterContentInit, AfterContentChecked {

  private modeCurrent: ModesBlocksInfos = 'warning';

  @ContentChildren(InfoForBlockDirective)
  infosDirective!: QueryList<InfoForBlockDirective>;

  #infos: string[] = [];

  constructor() { }

  ngAfterContentInit(): void {
    this.initModeDirective();
  }

  ngAfterContentChecked(): void {
    this.initModeDirective();
  }

  @Input()
  get infos(): string[] {
    return this.#infos;
  }

  set infos(arg: string[]) {
    this.#infos = arg;
  }

  @Input()
  get mode(): ModesBlocksInfos {
    return this.modeCurrent;
  }

  set mode(arg: ModesBlocksInfos) {
    this.modeCurrent = arg;
    this.initModeDirective();
  }

  private initModeDirective(): void {
    if (this.infosDirective) {
      this.infosDirective.forEach(d => {
        d.mode = this.mode;
        d.ref.nativeElement.style.margin = '0 0 10px';
      });
      if (this.infosDirective.last) this.infosDirective.last.ref.nativeElement.style.margin = '0';
    }
  }

}
