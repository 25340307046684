export class AttributionsListAnnonceResponse {
    // tslint:disable:variable-name
    public Attributions: AttributionAnnonce[] = [];
    public nb_total = 0;
    // tslint:enable:variable-name
}
  
  export class AttributionAnnonce {
    // tslint:disable:variable-name
 
    public cle_attribution = '';
    public cle_annonce = '';
    public num_attribution = 0;

    public statut_selection_gagnants = '';
    public raison_laureat_non_choisi = '';
    public identifiant_lot_attribution = '';
    public identifiant_offre = '';
    public identifiant_marche = '';
    public is_utilisation_sys_dynamique_abandonnee = 0;
    public new_estimation_valeur_accord_cadre = 0;
    public new_estimation_valeur_accord_cadre_devise = '';
    public valeur_max_accord_cadre = 0;
    public valeur_max_accord_cadre_devise = '';
    public type_vehicule = '';
    public categories_vehicule = '';
    public numerique_vehicule = 0;
    public valeur_offre_plus_basse = 0;
    public valeur_offre_plus_basse_devise = '';
    public valeur_offre_plus_haute = 0;
    public valeur_offre_plus_haute_devise = '';
    public type_irregulalite = '';
    public nombre_demande_reexamen = 0;
    public nb_text_plaignants = '';
    public nb_plaignants = 0;
    public type_soumissions_recues = '';
    public nb_offres_ou_demandes_part = 0;
    public base_juridique_vehicule_propre = '';
    public id_partie_financement = '';
    public id_partie_payante = '';

    // tslint:enable:variable-name
  }
  