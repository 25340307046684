<div class="anc-component" fxLayout="column nowrap" fxLayoutGap="10px">
  <app-field-edition *ngIf="siretFC && haveSiret()" [label]="'APP.ANNONCES.FACTURATION.SIRET' | translate | uppercase"
    [required]="true" [invalid]="siretFC.touched && siretFC.invalid">
    <mat-form-field appearance="outline">
      <input matInput type="text" [formControl]="siretFC" onlyNumber [decimalActive]="false" mask="000 000 000 00000">
    </mat-form-field>
  </app-field-edition>
  <ng-container *ngIf="siretFC && siretFC.valid">
    <ng-container *ngIf="loadChorus">

    </ng-container>
    <ng-container *ngIf="!loadChorus; else spinner">
      <ng-container *ngIf="isChorusResponse?.isChorus">
        <app-field-edition>
          <span sub-label class="chorus-logo"></span>
          <app-block-infos mode="warning">
            <p infoForBlock [innerHTML]="'APP.ANNONCES.INFO.NOT_CHORUS' | translate"></p>
          </app-block-infos>
        </app-field-edition>

        <ng-container *ngIf="isChorusFC && tvaInterFC && haveTvaIntra()">
          <app-field-edition
            [label]="'APP.ANNONCES.FACTURATION.ARE_YOU_CHORUS' | translate"
            [required]="true"
            [invalid]="false">
            <mat-radio-group color="primary" fxLayoutGap="10px" [formControl]="isChorusFC">
              <mat-radio-button [value]="'1'">{{'APP.MARCHES_SECURISES.PA.CREATE.YES' | translate | uppercase}}</mat-radio-button>
              <mat-radio-button [value]="'0'">{{'APP.MARCHES_SECURISES.PA.CREATE.NO' | translate | uppercase}}</mat-radio-button>
            </mat-radio-group>
          </app-field-edition>

          <app-field-edition *ngIf="isChorusFC.value === 1"
            [label]="'APP.ANNONCES.FACTURATION.TVA_INTRA' | translate"
            [required]="true"
            [invalid]="false">
            <mat-form-field appearance="outline">
              <input matInput [formControl]="tvaInterFC">
            </mat-form-field>
          </app-field-edition>

          <app-field-edition *ngIf="enfagementFC && haveEngagement()"
            [label]="'APP.ANNONCES.FACTURATION.ENGAGEMENT' | translate"
            [required]="isRequired(required?.engagement)"
            [invalid]="false">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field appearance="outline">
                <input matInput [formControl]="enfagementFC" maxlength="50">
              </mat-form-field>
              <span classs="text-it">{{ 'APP.ANNONCES.FACTURATION.INFOS.ENGAGEMENT' | translate }}</span>
            </div>
          </app-field-edition>

        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #spinner>
  <app-field-edition>
    <span sub-label class="chorus-logo"></span>
    <mat-spinner [strokeWidth]="5" [diameter]="50"></mat-spinner>
  </app-field-edition>
</ng-template>
