export class LotsListAnnonceResponse {
  // tslint:disable:variable-name
  public lots: LotAnnonce[] = [];
  public nb_total = 0;
  // tslint:enable:variable-name
}

export class LotAnnonce {
  // tslint:disable:variable-name
  public id_lot_boamp = 0;

  public cle_lot_boamp:string = '';

  public cle_annonce = '';

  public numero_lot = 0;
  public numero = '';

  public intitule_lot = '';

  public description = '';

  public quantite: number | null = null;

  public devise = '';

  public valeur = 0;

  public valeur_basse = 0;

  public valeur_haute = 0;

  public date_execution = 0;

  public date_livraison = 0;

  public mot_clef: string | null = null;

  public cpv = '';

  public cpv_1 = '';

  public cpv_2 = '';

  public cpv_3 = '';

  public cpv_4 = '';

  public infos_comp = '';

  public duree_nb_mois = 0;

  public duree_nb_jours = 0;

  public duree_fin = 0;

  public duree_debut = 0;

  public dateLancementPassation: string | null = null;

  public lieu_nuts: string | null = null;

  public lieuExecutionLivraison = '';

  public critereDoc = 0;

  public criteres: string | null = null;

  public criterePrix = '';

  public critereCout = '';

  public critereQualite = '';

  public reconduction = 0;

  public reconductionDesc = '';

  public nombre_candidat: string | null = null;

  public nombre_candidat_min: string | null = null;

  public nombre_candidat_max: string | null = null;

  public candidat_criteres_limitation: string | null = null;

  public carac_variante: string | null = null;

  public carac_options = '';

  public carac_options_description: string | null = null;

  public catalogueElec: string | null = null;

  public is_fonds_communautaire: string | null = null;

  public is_fonds_communautaire_info: string | null = null;

  public AC_ATP: number | null = null;

  public origine_request: string | null = null;

  // public origine_request: string | null = null;
  public id_previous_notice_lot: number = 0;
  public id_lot_previous_notice: number = 0;
  public internalidentifier_lot: string = '';
  public title_lot: string = '';
  public classificatorType_lot: string = '';
  public classificatorType_lot_1: string = '';
  public classificatorType_lot_2: string = '';
  public classificatorType_lot_3: string = '';
  public classificatorType_lot_4: string = '';
  public timezone_start_date: string = '';
  public opt_desc: string = '';
  public adr_elecauction_lot: string = '';
  public buyer_category_lot: string = '';
  public info_provider_fiscal: string = '';
  public env_info_fournisseur: string = '';
  public env_info_fournisseur_legis: string = '';
  public critere_procedure_invite: string = '';
  public type_accorder: string = '';
  public description_accorder: string = '';
  public nb_critere_accorder: number = 0;
  public nb_type_poids: number = 0;
  public nb_type_fix: number = 0;
  public nb_type_seuil_1: number = 0;
  public nom_accorder: string = '';
  public grp_ted_contract: string = '';
  public grp_ted_contract_necessary: string = '';
  public ted_info_deadline: string = '';
  public ted_info_additional: string = '';
  public participation_reserve: string = '';
  public variantes: string = '';
  public code_exigence_exec: string = '';
  public date_limite_ohs_timezone: string = '';
  public date_limite_offres_timezone_d: string = '';
  public date_limite_reception_offres_t: string = '';
  public date_limite_reception_offres_timezone: string = '';
  public date_envoi_invitations_timezone: string = '';
  public date_limite_demandes_participation_timezone: string = '';
  public deadline_receipt_requests_participate: string = '';
  public deadline_receipt_requests_participate_timezone: string = '';
  public date_limite_info_complementaires_timezone: string = '';
  public acces_certains_doc_restre: string = '';
  public etat_documents: string = '';
  public id_Fournisseur_avis_info: string = '';
  public timezone_end_date: string = '';
  public nature_contract_lot: string = '';
  public additional_nature_contract_lot_services: string = '';
  public unit_lot: string = '';
  public is_marche_petit_moy_ent: number =0;
  public reducing_env_impact_lot: string = '';
  public critere_marche_public_ecologique_lot: string = '';
  public social_objective_promoted_lot: string | null = null;
  public innovative_objective_lot: string = '';
  public aim_strategic_procurement_lot: string = '';
  public desc_strategic_procurement: string = '';
  public handicaped_technical_specification_lot: string = '';
  public justification_absence_access: string = '';
  public adr_postal_lot_1: string = '';
  public adr_postal_lot_2: string = '';
  public adr_postal_lot_3: string = '';
  public adr_cp_lot: string = '';
  public adr_ville_lot: string = '';
  public pays_lot: string = '';
  public exec_other_restrictions_lot: string = '';
  public additional_info_lot: string = '';
  public autre_duree: string = '';
  public duree_nb_semaine: string = '';
  public duree_nb_annee: string = '';
  public desc_contact: string = '';
  public renouvellement_contrat_details: string = '';
  public max_renouvellements: string = '';
  public is_elecauction_lot:number =0;
  public elecauction_desc_lot: string = '';
  public procurement_gov_agreement_lot: string = '';
  public financed_european_union_funds: string = '';
  public programme_fonds_ue: string = '';
  public identifiant_fonds_ue: string = '';
  public autre_informations_fonds_ue: string = '';
  public framework_agreeement_involved_lot: string = '';
  public dynamic_purchasing_system_lot: string = '';
  public justif_duration_agreement_lot: string = '';
  public additional_buyer_lot: string = '';
  public max_value_frm_lot: string = '';
  public max_value_frm_devise_lot: string = '';
  public fournisseur_info_legis_fiscale: string = '';
  public notice_pub_fiscal: string = '';
  public tax_doc_fiscal: string = '';
  public fournisseur_info_legis_env: string = '';
  public url_env_lot: string = '';
  public env_doc_lot: string = '';
  public fournisseur_info_legis_em: string = '';
  public url_emp_lot: string = '';
  public emp_doc_lot: string = '';
  public info_additional: string = '';
  public type_critere_lot: string = '';
  public nom_lot_critere: string = '';
  public critere_description: string = '';
  public utilisation_critere: string = '';
  public nb_type_poids_lot: number = 0;
  public nb_type_seuil_lot: number = 0;
  public nb_critere_invite: number = 0;
  public stage_elimination: string = '';
  public max_nb_candidat: string = '';
  public max_nb_stage_2: string = '';
  public CriterePrix_eform_ctrl: string = '';
  public description_criterePrix: string = '';
  public valeur_poids_criterePrix: string = '';
  public unite_poids_criterePrix: string = '';
  public valeur_fixe_criterePrix: string = '';
  public unite_fixe_criterePrix: string = '';
  public valeur_seuil_criterePrix: string = '';
  public unite_seuil_criterePrix: string = '';
  public nom_criterePrix: string = '';
  public description_criterePrix_supp: string = '';
  public valeur_poids_criterePrix_supp: string = '';
  public unite_poids_criterePrix_supp: string = '';
  public valeur_fixe_criterePrix_supp: string = '';
  public unite_fixe_criterePrix_supp: string = '';
  public valeur_seuil_criterePrix_supp: string = '';
  public unite_seuil_criterePrix_supp: string = '';
  public nom_criterePrix_supp: string = '';
  public description_critereCout: string = '';
  public valeur_poids_critereCout: string = '';
  public unite_poids_critereCout: string = '';
  public valeur_fixe_critereCout: string = '';
  public unite_fixe_critereCout: string = '';
  public valeur_seuil_critereCout: string = '';
  public unite_seuil_critereCout: string = '';
  public nom_critereCout: string = '';
  public description_critereCout_supp: string = '';
  public valeur_poids_critereCout_supp: string = '';
  public unite_poids_critereCout_supp: string = '';
  public valeur_fixe_critereCout_supp: string = '';
  public unite_fixe_critereCout_supp: string = '';
  public valeur_seuil_critereCout_supp: string = '';
  public unite_seuil_critereCout_supp: string = '';
  public nom_critereCout_supp: string = '';
  public description_critereQualite: string = '';
  public valeur_poids_critereQualite: string = '';
  public unite_poids_critereQualite: string = '';
  public valeur_fixe_critereQualite: string = '';
  public unite_fixe_critereQualite: string = '';
  public valeur_seuil_critereQualite: string = '';
  public unite_seuil_critereQualite: string = '';
  public nom_critereQualite: string = '';
  public description_critereQualite_supp: string = '';
  public valeur_poids_critereQualite_supp: string = '';
  public unite_poids_critereQualite_supp: string = '';
  public valeur_fixe_critereQualite_supp: string = '';
  public unite_fixe_critereQualite_supp: string = '';
  public valeur_seuil_critereQualite_supp: string = '';
  public unite_seuil_critereQualite_supp: string = '';
  public description_method: string = '';
  public justification_poids: string = '';
  public attrib_sans_nego: string = '';
  public form_juridique_group_attrib: string = '';
  public form_juridique_group_attrib_desc: string = '';
  public acheteur_info_sup_soumission: string = '';
  public info_comp_soumission_tardive: string = '';
  public participation_reservee: string = '';
  public is_recurrent_approvisionnement: string = '';
  public recurrence_description: string = '';
  public soumettre_plus_offre: string = '';
  public execmarch_emplois_proteges: string = '';
  public conditions_relatives_exec_contrat: string = '';
  public facturation_electronique: string = '';
  public noms_qualifications_pro_pers_exe_marche: string = '';
  public date_limite_ohs: string = '';
  public auth_securite_requise: string = '';
  public auth_securite_requise_Desc: string = '';
  public application_dir_2009_c3_ce: string = '';
  public base_juridique_directive: string = '';
  public accord_non_divulgation_requis: string = '';
  public informations_supp_accord_divulgation: string = '';
  public catalogue_electronique: string = '';
  public signature_cachet_electronique_qualifie: string = '';
  public commande_electronique_utilisee: string = '';
  public paiement_electronique_utilise: string = '';
  public arrangement_financier: string = '';
  public id_destinataire_offre: string = '';
  public id_evaluateur_appel_offres: string = '';
  public is_procedure_relance: string = '';
  public date_limite_reception_offres_d: string = '';
  public date_envoi_invitations: string = '';
  public date_limite_demandes_participation: string = '';
  public date_limite_info_complementaires: string = '';
  public deadline_marche_validite_nb: string = '';
  public deadline_marche_validite_type: string = '';
  public langues_lot: string = '';
  public date_ouverture_offres: string = '';
  public info_ouverture_offres: string = '';
  public lieu_ouverture_offres: string = '';
  public garantie_exigee: string = '';
  public garantie_exigee_desc: string = '';
  public soumission_electronique: string = '';
  public adresse_soumission: string = '';
  public justification_electronique: string = '';
  public methode_soumission_desc: string = '';
  public langues_doc_officiellement_disponibles: string = '';
  public langues_doc_marche_officieusement_disponibles: string = '';
  public acces_certains_doc_restreint: string = '';
  public justification_restriction_documents: string = '';
  public adresse_doc_approvisionnement: string = '';
  public info_doc_restreints_dispo: string = '';
  public id_doc_approvisionnement: string = '';
  public nom_outil_soumission: string = '';
  public url_outil_soumission: string = '';
  public id_Fournisseur_info_supplementaire: string = '';
  public id_Doc_fournisseur: string = '';
  public info_delais_revision: string = '';
  public id_organism_revision: string = '';
  public id_organisme_examen: string = '';
  public id_mediator: string = '';
  public isCriterePrix: string = '';
  public id_part_previous_notice: string = '';
  public additional_nature_contract_lot: string = '';
  public CriterePrix_eform: string = '';
  public CritereCout_eform: string = '';
  public CritereQualite_eform: string = '';
  public CriterePrix_supp: string = '';
  public CritereCout_supp: string = '';
  public CritereQualite_supp: string = '';
  public nom_critereQualite_supp: string = '';
  public is_marche_attribue_suite_concours: string = '';
  public is_decision_jury_contr_buyer: string = '';
  public membres_jury_concours: string = '';
  public participants_selectionnes: string = '';
  public lieu_reception_prime: string = '';
  public valeur_prime: string = '';
  public devise_prime: string = '';
  public info_comp_prime: string = '';
  public min_nb_stage_2: string= '';
  public indice_sous_traitan_dep: string = '';
  public respecter_matier_sous_traitan_dep: string = '';
  public max_val_sous_traitan: string = '';
  public min_val_sous_traitan: string = '';
  
}
