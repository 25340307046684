<div class="container" style="margin-left: 10%; margin-right: 10%;">
  <h2>Ajout d'une adresse</h2>
  
  <div *ngIf="isLoading" class="loading-overlay">
    <p>Chargement en cours...</p>
  </div>

  <form [formGroup]="addressForm" (ngSubmit)="onSubmit()">
    <mat-form-field class="full-width" style="width: 100%;">
      <mat-label>Intitulé de l'adresse</mat-label>
      <input matInput formControlName="addressTitle" required>
    </mat-form-field>

    <fieldset>
      <legend>Adresse</legend>
      <div class="flex-container">
        <div class="flex-item" style="flex: 1 1 30%;">
          <mat-form-field class="full-width" style="width: 100%;">
            <mat-label>Dénomination</mat-label>
            <input matInput formControlName="denomination">
          </mat-form-field>
        </div>
        <div class="flex-item" style="flex: 1 1 30%;">
          <mat-form-field class="full-width" style="width: 100%;">
            <mat-label>Point de contact</mat-label>
            <input matInput formControlName="contactPoint">
          </mat-form-field>
        </div>
        <div class="flex-item" style="flex: 1 1 30%;">
          <mat-form-field class="full-width" style="width: 100%;">
            <mat-label>Service ou destinataire</mat-label>
            <input matInput formControlName="serviceOrRecipient">
          </mat-form-field>
        </div>
        <div class="flex-item">
          <mat-form-field class="full-width" style="width: 100%;">
            <mat-label>Entrée, tour, immeuble, zone</mat-label>
            <input matInput formControlName="buildingZone">
          </mat-form-field>
        </div>
      </div>
      <div class="flex-container">
        <div class="flex-item">
          <mat-form-field class="full-width" style="width: 100%;">
            <mat-label>Numéro et voie</mat-label>
            <input matInput formControlName="streetNumber">
          </mat-form-field>
        </div>
        <div class="flex-item">
          <mat-form-field class="full-width" style="width: 100%;">
            <mat-label>Mention spéciale / lieu-dit</mat-label>
            <input matInput formControlName="specialMention">
          </mat-form-field>
        </div>
        <div class="half-width" style="display: flex; gap: 10px;">
          <mat-form-field class="half-width" style="width: 100%;">
            <mat-label>Code postal</mat-label>
            <input matInput formControlName="postalCode">
          </mat-form-field>
          <mat-form-field class="half-width" style="width: 100%;">
            <mat-label>Ville</mat-label>
            <input matInput formControlName="city">
          </mat-form-field>
        </div>
        <div class="flex-item" style="flex: 1 1 30%;">
          <mat-form-field class="full-width" style="width: 100%;">
            <mat-label>Pays</mat-label>
            <input matInput formControlName="country" value="FRANCE">
          </mat-form-field>
        </div>
      </div>
    </fieldset>

    <div style="display: flex; gap: 10px;">
      <fieldset style="flex: 1;">
        <legend>Coordonnées téléphoniques et électroniques</legend>
        <mat-form-field class="full-width" style="width: 100%;">
          <mat-label>Mail</mat-label>
          <input matInput formControlName="email">
        </mat-form-field>
        <mat-form-field class="full-width" style="width: 100%;">
          <mat-label>Site internet</mat-label>
          <input matInput formControlName="website">
        </mat-form-field>
        <mat-form-field class="full-width" style="width: 100%;">
          <mat-label>Tél</mat-label>
          <input matInput formControlName="phone">
        </mat-form-field>
        <mat-form-field class="full-width" style="width: 100%;">
          <mat-label>Fax</mat-label>
          <input matInput formControlName="fax">
        </mat-form-field>
      </fieldset>

      <fieldset style="flex: 1;">
        <legend>Données de facturation</legend>
        <mat-form-field class="full-width" style="width: 100%;">
          <mat-label>Siret</mat-label>
          <input matInput formControlName="siret">
        </mat-form-field>
        <mat-form-field class="full-width" style="width: 100%;">
          <mat-label>TVA intracommunautaire</mat-label>
          <input matInput formControlName="vat">
        </mat-form-field>
        <mat-form-field class="full-width" style="width: 100%;">
          <mat-label>Classe de profil</mat-label>
          <mat-select formControlName="profileClass">
            <mat-option value="Debiteur prive personne physique">Débiteur privé personne physique</mat-option>
          </mat-select>
        </mat-form-field>
      </fieldset>
    </div>

    <div class="actions" style="display: flex; gap: 10px;">
      <button mat-button type="submit" [disabled]="isLoading">Enregistrer</button>
      <button mat-button type="button" (click)="onCancel()">Fermer</button>
    </div>
  </form>
</div>
