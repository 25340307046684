import { Component, OnInit, Inject, ElementRef, OnDestroy } from '@angular/core';
import { Icons } from '@atline-shared/enums/icons.enum';
import { TOKEN_ANC_BLOCK } from '@atline/core/models/pa/annonce/anc-token-inject.model';
import { ComponentDisplay } from '@atline/core/models/pa/annonce/form-display.model';
import { ManagerPaEditionAnnonceService } from '../../manager-pa-edition-annonce.service';
import { AbstractAncComponent } from '../abstract-anc-component';
import { Subscription } from 'rxjs';
import { OrganisationItem } from '@atline/core/models/api/organisation.response';
import * as _ from 'lodash';
import { AutoUnsubscription } from '@atline/core/decorators/auto-unsub.decorator';
import { DialogsService } from '@atline/core/services/dialogs.service';

@AutoUnsubscription()
@Component({
  selector: 'anc-organisation-eform',
  templateUrl: './anc-organisation-eform.component.html',
  styleUrls: ['./anc-organisation-eform.component.scss']
})
export class AncOrganisationEformComponent extends AbstractAncComponent implements OnInit, OnDestroy {

  private readonly subs = new Subscription();

  organisations: (OrganisationItem)[] = [];

  Icons = Icons;

  constructor(
    @Inject(TOKEN_ANC_BLOCK) display: ComponentDisplay,
    private readonly manager: ManagerPaEditionAnnonceService,
    { nativeElement }: ElementRef<HTMLElement>,
    private readonly dialogService: DialogsService
  ) {
    super(display, nativeElement);
  }

  ngOnInit(): void {
    this.subs.add(
      this.manager.organisationsChange.subscribe({
        next: (res) => {
          this.organisations = res.filter(o => !_.isNil(o.cle))
        },
        error: () => {

        }
      })
    );

    // this.manager.loadOrganisations()
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  get isLoading(): boolean {
    return this.manager.loading.organisation;
  }

  addOrganisation(): void {
    this.dialogService.openInputFieldDialog('APP.DIALOG.INPUT_FIELD.ORGANISATION.LABEL', 'APP.DIALOG.INPUT_FIELD.ORGANISATION.TITLE', (res) => {      
      if (this.manager.cle_annonce && res)
        this.subs.add(this.manager.addNewOrganisation(res).subscribe());
    });
    // this.subs.add(this.manager.addNewOrganisation().subscribe());
    // if (this.manager.cle_annonce)
    //   this.annoncesService.createUpdateOrganisation({ cle_etab: this.manager.cleEtab, cle_pa: this.manager.clePa, cle_annonce: this.manager.cle_annonce }).subscribe({
    //     next: (res) => {
    //       this.manager.addNewOrganisation()
    //       this.organisations.push(res);
    //     },
    //     error: () => {
    //       this.dialogService.createInfoDialog('Organisation', 'Une erreur est survenu dans la création de l\'organisation. Veuillez réessayer ultérieurement', 'OK');
    //     }
    //   })
  }

  organisationChange(_index: number, _organisation: OrganisationItem): void { }

  removeAllOrganisation(): void {
    if (!this.manager.cle_annonce) return;
    for (const organisation of this.organisations) {
      // if (organisation && organisation.cle) {
        this.removeOrganisation(organisation);
      // this.annoncesService.deleteOrganisation({ cle_organisation: organisation.cle, cle_annonce: this.manager.cle_annonce, cle_etab: this.manager.cleEtab, cle_pa: this.manager.clePa }).subscribe(() => {
      //   const index = _.findIndex(this.organisations, ['cle_organisation', organisation.cle]);
      //   this.removeOrganisation(index);
      // });
      // }
    }
  }

  removeOrganisation(organisation: OrganisationItem): void {
    if (!this.manager.cle_annonce) return;
    this.subs.add(this.manager.removeOrganisation(organisation).subscribe())
    // const index = _.findIndex(this.organisations, ['cle_organisation', organisation.cle]);
    // this.organisations.splice(index, 1);
    // this.removeOrganisation(index);
    // });
  }

  // removeOrganisation(index: number): void {
  //   this.organisations.splice(index, 1);
  //   if (index > -1 && index < this.manager.lots.length) this.syncOrganisationArray();
  // }

  // @Debounce(100)
  // private syncOrganisationArray(): void {
  //   this.manager.organisations.length = 0;
  //   this.manager.organisations.push(...this.organisations.filter(value => !_.isNil(value)) as OrganisationItem[]);
  //   this.manager.refreshVerifyRulesCall();
  // }

}
