import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { AbstractAncComponent } from '../abstract-anc-component';
import { ComponentDisplay } from '@atline/core/models/pa/annonce/form-display.model';
import { TOKEN_ANC_BLOCK } from '@atline/core/models/pa/annonce/anc-token-inject.model';
import { ManagerPaEditionAnnonceService } from '../../manager-pa-edition-annonce.service';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-anc-rectif-info-original',
  templateUrl: './anc-rectif-info-original.component.html',
  styleUrls: ['./anc-rectif-info-original.component.scss']
})
export class AncRectifInfoOriginalComponent extends AbstractAncComponent implements OnInit  {

  
  readonly rectifInfoFC = this.manager.controls.rectif_info_original_ao;
  readonly descriptionFC = this.manager.controls.rectif_info_original_ao_description;

  readonly options = [
    { value: 'cancel', libelle: 'Avis annulé' },
    { value: 'cancel-intent', libelle: 'Intention d’annuler' },
    { value: 'cor-buy', libelle: 'Correction par l’acheteur' },
    { value: 'cor-esen', libelle: 'Correction par l’eSender' },
    { value: 'cor-pub', libelle: 'Correction par l’éditeur' },
    { value: 'info-release', libelle: 'Informations désormais disponibles' },
    { value: 'update-add', libelle: 'Mise à jour d’informations' },
  ] as const;

  constructor(
    @Inject(TOKEN_ANC_BLOCK) display: ComponentDisplay,
    private readonly manager: ManagerPaEditionAnnonceService,
    { nativeElement }: ElementRef<HTMLElement>,
  ) { super(display, nativeElement); }

  
  ngOnInit(): void {
    if (this.rectifInfoFC) {
      if (!this.rectifInfoFC.value) this.rectifInfoFC.setValue(this.manager.annonce?.rectif_info_original_ao);
      if (this.required?.purshasingTech) this.rectifInfoFC.setValidators(Validators.required);
      else this.rectifInfoFC.clearValidators();
    }
    if (this.descriptionFC) {
      if (!this.descriptionFC.value) this.descriptionFC.setValue(this.manager.annonce?.rectif_info_original_ao_description);
      if (this.required?.purshasingTech) this.descriptionFC.setValidators(Validators.required);
      else this.descriptionFC.clearValidators();
    }
  }

}
