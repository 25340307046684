import { Subscription } from 'rxjs';

type DecoratorAutoUnsubscription = (target: new (...args: any[]) => object) => void;

export function AutoUnsubscription(): DecoratorAutoUnsubscription {
  const obs: Subscription[] = [];
  return (target: new (...args: any[]) => object): void => {
    const origin: () => void | undefined = target.prototype.ngOnDestroy;
    target.prototype.ngOnDestroy = function(): void {
      for (const prop in this) {
        const value = this[prop];
        if (typeof value?.unsubscribe === "function" && !obs.includes(value)) obs.push(value);
      }
      for (const o of obs) o.unsubscribe();
      origin?.apply(this);
      console.log("NG DESTROY");
    }
  }
}
