<div class="anc-component">
  <app-field-edition
    [label]="'APP.ANNONCES.MARKET_SPACE.DESCRIPTION_WORDS' | translate"
    [required]="isRequired(required?.select)"
    [invalid]="false"
    *ngIf="composition?.select">
    <span sub-label class="text-it"> {{'APP.ANNONCES.MARKET_SPACE.INFO.DESCRIPTION_WORDS' | translate}} </span>
    <div fxLayout="row" fxLayoutGap="10px">
      <mat-form-field appearance="outline" fxFlex="50">
        <mat-select #wordSelect>
          <mat-option>{{ 'APP.ANNONCES.SELECT_DEFAULT.DESCRIPTION_WORLD' | translate }}</mat-option>
          <mat-option *ngFor="let word of descriptionWord" [value]="word">
            {{word.label_cpv}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="container-btn-add">
        <button mat-stroked-button class="add-btn" (click)="add(wordSelect.value)"> + </button>
      </div>
    </div>
    <div fxLayout="row wrap" style="gap: 10px">
      <div *ngFor="let cpv of selected; let i = index"
        class="descriptors"
        fxLayout="row nowrap"
        fxLayoutAlign="start center"
        fxLayoutGap="10px">
        <span>{{ cpv.label_cpv }}</span>
        <button mat-icon-button (click)="remove(i)">
          <mat-icon>cancel</mat-icon>
        </button>
      </div>
    </div>
  </app-field-edition>
</div>
