import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticateService } from '@atline/core/services/authenticate.service';
import { SsoService } from '@atline/core/services/sso.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.scss']
})
export class SsoComponent implements OnInit {

  error = false;
  secondsBeforeRedirection = 5;
  loading = true;

  constructor(
    private readonly ssoService: SsoService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly authenticateService: AuthenticateService,
    private readonly router: Router,
  ) { }

  ngOnInit(): void {

    const code = this.activatedRoute.snapshot.queryParams.code;

    if (code)
      this.ssoService.token({
        client_id: environment.sso.microsoft.clientId,
        client_secret: environment.sso.microsoft.clientSecret,
        code: code,
        grant_type: 'authorization_code',
        redirect_uri: environment.sso.microsoft.redirectUri
      }).subscribe({
        next: (res) => {
          this.loading = false
          if (res.access_token) {
            this.authenticateService.setData(res);
          } else {
            this.error = true;
            this.redirectToHomePage();
          }

        },
        error: () => {
          this.loading = false
          this.error = true;
          this.redirectToHomePage();
        }
      })
  }
  redirectToHomePage(): void {

    const interval = setInterval(() => {
      this.secondsBeforeRedirection  -= 1;
    }, 1000)

    setTimeout(() => {
      clearInterval(interval);
      this.router.navigate(['/']);
    }, 5000)
  }


}
