import { Component, Input, OnInit, HostBinding } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-field-edition',
  templateUrl: './field-edition.component.html',
  styleUrls: ['./field-edition.component.scss']
})
export class FieldEditionComponent implements OnInit {

  @HostBinding('class.field-edition') readonly fieldEdition = true;

  @Input()
  label!: string;

  @Input()
  infos?: string;

  @Input()
  required = false;

  @Input()
  invalid!: any;

  @Input()
  center = false;

  constructor() { }

  ngOnInit(): void {

  }

  get haveInfo(): boolean {
    return !_.isEmpty(this.infos);
  }

  get fxLayoutAlign(): string {
    const vertical = this.center ? 'center' : 'stretch'
    return `space-between ${vertical}`
  }

}
