<div class="sign-modal-dialog login" fxLayout="row wrap" ngClass.lt-sm="mobile-dialog">
  <!--  Login image on the left of the form for large screens-->
  <div class="login-img" fxFlex="50" fxFlex.lt-lg="100" fxHide.lt-lg="true" fxLayout="column" fxLayoutAlign="end" fxLayoutAlign.lt-lg="center center">
    <div class="login-img-bottom">
      <h2 class="login-img-msg">{{ "APP.LOGIN.MESSAGE" | translate }}</h2>
      <!--<div class="login-pictos">
        <mat-icon svgIcon="helios"></mat-icon>
        <mat-icon svgIcon="valid"></mat-icon>
        <mat-icon svgIcon="meeting"></mat-icon>
        <mat-icon svgIcon="tender"></mat-icon>
      </div>-->
    </div>
  </div>
  <!--  Login form with username and password -->
  <div class="login-form-container mat-typography" fxFlex.gt-md="50" fxFlex.lt-lg="100">
    <mat-icon class="logo-ats" svgIcon="logo-ats"></mat-icon>
    <app-login-form [incorrectCredentials]="incorrectCredentials" (formData)="onSubmit($event)"></app-login-form>
    <div class="sso" fxLayout="row" fxLayoutAlign="center" >
      <mat-icon [svgIcon]="miscrosoftIcon" (click)="ssoLogin(ssoPlateform.MICROSOFT)" *ngIf="ssoActiviation.microsoft"></mat-icon>
    </div>
  </div>



  <!--  contact us widget at the bottom of the form for small screens-->
  <!-- <div class="contact-container full-width" fxHide.gt-md="true" fxLayoutAlign="space-evenly">
    <a class="hotline-item" fxFlex="50">
      <app-hotline></app-hotline>
    </a>
    <mat-divider vertical></mat-divider>
    <a class="contact-us-item">
      <mat-icon svgIcon="talk"></mat-icon>
      {{ "APP.TOP_MENU.CONTACT" | translate | uppercase }}
    </a>
  </div> -->
</div>