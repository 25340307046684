import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { TOKEN_ANC_BLOCK } from '@atline/core/models/pa/annonce/anc-token-inject.model';
import { ComponentDisplay } from '@atline/core/models/pa/annonce/form-display.model';
import { ManagerPaEditionAnnonceService } from '../../manager-pa-edition-annonce.service';
import { AbstractAncComponent } from '../abstract-anc-component';
import { Validators } from '@angular/forms';

// const reservationsMarche = ['entrepriseAdaptee', 'structureInsertion', 'servicesSociaux'] as const;
// type TypeReservationMarche = typeof reservationsMarche[number];

// function isTypeReservationMarche(arg: any): arg is TypeReservationMarche {
//   return reservationsMarche.includes(arg);
// }

// function splitTypeReservationMarche(arg?: string): TypeReservationMarche[] {
//   return arg?.split(';').filter(isTypeReservationMarche) || [];
// }

@Component({
  selector: 'app-anc-procedure-acceleree',
  templateUrl: './anc-procedure-acceleree.component.html',
  styleUrls: ['./anc-procedure-acceleree.component.scss']
})
export class AncProcedureAccelereeComponent extends AbstractAncComponent implements OnInit {

  // readonly descriptionFC = this.manager.controls.delai_urgence_info;
  readonly maxLengthDescription = 4000;
  // readonly delaiFC = this.manager.controls.delai_urgence;
  // readonly contactPointFC = this.manager.controls.point_contact;
  // readonly typesSelected = new Set<TypeReservationMarche>();
  // readonly reservedMarketFC = this.manager.controls.reservationMarche;
  // readonly situationChange = this.delaiFC?.valueChanges.subscribe(value => {
  //   if (!value) this.reservedMarketFC?.setValue(null);
  // });

  readonly isAcceleratedMarketFC = this.manager.controls.delai_urgence;
  readonly descriptionFC = this.manager.controls.delai_urgence_info;



  // readonly reservedChange = this.reservedMarketFC?.valueChanges.subscribe(value => {
  //   this.typesSelected.clear();
  //   splitTypeReservationMarche(value).forEach(item => this.typesSelected.add(item));
  // });

  isCheckboxDisabled: boolean = true;
  // isAdaptedStructureChecked = false;
  isAcceleratedProcedure = false;

  constructor(
    @Inject(TOKEN_ANC_BLOCK) display: ComponentDisplay,
    private readonly manager: ManagerPaEditionAnnonceService,
    { nativeElement }: ElementRef<HTMLElement>,
  ) { super(display, nativeElement); }

  ngOnInit(): void {
    this.descriptionFC?.setValidators([this.createValidatorRequired('description'), Validators.maxLength(this.maxLengthDescription)]);
    this.isAcceleratedProcedure = this.isAcceleratedMarketFC?.value;
  }

  ngOnDestroy(): void {
    // this.reservedChange?.unsubscribe();
  }

  toggleAdaptedStructure() {
    this.isAcceleratedProcedure = !this.isAcceleratedProcedure;
  }

  // setReserved(type: TypeReservationMarche, remove = false): void {
  //   if (!this.isCheckboxDisabled) {
  //     if (remove) {
  //       this.typesSelected.delete(type);
  //     } else {
  //       this.typesSelected.add(type);
  //     }

  //     this.reservedMarketFC?.setValue([...this.typesSelected].join(';'), { emitEvent: false });
  //   }
  // }



}
