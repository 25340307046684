export class MarchesListAnnonceResponse {
    // tslint:disable:variable-name
    public marches: MarcheAnnonce[] = [];
    public nb_total = 0;
    // tslint:enable:variable-name
}

export class MarcheAnnonce {
    // tslint:disable:variable-name
    public cleMarche = '';
  
    public cleAnnonce = '';
    
    public num = '';

    public nom = '';

    public identifiant = '';

    public date: {
        laureat: string;
        conclusion: string;
    } | undefined;

    public titre = '';

    public adresse = '';

    public isAccordCadre: boolean| null = null;

    public idAccordCadre = '';

    public offre: {
        id: string;
        cle: string;
    } | undefined;

    public idSignataire = '';

    public cleOrganisation = '';

    public ue: {
        nomProjet: string;
        id: string;
        other: string;
    } | undefined;
}