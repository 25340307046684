import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

// import { TranslateService } from '@ngx-translate/core';

import { AbstractAncComponent } from '../abstract-anc-component';
import { TOKEN_ANC_BLOCK } from '@atline/core/models/pa/annonce/anc-token-inject.model';
import { ComponentDisplay } from '@atline/core/models/pa/annonce/form-display.model';

import { ManagerPaEditionAnnonceService } from '../../manager-pa-edition-annonce.service';

@Component({
  selector: 'anc-principal-activity',
  templateUrl: './anc-principal-activity.component.html',
  styleUrls: ['./anc-principal-activity.component.scss']
})
export class AncPrincipalActivityComponent extends AbstractAncComponent implements OnInit {

  readonly options = [
    'activ_sante',
    'activ_logement_devcol',
    'activ_protection_sociale',
    'activ_loisirs_culture_religion',
    'activ_education',
    'activ_services_generaux_adm_pub',
    'activ_defense',
    'activ_ordre_securite_pub',
    'activ_environnement',
    'activ_affaires_ecofi',
  ] as const;

  readonly formActivities: { [key: string]: FormControl } = {};

  isEform = this.manager.isEforms;

  public other = !!this.manager.values.activ_autre;
  readonly otherFC = this.manager.controls.activ_autre;

  constructor(
    @Inject(TOKEN_ANC_BLOCK) display: ComponentDisplay,
    private readonly manager: ManagerPaEditionAnnonceService,
    { nativeElement }: ElementRef<HTMLElement>,
  ) { super(display, nativeElement); }

  ngOnInit(): void {
    this.options.forEach((value) => {
      // console.log(v);
      const control: FormControl | undefined = (this.manager.controls as any)[value];
      if (control) this.formActivities[value] = control;
    })    
  }

}
