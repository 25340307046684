import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProcedureService } from '@atline/core/services/procedure.service';
import { UtilsService } from '@atline/core/services/utils.service';
import * as _ from 'lodash';
import { TypeMarcheEnum } from '@atline-shared/enums/typeMarche.enum';
import { ProceduresListRequest } from '@atline/core/models/api/requests/proceduresList.request';
import { Icons } from '@atline-shared/enums/icons.enum';
import { AssignProcedureRequest } from '@atline/core/models/api/requests/AssignProcedureRequest';
import { ProcedureItem } from '@atline/core/models/procedureItem.model';
import { DeleteProcedureRequest } from '@atline/core/models/api/requests/DeleteProcedureRequest';
import { ConsultationFinaliteMarche } from '@atline-shared/enums/consultation.enum';
@Component({
  selector: 'app-procedure',
  templateUrl: './procedure.component.html',
  styleUrls: ['./procedure.component.scss']
})
export class ProcedureComponent implements OnInit {

  readonly types: { value: string, display: string }[] = [
    { value: TypeMarcheEnum.PUBLIC, display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.GROUPS.TYPES.PUBLIC' },
    { value: TypeMarcheEnum.ORDONNANCE, display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.GROUPS.TYPES.ORDONNANCE' },
    { value: TypeMarcheEnum.PRIVE, display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.GROUPS.TYPES.PRIVE' }
  ];
  public empty: boolean = false; 
  public procedureForm: FormGroup;
  public proceduresList: any;
  cleEtab!: string;
  public showProcedures: boolean = true;
  readonly finalites: { value: string, display: string }[] = [
    { value: ConsultationFinaliteMarche.MARCHE, display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.GROUPS.FINALITES.MARCHE' },
    { value: ConsultationFinaliteMarche.CONCESSION, display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.GROUPS.FINALITES.DSP' },
    { value: ConsultationFinaliteMarche.AUTRE, display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.GROUPS.FINALITES.AUTRES' }
  ];
  
  icons = {
    plus: Icons.Plus,
    check: Icons.Check
  };

  constructor(
    private fb: FormBuilder,
    private readonly utils: UtilsService,
    private readonly procedureService: ProcedureService,
    // @Optional() public usersList: UsersListComponent,
    // @Optional() public GroupsList: GroupsListComponent
  ) {
    this.procedureForm = this.fb.group({
      type: [this.types[0].value, [Validators.required]],
      finalite: [this.finalites[0].value, [Validators.required]],
    });
  }

  ngOnInit(): void {

    // if (this.usersList) {
    //   this.finalites.push({ value: ConsultationFinaliteMarche.SEM, display: 'APP.MARCHES_SECURISES.PA.ADMINISTRATION.USER.FINALITES.SEM' });
    // }
    if (this.procedureForm?.get('finalite')?.value && this.procedureForm?.get('type')?.value) {
      this.procedures(); 
    }
    
  }

  procedures(): void {
    this.empty = false;
    this.cleEtab = this.utils.cleEtab || '';
    const procListRequest = new ProceduresListRequest();
    procListRequest.cle_etab = this.cleEtab;
    procListRequest.type_pa = this.procedureForm?.get('type')?.value;
    procListRequest.finalite = this.procedureForm?.get('finalite')?.value;

    // if (this.GroupsList){ 
    //   procListRequest.cle_group = this.GroupsList.group.cle_gr;
      
    // }
    // if (this.usersList) {
    //   procListRequest.cle_utilisateur = this.usersList.user.idIdent;
      
    // }

    this.procedureService.getProcedures(procListRequest)
    .subscribe(
      {
        next: (res) => {
          this.proceduresList = res.procedures;
            if(_.isEmpty(this.proceduresList)) {
              this.empty = true;
            }
        },
      });
  }

  addAllProc(): void {
    this.cleEtab = this.utils.cleEtab || '';
    const procAddRequest = new AssignProcedureRequest();
    procAddRequest.cle_etab = this.cleEtab;
    procAddRequest.type_pa = this.procedureForm?.get('type')?.value;
    procAddRequest.finalite = this.procedureForm?.get('finalite')?.value;

    // if (this.GroupsList){ 
    //   procAddRequest.cle_group = this.GroupsList.group.cle_gr;
      
    // }
    // if (this.usersList) {
    //   procAddRequest.cle_utilisateur = this.usersList.user.idIdent;
    // }

    this.procedureService.addProcedures(procAddRequest)
    .subscribe(() => {
        this.proceduresList.forEach((item: { autorise: boolean; }) => {
          item.autorise = true;
        });
      }
    );
  }

  deleteAllProc(): void {
    this.cleEtab = this.utils.cleEtab || '';
    const procDeleteRequest = new DeleteProcedureRequest();
    procDeleteRequest.cle_etab = this.cleEtab;

    // if (this.GroupsList){ 
    //   procDeleteRequest.cle_group = this.GroupsList.group.cle_gr;
    // }

    // if (this.usersList) {
    //   procDeleteRequest.cle_utilisateur = this.usersList.user.idIdent;
    // }

    this.procedureService.deleteProcedures(procDeleteRequest)
    .subscribe(() => {
        this.proceduresList.forEach((item: { autorise: boolean; }) => {
          item.autorise = false;
        });
    });
   
  }

  checkProc(procedure: ProcedureItem): void {
    this.cleEtab = this.utils.cleEtab || '';

    if(!procedure.autorise) {
      const procRequest = new AssignProcedureRequest();
      procRequest.cle_etab = this.cleEtab;
      procRequest.type_pa = this.procedureForm?.get('type')?.value;
      procRequest.finalite = this.procedureForm?.get('finalite')?.value;
      procRequest.id_passation = procedure.code_precedure;
      // if (this.GroupsList){ 
      //   procRequest.cle_group = this.GroupsList.group.cle_gr;
        
      // }
      // if (this.usersList) {
      //   procRequest.cle_utilisateur = this.usersList.user.idIdent;
      // }

      this.procedureService.addProcedures(procRequest)
      .subscribe(() => {
            procedure.autorise = true;
          }
      );
    }

    else if(procedure.autorise) {
      const procRequest = new DeleteProcedureRequest();
      procRequest.cle_etab = this.cleEtab;
      procRequest.linkId = procedure.linkId;

      // if (this.GroupsList){ 
      //   procRequest.cle_group = this.GroupsList.group.cle_gr;
        
      // }
      // if (this.usersList) {
      //   procRequest.cle_utilisateur = this.usersList.user.idIdent;
      // }
      this.procedureService.deleteProcedures(procRequest)
      .subscribe(() => {
            procedure.autorise = false;
          }
      );
    }
    
  }
}
