import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { TOKEN_ANC_BLOCK } from '@atline/core/models/pa/annonce/anc-token-inject.model';
import { ComponentDisplay } from '@atline/core/models/pa/annonce/form-display.model';
import { ManagerPaEditionAnnonceService } from '../../manager-pa-edition-annonce.service';
import { AbstractAncComponent } from '../abstract-anc-component';
import * as _ from 'lodash';

@Component({
  selector: 'anc-description-procedure',
  templateUrl: './anc-description-procedure.component.html',
  styleUrls: ['./anc-description-procedure.component.scss']
})
export class AncDescriptionProcedureComponent extends AbstractAncComponent implements OnInit {

  readonly identifiantInterneFC = this.manager.controls.identifiantInterne;
  readonly titreFC = this.manager.controls.titre_marche;
  readonly natureContractFC = this.manager.controls.nature_marche;
  readonly additionalNatureContractFC = this.manager.controls.nature_marche_additionnel;

  readonly maxLengthDescription = 6000;
  readonly naturesContract = ['TVX', 'SVC', 'FNT'] as const;

  isEform = this.manager.isEforms;

  constructor(
    @Inject(TOKEN_ANC_BLOCK) display: ComponentDisplay,
    private readonly manager: ManagerPaEditionAnnonceService,
    { nativeElement }: ElementRef<HTMLElement>,
  ) { super(display, nativeElement); }

  ngOnInit(): void {

    console.log(this.display);
    this.identifiantInterneFC?.setValidators([this.createValidatorRequired('identifiantInterne')]);
    this.titreFC?.setValidators([this.createValidatorRequired('titre'), Validators.maxLength(400)]);
    this.natureContractFC?.setValidators([this.createValidatorRequired('natureContract')]);
    this.additionalNatureContractFC?.setValidators([this.createValidatorRequired('additionalNatureContract')]);
    this.natureContractFC?.setValue(this.natureContractFC.value || null);
    this.additionalNatureContractFC?.setValue(this.additionalNatureContractFC.value || null);
  }

  get descriptionLabel(): string {
    if (_.isNil(this.display.labels))
      return 'DESCRIPT';
    return this.display.labels.description;
  }

}
