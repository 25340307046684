<div class="anc-component">
  <app-field-edition *ngIf="composition?.intitule && object"
    [label]="'APP.ANNONCES.MARKET_SPACE.OBJECT' | translate"
    [required]="isRequired(required?.intitule)"
    [invalid]="false">
    <span sub-label class="sub-label">BT-24-Procedure</span>
    <span sub-label class="text-it">{{'APP.ANNONCES.MARKET_SPACE.INFO.OBJECT' | translate}}</span>
    <div class="textarea" fxLayout="row">
      <mat-form-field appearance="outline" fxFlex="90">
          <textarea matInput
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="5"
            cdkAutosizeMaxRows="10"
            [formControl]="object"
            class="text-area-not-rised">
          </textarea>
      </mat-form-field>
    </div>
  </app-field-edition>
</div>
