import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { ChorusResponse } from '../models/api/responses/chorus.response';

@Injectable({
  providedIn: 'root'
})
export class ChorusService {

  constructor(readonly api: ApiService) { }

  public isChorusUser(siret: string): Observable<ChorusResponse> {
    return this.api.post(ChorusResponse, { siret }, 'seal/is_chorus_user');
  }

}
